// source: proto/taxfilingpb/form1099_misc.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_type_date_pb = require('../../google/type/date_pb.js');
goog.object.extend(proto, google_type_date_pb);
var proto_commonpb_file_pb = require('../../proto/commonpb/file_pb.js');
goog.object.extend(proto, proto_commonpb_file_pb);
var proto_utilspb_pagination_pb = require('../../proto/utilspb/pagination_pb.js');
goog.object.extend(proto, proto_utilspb_pagination_pb);
goog.exportSymbol('proto.taxfilingpb.DownLoadMiscRequest', null, global);
goog.exportSymbol('proto.taxfilingpb.Form1099Misc', null, global);
goog.exportSymbol('proto.taxfilingpb.Form1099MiscDetails', null, global);
goog.exportSymbol('proto.taxfilingpb.Form1099MiscDetailsResponse', null, global);
goog.exportSymbol('proto.taxfilingpb.Form1099MiscProcessRequest', null, global);
goog.exportSymbol('proto.taxfilingpb.Form1099MiscProcessResponse', null, global);
goog.exportSymbol('proto.taxfilingpb.Form1099MiscRequest', null, global);
goog.exportSymbol('proto.taxfilingpb.GenerateMiscResponse', null, global);
goog.exportSymbol('proto.taxfilingpb.ListForm1099MiscDetailsSummary', null, global);
goog.exportSymbol('proto.taxfilingpb.ListForm1099MiscResponse', null, global);
goog.exportSymbol('proto.taxfilingpb.ListForm1099MiscSummary', null, global);
goog.exportSymbol('proto.taxfilingpb.UpdateMiscResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.Form1099MiscRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.taxfilingpb.Form1099MiscRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.Form1099MiscRequest.displayName = 'proto.taxfilingpb.Form1099MiscRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.Form1099Misc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.taxfilingpb.Form1099Misc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.Form1099Misc.displayName = 'proto.taxfilingpb.Form1099Misc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.Form1099MiscDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.taxfilingpb.Form1099MiscDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.Form1099MiscDetails.displayName = 'proto.taxfilingpb.Form1099MiscDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.ListForm1099MiscResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.taxfilingpb.ListForm1099MiscResponse.repeatedFields_, null);
};
goog.inherits(proto.taxfilingpb.ListForm1099MiscResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.ListForm1099MiscResponse.displayName = 'proto.taxfilingpb.ListForm1099MiscResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.ListForm1099MiscSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.taxfilingpb.ListForm1099MiscSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.ListForm1099MiscSummary.displayName = 'proto.taxfilingpb.ListForm1099MiscSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.Form1099MiscDetailsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.taxfilingpb.Form1099MiscDetailsResponse.repeatedFields_, null);
};
goog.inherits(proto.taxfilingpb.Form1099MiscDetailsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.Form1099MiscDetailsResponse.displayName = 'proto.taxfilingpb.Form1099MiscDetailsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.ListForm1099MiscDetailsSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.taxfilingpb.ListForm1099MiscDetailsSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.ListForm1099MiscDetailsSummary.displayName = 'proto.taxfilingpb.ListForm1099MiscDetailsSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.Form1099MiscProcessRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.taxfilingpb.Form1099MiscProcessRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.Form1099MiscProcessRequest.displayName = 'proto.taxfilingpb.Form1099MiscProcessRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.Form1099MiscProcessResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.taxfilingpb.Form1099MiscProcessResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.Form1099MiscProcessResponse.displayName = 'proto.taxfilingpb.Form1099MiscProcessResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.DownLoadMiscRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.taxfilingpb.DownLoadMiscRequest.repeatedFields_, null);
};
goog.inherits(proto.taxfilingpb.DownLoadMiscRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.DownLoadMiscRequest.displayName = 'proto.taxfilingpb.DownLoadMiscRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.GenerateMiscResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.taxfilingpb.GenerateMiscResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.GenerateMiscResponse.displayName = 'proto.taxfilingpb.GenerateMiscResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.UpdateMiscResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.taxfilingpb.UpdateMiscResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.UpdateMiscResponse.displayName = 'proto.taxfilingpb.UpdateMiscResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.Form1099MiscRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.Form1099MiscRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.Form1099MiscRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.Form1099MiscRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    year: jspb.Message.getFieldWithDefault(msg, 1, ""),
    correspondent: jspb.Message.getFieldWithDefault(msg, 2, ""),
    branch: jspb.Message.getFieldWithDefault(msg, 3, ""),
    accountNo: jspb.Message.getFieldWithDefault(msg, 4, ""),
    accountId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    pagination: (f = msg.getPagination()) && proto_utilspb_pagination_pb.Pagination.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.Form1099MiscRequest}
 */
proto.taxfilingpb.Form1099MiscRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.Form1099MiscRequest;
  return proto.taxfilingpb.Form1099MiscRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.Form1099MiscRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.Form1099MiscRequest}
 */
proto.taxfilingpb.Form1099MiscRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setYear(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBranch(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAccountId(value);
      break;
    case 6:
      var value = new proto_utilspb_pagination_pb.Pagination;
      reader.readMessage(value,proto_utilspb_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.Form1099MiscRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.Form1099MiscRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.Form1099MiscRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.Form1099MiscRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getYear();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBranch();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_utilspb_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
};


/**
 * optional string year = 1;
 * @return {string}
 */
proto.taxfilingpb.Form1099MiscRequest.prototype.getYear = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099MiscRequest} returns this
 */
proto.taxfilingpb.Form1099MiscRequest.prototype.setYear = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string correspondent = 2;
 * @return {string}
 */
proto.taxfilingpb.Form1099MiscRequest.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099MiscRequest} returns this
 */
proto.taxfilingpb.Form1099MiscRequest.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string branch = 3;
 * @return {string}
 */
proto.taxfilingpb.Form1099MiscRequest.prototype.getBranch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099MiscRequest} returns this
 */
proto.taxfilingpb.Form1099MiscRequest.prototype.setBranch = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string account_no = 4;
 * @return {string}
 */
proto.taxfilingpb.Form1099MiscRequest.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099MiscRequest} returns this
 */
proto.taxfilingpb.Form1099MiscRequest.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 account_id = 5;
 * @return {number}
 */
proto.taxfilingpb.Form1099MiscRequest.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.taxfilingpb.Form1099MiscRequest} returns this
 */
proto.taxfilingpb.Form1099MiscRequest.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional utilspb.Pagination pagination = 6;
 * @return {?proto.utilspb.Pagination}
 */
proto.taxfilingpb.Form1099MiscRequest.prototype.getPagination = function() {
  return /** @type{?proto.utilspb.Pagination} */ (
    jspb.Message.getWrapperField(this, proto_utilspb_pagination_pb.Pagination, 6));
};


/**
 * @param {?proto.utilspb.Pagination|undefined} value
 * @return {!proto.taxfilingpb.Form1099MiscRequest} returns this
*/
proto.taxfilingpb.Form1099MiscRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.taxfilingpb.Form1099MiscRequest} returns this
 */
proto.taxfilingpb.Form1099MiscRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.taxfilingpb.Form1099MiscRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.Form1099Misc.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.Form1099Misc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.Form1099Misc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.Form1099Misc.toObject = function(includeInstance, msg) {
  var f, obj = {
    year: jspb.Message.getFieldWithDefault(msg, 1, ""),
    correspondent: jspb.Message.getFieldWithDefault(msg, 2, ""),
    account: jspb.Message.getFieldWithDefault(msg, 3, ""),
    accountName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    address: jspb.Message.getFieldWithDefault(msg, 5, ""),
    city: jspb.Message.getFieldWithDefault(msg, 6, ""),
    state: jspb.Message.getFieldWithDefault(msg, 7, ""),
    zip: jspb.Message.getFieldWithDefault(msg, 8, ""),
    country: jspb.Message.getFieldWithDefault(msg, 9, ""),
    misc: jspb.Message.getFieldWithDefault(msg, 10, ""),
    branch: jspb.Message.getFieldWithDefault(msg, 12, ""),
    accountNo: jspb.Message.getFieldWithDefault(msg, 13, ""),
    externalId: jspb.Message.getFieldWithDefault(msg, 14, ""),
    accountId: jspb.Message.getFieldWithDefault(msg, 15, 0),
    taxCountry: jspb.Message.getFieldWithDefault(msg, 16, ""),
    legalEntity: jspb.Message.getFieldWithDefault(msg, 17, ""),
    governmentIdType: jspb.Message.getFieldWithDefault(msg, 18, ""),
    form1099miscId: jspb.Message.getFieldWithDefault(msg, 19, 0),
    entryType: jspb.Message.getFieldWithDefault(msg, 20, ""),
    entryTypeDescription: jspb.Message.getFieldWithDefault(msg, 21, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.Form1099Misc}
 */
proto.taxfilingpb.Form1099Misc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.Form1099Misc;
  return proto.taxfilingpb.Form1099Misc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.Form1099Misc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.Form1099Misc}
 */
proto.taxfilingpb.Form1099Misc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setYear(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccount(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setZip(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setMisc(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setBranch(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalId(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAccountId(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaxCountry(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setLegalEntity(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setGovernmentIdType(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setForm1099miscId(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntryType(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntryTypeDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.Form1099Misc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.Form1099Misc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.Form1099Misc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.Form1099Misc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getYear();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccount();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getZip();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getMisc();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getBranch();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getExternalId();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getTaxCountry();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getLegalEntity();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getGovernmentIdType();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getForm1099miscId();
  if (f !== 0) {
    writer.writeUint32(
      19,
      f
    );
  }
  f = message.getEntryType();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getEntryTypeDescription();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
};


/**
 * optional string year = 1;
 * @return {string}
 */
proto.taxfilingpb.Form1099Misc.prototype.getYear = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099Misc} returns this
 */
proto.taxfilingpb.Form1099Misc.prototype.setYear = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string correspondent = 2;
 * @return {string}
 */
proto.taxfilingpb.Form1099Misc.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099Misc} returns this
 */
proto.taxfilingpb.Form1099Misc.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string account = 3;
 * @return {string}
 */
proto.taxfilingpb.Form1099Misc.prototype.getAccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099Misc} returns this
 */
proto.taxfilingpb.Form1099Misc.prototype.setAccount = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string account_name = 4;
 * @return {string}
 */
proto.taxfilingpb.Form1099Misc.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099Misc} returns this
 */
proto.taxfilingpb.Form1099Misc.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string address = 5;
 * @return {string}
 */
proto.taxfilingpb.Form1099Misc.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099Misc} returns this
 */
proto.taxfilingpb.Form1099Misc.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string city = 6;
 * @return {string}
 */
proto.taxfilingpb.Form1099Misc.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099Misc} returns this
 */
proto.taxfilingpb.Form1099Misc.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string state = 7;
 * @return {string}
 */
proto.taxfilingpb.Form1099Misc.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099Misc} returns this
 */
proto.taxfilingpb.Form1099Misc.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string zip = 8;
 * @return {string}
 */
proto.taxfilingpb.Form1099Misc.prototype.getZip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099Misc} returns this
 */
proto.taxfilingpb.Form1099Misc.prototype.setZip = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string country = 9;
 * @return {string}
 */
proto.taxfilingpb.Form1099Misc.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099Misc} returns this
 */
proto.taxfilingpb.Form1099Misc.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string misc = 10;
 * @return {string}
 */
proto.taxfilingpb.Form1099Misc.prototype.getMisc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099Misc} returns this
 */
proto.taxfilingpb.Form1099Misc.prototype.setMisc = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string branch = 12;
 * @return {string}
 */
proto.taxfilingpb.Form1099Misc.prototype.getBranch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099Misc} returns this
 */
proto.taxfilingpb.Form1099Misc.prototype.setBranch = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string account_no = 13;
 * @return {string}
 */
proto.taxfilingpb.Form1099Misc.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099Misc} returns this
 */
proto.taxfilingpb.Form1099Misc.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string external_id = 14;
 * @return {string}
 */
proto.taxfilingpb.Form1099Misc.prototype.getExternalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099Misc} returns this
 */
proto.taxfilingpb.Form1099Misc.prototype.setExternalId = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional int32 account_id = 15;
 * @return {number}
 */
proto.taxfilingpb.Form1099Misc.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.taxfilingpb.Form1099Misc} returns this
 */
proto.taxfilingpb.Form1099Misc.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional string tax_country = 16;
 * @return {string}
 */
proto.taxfilingpb.Form1099Misc.prototype.getTaxCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099Misc} returns this
 */
proto.taxfilingpb.Form1099Misc.prototype.setTaxCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string legal_entity = 17;
 * @return {string}
 */
proto.taxfilingpb.Form1099Misc.prototype.getLegalEntity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099Misc} returns this
 */
proto.taxfilingpb.Form1099Misc.prototype.setLegalEntity = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string government_id_type = 18;
 * @return {string}
 */
proto.taxfilingpb.Form1099Misc.prototype.getGovernmentIdType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099Misc} returns this
 */
proto.taxfilingpb.Form1099Misc.prototype.setGovernmentIdType = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional uint32 form1099misc_id = 19;
 * @return {number}
 */
proto.taxfilingpb.Form1099Misc.prototype.getForm1099miscId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.taxfilingpb.Form1099Misc} returns this
 */
proto.taxfilingpb.Form1099Misc.prototype.setForm1099miscId = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional string entry_type = 20;
 * @return {string}
 */
proto.taxfilingpb.Form1099Misc.prototype.getEntryType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099Misc} returns this
 */
proto.taxfilingpb.Form1099Misc.prototype.setEntryType = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string entry_type_description = 21;
 * @return {string}
 */
proto.taxfilingpb.Form1099Misc.prototype.getEntryTypeDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099Misc} returns this
 */
proto.taxfilingpb.Form1099Misc.prototype.setEntryTypeDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.Form1099MiscDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.Form1099MiscDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.Form1099MiscDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.Form1099MiscDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    trnsId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    year: jspb.Message.getFieldWithDefault(msg, 2, ""),
    settleDate: (f = msg.getSettleDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    misc: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.Form1099MiscDetails}
 */
proto.taxfilingpb.Form1099MiscDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.Form1099MiscDetails;
  return proto.taxfilingpb.Form1099MiscDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.Form1099MiscDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.Form1099MiscDetails}
 */
proto.taxfilingpb.Form1099MiscDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrnsId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setYear(value);
      break;
    case 3:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setSettleDate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMisc(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.Form1099MiscDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.Form1099MiscDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.Form1099MiscDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.Form1099MiscDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrnsId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getYear();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSettleDate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMisc();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string trns_id = 1;
 * @return {string}
 */
proto.taxfilingpb.Form1099MiscDetails.prototype.getTrnsId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099MiscDetails} returns this
 */
proto.taxfilingpb.Form1099MiscDetails.prototype.setTrnsId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string year = 2;
 * @return {string}
 */
proto.taxfilingpb.Form1099MiscDetails.prototype.getYear = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099MiscDetails} returns this
 */
proto.taxfilingpb.Form1099MiscDetails.prototype.setYear = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.type.Date settle_date = 3;
 * @return {?proto.google.type.Date}
 */
proto.taxfilingpb.Form1099MiscDetails.prototype.getSettleDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 3));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.taxfilingpb.Form1099MiscDetails} returns this
*/
proto.taxfilingpb.Form1099MiscDetails.prototype.setSettleDate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.taxfilingpb.Form1099MiscDetails} returns this
 */
proto.taxfilingpb.Form1099MiscDetails.prototype.clearSettleDate = function() {
  return this.setSettleDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.taxfilingpb.Form1099MiscDetails.prototype.hasSettleDate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.taxfilingpb.Form1099MiscDetails.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099MiscDetails} returns this
 */
proto.taxfilingpb.Form1099MiscDetails.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string misc = 5;
 * @return {string}
 */
proto.taxfilingpb.Form1099MiscDetails.prototype.getMisc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099MiscDetails} returns this
 */
proto.taxfilingpb.Form1099MiscDetails.prototype.setMisc = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.taxfilingpb.ListForm1099MiscResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.ListForm1099MiscResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.ListForm1099MiscResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.ListForm1099MiscResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.ListForm1099MiscResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    form1099MiscList: jspb.Message.toObjectList(msg.getForm1099MiscList(),
    proto.taxfilingpb.Form1099Misc.toObject, includeInstance),
    summary: (f = msg.getSummary()) && proto.taxfilingpb.ListForm1099MiscSummary.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.ListForm1099MiscResponse}
 */
proto.taxfilingpb.ListForm1099MiscResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.ListForm1099MiscResponse;
  return proto.taxfilingpb.ListForm1099MiscResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.ListForm1099MiscResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.ListForm1099MiscResponse}
 */
proto.taxfilingpb.ListForm1099MiscResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.taxfilingpb.Form1099Misc;
      reader.readMessage(value,proto.taxfilingpb.Form1099Misc.deserializeBinaryFromReader);
      msg.addForm1099Misc(value);
      break;
    case 2:
      var value = new proto.taxfilingpb.ListForm1099MiscSummary;
      reader.readMessage(value,proto.taxfilingpb.ListForm1099MiscSummary.deserializeBinaryFromReader);
      msg.setSummary(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.ListForm1099MiscResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.ListForm1099MiscResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.ListForm1099MiscResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.ListForm1099MiscResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getForm1099MiscList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.taxfilingpb.Form1099Misc.serializeBinaryToWriter
    );
  }
  f = message.getSummary();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.taxfilingpb.ListForm1099MiscSummary.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Form1099Misc form1099_misc = 1;
 * @return {!Array<!proto.taxfilingpb.Form1099Misc>}
 */
proto.taxfilingpb.ListForm1099MiscResponse.prototype.getForm1099MiscList = function() {
  return /** @type{!Array<!proto.taxfilingpb.Form1099Misc>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.taxfilingpb.Form1099Misc, 1));
};


/**
 * @param {!Array<!proto.taxfilingpb.Form1099Misc>} value
 * @return {!proto.taxfilingpb.ListForm1099MiscResponse} returns this
*/
proto.taxfilingpb.ListForm1099MiscResponse.prototype.setForm1099MiscList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.taxfilingpb.Form1099Misc=} opt_value
 * @param {number=} opt_index
 * @return {!proto.taxfilingpb.Form1099Misc}
 */
proto.taxfilingpb.ListForm1099MiscResponse.prototype.addForm1099Misc = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.taxfilingpb.Form1099Misc, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.taxfilingpb.ListForm1099MiscResponse} returns this
 */
proto.taxfilingpb.ListForm1099MiscResponse.prototype.clearForm1099MiscList = function() {
  return this.setForm1099MiscList([]);
};


/**
 * optional ListForm1099MiscSummary summary = 2;
 * @return {?proto.taxfilingpb.ListForm1099MiscSummary}
 */
proto.taxfilingpb.ListForm1099MiscResponse.prototype.getSummary = function() {
  return /** @type{?proto.taxfilingpb.ListForm1099MiscSummary} */ (
    jspb.Message.getWrapperField(this, proto.taxfilingpb.ListForm1099MiscSummary, 2));
};


/**
 * @param {?proto.taxfilingpb.ListForm1099MiscSummary|undefined} value
 * @return {!proto.taxfilingpb.ListForm1099MiscResponse} returns this
*/
proto.taxfilingpb.ListForm1099MiscResponse.prototype.setSummary = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.taxfilingpb.ListForm1099MiscResponse} returns this
 */
proto.taxfilingpb.ListForm1099MiscResponse.prototype.clearSummary = function() {
  return this.setSummary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.taxfilingpb.ListForm1099MiscResponse.prototype.hasSummary = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.ListForm1099MiscSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.ListForm1099MiscSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.ListForm1099MiscSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.ListForm1099MiscSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalRows: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.ListForm1099MiscSummary}
 */
proto.taxfilingpb.ListForm1099MiscSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.ListForm1099MiscSummary;
  return proto.taxfilingpb.ListForm1099MiscSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.ListForm1099MiscSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.ListForm1099MiscSummary}
 */
proto.taxfilingpb.ListForm1099MiscSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalRows(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.ListForm1099MiscSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.ListForm1099MiscSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.ListForm1099MiscSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.ListForm1099MiscSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalRows();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 total_rows = 1;
 * @return {number}
 */
proto.taxfilingpb.ListForm1099MiscSummary.prototype.getTotalRows = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.taxfilingpb.ListForm1099MiscSummary} returns this
 */
proto.taxfilingpb.ListForm1099MiscSummary.prototype.setTotalRows = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.taxfilingpb.Form1099MiscDetailsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.Form1099MiscDetailsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.Form1099MiscDetailsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.Form1099MiscDetailsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.Form1099MiscDetailsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    form1099MiscDetailsList: jspb.Message.toObjectList(msg.getForm1099MiscDetailsList(),
    proto.taxfilingpb.Form1099MiscDetails.toObject, includeInstance),
    summary: (f = msg.getSummary()) && proto.taxfilingpb.ListForm1099MiscDetailsSummary.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.Form1099MiscDetailsResponse}
 */
proto.taxfilingpb.Form1099MiscDetailsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.Form1099MiscDetailsResponse;
  return proto.taxfilingpb.Form1099MiscDetailsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.Form1099MiscDetailsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.Form1099MiscDetailsResponse}
 */
proto.taxfilingpb.Form1099MiscDetailsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.taxfilingpb.Form1099MiscDetails;
      reader.readMessage(value,proto.taxfilingpb.Form1099MiscDetails.deserializeBinaryFromReader);
      msg.addForm1099MiscDetails(value);
      break;
    case 2:
      var value = new proto.taxfilingpb.ListForm1099MiscDetailsSummary;
      reader.readMessage(value,proto.taxfilingpb.ListForm1099MiscDetailsSummary.deserializeBinaryFromReader);
      msg.setSummary(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.Form1099MiscDetailsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.Form1099MiscDetailsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.Form1099MiscDetailsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.Form1099MiscDetailsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getForm1099MiscDetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.taxfilingpb.Form1099MiscDetails.serializeBinaryToWriter
    );
  }
  f = message.getSummary();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.taxfilingpb.ListForm1099MiscDetailsSummary.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Form1099MiscDetails form1099_misc_details = 1;
 * @return {!Array<!proto.taxfilingpb.Form1099MiscDetails>}
 */
proto.taxfilingpb.Form1099MiscDetailsResponse.prototype.getForm1099MiscDetailsList = function() {
  return /** @type{!Array<!proto.taxfilingpb.Form1099MiscDetails>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.taxfilingpb.Form1099MiscDetails, 1));
};


/**
 * @param {!Array<!proto.taxfilingpb.Form1099MiscDetails>} value
 * @return {!proto.taxfilingpb.Form1099MiscDetailsResponse} returns this
*/
proto.taxfilingpb.Form1099MiscDetailsResponse.prototype.setForm1099MiscDetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.taxfilingpb.Form1099MiscDetails=} opt_value
 * @param {number=} opt_index
 * @return {!proto.taxfilingpb.Form1099MiscDetails}
 */
proto.taxfilingpb.Form1099MiscDetailsResponse.prototype.addForm1099MiscDetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.taxfilingpb.Form1099MiscDetails, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.taxfilingpb.Form1099MiscDetailsResponse} returns this
 */
proto.taxfilingpb.Form1099MiscDetailsResponse.prototype.clearForm1099MiscDetailsList = function() {
  return this.setForm1099MiscDetailsList([]);
};


/**
 * optional ListForm1099MiscDetailsSummary summary = 2;
 * @return {?proto.taxfilingpb.ListForm1099MiscDetailsSummary}
 */
proto.taxfilingpb.Form1099MiscDetailsResponse.prototype.getSummary = function() {
  return /** @type{?proto.taxfilingpb.ListForm1099MiscDetailsSummary} */ (
    jspb.Message.getWrapperField(this, proto.taxfilingpb.ListForm1099MiscDetailsSummary, 2));
};


/**
 * @param {?proto.taxfilingpb.ListForm1099MiscDetailsSummary|undefined} value
 * @return {!proto.taxfilingpb.Form1099MiscDetailsResponse} returns this
*/
proto.taxfilingpb.Form1099MiscDetailsResponse.prototype.setSummary = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.taxfilingpb.Form1099MiscDetailsResponse} returns this
 */
proto.taxfilingpb.Form1099MiscDetailsResponse.prototype.clearSummary = function() {
  return this.setSummary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.taxfilingpb.Form1099MiscDetailsResponse.prototype.hasSummary = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.ListForm1099MiscDetailsSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.ListForm1099MiscDetailsSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.ListForm1099MiscDetailsSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.ListForm1099MiscDetailsSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalRows: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.ListForm1099MiscDetailsSummary}
 */
proto.taxfilingpb.ListForm1099MiscDetailsSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.ListForm1099MiscDetailsSummary;
  return proto.taxfilingpb.ListForm1099MiscDetailsSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.ListForm1099MiscDetailsSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.ListForm1099MiscDetailsSummary}
 */
proto.taxfilingpb.ListForm1099MiscDetailsSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalRows(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.ListForm1099MiscDetailsSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.ListForm1099MiscDetailsSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.ListForm1099MiscDetailsSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.ListForm1099MiscDetailsSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalRows();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 total_rows = 1;
 * @return {number}
 */
proto.taxfilingpb.ListForm1099MiscDetailsSummary.prototype.getTotalRows = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.taxfilingpb.ListForm1099MiscDetailsSummary} returns this
 */
proto.taxfilingpb.ListForm1099MiscDetailsSummary.prototype.setTotalRows = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.Form1099MiscProcessRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.Form1099MiscProcessRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.Form1099MiscProcessRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.Form1099MiscProcessRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    year: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.Form1099MiscProcessRequest}
 */
proto.taxfilingpb.Form1099MiscProcessRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.Form1099MiscProcessRequest;
  return proto.taxfilingpb.Form1099MiscProcessRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.Form1099MiscProcessRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.Form1099MiscProcessRequest}
 */
proto.taxfilingpb.Form1099MiscProcessRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setYear(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.Form1099MiscProcessRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.Form1099MiscProcessRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.Form1099MiscProcessRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.Form1099MiscProcessRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getYear();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string year = 1;
 * @return {string}
 */
proto.taxfilingpb.Form1099MiscProcessRequest.prototype.getYear = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099MiscProcessRequest} returns this
 */
proto.taxfilingpb.Form1099MiscProcessRequest.prototype.setYear = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.Form1099MiscProcessResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.Form1099MiscProcessResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.Form1099MiscProcessResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.Form1099MiscProcessResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.Form1099MiscProcessResponse}
 */
proto.taxfilingpb.Form1099MiscProcessResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.Form1099MiscProcessResponse;
  return proto.taxfilingpb.Form1099MiscProcessResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.Form1099MiscProcessResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.Form1099MiscProcessResponse}
 */
proto.taxfilingpb.Form1099MiscProcessResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.Form1099MiscProcessResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.Form1099MiscProcessResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.Form1099MiscProcessResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.Form1099MiscProcessResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.taxfilingpb.Form1099MiscProcessResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.taxfilingpb.Form1099MiscProcessResponse} returns this
 */
proto.taxfilingpb.Form1099MiscProcessResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.taxfilingpb.DownLoadMiscRequest.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.DownLoadMiscRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.DownLoadMiscRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.DownLoadMiscRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.DownLoadMiscRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    form1099MiscRequest: (f = msg.getForm1099MiscRequest()) && proto.taxfilingpb.Form1099MiscRequest.toObject(includeInstance, f),
    fileType: jspb.Message.getFieldWithDefault(msg, 2, ""),
    downloadType: jspb.Message.getFieldWithDefault(msg, 3, ""),
    batchNo: jspb.Message.getFieldWithDefault(msg, 4, ""),
    saveToExternal: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    accountIdList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.DownLoadMiscRequest}
 */
proto.taxfilingpb.DownLoadMiscRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.DownLoadMiscRequest;
  return proto.taxfilingpb.DownLoadMiscRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.DownLoadMiscRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.DownLoadMiscRequest}
 */
proto.taxfilingpb.DownLoadMiscRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.taxfilingpb.Form1099MiscRequest;
      reader.readMessage(value,proto.taxfilingpb.Form1099MiscRequest.deserializeBinaryFromReader);
      msg.setForm1099MiscRequest(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDownloadType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBatchNo(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSaveToExternal(value);
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAccountId(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.DownLoadMiscRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.DownLoadMiscRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.DownLoadMiscRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.DownLoadMiscRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getForm1099MiscRequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.taxfilingpb.Form1099MiscRequest.serializeBinaryToWriter
    );
  }
  f = message.getFileType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDownloadType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBatchNo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSaveToExternal();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getAccountIdList();
  if (f.length > 0) {
    writer.writePackedInt32(
      6,
      f
    );
  }
};


/**
 * optional Form1099MiscRequest form1099_misc_request = 1;
 * @return {?proto.taxfilingpb.Form1099MiscRequest}
 */
proto.taxfilingpb.DownLoadMiscRequest.prototype.getForm1099MiscRequest = function() {
  return /** @type{?proto.taxfilingpb.Form1099MiscRequest} */ (
    jspb.Message.getWrapperField(this, proto.taxfilingpb.Form1099MiscRequest, 1));
};


/**
 * @param {?proto.taxfilingpb.Form1099MiscRequest|undefined} value
 * @return {!proto.taxfilingpb.DownLoadMiscRequest} returns this
*/
proto.taxfilingpb.DownLoadMiscRequest.prototype.setForm1099MiscRequest = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.taxfilingpb.DownLoadMiscRequest} returns this
 */
proto.taxfilingpb.DownLoadMiscRequest.prototype.clearForm1099MiscRequest = function() {
  return this.setForm1099MiscRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.taxfilingpb.DownLoadMiscRequest.prototype.hasForm1099MiscRequest = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string file_type = 2;
 * @return {string}
 */
proto.taxfilingpb.DownLoadMiscRequest.prototype.getFileType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.DownLoadMiscRequest} returns this
 */
proto.taxfilingpb.DownLoadMiscRequest.prototype.setFileType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string download_type = 3;
 * @return {string}
 */
proto.taxfilingpb.DownLoadMiscRequest.prototype.getDownloadType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.DownLoadMiscRequest} returns this
 */
proto.taxfilingpb.DownLoadMiscRequest.prototype.setDownloadType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string batch_no = 4;
 * @return {string}
 */
proto.taxfilingpb.DownLoadMiscRequest.prototype.getBatchNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.DownLoadMiscRequest} returns this
 */
proto.taxfilingpb.DownLoadMiscRequest.prototype.setBatchNo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool save_to_external = 5;
 * @return {boolean}
 */
proto.taxfilingpb.DownLoadMiscRequest.prototype.getSaveToExternal = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.taxfilingpb.DownLoadMiscRequest} returns this
 */
proto.taxfilingpb.DownLoadMiscRequest.prototype.setSaveToExternal = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * repeated int32 account_id = 6;
 * @return {!Array<number>}
 */
proto.taxfilingpb.DownLoadMiscRequest.prototype.getAccountIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.taxfilingpb.DownLoadMiscRequest} returns this
 */
proto.taxfilingpb.DownLoadMiscRequest.prototype.setAccountIdList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.taxfilingpb.DownLoadMiscRequest} returns this
 */
proto.taxfilingpb.DownLoadMiscRequest.prototype.addAccountId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.taxfilingpb.DownLoadMiscRequest} returns this
 */
proto.taxfilingpb.DownLoadMiscRequest.prototype.clearAccountIdList = function() {
  return this.setAccountIdList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.GenerateMiscResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.GenerateMiscResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.GenerateMiscResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.GenerateMiscResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.GenerateMiscResponse}
 */
proto.taxfilingpb.GenerateMiscResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.GenerateMiscResponse;
  return proto.taxfilingpb.GenerateMiscResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.GenerateMiscResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.GenerateMiscResponse}
 */
proto.taxfilingpb.GenerateMiscResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.GenerateMiscResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.GenerateMiscResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.GenerateMiscResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.GenerateMiscResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.taxfilingpb.GenerateMiscResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.taxfilingpb.GenerateMiscResponse} returns this
 */
proto.taxfilingpb.GenerateMiscResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.UpdateMiscResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.UpdateMiscResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.UpdateMiscResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.UpdateMiscResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    formMisc: (f = msg.getFormMisc()) && proto.taxfilingpb.Form1099Misc.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.UpdateMiscResponse}
 */
proto.taxfilingpb.UpdateMiscResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.UpdateMiscResponse;
  return proto.taxfilingpb.UpdateMiscResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.UpdateMiscResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.UpdateMiscResponse}
 */
proto.taxfilingpb.UpdateMiscResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.taxfilingpb.Form1099Misc;
      reader.readMessage(value,proto.taxfilingpb.Form1099Misc.deserializeBinaryFromReader);
      msg.setFormMisc(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.UpdateMiscResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.UpdateMiscResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.UpdateMiscResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.UpdateMiscResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFormMisc();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.taxfilingpb.Form1099Misc.serializeBinaryToWriter
    );
  }
};


/**
 * optional Form1099Misc form_misc = 1;
 * @return {?proto.taxfilingpb.Form1099Misc}
 */
proto.taxfilingpb.UpdateMiscResponse.prototype.getFormMisc = function() {
  return /** @type{?proto.taxfilingpb.Form1099Misc} */ (
    jspb.Message.getWrapperField(this, proto.taxfilingpb.Form1099Misc, 1));
};


/**
 * @param {?proto.taxfilingpb.Form1099Misc|undefined} value
 * @return {!proto.taxfilingpb.UpdateMiscResponse} returns this
*/
proto.taxfilingpb.UpdateMiscResponse.prototype.setFormMisc = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.taxfilingpb.UpdateMiscResponse} returns this
 */
proto.taxfilingpb.UpdateMiscResponse.prototype.clearFormMisc = function() {
  return this.setFormMisc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.taxfilingpb.UpdateMiscResponse.prototype.hasFormMisc = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.taxfilingpb);
