/*eslint eqeqeq: "off"*/
import React, { useState, useEffect } from 'react';
import Form1099MiscDetailsModal from './Form1099MiscDetailsModal';
import {
  notifyInfo,
  notifyError,
  notifySuccess,
} from 'components/Notification/Notification';
import {
  listForm1099Misc,
  downloadMisc,
  generateMisc,
  updateMisc,
} from '../../../services/Form1099MiscService';
import { Box, TextField, IconButton, Tooltip } from '@material-ui/core';
import { Visibility as ViewIcon, Create as EditIcon } from '@material-ui/icons';

import Table, { columnType } from 'components/Table/Table';

import useStyles from '../../../styles';
import SearchButton from '../../../components/Button/Search';
import SelectCorrespondent from '../../../components/AutoComplete/SelectCorrespondent';
import SelectAccountNo from '../../../components/AutoComplete/SelectAccountNo';
import QueryParam from '../../../services/QueryParamService';
import DownloadProgress from 'components/Progress/DownloadProgress';
import { useConfirm } from 'material-ui-confirm';
import { getSystemDate } from '../../../services/ProfileService';
import Form1099MiscModal from './Form1099MiscModal';

export default function Form1099MiscTable() {
  const classes = useStyles();
  const [pagination, setPagination] = useState({});
  const [previousRows, setPreviousRows] = useState(0);
  const [searchDataCsv, setSearchDataCsv] = React.useState([]);
  const confirm = useConfirm();
  const [openEdit, setOpenEdit] = React.useState(false);
  const [rowData, setRowData] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(true);
  const [rows, setRows] = useState([]);
  const [searchData, setSearchData] = React.useState(
    QueryParam.get({
      year: '',
      correspondent: '',
      branch: '',
      accountNo: '',
    })
  );

  useEffect(() => {
    async function init() {
      const systemDate = await getSystemDate();
      const date = new Date(systemDate);
      const searchDataCopy = { ...searchData };

      if (!searchData.year) {
        searchDataCopy.year = date.getFullYear().toString();
      }

      setSearchData(searchDataCopy);
    }

    init();
  }, []);

  let columns = [
    {
      name: '',
      type: columnType.buttons,
      options: {
        customBodyRenderLite: (dataIndex) => {
          return (
            <div
              align={'left'}
              className={classes.grdRow}
              style={{ margin: 0, padding: 0 }}
            >
              <Tooltip title="Download PDF" arrow>
                <div>
                  <DownloadProgress
                    selected={rows[dataIndex]}
                    fileType="pdf"
                    rows={rows}
                    download={downloadMisc}
                    generate={generateMisc}
                    useSystemUpdateIcon={false}
                  />
                </div>
              </Tooltip>
              <Tooltip title="Download CSV" arrow>
                <div>
                  <DownloadProgress
                    selected={rows[dataIndex]}
                    fileType="csv"
                    rows={rows}
                    download={downloadMisc}
                    generate={generateMisc}
                    useSystemUpdateIcon={true}
                  />
                </div>
              </Tooltip>
              <div align={'left'} className={classes.grdCellNone}>
                <IconButton
                  aria-label="view"
                  onClick={() => {
                    setRowData(rows[dataIndex]);
                    setOpen(true);
                  }}
                >
                  <ViewIcon />
                </IconButton>
              </div>
              <div align={'left'} className={classes.grdCellNone}>
                <IconButton
                  aria-label="edit"
                  onClick={() => handleOpen(rows[dataIndex])}
                >
                  <EditIcon />
                </IconButton>
              </div>
            </div>
          );
        },
        setCellProps: () => ({
          style: { padding: '4px 16px' },
        }),
      },
    },
    {
      name: 'year',
      label: 'Year',
    },
    {
      name: 'correspondent',
      label: 'Correspondent',
    },
    {
      name: 'branch',
      label: 'Branch',
    },
    {
      name: 'accountId',
      label: 'Account ID',
      options: {
        display: false,
      },
    },
    {
      name: 'accountNo',
      label: 'Account No',
    },
    {
      name: 'taxCountry',
      label: 'Tax Country',
    },
    {
      name: 'misc',
      label: 'Miscellaneous',
      type: columnType.amount,
      addFooter: true,
    },
    {
      name: 'accountName',
      label: 'Account Name',
      options: {
        display: false,
      },
    },
    {
      name: 'address',
      label: 'Address',
      options: {
        display: false,
      },
    },
    {
      name: 'city',
      label: 'City',
      options: {
        display: false,
      },
    },
    {
      name: 'state',
      label: 'State',
      options: {
        display: false,
      },
    },
    {
      name: 'zip',
      label: 'Zip',
      options: {
        display: false,
      },
    },
    {
      name: 'country',
      label: 'Country',
      options: {
        display: false,
      },
    },
  ];

  const options = {
    // serverSide: true,
    customToolbarSelect: (selectedRows) => {
      return (
        <div
          className={classes.grdRow}
          style={{ margin: 0, padding: 0, marginRight: 20 }}
        >
          <div className={classes.grdCell1}>
            <Tooltip title="Download PDF" arrow>
              <div>
                <DownloadProgress
                  selected={selectedRows.data}
                  fileType="pdf"
                  rows={rows}
                  download={downloadMisc}
                  generate={generateMisc}
                  useSystemUpdateIcon={false}
                  downloadType="multiple"
                />
              </div>
            </Tooltip>
          </div>
          <div className={classes.grdCell1}>
            <Tooltip title="Download CSV" arrow>
              <div>
                <DownloadProgress
                  selected={selectedRows.data}
                  fileType="csv"
                  rows={rows}
                  download={downloadMisc}
                  generate={generateMisc}
                  useSystemUpdateIcon={true}
                  downloadType="multiple"
                />
              </div>
            </Tooltip>
          </div>
        </div>
      );
    },
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;

    setSearchData({
      ...searchData,
      [input.name]: input.value,
    });
  };

  const handleSearch = async (paginationChange) => {
    try {
      setLoading(true);
      QueryParam.set(searchData);

      let paginationCopy = {
        ...pagination,
        reload: false,
        pageNo: paginationChange === true ? pagination.pageNo : 0,
        rowsPerPage: pagination.rowsPerPage || 100,
      };
      if (previousRows < pagination.rowsPerPage * pagination.pageNo) {
        paginationCopy.pageNo = 0;
      }
      if (paginationChange !== true) {
        paginationCopy.filterNames = [];
        paginationCopy.filterValues = [];
      }
      const data = await listForm1099Misc(searchData, paginationCopy);
      setSearchDataCsv(searchData);
      paginationCopy.count = data.summary.totalRows;
      setPreviousRows(data.summary.totalRows);
      setPagination(paginationCopy);
      setRows(data.form1099MiscList);

      notifyInfo(data.form1099MiscList.length + ' search results.');

      // if (paginationChange !== true) {
      //   notifyInfo(data.summary.totalRows + ' search results.');
      // }
    } catch (error) {
      notifyError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const getCsvData = async () => {
    const MAX_RECORDS_ALLOWED = 100000;

    let paginationCopy = {
      ...pagination,
      reload: false,
      pageNo: 0,
      rowsPerPage: MAX_RECORDS_ALLOWED,
    };

    const data = await listForm1099Misc(searchDataCsv, paginationCopy);

    if (data.form1099MiscList.length >= MAX_RECORDS_ALLOWED) {
      confirm({
        description:
          'Only ' +
          MAX_RECORDS_ALLOWED.toLocaleString() +
          ' items will be downloaded, if you need more please use the metabase.',
        confirmationText: 'I understand',
      });
    }

    return data.form1099MiscList;
  };

  const handleCloseUpdate = async (data) => {
    if (!data) {
      setOpenEdit(false);
      return;
    }

    let valid = true;
    if (!valid) {
      return;
    }

    try {
      const resp = await updateMisc(data);
      const rowsCopy = [...rows];
      const index = rows.indexOf(rowData);
      rowsCopy[index] = resp.formMisc;
      setRows(rowsCopy);
      notifySuccess('1099 Misc has been updated.');

      setOpenEdit(false);
    } catch (error) {
      notifyError(error);
    }
  };

  const handleOpen = (data) => {
    setRowData(data);
    setOpenEdit(true);
  };

  return (
    <div className={classes.root}>
      <Box component="div" mt={5}>
        {showFilter ? (
          <div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  name="year"
                  label="Year"
                  type="number"
                  inputProps={{
                    min: 2000,
                    max: 3000,
                    maxLength: 4,
                    minLength: 4,
                  }}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={searchData.year}
                  onChange={handleChange}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <SelectCorrespondent
                  required={false}
                  name="correspondent"
                  label="Correspondent"
                  value={searchData.correspondent}
                  onChange={handleChange}
                ></SelectCorrespondent>
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  name="branch"
                  fullWidth
                  label="Branch"
                  value={searchData.branch}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ maxLength: 3 }}
                />
              </div>
              <div className={classes.grdCell1}>
                <SelectAccountNo
                  required={false}
                  name="accountNo"
                  label="Account No"
                  value={searchData.accountNo}
                  onChange={handleChange}
                ></SelectAccountNo>
              </div>
            </div>
          </div>
        ) : null}
        <div className={classes.actionContainer}>
          <div className={classes.grdCellNone}>
            <SearchButton
              onClick={handleSearch}
              loading={loading}
              showfilter={(status) => setShowFilter(status)}
            />
          </div>
        </div>
      </Box>
      <Box component="div" mt={2}>
        <Table
          title={'Form 1099 MISC'}
          data={rows}
          columns={columns}
          options={options}
          additionalCell={true}
          // pagination={pagination}
          // setPagination={setPagination}
          // onSearch={handleSearch}
          // getCsvData={getCsvData}
        />
      </Box>
      {open && (
        <Form1099MiscDetailsModal
          onClose={handleClose}
          open={open}
          value={rowData}
        ></Form1099MiscDetailsModal>
      )}
      {openEdit && (
        <Form1099MiscModal
          onClose={handleCloseUpdate}
          open={openEdit}
          value={rowData}
        ></Form1099MiscModal>
      )}
    </div>
  );
}
