/*eslint eqeqeq: "off"*/
import { createSelector } from 'reselect'; //memoization
import {
  ReadProfileRequest,
  UpdateProfileRequest,
  SettleDateRequest,
  ProfileServiceClient,
  Profile,
} from '../proto/admpb/profile_grpc_web_pb';
import { auth } from '../lib/auth/Auth';
import { padString, stringToProtoDate } from './ConvertService';

import { notifyError } from 'components/Notification/Notification';
const service = new ProfileServiceClient(
  window.env.GRPC_ENDPOINT,
  {},
  { ...auth }
);

async function readProfilePromise() {
  return new Promise((resolve, reject) => {
    service.readProfile(new ReadProfileRequest(), {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

//memoization -  cached result when the same inputs occur again
export const readProfile = createSelector(
  () =>
    (async () => {
      const { profile } = await readProfilePromise();
      return profile;
    })(),
  (profile) => profile
);

const getDate = async (type) => {
  const profile = await readProfile();

  const { year, month, day } = profile[type];
  const m = padString(month, '0', 2);
  const d = padString(day, '0', 2);

  return `${year}-${m}-${d}`;
};

export async function getTradeDate() {
  return await getDate('tradeDate');
}

export async function getSettleDate() {
  return await getDate('settleDate');
}

export async function getSystemDate() {
  return await getDate('systemDate');
}

export async function getPreviousDate() {
  return await getDate('previousDate');
}

export async function listProfile(param) {
  return new Promise((resolve, reject) => {
    const req = new Profile();
    service.listProfile(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function updateProfile(param) {
  return new Promise((resolve, reject) => {
    let req = new UpdateProfileRequest();
    req.setCompanyName(param.companyName);
    req.setCompanyTin(param.companyTin);
    req.setAddress(param.address);
    req.setState(param.state);
    req.setCity(param.city);
    req.setZip(param.zip);
    req.setCountry(param.country);
    req.setPhoto(param.photo);
    req.setTelNo(param.telNo);
    req.setPhotoLink(param.photoLink);

    service.updateProfile(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function readSettleDate(params) {
  if (params.entryType === 'TRD' && params.tradeDate) {
    let req = new SettleDateRequest();
    req.setTradeDate(stringToProtoDate(params.tradeDate));
    req.setAssetType(params.assetType);
    await service.getSettleDate(req, {}, (err, res) => {
      if (err) {
        console.error(err);
      } else {
        return res.toObject().profile.settleDate;
      }
    });
  } else {
    await service.readProfile(new ReadProfileRequest(), {}, (err, res) => {
      if (err) {
        console.error(err);
      } else {
        return res.toObject().profile.systemDate;
      }
    });
  }
}
