// source: proto/taxfilingpb/form1099_divreclass.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_type_date_pb = require('../../google/type/date_pb.js');
goog.object.extend(proto, google_type_date_pb);
var proto_commonpb_file_pb = require('../../proto/commonpb/file_pb.js');
goog.object.extend(proto, proto_commonpb_file_pb);
var proto_utilspb_pagination_pb = require('../../proto/utilspb/pagination_pb.js');
goog.object.extend(proto, proto_utilspb_pagination_pb);
goog.exportSymbol('proto.taxfilingpb.CreateForm1099DivReClassResponse', null, global);
goog.exportSymbol('proto.taxfilingpb.DeleteForm1099DivReClassRequest', null, global);
goog.exportSymbol('proto.taxfilingpb.DeleteForm1099DivReClassResponse', null, global);
goog.exportSymbol('proto.taxfilingpb.Form1099DivReClass', null, global);
goog.exportSymbol('proto.taxfilingpb.ListForm1099DivReClassRequest', null, global);
goog.exportSymbol('proto.taxfilingpb.ListForm1099DivReClassResponse', null, global);
goog.exportSymbol('proto.taxfilingpb.ListForm1099DivReClassSummary', null, global);
goog.exportSymbol('proto.taxfilingpb.ProcessRequest', null, global);
goog.exportSymbol('proto.taxfilingpb.ProcessResponse', null, global);
goog.exportSymbol('proto.taxfilingpb.ReadForm1099DivReClassRequest', null, global);
goog.exportSymbol('proto.taxfilingpb.ReadForm1099DivReClassResponse', null, global);
goog.exportSymbol('proto.taxfilingpb.UpdateForm1099DivReClassResponse', null, global);
goog.exportSymbol('proto.taxfilingpb.UploadRequest', null, global);
goog.exportSymbol('proto.taxfilingpb.UploadResponse', null, global);
goog.exportSymbol('proto.taxfilingpb.UploadStatus', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.Form1099DivReClass = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.taxfilingpb.Form1099DivReClass, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.Form1099DivReClass.displayName = 'proto.taxfilingpb.Form1099DivReClass';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.UploadStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.taxfilingpb.UploadStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.UploadStatus.displayName = 'proto.taxfilingpb.UploadStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.CreateForm1099DivReClassResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.taxfilingpb.CreateForm1099DivReClassResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.CreateForm1099DivReClassResponse.displayName = 'proto.taxfilingpb.CreateForm1099DivReClassResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.ReadForm1099DivReClassRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.taxfilingpb.ReadForm1099DivReClassRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.ReadForm1099DivReClassRequest.displayName = 'proto.taxfilingpb.ReadForm1099DivReClassRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.ReadForm1099DivReClassResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.taxfilingpb.ReadForm1099DivReClassResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.ReadForm1099DivReClassResponse.displayName = 'proto.taxfilingpb.ReadForm1099DivReClassResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.UpdateForm1099DivReClassResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.taxfilingpb.UpdateForm1099DivReClassResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.UpdateForm1099DivReClassResponse.displayName = 'proto.taxfilingpb.UpdateForm1099DivReClassResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.DeleteForm1099DivReClassRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.taxfilingpb.DeleteForm1099DivReClassRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.DeleteForm1099DivReClassRequest.displayName = 'proto.taxfilingpb.DeleteForm1099DivReClassRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.DeleteForm1099DivReClassResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.taxfilingpb.DeleteForm1099DivReClassResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.DeleteForm1099DivReClassResponse.displayName = 'proto.taxfilingpb.DeleteForm1099DivReClassResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.ListForm1099DivReClassRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.taxfilingpb.ListForm1099DivReClassRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.ListForm1099DivReClassRequest.displayName = 'proto.taxfilingpb.ListForm1099DivReClassRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.ListForm1099DivReClassResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.taxfilingpb.ListForm1099DivReClassResponse.repeatedFields_, null);
};
goog.inherits(proto.taxfilingpb.ListForm1099DivReClassResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.ListForm1099DivReClassResponse.displayName = 'proto.taxfilingpb.ListForm1099DivReClassResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.ListForm1099DivReClassSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.taxfilingpb.ListForm1099DivReClassSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.ListForm1099DivReClassSummary.displayName = 'proto.taxfilingpb.ListForm1099DivReClassSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.UploadRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.taxfilingpb.UploadRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.UploadRequest.displayName = 'proto.taxfilingpb.UploadRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.UploadResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.taxfilingpb.UploadResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.UploadResponse.displayName = 'proto.taxfilingpb.UploadResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.ProcessRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.taxfilingpb.ProcessRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.ProcessRequest.displayName = 'proto.taxfilingpb.ProcessRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.ProcessResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.taxfilingpb.ProcessResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.ProcessResponse.displayName = 'proto.taxfilingpb.ProcessResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.Form1099DivReClass.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.Form1099DivReClass.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.Form1099DivReClass} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.Form1099DivReClass.toObject = function(includeInstance, msg) {
  var f, obj = {
    year: jspb.Message.getFieldWithDefault(msg, 1, ""),
    cusip: jspb.Message.getFieldWithDefault(msg, 2, ""),
    symbol: jspb.Message.getFieldWithDefault(msg, 3, ""),
    symbolId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    recordDate: (f = msg.getRecordDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    paymentDate: (f = msg.getPaymentDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    divQualified: jspb.Message.getFieldWithDefault(msg, 7, ""),
    divNonQualified: jspb.Message.getFieldWithDefault(msg, 8, ""),
    foreignTaxQualified: jspb.Message.getFieldWithDefault(msg, 9, ""),
    foreignTaxNonQualified: jspb.Message.getFieldWithDefault(msg, 10, ""),
    stGainQualified: jspb.Message.getFieldWithDefault(msg, 11, ""),
    stGainNonQualified: jspb.Message.getFieldWithDefault(msg, 12, ""),
    ltCapitalGain: jspb.Message.getFieldWithDefault(msg, 13, ""),
    rcap: jspb.Message.getFieldWithDefault(msg, 14, ""),
    unrecapturedTwelvefiftyGain: jspb.Message.getFieldWithDefault(msg, 15, ""),
    defPymtAmount: jspb.Message.getFieldWithDefault(msg, 16, ""),
    cashLiquidation: jspb.Message.getFieldWithDefault(msg, 17, ""),
    nonCashLiquidation: jspb.Message.getFieldWithDefault(msg, 18, ""),
    sectionTwelvezerotwoGain: jspb.Message.getFieldWithDefault(msg, 19, ""),
    collectibleTwentyeightGain: jspb.Message.getFieldWithDefault(msg, 20, ""),
    intt: jspb.Message.getFieldWithDefault(msg, 21, ""),
    adrFee: jspb.Message.getFieldWithDefault(msg, 22, ""),
    exemptInterestDiv: jspb.Message.getFieldWithDefault(msg, 23, ""),
    altMinTax: jspb.Message.getFieldWithDefault(msg, 24, ""),
    divNineteenninea: jspb.Message.getFieldWithDefault(msg, 25, ""),
    total: jspb.Message.getFieldWithDefault(msg, 26, ""),
    filePath: jspb.Message.getFieldWithDefault(msg, 27, ""),
    id: jspb.Message.getFieldWithDefault(msg, 28, 0),
    stGainNineteenninea: jspb.Message.getFieldWithDefault(msg, 29, ""),
    grossProceeds: jspb.Message.getFieldWithDefault(msg, 30, ""),
    pagination: (f = msg.getPagination()) && proto_utilspb_pagination_pb.Pagination.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.Form1099DivReClass}
 */
proto.taxfilingpb.Form1099DivReClass.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.Form1099DivReClass;
  return proto.taxfilingpb.Form1099DivReClass.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.Form1099DivReClass} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.Form1099DivReClass}
 */
proto.taxfilingpb.Form1099DivReClass.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setYear(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCusip(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbolId(value);
      break;
    case 5:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setRecordDate(value);
      break;
    case 6:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setPaymentDate(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDivQualified(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDivNonQualified(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setForeignTaxQualified(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setForeignTaxNonQualified(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setStGainQualified(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setStGainNonQualified(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setLtCapitalGain(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setRcap(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnrecapturedTwelvefiftyGain(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setDefPymtAmount(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setCashLiquidation(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setNonCashLiquidation(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setSectionTwelvezerotwoGain(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setCollectibleTwentyeightGain(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setIntt(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdrFee(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setExemptInterestDiv(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setAltMinTax(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setDivNineteenninea(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setTotal(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilePath(value);
      break;
    case 28:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 29:
      var value = /** @type {string} */ (reader.readString());
      msg.setStGainNineteenninea(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setGrossProceeds(value);
      break;
    case 31:
      var value = new proto_utilspb_pagination_pb.Pagination;
      reader.readMessage(value,proto_utilspb_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.Form1099DivReClass.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.Form1099DivReClass.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.Form1099DivReClass} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.Form1099DivReClass.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getYear();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCusip();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSymbolId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRecordDate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getPaymentDate();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getDivQualified();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDivNonQualified();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getForeignTaxQualified();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getForeignTaxNonQualified();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getStGainQualified();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getStGainNonQualified();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getLtCapitalGain();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getRcap();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getUnrecapturedTwelvefiftyGain();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getDefPymtAmount();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getCashLiquidation();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getNonCashLiquidation();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getSectionTwelvezerotwoGain();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getCollectibleTwentyeightGain();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getIntt();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getAdrFee();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getExemptInterestDiv();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getAltMinTax();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getDivNineteenninea();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getTotal();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getFilePath();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      28,
      f
    );
  }
  f = message.getStGainNineteenninea();
  if (f.length > 0) {
    writer.writeString(
      29,
      f
    );
  }
  f = message.getGrossProceeds();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      31,
      f,
      proto_utilspb_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
};


/**
 * optional string year = 1;
 * @return {string}
 */
proto.taxfilingpb.Form1099DivReClass.prototype.getYear = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099DivReClass} returns this
 */
proto.taxfilingpb.Form1099DivReClass.prototype.setYear = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string cusip = 2;
 * @return {string}
 */
proto.taxfilingpb.Form1099DivReClass.prototype.getCusip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099DivReClass} returns this
 */
proto.taxfilingpb.Form1099DivReClass.prototype.setCusip = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string symbol = 3;
 * @return {string}
 */
proto.taxfilingpb.Form1099DivReClass.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099DivReClass} returns this
 */
proto.taxfilingpb.Form1099DivReClass.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string symbol_id = 4;
 * @return {string}
 */
proto.taxfilingpb.Form1099DivReClass.prototype.getSymbolId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099DivReClass} returns this
 */
proto.taxfilingpb.Form1099DivReClass.prototype.setSymbolId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.type.Date record_date = 5;
 * @return {?proto.google.type.Date}
 */
proto.taxfilingpb.Form1099DivReClass.prototype.getRecordDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 5));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.taxfilingpb.Form1099DivReClass} returns this
*/
proto.taxfilingpb.Form1099DivReClass.prototype.setRecordDate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.taxfilingpb.Form1099DivReClass} returns this
 */
proto.taxfilingpb.Form1099DivReClass.prototype.clearRecordDate = function() {
  return this.setRecordDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.taxfilingpb.Form1099DivReClass.prototype.hasRecordDate = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.type.Date payment_date = 6;
 * @return {?proto.google.type.Date}
 */
proto.taxfilingpb.Form1099DivReClass.prototype.getPaymentDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 6));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.taxfilingpb.Form1099DivReClass} returns this
*/
proto.taxfilingpb.Form1099DivReClass.prototype.setPaymentDate = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.taxfilingpb.Form1099DivReClass} returns this
 */
proto.taxfilingpb.Form1099DivReClass.prototype.clearPaymentDate = function() {
  return this.setPaymentDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.taxfilingpb.Form1099DivReClass.prototype.hasPaymentDate = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string div_qualified = 7;
 * @return {string}
 */
proto.taxfilingpb.Form1099DivReClass.prototype.getDivQualified = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099DivReClass} returns this
 */
proto.taxfilingpb.Form1099DivReClass.prototype.setDivQualified = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string div_non_qualified = 8;
 * @return {string}
 */
proto.taxfilingpb.Form1099DivReClass.prototype.getDivNonQualified = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099DivReClass} returns this
 */
proto.taxfilingpb.Form1099DivReClass.prototype.setDivNonQualified = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string foreign_tax_qualified = 9;
 * @return {string}
 */
proto.taxfilingpb.Form1099DivReClass.prototype.getForeignTaxQualified = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099DivReClass} returns this
 */
proto.taxfilingpb.Form1099DivReClass.prototype.setForeignTaxQualified = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string foreign_tax_non_qualified = 10;
 * @return {string}
 */
proto.taxfilingpb.Form1099DivReClass.prototype.getForeignTaxNonQualified = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099DivReClass} returns this
 */
proto.taxfilingpb.Form1099DivReClass.prototype.setForeignTaxNonQualified = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string st_gain_qualified = 11;
 * @return {string}
 */
proto.taxfilingpb.Form1099DivReClass.prototype.getStGainQualified = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099DivReClass} returns this
 */
proto.taxfilingpb.Form1099DivReClass.prototype.setStGainQualified = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string st_gain_non_qualified = 12;
 * @return {string}
 */
proto.taxfilingpb.Form1099DivReClass.prototype.getStGainNonQualified = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099DivReClass} returns this
 */
proto.taxfilingpb.Form1099DivReClass.prototype.setStGainNonQualified = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string lt_capital_gain = 13;
 * @return {string}
 */
proto.taxfilingpb.Form1099DivReClass.prototype.getLtCapitalGain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099DivReClass} returns this
 */
proto.taxfilingpb.Form1099DivReClass.prototype.setLtCapitalGain = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string rcap = 14;
 * @return {string}
 */
proto.taxfilingpb.Form1099DivReClass.prototype.getRcap = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099DivReClass} returns this
 */
proto.taxfilingpb.Form1099DivReClass.prototype.setRcap = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string unrecaptured_twelvefifty_gain = 15;
 * @return {string}
 */
proto.taxfilingpb.Form1099DivReClass.prototype.getUnrecapturedTwelvefiftyGain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099DivReClass} returns this
 */
proto.taxfilingpb.Form1099DivReClass.prototype.setUnrecapturedTwelvefiftyGain = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string def_pymt_amount = 16;
 * @return {string}
 */
proto.taxfilingpb.Form1099DivReClass.prototype.getDefPymtAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099DivReClass} returns this
 */
proto.taxfilingpb.Form1099DivReClass.prototype.setDefPymtAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string cash_liquidation = 17;
 * @return {string}
 */
proto.taxfilingpb.Form1099DivReClass.prototype.getCashLiquidation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099DivReClass} returns this
 */
proto.taxfilingpb.Form1099DivReClass.prototype.setCashLiquidation = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string non_cash_liquidation = 18;
 * @return {string}
 */
proto.taxfilingpb.Form1099DivReClass.prototype.getNonCashLiquidation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099DivReClass} returns this
 */
proto.taxfilingpb.Form1099DivReClass.prototype.setNonCashLiquidation = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string section_twelvezerotwo_gain = 19;
 * @return {string}
 */
proto.taxfilingpb.Form1099DivReClass.prototype.getSectionTwelvezerotwoGain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099DivReClass} returns this
 */
proto.taxfilingpb.Form1099DivReClass.prototype.setSectionTwelvezerotwoGain = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string collectible_twentyeight_gain = 20;
 * @return {string}
 */
proto.taxfilingpb.Form1099DivReClass.prototype.getCollectibleTwentyeightGain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099DivReClass} returns this
 */
proto.taxfilingpb.Form1099DivReClass.prototype.setCollectibleTwentyeightGain = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string intt = 21;
 * @return {string}
 */
proto.taxfilingpb.Form1099DivReClass.prototype.getIntt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099DivReClass} returns this
 */
proto.taxfilingpb.Form1099DivReClass.prototype.setIntt = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string adr_fee = 22;
 * @return {string}
 */
proto.taxfilingpb.Form1099DivReClass.prototype.getAdrFee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099DivReClass} returns this
 */
proto.taxfilingpb.Form1099DivReClass.prototype.setAdrFee = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string exempt_interest_div = 23;
 * @return {string}
 */
proto.taxfilingpb.Form1099DivReClass.prototype.getExemptInterestDiv = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099DivReClass} returns this
 */
proto.taxfilingpb.Form1099DivReClass.prototype.setExemptInterestDiv = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string alt_min_tax = 24;
 * @return {string}
 */
proto.taxfilingpb.Form1099DivReClass.prototype.getAltMinTax = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099DivReClass} returns this
 */
proto.taxfilingpb.Form1099DivReClass.prototype.setAltMinTax = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string div_nineteenninea = 25;
 * @return {string}
 */
proto.taxfilingpb.Form1099DivReClass.prototype.getDivNineteenninea = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099DivReClass} returns this
 */
proto.taxfilingpb.Form1099DivReClass.prototype.setDivNineteenninea = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional string total = 26;
 * @return {string}
 */
proto.taxfilingpb.Form1099DivReClass.prototype.getTotal = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099DivReClass} returns this
 */
proto.taxfilingpb.Form1099DivReClass.prototype.setTotal = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional string file_path = 27;
 * @return {string}
 */
proto.taxfilingpb.Form1099DivReClass.prototype.getFilePath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099DivReClass} returns this
 */
proto.taxfilingpb.Form1099DivReClass.prototype.setFilePath = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional int32 id = 28;
 * @return {number}
 */
proto.taxfilingpb.Form1099DivReClass.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 28, 0));
};


/**
 * @param {number} value
 * @return {!proto.taxfilingpb.Form1099DivReClass} returns this
 */
proto.taxfilingpb.Form1099DivReClass.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 28, value);
};


/**
 * optional string st_gain_nineteenninea = 29;
 * @return {string}
 */
proto.taxfilingpb.Form1099DivReClass.prototype.getStGainNineteenninea = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 29, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099DivReClass} returns this
 */
proto.taxfilingpb.Form1099DivReClass.prototype.setStGainNineteenninea = function(value) {
  return jspb.Message.setProto3StringField(this, 29, value);
};


/**
 * optional string gross_proceeds = 30;
 * @return {string}
 */
proto.taxfilingpb.Form1099DivReClass.prototype.getGrossProceeds = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099DivReClass} returns this
 */
proto.taxfilingpb.Form1099DivReClass.prototype.setGrossProceeds = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional utilspb.Pagination pagination = 31;
 * @return {?proto.utilspb.Pagination}
 */
proto.taxfilingpb.Form1099DivReClass.prototype.getPagination = function() {
  return /** @type{?proto.utilspb.Pagination} */ (
    jspb.Message.getWrapperField(this, proto_utilspb_pagination_pb.Pagination, 31));
};


/**
 * @param {?proto.utilspb.Pagination|undefined} value
 * @return {!proto.taxfilingpb.Form1099DivReClass} returns this
*/
proto.taxfilingpb.Form1099DivReClass.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 31, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.taxfilingpb.Form1099DivReClass} returns this
 */
proto.taxfilingpb.Form1099DivReClass.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.taxfilingpb.Form1099DivReClass.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 31) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.UploadStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.UploadStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.UploadStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.UploadStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    batchNo: jspb.Message.getFieldWithDefault(msg, 2, ""),
    uploadedSheets: jspb.Message.getFieldWithDefault(msg, 3, 0),
    isProcessing: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    errorMessage: jspb.Message.getFieldWithDefault(msg, 5, ""),
    notEqualTotal: jspb.Message.getFieldWithDefault(msg, 6, 0),
    emptySymbol: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.UploadStatus}
 */
proto.taxfilingpb.UploadStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.UploadStatus;
  return proto.taxfilingpb.UploadStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.UploadStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.UploadStatus}
 */
proto.taxfilingpb.UploadStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBatchNo(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUploadedSheets(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsProcessing(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorMessage(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNotEqualTotal(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEmptySymbol(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.UploadStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.UploadStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.UploadStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.UploadStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getBatchNo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUploadedSheets();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getIsProcessing();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getErrorMessage();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getNotEqualTotal();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getEmptySymbol();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.taxfilingpb.UploadStatus.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.taxfilingpb.UploadStatus} returns this
 */
proto.taxfilingpb.UploadStatus.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string batch_no = 2;
 * @return {string}
 */
proto.taxfilingpb.UploadStatus.prototype.getBatchNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.UploadStatus} returns this
 */
proto.taxfilingpb.UploadStatus.prototype.setBatchNo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 uploaded_sheets = 3;
 * @return {number}
 */
proto.taxfilingpb.UploadStatus.prototype.getUploadedSheets = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.taxfilingpb.UploadStatus} returns this
 */
proto.taxfilingpb.UploadStatus.prototype.setUploadedSheets = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool is_processing = 4;
 * @return {boolean}
 */
proto.taxfilingpb.UploadStatus.prototype.getIsProcessing = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.taxfilingpb.UploadStatus} returns this
 */
proto.taxfilingpb.UploadStatus.prototype.setIsProcessing = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string error_message = 5;
 * @return {string}
 */
proto.taxfilingpb.UploadStatus.prototype.getErrorMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.UploadStatus} returns this
 */
proto.taxfilingpb.UploadStatus.prototype.setErrorMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 not_equal_total = 6;
 * @return {number}
 */
proto.taxfilingpb.UploadStatus.prototype.getNotEqualTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.taxfilingpb.UploadStatus} returns this
 */
proto.taxfilingpb.UploadStatus.prototype.setNotEqualTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 empty_symbol = 7;
 * @return {number}
 */
proto.taxfilingpb.UploadStatus.prototype.getEmptySymbol = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.taxfilingpb.UploadStatus} returns this
 */
proto.taxfilingpb.UploadStatus.prototype.setEmptySymbol = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.CreateForm1099DivReClassResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.CreateForm1099DivReClassResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.CreateForm1099DivReClassResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.CreateForm1099DivReClassResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    divReclass: (f = msg.getDivReclass()) && proto.taxfilingpb.Form1099DivReClass.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.CreateForm1099DivReClassResponse}
 */
proto.taxfilingpb.CreateForm1099DivReClassResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.CreateForm1099DivReClassResponse;
  return proto.taxfilingpb.CreateForm1099DivReClassResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.CreateForm1099DivReClassResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.CreateForm1099DivReClassResponse}
 */
proto.taxfilingpb.CreateForm1099DivReClassResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.taxfilingpb.Form1099DivReClass;
      reader.readMessage(value,proto.taxfilingpb.Form1099DivReClass.deserializeBinaryFromReader);
      msg.setDivReclass(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.CreateForm1099DivReClassResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.CreateForm1099DivReClassResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.CreateForm1099DivReClassResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.CreateForm1099DivReClassResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDivReclass();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.taxfilingpb.Form1099DivReClass.serializeBinaryToWriter
    );
  }
};


/**
 * optional Form1099DivReClass div_reclass = 1;
 * @return {?proto.taxfilingpb.Form1099DivReClass}
 */
proto.taxfilingpb.CreateForm1099DivReClassResponse.prototype.getDivReclass = function() {
  return /** @type{?proto.taxfilingpb.Form1099DivReClass} */ (
    jspb.Message.getWrapperField(this, proto.taxfilingpb.Form1099DivReClass, 1));
};


/**
 * @param {?proto.taxfilingpb.Form1099DivReClass|undefined} value
 * @return {!proto.taxfilingpb.CreateForm1099DivReClassResponse} returns this
*/
proto.taxfilingpb.CreateForm1099DivReClassResponse.prototype.setDivReclass = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.taxfilingpb.CreateForm1099DivReClassResponse} returns this
 */
proto.taxfilingpb.CreateForm1099DivReClassResponse.prototype.clearDivReclass = function() {
  return this.setDivReclass(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.taxfilingpb.CreateForm1099DivReClassResponse.prototype.hasDivReclass = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.ReadForm1099DivReClassRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.ReadForm1099DivReClassRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.ReadForm1099DivReClassRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.ReadForm1099DivReClassRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.ReadForm1099DivReClassRequest}
 */
proto.taxfilingpb.ReadForm1099DivReClassRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.ReadForm1099DivReClassRequest;
  return proto.taxfilingpb.ReadForm1099DivReClassRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.ReadForm1099DivReClassRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.ReadForm1099DivReClassRequest}
 */
proto.taxfilingpb.ReadForm1099DivReClassRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.ReadForm1099DivReClassRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.ReadForm1099DivReClassRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.ReadForm1099DivReClassRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.ReadForm1099DivReClassRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.taxfilingpb.ReadForm1099DivReClassRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.taxfilingpb.ReadForm1099DivReClassRequest} returns this
 */
proto.taxfilingpb.ReadForm1099DivReClassRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.ReadForm1099DivReClassResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.ReadForm1099DivReClassResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.ReadForm1099DivReClassResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.ReadForm1099DivReClassResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    divReclass: (f = msg.getDivReclass()) && proto.taxfilingpb.Form1099DivReClass.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.ReadForm1099DivReClassResponse}
 */
proto.taxfilingpb.ReadForm1099DivReClassResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.ReadForm1099DivReClassResponse;
  return proto.taxfilingpb.ReadForm1099DivReClassResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.ReadForm1099DivReClassResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.ReadForm1099DivReClassResponse}
 */
proto.taxfilingpb.ReadForm1099DivReClassResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.taxfilingpb.Form1099DivReClass;
      reader.readMessage(value,proto.taxfilingpb.Form1099DivReClass.deserializeBinaryFromReader);
      msg.setDivReclass(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.ReadForm1099DivReClassResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.ReadForm1099DivReClassResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.ReadForm1099DivReClassResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.ReadForm1099DivReClassResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDivReclass();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.taxfilingpb.Form1099DivReClass.serializeBinaryToWriter
    );
  }
};


/**
 * optional Form1099DivReClass div_reclass = 1;
 * @return {?proto.taxfilingpb.Form1099DivReClass}
 */
proto.taxfilingpb.ReadForm1099DivReClassResponse.prototype.getDivReclass = function() {
  return /** @type{?proto.taxfilingpb.Form1099DivReClass} */ (
    jspb.Message.getWrapperField(this, proto.taxfilingpb.Form1099DivReClass, 1));
};


/**
 * @param {?proto.taxfilingpb.Form1099DivReClass|undefined} value
 * @return {!proto.taxfilingpb.ReadForm1099DivReClassResponse} returns this
*/
proto.taxfilingpb.ReadForm1099DivReClassResponse.prototype.setDivReclass = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.taxfilingpb.ReadForm1099DivReClassResponse} returns this
 */
proto.taxfilingpb.ReadForm1099DivReClassResponse.prototype.clearDivReclass = function() {
  return this.setDivReclass(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.taxfilingpb.ReadForm1099DivReClassResponse.prototype.hasDivReclass = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.UpdateForm1099DivReClassResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.UpdateForm1099DivReClassResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.UpdateForm1099DivReClassResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.UpdateForm1099DivReClassResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    divReclass: (f = msg.getDivReclass()) && proto.taxfilingpb.Form1099DivReClass.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.UpdateForm1099DivReClassResponse}
 */
proto.taxfilingpb.UpdateForm1099DivReClassResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.UpdateForm1099DivReClassResponse;
  return proto.taxfilingpb.UpdateForm1099DivReClassResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.UpdateForm1099DivReClassResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.UpdateForm1099DivReClassResponse}
 */
proto.taxfilingpb.UpdateForm1099DivReClassResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.taxfilingpb.Form1099DivReClass;
      reader.readMessage(value,proto.taxfilingpb.Form1099DivReClass.deserializeBinaryFromReader);
      msg.setDivReclass(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.UpdateForm1099DivReClassResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.UpdateForm1099DivReClassResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.UpdateForm1099DivReClassResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.UpdateForm1099DivReClassResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDivReclass();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.taxfilingpb.Form1099DivReClass.serializeBinaryToWriter
    );
  }
};


/**
 * optional Form1099DivReClass div_reclass = 1;
 * @return {?proto.taxfilingpb.Form1099DivReClass}
 */
proto.taxfilingpb.UpdateForm1099DivReClassResponse.prototype.getDivReclass = function() {
  return /** @type{?proto.taxfilingpb.Form1099DivReClass} */ (
    jspb.Message.getWrapperField(this, proto.taxfilingpb.Form1099DivReClass, 1));
};


/**
 * @param {?proto.taxfilingpb.Form1099DivReClass|undefined} value
 * @return {!proto.taxfilingpb.UpdateForm1099DivReClassResponse} returns this
*/
proto.taxfilingpb.UpdateForm1099DivReClassResponse.prototype.setDivReclass = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.taxfilingpb.UpdateForm1099DivReClassResponse} returns this
 */
proto.taxfilingpb.UpdateForm1099DivReClassResponse.prototype.clearDivReclass = function() {
  return this.setDivReclass(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.taxfilingpb.UpdateForm1099DivReClassResponse.prototype.hasDivReclass = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.DeleteForm1099DivReClassRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.DeleteForm1099DivReClassRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.DeleteForm1099DivReClassRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.DeleteForm1099DivReClassRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.DeleteForm1099DivReClassRequest}
 */
proto.taxfilingpb.DeleteForm1099DivReClassRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.DeleteForm1099DivReClassRequest;
  return proto.taxfilingpb.DeleteForm1099DivReClassRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.DeleteForm1099DivReClassRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.DeleteForm1099DivReClassRequest}
 */
proto.taxfilingpb.DeleteForm1099DivReClassRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.DeleteForm1099DivReClassRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.DeleteForm1099DivReClassRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.DeleteForm1099DivReClassRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.DeleteForm1099DivReClassRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.taxfilingpb.DeleteForm1099DivReClassRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.taxfilingpb.DeleteForm1099DivReClassRequest} returns this
 */
proto.taxfilingpb.DeleteForm1099DivReClassRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.DeleteForm1099DivReClassResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.DeleteForm1099DivReClassResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.DeleteForm1099DivReClassResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.DeleteForm1099DivReClassResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.DeleteForm1099DivReClassResponse}
 */
proto.taxfilingpb.DeleteForm1099DivReClassResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.DeleteForm1099DivReClassResponse;
  return proto.taxfilingpb.DeleteForm1099DivReClassResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.DeleteForm1099DivReClassResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.DeleteForm1099DivReClassResponse}
 */
proto.taxfilingpb.DeleteForm1099DivReClassResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.DeleteForm1099DivReClassResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.DeleteForm1099DivReClassResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.DeleteForm1099DivReClassResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.DeleteForm1099DivReClassResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.taxfilingpb.DeleteForm1099DivReClassResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.taxfilingpb.DeleteForm1099DivReClassResponse} returns this
 */
proto.taxfilingpb.DeleteForm1099DivReClassResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.ListForm1099DivReClassRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.ListForm1099DivReClassRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.ListForm1099DivReClassRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.ListForm1099DivReClassRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    symbol: jspb.Message.getFieldWithDefault(msg, 1, ""),
    year: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.ListForm1099DivReClassRequest}
 */
proto.taxfilingpb.ListForm1099DivReClassRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.ListForm1099DivReClassRequest;
  return proto.taxfilingpb.ListForm1099DivReClassRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.ListForm1099DivReClassRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.ListForm1099DivReClassRequest}
 */
proto.taxfilingpb.ListForm1099DivReClassRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setYear(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.ListForm1099DivReClassRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.ListForm1099DivReClassRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.ListForm1099DivReClassRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.ListForm1099DivReClassRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getYear();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string symbol = 1;
 * @return {string}
 */
proto.taxfilingpb.ListForm1099DivReClassRequest.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.ListForm1099DivReClassRequest} returns this
 */
proto.taxfilingpb.ListForm1099DivReClassRequest.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string year = 2;
 * @return {string}
 */
proto.taxfilingpb.ListForm1099DivReClassRequest.prototype.getYear = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.ListForm1099DivReClassRequest} returns this
 */
proto.taxfilingpb.ListForm1099DivReClassRequest.prototype.setYear = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.taxfilingpb.ListForm1099DivReClassResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.ListForm1099DivReClassResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.ListForm1099DivReClassResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.ListForm1099DivReClassResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.ListForm1099DivReClassResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    divReclassesList: jspb.Message.toObjectList(msg.getDivReclassesList(),
    proto.taxfilingpb.Form1099DivReClass.toObject, includeInstance),
    summary: (f = msg.getSummary()) && proto.taxfilingpb.ListForm1099DivReClassSummary.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.ListForm1099DivReClassResponse}
 */
proto.taxfilingpb.ListForm1099DivReClassResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.ListForm1099DivReClassResponse;
  return proto.taxfilingpb.ListForm1099DivReClassResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.ListForm1099DivReClassResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.ListForm1099DivReClassResponse}
 */
proto.taxfilingpb.ListForm1099DivReClassResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.taxfilingpb.Form1099DivReClass;
      reader.readMessage(value,proto.taxfilingpb.Form1099DivReClass.deserializeBinaryFromReader);
      msg.addDivReclasses(value);
      break;
    case 2:
      var value = new proto.taxfilingpb.ListForm1099DivReClassSummary;
      reader.readMessage(value,proto.taxfilingpb.ListForm1099DivReClassSummary.deserializeBinaryFromReader);
      msg.setSummary(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.ListForm1099DivReClassResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.ListForm1099DivReClassResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.ListForm1099DivReClassResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.ListForm1099DivReClassResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDivReclassesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.taxfilingpb.Form1099DivReClass.serializeBinaryToWriter
    );
  }
  f = message.getSummary();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.taxfilingpb.ListForm1099DivReClassSummary.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Form1099DivReClass div_reclasses = 1;
 * @return {!Array<!proto.taxfilingpb.Form1099DivReClass>}
 */
proto.taxfilingpb.ListForm1099DivReClassResponse.prototype.getDivReclassesList = function() {
  return /** @type{!Array<!proto.taxfilingpb.Form1099DivReClass>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.taxfilingpb.Form1099DivReClass, 1));
};


/**
 * @param {!Array<!proto.taxfilingpb.Form1099DivReClass>} value
 * @return {!proto.taxfilingpb.ListForm1099DivReClassResponse} returns this
*/
proto.taxfilingpb.ListForm1099DivReClassResponse.prototype.setDivReclassesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.taxfilingpb.Form1099DivReClass=} opt_value
 * @param {number=} opt_index
 * @return {!proto.taxfilingpb.Form1099DivReClass}
 */
proto.taxfilingpb.ListForm1099DivReClassResponse.prototype.addDivReclasses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.taxfilingpb.Form1099DivReClass, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.taxfilingpb.ListForm1099DivReClassResponse} returns this
 */
proto.taxfilingpb.ListForm1099DivReClassResponse.prototype.clearDivReclassesList = function() {
  return this.setDivReclassesList([]);
};


/**
 * optional ListForm1099DivReClassSummary summary = 2;
 * @return {?proto.taxfilingpb.ListForm1099DivReClassSummary}
 */
proto.taxfilingpb.ListForm1099DivReClassResponse.prototype.getSummary = function() {
  return /** @type{?proto.taxfilingpb.ListForm1099DivReClassSummary} */ (
    jspb.Message.getWrapperField(this, proto.taxfilingpb.ListForm1099DivReClassSummary, 2));
};


/**
 * @param {?proto.taxfilingpb.ListForm1099DivReClassSummary|undefined} value
 * @return {!proto.taxfilingpb.ListForm1099DivReClassResponse} returns this
*/
proto.taxfilingpb.ListForm1099DivReClassResponse.prototype.setSummary = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.taxfilingpb.ListForm1099DivReClassResponse} returns this
 */
proto.taxfilingpb.ListForm1099DivReClassResponse.prototype.clearSummary = function() {
  return this.setSummary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.taxfilingpb.ListForm1099DivReClassResponse.prototype.hasSummary = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.ListForm1099DivReClassSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.ListForm1099DivReClassSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.ListForm1099DivReClassSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.ListForm1099DivReClassSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalRows: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.ListForm1099DivReClassSummary}
 */
proto.taxfilingpb.ListForm1099DivReClassSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.ListForm1099DivReClassSummary;
  return proto.taxfilingpb.ListForm1099DivReClassSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.ListForm1099DivReClassSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.ListForm1099DivReClassSummary}
 */
proto.taxfilingpb.ListForm1099DivReClassSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalRows(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.ListForm1099DivReClassSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.ListForm1099DivReClassSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.ListForm1099DivReClassSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.ListForm1099DivReClassSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalRows();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 total_rows = 1;
 * @return {number}
 */
proto.taxfilingpb.ListForm1099DivReClassSummary.prototype.getTotalRows = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.taxfilingpb.ListForm1099DivReClassSummary} returns this
 */
proto.taxfilingpb.ListForm1099DivReClassSummary.prototype.setTotalRows = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.UploadRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.UploadRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.UploadRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.UploadRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    batchNo: jspb.Message.getFieldWithDefault(msg, 1, ""),
    file: (f = msg.getFile()) && proto_commonpb_file_pb.File.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.UploadRequest}
 */
proto.taxfilingpb.UploadRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.UploadRequest;
  return proto.taxfilingpb.UploadRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.UploadRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.UploadRequest}
 */
proto.taxfilingpb.UploadRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBatchNo(value);
      break;
    case 2:
      var value = new proto_commonpb_file_pb.File;
      reader.readMessage(value,proto_commonpb_file_pb.File.deserializeBinaryFromReader);
      msg.setFile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.UploadRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.UploadRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.UploadRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.UploadRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBatchNo();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFile();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commonpb_file_pb.File.serializeBinaryToWriter
    );
  }
};


/**
 * optional string batch_no = 1;
 * @return {string}
 */
proto.taxfilingpb.UploadRequest.prototype.getBatchNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.UploadRequest} returns this
 */
proto.taxfilingpb.UploadRequest.prototype.setBatchNo = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional commonpb.File file = 2;
 * @return {?proto.commonpb.File}
 */
proto.taxfilingpb.UploadRequest.prototype.getFile = function() {
  return /** @type{?proto.commonpb.File} */ (
    jspb.Message.getWrapperField(this, proto_commonpb_file_pb.File, 2));
};


/**
 * @param {?proto.commonpb.File|undefined} value
 * @return {!proto.taxfilingpb.UploadRequest} returns this
*/
proto.taxfilingpb.UploadRequest.prototype.setFile = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.taxfilingpb.UploadRequest} returns this
 */
proto.taxfilingpb.UploadRequest.prototype.clearFile = function() {
  return this.setFile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.taxfilingpb.UploadRequest.prototype.hasFile = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.UploadResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.UploadResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.UploadResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.UploadResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.UploadResponse}
 */
proto.taxfilingpb.UploadResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.UploadResponse;
  return proto.taxfilingpb.UploadResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.UploadResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.UploadResponse}
 */
proto.taxfilingpb.UploadResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.UploadResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.UploadResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.UploadResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.UploadResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.taxfilingpb.UploadResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.taxfilingpb.UploadResponse} returns this
 */
proto.taxfilingpb.UploadResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.ProcessRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.ProcessRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.ProcessRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.ProcessRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    batchNo: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.ProcessRequest}
 */
proto.taxfilingpb.ProcessRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.ProcessRequest;
  return proto.taxfilingpb.ProcessRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.ProcessRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.ProcessRequest}
 */
proto.taxfilingpb.ProcessRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBatchNo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.ProcessRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.ProcessRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.ProcessRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.ProcessRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBatchNo();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string batch_no = 1;
 * @return {string}
 */
proto.taxfilingpb.ProcessRequest.prototype.getBatchNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.ProcessRequest} returns this
 */
proto.taxfilingpb.ProcessRequest.prototype.setBatchNo = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.ProcessResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.ProcessResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.ProcessResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.ProcessResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.ProcessResponse}
 */
proto.taxfilingpb.ProcessResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.ProcessResponse;
  return proto.taxfilingpb.ProcessResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.ProcessResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.ProcessResponse}
 */
proto.taxfilingpb.ProcessResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.ProcessResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.ProcessResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.ProcessResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.ProcessResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.taxfilingpb.ProcessResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.taxfilingpb.ProcessResponse} returns this
 */
proto.taxfilingpb.ProcessResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


goog.object.extend(exports, proto.taxfilingpb);
