/**
 * @fileoverview gRPC-Web generated client stub for taxfilingpb
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_type_date_pb = require('../../google/type/date_pb.js')

var proto_commonpb_file_pb = require('../../proto/commonpb/file_pb.js')

var proto_utilspb_pagination_pb = require('../../proto/utilspb/pagination_pb.js')
const proto = {};
proto.taxfilingpb = require('./form1099_misc_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.taxfilingpb.Form1099MiscServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.taxfilingpb.Form1099MiscServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.taxfilingpb.Form1099MiscRequest,
 *   !proto.taxfilingpb.ListForm1099MiscResponse>}
 */
const methodDescriptor_Form1099MiscService_ListForm1099Misc = new grpc.web.MethodDescriptor(
  '/taxfilingpb.Form1099MiscService/ListForm1099Misc',
  grpc.web.MethodType.UNARY,
  proto.taxfilingpb.Form1099MiscRequest,
  proto.taxfilingpb.ListForm1099MiscResponse,
  /**
   * @param {!proto.taxfilingpb.Form1099MiscRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.ListForm1099MiscResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.taxfilingpb.Form1099MiscRequest,
 *   !proto.taxfilingpb.ListForm1099MiscResponse>}
 */
const methodInfo_Form1099MiscService_ListForm1099Misc = new grpc.web.AbstractClientBase.MethodInfo(
  proto.taxfilingpb.ListForm1099MiscResponse,
  /**
   * @param {!proto.taxfilingpb.Form1099MiscRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.ListForm1099MiscResponse.deserializeBinary
);


/**
 * @param {!proto.taxfilingpb.Form1099MiscRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.taxfilingpb.ListForm1099MiscResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.taxfilingpb.ListForm1099MiscResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.taxfilingpb.Form1099MiscServiceClient.prototype.listForm1099Misc =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/taxfilingpb.Form1099MiscService/ListForm1099Misc',
      request,
      metadata || {},
      methodDescriptor_Form1099MiscService_ListForm1099Misc,
      callback);
};


/**
 * @param {!proto.taxfilingpb.Form1099MiscRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.taxfilingpb.ListForm1099MiscResponse>}
 *     Promise that resolves to the response
 */
proto.taxfilingpb.Form1099MiscServicePromiseClient.prototype.listForm1099Misc =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/taxfilingpb.Form1099MiscService/ListForm1099Misc',
      request,
      metadata || {},
      methodDescriptor_Form1099MiscService_ListForm1099Misc);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.taxfilingpb.Form1099MiscRequest,
 *   !proto.taxfilingpb.Form1099MiscDetailsResponse>}
 */
const methodDescriptor_Form1099MiscService_Form1099MiscDetails = new grpc.web.MethodDescriptor(
  '/taxfilingpb.Form1099MiscService/Form1099MiscDetails',
  grpc.web.MethodType.UNARY,
  proto.taxfilingpb.Form1099MiscRequest,
  proto.taxfilingpb.Form1099MiscDetailsResponse,
  /**
   * @param {!proto.taxfilingpb.Form1099MiscRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.Form1099MiscDetailsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.taxfilingpb.Form1099MiscRequest,
 *   !proto.taxfilingpb.Form1099MiscDetailsResponse>}
 */
const methodInfo_Form1099MiscService_Form1099MiscDetails = new grpc.web.AbstractClientBase.MethodInfo(
  proto.taxfilingpb.Form1099MiscDetailsResponse,
  /**
   * @param {!proto.taxfilingpb.Form1099MiscRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.Form1099MiscDetailsResponse.deserializeBinary
);


/**
 * @param {!proto.taxfilingpb.Form1099MiscRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.taxfilingpb.Form1099MiscDetailsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.taxfilingpb.Form1099MiscDetailsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.taxfilingpb.Form1099MiscServiceClient.prototype.form1099MiscDetails =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/taxfilingpb.Form1099MiscService/Form1099MiscDetails',
      request,
      metadata || {},
      methodDescriptor_Form1099MiscService_Form1099MiscDetails,
      callback);
};


/**
 * @param {!proto.taxfilingpb.Form1099MiscRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.taxfilingpb.Form1099MiscDetailsResponse>}
 *     Promise that resolves to the response
 */
proto.taxfilingpb.Form1099MiscServicePromiseClient.prototype.form1099MiscDetails =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/taxfilingpb.Form1099MiscService/Form1099MiscDetails',
      request,
      metadata || {},
      methodDescriptor_Form1099MiscService_Form1099MiscDetails);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.taxfilingpb.Form1099MiscProcessRequest,
 *   !proto.taxfilingpb.Form1099MiscProcessResponse>}
 */
const methodDescriptor_Form1099MiscService_Form1099MiscProcess = new grpc.web.MethodDescriptor(
  '/taxfilingpb.Form1099MiscService/Form1099MiscProcess',
  grpc.web.MethodType.UNARY,
  proto.taxfilingpb.Form1099MiscProcessRequest,
  proto.taxfilingpb.Form1099MiscProcessResponse,
  /**
   * @param {!proto.taxfilingpb.Form1099MiscProcessRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.Form1099MiscProcessResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.taxfilingpb.Form1099MiscProcessRequest,
 *   !proto.taxfilingpb.Form1099MiscProcessResponse>}
 */
const methodInfo_Form1099MiscService_Form1099MiscProcess = new grpc.web.AbstractClientBase.MethodInfo(
  proto.taxfilingpb.Form1099MiscProcessResponse,
  /**
   * @param {!proto.taxfilingpb.Form1099MiscProcessRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.Form1099MiscProcessResponse.deserializeBinary
);


/**
 * @param {!proto.taxfilingpb.Form1099MiscProcessRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.taxfilingpb.Form1099MiscProcessResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.taxfilingpb.Form1099MiscProcessResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.taxfilingpb.Form1099MiscServiceClient.prototype.form1099MiscProcess =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/taxfilingpb.Form1099MiscService/Form1099MiscProcess',
      request,
      metadata || {},
      methodDescriptor_Form1099MiscService_Form1099MiscProcess,
      callback);
};


/**
 * @param {!proto.taxfilingpb.Form1099MiscProcessRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.taxfilingpb.Form1099MiscProcessResponse>}
 *     Promise that resolves to the response
 */
proto.taxfilingpb.Form1099MiscServicePromiseClient.prototype.form1099MiscProcess =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/taxfilingpb.Form1099MiscService/Form1099MiscProcess',
      request,
      metadata || {},
      methodDescriptor_Form1099MiscService_Form1099MiscProcess);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.taxfilingpb.DownLoadMiscRequest,
 *   !proto.commonpb.File>}
 */
const methodDescriptor_Form1099MiscService_Download = new grpc.web.MethodDescriptor(
  '/taxfilingpb.Form1099MiscService/Download',
  grpc.web.MethodType.UNARY,
  proto.taxfilingpb.DownLoadMiscRequest,
  proto_commonpb_file_pb.File,
  /**
   * @param {!proto.taxfilingpb.DownLoadMiscRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_commonpb_file_pb.File.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.taxfilingpb.DownLoadMiscRequest,
 *   !proto.commonpb.File>}
 */
const methodInfo_Form1099MiscService_Download = new grpc.web.AbstractClientBase.MethodInfo(
  proto_commonpb_file_pb.File,
  /**
   * @param {!proto.taxfilingpb.DownLoadMiscRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_commonpb_file_pb.File.deserializeBinary
);


/**
 * @param {!proto.taxfilingpb.DownLoadMiscRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.commonpb.File)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.commonpb.File>|undefined}
 *     The XHR Node Readable Stream
 */
proto.taxfilingpb.Form1099MiscServiceClient.prototype.download =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/taxfilingpb.Form1099MiscService/Download',
      request,
      metadata || {},
      methodDescriptor_Form1099MiscService_Download,
      callback);
};


/**
 * @param {!proto.taxfilingpb.DownLoadMiscRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.commonpb.File>}
 *     Promise that resolves to the response
 */
proto.taxfilingpb.Form1099MiscServicePromiseClient.prototype.download =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/taxfilingpb.Form1099MiscService/Download',
      request,
      metadata || {},
      methodDescriptor_Form1099MiscService_Download);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.taxfilingpb.DownLoadMiscRequest,
 *   !proto.taxfilingpb.GenerateMiscResponse>}
 */
const methodDescriptor_Form1099MiscService_Generate = new grpc.web.MethodDescriptor(
  '/taxfilingpb.Form1099MiscService/Generate',
  grpc.web.MethodType.UNARY,
  proto.taxfilingpb.DownLoadMiscRequest,
  proto.taxfilingpb.GenerateMiscResponse,
  /**
   * @param {!proto.taxfilingpb.DownLoadMiscRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.GenerateMiscResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.taxfilingpb.DownLoadMiscRequest,
 *   !proto.taxfilingpb.GenerateMiscResponse>}
 */
const methodInfo_Form1099MiscService_Generate = new grpc.web.AbstractClientBase.MethodInfo(
  proto.taxfilingpb.GenerateMiscResponse,
  /**
   * @param {!proto.taxfilingpb.DownLoadMiscRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.GenerateMiscResponse.deserializeBinary
);


/**
 * @param {!proto.taxfilingpb.DownLoadMiscRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.taxfilingpb.GenerateMiscResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.taxfilingpb.GenerateMiscResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.taxfilingpb.Form1099MiscServiceClient.prototype.generate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/taxfilingpb.Form1099MiscService/Generate',
      request,
      metadata || {},
      methodDescriptor_Form1099MiscService_Generate,
      callback);
};


/**
 * @param {!proto.taxfilingpb.DownLoadMiscRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.taxfilingpb.GenerateMiscResponse>}
 *     Promise that resolves to the response
 */
proto.taxfilingpb.Form1099MiscServicePromiseClient.prototype.generate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/taxfilingpb.Form1099MiscService/Generate',
      request,
      metadata || {},
      methodDescriptor_Form1099MiscService_Generate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.taxfilingpb.Form1099Misc,
 *   !proto.taxfilingpb.UpdateMiscResponse>}
 */
const methodDescriptor_Form1099MiscService_UpdateMisc = new grpc.web.MethodDescriptor(
  '/taxfilingpb.Form1099MiscService/UpdateMisc',
  grpc.web.MethodType.UNARY,
  proto.taxfilingpb.Form1099Misc,
  proto.taxfilingpb.UpdateMiscResponse,
  /**
   * @param {!proto.taxfilingpb.Form1099Misc} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.UpdateMiscResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.taxfilingpb.Form1099Misc,
 *   !proto.taxfilingpb.UpdateMiscResponse>}
 */
const methodInfo_Form1099MiscService_UpdateMisc = new grpc.web.AbstractClientBase.MethodInfo(
  proto.taxfilingpb.UpdateMiscResponse,
  /**
   * @param {!proto.taxfilingpb.Form1099Misc} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.UpdateMiscResponse.deserializeBinary
);


/**
 * @param {!proto.taxfilingpb.Form1099Misc} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.taxfilingpb.UpdateMiscResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.taxfilingpb.UpdateMiscResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.taxfilingpb.Form1099MiscServiceClient.prototype.updateMisc =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/taxfilingpb.Form1099MiscService/UpdateMisc',
      request,
      metadata || {},
      methodDescriptor_Form1099MiscService_UpdateMisc,
      callback);
};


/**
 * @param {!proto.taxfilingpb.Form1099Misc} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.taxfilingpb.UpdateMiscResponse>}
 *     Promise that resolves to the response
 */
proto.taxfilingpb.Form1099MiscServicePromiseClient.prototype.updateMisc =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/taxfilingpb.Form1099MiscService/UpdateMisc',
      request,
      metadata || {},
      methodDescriptor_Form1099MiscService_UpdateMisc);
};


module.exports = proto.taxfilingpb;

