/**
 * @fileoverview gRPC-Web generated client stub for taxfilingpb
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_type_date_pb = require('../../google/type/date_pb.js')

var proto_commonpb_file_pb = require('../../proto/commonpb/file_pb.js')
const proto = {};
proto.taxfilingpb = require('./trns_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.taxfilingpb.TrnsServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.taxfilingpb.TrnsServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.taxfilingpb.Trns,
 *   !proto.taxfilingpb.CreateTrnsResponse>}
 */
const methodDescriptor_TrnsService_CreateTrns = new grpc.web.MethodDescriptor(
  '/taxfilingpb.TrnsService/CreateTrns',
  grpc.web.MethodType.UNARY,
  proto.taxfilingpb.Trns,
  proto.taxfilingpb.CreateTrnsResponse,
  /**
   * @param {!proto.taxfilingpb.Trns} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.CreateTrnsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.taxfilingpb.Trns,
 *   !proto.taxfilingpb.CreateTrnsResponse>}
 */
const methodInfo_TrnsService_CreateTrns = new grpc.web.AbstractClientBase.MethodInfo(
  proto.taxfilingpb.CreateTrnsResponse,
  /**
   * @param {!proto.taxfilingpb.Trns} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.CreateTrnsResponse.deserializeBinary
);


/**
 * @param {!proto.taxfilingpb.Trns} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.taxfilingpb.CreateTrnsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.taxfilingpb.CreateTrnsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.taxfilingpb.TrnsServiceClient.prototype.createTrns =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/taxfilingpb.TrnsService/CreateTrns',
      request,
      metadata || {},
      methodDescriptor_TrnsService_CreateTrns,
      callback);
};


/**
 * @param {!proto.taxfilingpb.Trns} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.taxfilingpb.CreateTrnsResponse>}
 *     Promise that resolves to the response
 */
proto.taxfilingpb.TrnsServicePromiseClient.prototype.createTrns =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/taxfilingpb.TrnsService/CreateTrns',
      request,
      metadata || {},
      methodDescriptor_TrnsService_CreateTrns);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.taxfilingpb.Trns,
 *   !proto.taxfilingpb.UpdateTrnsResponse>}
 */
const methodDescriptor_TrnsService_UpdateTrns = new grpc.web.MethodDescriptor(
  '/taxfilingpb.TrnsService/UpdateTrns',
  grpc.web.MethodType.UNARY,
  proto.taxfilingpb.Trns,
  proto.taxfilingpb.UpdateTrnsResponse,
  /**
   * @param {!proto.taxfilingpb.Trns} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.UpdateTrnsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.taxfilingpb.Trns,
 *   !proto.taxfilingpb.UpdateTrnsResponse>}
 */
const methodInfo_TrnsService_UpdateTrns = new grpc.web.AbstractClientBase.MethodInfo(
  proto.taxfilingpb.UpdateTrnsResponse,
  /**
   * @param {!proto.taxfilingpb.Trns} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.UpdateTrnsResponse.deserializeBinary
);


/**
 * @param {!proto.taxfilingpb.Trns} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.taxfilingpb.UpdateTrnsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.taxfilingpb.UpdateTrnsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.taxfilingpb.TrnsServiceClient.prototype.updateTrns =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/taxfilingpb.TrnsService/UpdateTrns',
      request,
      metadata || {},
      methodDescriptor_TrnsService_UpdateTrns,
      callback);
};


/**
 * @param {!proto.taxfilingpb.Trns} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.taxfilingpb.UpdateTrnsResponse>}
 *     Promise that resolves to the response
 */
proto.taxfilingpb.TrnsServicePromiseClient.prototype.updateTrns =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/taxfilingpb.TrnsService/UpdateTrns',
      request,
      metadata || {},
      methodDescriptor_TrnsService_UpdateTrns);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.taxfilingpb.ReadTrnsRequest,
 *   !proto.taxfilingpb.ReadTrnsResponse>}
 */
const methodDescriptor_TrnsService_ReadTrns = new grpc.web.MethodDescriptor(
  '/taxfilingpb.TrnsService/ReadTrns',
  grpc.web.MethodType.UNARY,
  proto.taxfilingpb.ReadTrnsRequest,
  proto.taxfilingpb.ReadTrnsResponse,
  /**
   * @param {!proto.taxfilingpb.ReadTrnsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.ReadTrnsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.taxfilingpb.ReadTrnsRequest,
 *   !proto.taxfilingpb.ReadTrnsResponse>}
 */
const methodInfo_TrnsService_ReadTrns = new grpc.web.AbstractClientBase.MethodInfo(
  proto.taxfilingpb.ReadTrnsResponse,
  /**
   * @param {!proto.taxfilingpb.ReadTrnsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.ReadTrnsResponse.deserializeBinary
);


/**
 * @param {!proto.taxfilingpb.ReadTrnsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.taxfilingpb.ReadTrnsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.taxfilingpb.ReadTrnsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.taxfilingpb.TrnsServiceClient.prototype.readTrns =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/taxfilingpb.TrnsService/ReadTrns',
      request,
      metadata || {},
      methodDescriptor_TrnsService_ReadTrns,
      callback);
};


/**
 * @param {!proto.taxfilingpb.ReadTrnsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.taxfilingpb.ReadTrnsResponse>}
 *     Promise that resolves to the response
 */
proto.taxfilingpb.TrnsServicePromiseClient.prototype.readTrns =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/taxfilingpb.TrnsService/ReadTrns',
      request,
      metadata || {},
      methodDescriptor_TrnsService_ReadTrns);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.taxfilingpb.DeleteTrnsRequest,
 *   !proto.taxfilingpb.DeleteTrnsResponse>}
 */
const methodDescriptor_TrnsService_DeleteTrns = new grpc.web.MethodDescriptor(
  '/taxfilingpb.TrnsService/DeleteTrns',
  grpc.web.MethodType.UNARY,
  proto.taxfilingpb.DeleteTrnsRequest,
  proto.taxfilingpb.DeleteTrnsResponse,
  /**
   * @param {!proto.taxfilingpb.DeleteTrnsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.DeleteTrnsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.taxfilingpb.DeleteTrnsRequest,
 *   !proto.taxfilingpb.DeleteTrnsResponse>}
 */
const methodInfo_TrnsService_DeleteTrns = new grpc.web.AbstractClientBase.MethodInfo(
  proto.taxfilingpb.DeleteTrnsResponse,
  /**
   * @param {!proto.taxfilingpb.DeleteTrnsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.DeleteTrnsResponse.deserializeBinary
);


/**
 * @param {!proto.taxfilingpb.DeleteTrnsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.taxfilingpb.DeleteTrnsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.taxfilingpb.DeleteTrnsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.taxfilingpb.TrnsServiceClient.prototype.deleteTrns =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/taxfilingpb.TrnsService/DeleteTrns',
      request,
      metadata || {},
      methodDescriptor_TrnsService_DeleteTrns,
      callback);
};


/**
 * @param {!proto.taxfilingpb.DeleteTrnsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.taxfilingpb.DeleteTrnsResponse>}
 *     Promise that resolves to the response
 */
proto.taxfilingpb.TrnsServicePromiseClient.prototype.deleteTrns =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/taxfilingpb.TrnsService/DeleteTrns',
      request,
      metadata || {},
      methodDescriptor_TrnsService_DeleteTrns);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.taxfilingpb.Trns,
 *   !proto.taxfilingpb.ListTrnsResponse>}
 */
const methodDescriptor_TrnsService_ListTrns = new grpc.web.MethodDescriptor(
  '/taxfilingpb.TrnsService/ListTrns',
  grpc.web.MethodType.UNARY,
  proto.taxfilingpb.Trns,
  proto.taxfilingpb.ListTrnsResponse,
  /**
   * @param {!proto.taxfilingpb.Trns} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.ListTrnsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.taxfilingpb.Trns,
 *   !proto.taxfilingpb.ListTrnsResponse>}
 */
const methodInfo_TrnsService_ListTrns = new grpc.web.AbstractClientBase.MethodInfo(
  proto.taxfilingpb.ListTrnsResponse,
  /**
   * @param {!proto.taxfilingpb.Trns} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.ListTrnsResponse.deserializeBinary
);


/**
 * @param {!proto.taxfilingpb.Trns} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.taxfilingpb.ListTrnsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.taxfilingpb.ListTrnsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.taxfilingpb.TrnsServiceClient.prototype.listTrns =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/taxfilingpb.TrnsService/ListTrns',
      request,
      metadata || {},
      methodDescriptor_TrnsService_ListTrns,
      callback);
};


/**
 * @param {!proto.taxfilingpb.Trns} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.taxfilingpb.ListTrnsResponse>}
 *     Promise that resolves to the response
 */
proto.taxfilingpb.TrnsServicePromiseClient.prototype.listTrns =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/taxfilingpb.TrnsService/ListTrns',
      request,
      metadata || {},
      methodDescriptor_TrnsService_ListTrns);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.taxfilingpb.Trns,
 *   !proto.taxfilingpb.CreateTrnsResponse>}
 */
const methodDescriptor_TrnsService_ImportTrns = new grpc.web.MethodDescriptor(
  '/taxfilingpb.TrnsService/ImportTrns',
  grpc.web.MethodType.UNARY,
  proto.taxfilingpb.Trns,
  proto.taxfilingpb.CreateTrnsResponse,
  /**
   * @param {!proto.taxfilingpb.Trns} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.CreateTrnsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.taxfilingpb.Trns,
 *   !proto.taxfilingpb.CreateTrnsResponse>}
 */
const methodInfo_TrnsService_ImportTrns = new grpc.web.AbstractClientBase.MethodInfo(
  proto.taxfilingpb.CreateTrnsResponse,
  /**
   * @param {!proto.taxfilingpb.Trns} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.CreateTrnsResponse.deserializeBinary
);


/**
 * @param {!proto.taxfilingpb.Trns} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.taxfilingpb.CreateTrnsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.taxfilingpb.CreateTrnsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.taxfilingpb.TrnsServiceClient.prototype.importTrns =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/taxfilingpb.TrnsService/ImportTrns',
      request,
      metadata || {},
      methodDescriptor_TrnsService_ImportTrns,
      callback);
};


/**
 * @param {!proto.taxfilingpb.Trns} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.taxfilingpb.CreateTrnsResponse>}
 *     Promise that resolves to the response
 */
proto.taxfilingpb.TrnsServicePromiseClient.prototype.importTrns =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/taxfilingpb.TrnsService/ImportTrns',
      request,
      metadata || {},
      methodDescriptor_TrnsService_ImportTrns);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.taxfilingpb.TrnsUploadRequest,
 *   !proto.taxfilingpb.TrnsUploadResponse>}
 */
const methodDescriptor_TrnsService_Upload = new grpc.web.MethodDescriptor(
  '/taxfilingpb.TrnsService/Upload',
  grpc.web.MethodType.UNARY,
  proto.taxfilingpb.TrnsUploadRequest,
  proto.taxfilingpb.TrnsUploadResponse,
  /**
   * @param {!proto.taxfilingpb.TrnsUploadRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.TrnsUploadResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.taxfilingpb.TrnsUploadRequest,
 *   !proto.taxfilingpb.TrnsUploadResponse>}
 */
const methodInfo_TrnsService_Upload = new grpc.web.AbstractClientBase.MethodInfo(
  proto.taxfilingpb.TrnsUploadResponse,
  /**
   * @param {!proto.taxfilingpb.TrnsUploadRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.TrnsUploadResponse.deserializeBinary
);


/**
 * @param {!proto.taxfilingpb.TrnsUploadRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.taxfilingpb.TrnsUploadResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.taxfilingpb.TrnsUploadResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.taxfilingpb.TrnsServiceClient.prototype.upload =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/taxfilingpb.TrnsService/Upload',
      request,
      metadata || {},
      methodDescriptor_TrnsService_Upload,
      callback);
};


/**
 * @param {!proto.taxfilingpb.TrnsUploadRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.taxfilingpb.TrnsUploadResponse>}
 *     Promise that resolves to the response
 */
proto.taxfilingpb.TrnsServicePromiseClient.prototype.upload =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/taxfilingpb.TrnsService/Upload',
      request,
      metadata || {},
      methodDescriptor_TrnsService_Upload);
};


module.exports = proto.taxfilingpb;

