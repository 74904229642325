/**
 * @fileoverview gRPC-Web generated client stub for taxfilingpb
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_type_date_pb = require('../../google/type/date_pb.js')

var proto_commonpb_file_pb = require('../../proto/commonpb/file_pb.js')

var proto_utilspb_pagination_pb = require('../../proto/utilspb/pagination_pb.js')
const proto = {};
proto.taxfilingpb = require('./form1099_divreclass_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.taxfilingpb.Form1099DivReClassServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.taxfilingpb.Form1099DivReClassServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.taxfilingpb.Form1099DivReClass,
 *   !proto.taxfilingpb.CreateForm1099DivReClassResponse>}
 */
const methodDescriptor_Form1099DivReClassService_CreateForm1099DivReClass = new grpc.web.MethodDescriptor(
  '/taxfilingpb.Form1099DivReClassService/CreateForm1099DivReClass',
  grpc.web.MethodType.UNARY,
  proto.taxfilingpb.Form1099DivReClass,
  proto.taxfilingpb.CreateForm1099DivReClassResponse,
  /**
   * @param {!proto.taxfilingpb.Form1099DivReClass} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.CreateForm1099DivReClassResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.taxfilingpb.Form1099DivReClass,
 *   !proto.taxfilingpb.CreateForm1099DivReClassResponse>}
 */
const methodInfo_Form1099DivReClassService_CreateForm1099DivReClass = new grpc.web.AbstractClientBase.MethodInfo(
  proto.taxfilingpb.CreateForm1099DivReClassResponse,
  /**
   * @param {!proto.taxfilingpb.Form1099DivReClass} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.CreateForm1099DivReClassResponse.deserializeBinary
);


/**
 * @param {!proto.taxfilingpb.Form1099DivReClass} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.taxfilingpb.CreateForm1099DivReClassResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.taxfilingpb.CreateForm1099DivReClassResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.taxfilingpb.Form1099DivReClassServiceClient.prototype.createForm1099DivReClass =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/taxfilingpb.Form1099DivReClassService/CreateForm1099DivReClass',
      request,
      metadata || {},
      methodDescriptor_Form1099DivReClassService_CreateForm1099DivReClass,
      callback);
};


/**
 * @param {!proto.taxfilingpb.Form1099DivReClass} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.taxfilingpb.CreateForm1099DivReClassResponse>}
 *     Promise that resolves to the response
 */
proto.taxfilingpb.Form1099DivReClassServicePromiseClient.prototype.createForm1099DivReClass =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/taxfilingpb.Form1099DivReClassService/CreateForm1099DivReClass',
      request,
      metadata || {},
      methodDescriptor_Form1099DivReClassService_CreateForm1099DivReClass);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.taxfilingpb.Form1099DivReClass,
 *   !proto.taxfilingpb.UpdateForm1099DivReClassResponse>}
 */
const methodDescriptor_Form1099DivReClassService_UpdateForm1099DivReClass = new grpc.web.MethodDescriptor(
  '/taxfilingpb.Form1099DivReClassService/UpdateForm1099DivReClass',
  grpc.web.MethodType.UNARY,
  proto.taxfilingpb.Form1099DivReClass,
  proto.taxfilingpb.UpdateForm1099DivReClassResponse,
  /**
   * @param {!proto.taxfilingpb.Form1099DivReClass} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.UpdateForm1099DivReClassResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.taxfilingpb.Form1099DivReClass,
 *   !proto.taxfilingpb.UpdateForm1099DivReClassResponse>}
 */
const methodInfo_Form1099DivReClassService_UpdateForm1099DivReClass = new grpc.web.AbstractClientBase.MethodInfo(
  proto.taxfilingpb.UpdateForm1099DivReClassResponse,
  /**
   * @param {!proto.taxfilingpb.Form1099DivReClass} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.UpdateForm1099DivReClassResponse.deserializeBinary
);


/**
 * @param {!proto.taxfilingpb.Form1099DivReClass} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.taxfilingpb.UpdateForm1099DivReClassResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.taxfilingpb.UpdateForm1099DivReClassResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.taxfilingpb.Form1099DivReClassServiceClient.prototype.updateForm1099DivReClass =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/taxfilingpb.Form1099DivReClassService/UpdateForm1099DivReClass',
      request,
      metadata || {},
      methodDescriptor_Form1099DivReClassService_UpdateForm1099DivReClass,
      callback);
};


/**
 * @param {!proto.taxfilingpb.Form1099DivReClass} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.taxfilingpb.UpdateForm1099DivReClassResponse>}
 *     Promise that resolves to the response
 */
proto.taxfilingpb.Form1099DivReClassServicePromiseClient.prototype.updateForm1099DivReClass =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/taxfilingpb.Form1099DivReClassService/UpdateForm1099DivReClass',
      request,
      metadata || {},
      methodDescriptor_Form1099DivReClassService_UpdateForm1099DivReClass);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.taxfilingpb.ReadForm1099DivReClassRequest,
 *   !proto.taxfilingpb.ReadForm1099DivReClassResponse>}
 */
const methodDescriptor_Form1099DivReClassService_ReadForm1099DivReClass = new grpc.web.MethodDescriptor(
  '/taxfilingpb.Form1099DivReClassService/ReadForm1099DivReClass',
  grpc.web.MethodType.UNARY,
  proto.taxfilingpb.ReadForm1099DivReClassRequest,
  proto.taxfilingpb.ReadForm1099DivReClassResponse,
  /**
   * @param {!proto.taxfilingpb.ReadForm1099DivReClassRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.ReadForm1099DivReClassResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.taxfilingpb.ReadForm1099DivReClassRequest,
 *   !proto.taxfilingpb.ReadForm1099DivReClassResponse>}
 */
const methodInfo_Form1099DivReClassService_ReadForm1099DivReClass = new grpc.web.AbstractClientBase.MethodInfo(
  proto.taxfilingpb.ReadForm1099DivReClassResponse,
  /**
   * @param {!proto.taxfilingpb.ReadForm1099DivReClassRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.ReadForm1099DivReClassResponse.deserializeBinary
);


/**
 * @param {!proto.taxfilingpb.ReadForm1099DivReClassRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.taxfilingpb.ReadForm1099DivReClassResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.taxfilingpb.ReadForm1099DivReClassResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.taxfilingpb.Form1099DivReClassServiceClient.prototype.readForm1099DivReClass =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/taxfilingpb.Form1099DivReClassService/ReadForm1099DivReClass',
      request,
      metadata || {},
      methodDescriptor_Form1099DivReClassService_ReadForm1099DivReClass,
      callback);
};


/**
 * @param {!proto.taxfilingpb.ReadForm1099DivReClassRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.taxfilingpb.ReadForm1099DivReClassResponse>}
 *     Promise that resolves to the response
 */
proto.taxfilingpb.Form1099DivReClassServicePromiseClient.prototype.readForm1099DivReClass =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/taxfilingpb.Form1099DivReClassService/ReadForm1099DivReClass',
      request,
      metadata || {},
      methodDescriptor_Form1099DivReClassService_ReadForm1099DivReClass);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.taxfilingpb.DeleteForm1099DivReClassRequest,
 *   !proto.taxfilingpb.DeleteForm1099DivReClassResponse>}
 */
const methodDescriptor_Form1099DivReClassService_DeleteForm1099DivReClass = new grpc.web.MethodDescriptor(
  '/taxfilingpb.Form1099DivReClassService/DeleteForm1099DivReClass',
  grpc.web.MethodType.UNARY,
  proto.taxfilingpb.DeleteForm1099DivReClassRequest,
  proto.taxfilingpb.DeleteForm1099DivReClassResponse,
  /**
   * @param {!proto.taxfilingpb.DeleteForm1099DivReClassRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.DeleteForm1099DivReClassResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.taxfilingpb.DeleteForm1099DivReClassRequest,
 *   !proto.taxfilingpb.DeleteForm1099DivReClassResponse>}
 */
const methodInfo_Form1099DivReClassService_DeleteForm1099DivReClass = new grpc.web.AbstractClientBase.MethodInfo(
  proto.taxfilingpb.DeleteForm1099DivReClassResponse,
  /**
   * @param {!proto.taxfilingpb.DeleteForm1099DivReClassRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.DeleteForm1099DivReClassResponse.deserializeBinary
);


/**
 * @param {!proto.taxfilingpb.DeleteForm1099DivReClassRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.taxfilingpb.DeleteForm1099DivReClassResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.taxfilingpb.DeleteForm1099DivReClassResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.taxfilingpb.Form1099DivReClassServiceClient.prototype.deleteForm1099DivReClass =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/taxfilingpb.Form1099DivReClassService/DeleteForm1099DivReClass',
      request,
      metadata || {},
      methodDescriptor_Form1099DivReClassService_DeleteForm1099DivReClass,
      callback);
};


/**
 * @param {!proto.taxfilingpb.DeleteForm1099DivReClassRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.taxfilingpb.DeleteForm1099DivReClassResponse>}
 *     Promise that resolves to the response
 */
proto.taxfilingpb.Form1099DivReClassServicePromiseClient.prototype.deleteForm1099DivReClass =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/taxfilingpb.Form1099DivReClassService/DeleteForm1099DivReClass',
      request,
      metadata || {},
      methodDescriptor_Form1099DivReClassService_DeleteForm1099DivReClass);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.taxfilingpb.Form1099DivReClass,
 *   !proto.taxfilingpb.ListForm1099DivReClassResponse>}
 */
const methodDescriptor_Form1099DivReClassService_ListForm1099DivReClass = new grpc.web.MethodDescriptor(
  '/taxfilingpb.Form1099DivReClassService/ListForm1099DivReClass',
  grpc.web.MethodType.UNARY,
  proto.taxfilingpb.Form1099DivReClass,
  proto.taxfilingpb.ListForm1099DivReClassResponse,
  /**
   * @param {!proto.taxfilingpb.Form1099DivReClass} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.ListForm1099DivReClassResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.taxfilingpb.Form1099DivReClass,
 *   !proto.taxfilingpb.ListForm1099DivReClassResponse>}
 */
const methodInfo_Form1099DivReClassService_ListForm1099DivReClass = new grpc.web.AbstractClientBase.MethodInfo(
  proto.taxfilingpb.ListForm1099DivReClassResponse,
  /**
   * @param {!proto.taxfilingpb.Form1099DivReClass} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.ListForm1099DivReClassResponse.deserializeBinary
);


/**
 * @param {!proto.taxfilingpb.Form1099DivReClass} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.taxfilingpb.ListForm1099DivReClassResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.taxfilingpb.ListForm1099DivReClassResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.taxfilingpb.Form1099DivReClassServiceClient.prototype.listForm1099DivReClass =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/taxfilingpb.Form1099DivReClassService/ListForm1099DivReClass',
      request,
      metadata || {},
      methodDescriptor_Form1099DivReClassService_ListForm1099DivReClass,
      callback);
};


/**
 * @param {!proto.taxfilingpb.Form1099DivReClass} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.taxfilingpb.ListForm1099DivReClassResponse>}
 *     Promise that resolves to the response
 */
proto.taxfilingpb.Form1099DivReClassServicePromiseClient.prototype.listForm1099DivReClass =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/taxfilingpb.Form1099DivReClassService/ListForm1099DivReClass',
      request,
      metadata || {},
      methodDescriptor_Form1099DivReClassService_ListForm1099DivReClass);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.taxfilingpb.UploadRequest,
 *   !proto.taxfilingpb.UploadResponse>}
 */
const methodDescriptor_Form1099DivReClassService_Upload = new grpc.web.MethodDescriptor(
  '/taxfilingpb.Form1099DivReClassService/Upload',
  grpc.web.MethodType.UNARY,
  proto.taxfilingpb.UploadRequest,
  proto.taxfilingpb.UploadResponse,
  /**
   * @param {!proto.taxfilingpb.UploadRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.UploadResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.taxfilingpb.UploadRequest,
 *   !proto.taxfilingpb.UploadResponse>}
 */
const methodInfo_Form1099DivReClassService_Upload = new grpc.web.AbstractClientBase.MethodInfo(
  proto.taxfilingpb.UploadResponse,
  /**
   * @param {!proto.taxfilingpb.UploadRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.UploadResponse.deserializeBinary
);


/**
 * @param {!proto.taxfilingpb.UploadRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.taxfilingpb.UploadResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.taxfilingpb.UploadResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.taxfilingpb.Form1099DivReClassServiceClient.prototype.upload =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/taxfilingpb.Form1099DivReClassService/Upload',
      request,
      metadata || {},
      methodDescriptor_Form1099DivReClassService_Upload,
      callback);
};


/**
 * @param {!proto.taxfilingpb.UploadRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.taxfilingpb.UploadResponse>}
 *     Promise that resolves to the response
 */
proto.taxfilingpb.Form1099DivReClassServicePromiseClient.prototype.upload =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/taxfilingpb.Form1099DivReClassService/Upload',
      request,
      metadata || {},
      methodDescriptor_Form1099DivReClassService_Upload);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.taxfilingpb.UploadStatus,
 *   !proto.taxfilingpb.UploadResponse>}
 */
const methodDescriptor_Form1099DivReClassService_CreateUploadStatus = new grpc.web.MethodDescriptor(
  '/taxfilingpb.Form1099DivReClassService/CreateUploadStatus',
  grpc.web.MethodType.UNARY,
  proto.taxfilingpb.UploadStatus,
  proto.taxfilingpb.UploadResponse,
  /**
   * @param {!proto.taxfilingpb.UploadStatus} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.UploadResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.taxfilingpb.UploadStatus,
 *   !proto.taxfilingpb.UploadResponse>}
 */
const methodInfo_Form1099DivReClassService_CreateUploadStatus = new grpc.web.AbstractClientBase.MethodInfo(
  proto.taxfilingpb.UploadResponse,
  /**
   * @param {!proto.taxfilingpb.UploadStatus} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.UploadResponse.deserializeBinary
);


/**
 * @param {!proto.taxfilingpb.UploadStatus} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.taxfilingpb.UploadResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.taxfilingpb.UploadResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.taxfilingpb.Form1099DivReClassServiceClient.prototype.createUploadStatus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/taxfilingpb.Form1099DivReClassService/CreateUploadStatus',
      request,
      metadata || {},
      methodDescriptor_Form1099DivReClassService_CreateUploadStatus,
      callback);
};


/**
 * @param {!proto.taxfilingpb.UploadStatus} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.taxfilingpb.UploadResponse>}
 *     Promise that resolves to the response
 */
proto.taxfilingpb.Form1099DivReClassServicePromiseClient.prototype.createUploadStatus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/taxfilingpb.Form1099DivReClassService/CreateUploadStatus',
      request,
      metadata || {},
      methodDescriptor_Form1099DivReClassService_CreateUploadStatus);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.taxfilingpb.ProcessRequest,
 *   !proto.taxfilingpb.ProcessResponse>}
 */
const methodDescriptor_Form1099DivReClassService_ProcessUpload = new grpc.web.MethodDescriptor(
  '/taxfilingpb.Form1099DivReClassService/ProcessUpload',
  grpc.web.MethodType.UNARY,
  proto.taxfilingpb.ProcessRequest,
  proto.taxfilingpb.ProcessResponse,
  /**
   * @param {!proto.taxfilingpb.ProcessRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.ProcessResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.taxfilingpb.ProcessRequest,
 *   !proto.taxfilingpb.ProcessResponse>}
 */
const methodInfo_Form1099DivReClassService_ProcessUpload = new grpc.web.AbstractClientBase.MethodInfo(
  proto.taxfilingpb.ProcessResponse,
  /**
   * @param {!proto.taxfilingpb.ProcessRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.ProcessResponse.deserializeBinary
);


/**
 * @param {!proto.taxfilingpb.ProcessRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.taxfilingpb.ProcessResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.taxfilingpb.ProcessResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.taxfilingpb.Form1099DivReClassServiceClient.prototype.processUpload =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/taxfilingpb.Form1099DivReClassService/ProcessUpload',
      request,
      metadata || {},
      methodDescriptor_Form1099DivReClassService_ProcessUpload,
      callback);
};


/**
 * @param {!proto.taxfilingpb.ProcessRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.taxfilingpb.ProcessResponse>}
 *     Promise that resolves to the response
 */
proto.taxfilingpb.Form1099DivReClassServicePromiseClient.prototype.processUpload =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/taxfilingpb.Form1099DivReClassService/ProcessUpload',
      request,
      metadata || {},
      methodDescriptor_Form1099DivReClassService_ProcessUpload);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.taxfilingpb.UploadStatus,
 *   !proto.taxfilingpb.UploadStatus>}
 */
const methodDescriptor_Form1099DivReClassService_ReadUploadStatus = new grpc.web.MethodDescriptor(
  '/taxfilingpb.Form1099DivReClassService/ReadUploadStatus',
  grpc.web.MethodType.UNARY,
  proto.taxfilingpb.UploadStatus,
  proto.taxfilingpb.UploadStatus,
  /**
   * @param {!proto.taxfilingpb.UploadStatus} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.UploadStatus.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.taxfilingpb.UploadStatus,
 *   !proto.taxfilingpb.UploadStatus>}
 */
const methodInfo_Form1099DivReClassService_ReadUploadStatus = new grpc.web.AbstractClientBase.MethodInfo(
  proto.taxfilingpb.UploadStatus,
  /**
   * @param {!proto.taxfilingpb.UploadStatus} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.UploadStatus.deserializeBinary
);


/**
 * @param {!proto.taxfilingpb.UploadStatus} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.taxfilingpb.UploadStatus)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.taxfilingpb.UploadStatus>|undefined}
 *     The XHR Node Readable Stream
 */
proto.taxfilingpb.Form1099DivReClassServiceClient.prototype.readUploadStatus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/taxfilingpb.Form1099DivReClassService/ReadUploadStatus',
      request,
      metadata || {},
      methodDescriptor_Form1099DivReClassService_ReadUploadStatus,
      callback);
};


/**
 * @param {!proto.taxfilingpb.UploadStatus} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.taxfilingpb.UploadStatus>}
 *     Promise that resolves to the response
 */
proto.taxfilingpb.Form1099DivReClassServicePromiseClient.prototype.readUploadStatus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/taxfilingpb.Form1099DivReClassService/ReadUploadStatus',
      request,
      metadata || {},
      methodDescriptor_Form1099DivReClassService_ReadUploadStatus);
};


module.exports = proto.taxfilingpb;

