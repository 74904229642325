import React from 'react';
import {
  TableFooter,
  TableRow,
  TableCell,
  makeStyles,
} from '@material-ui/core';
import { formatCurrency, formatPercentage, formatQty } from 'lib/fmt';

const useStyles = () => {
  return makeStyles((theme) => {
    return {
      totalBadge: {
        padding: '5px',
        borderRadius: '3px',
        color: '#ffffff',
      },
      footerCell: {
        backgroundColor: theme.palette.background.dark,
        color: theme.palette.text.light,
        border: 'none',
        position: 'sticky',
        bottom: 0,
        zIndex: 100,
        textAlign: 'right',
        padding: '1px 2px',
      },
    };
  })();
};

const quantity = 'quantity';
const amount = 'amount';
const percentage = 'percentage';

export default function ServerSideTableBodyFooter({
  columnOrder,
  columns,
  columnsWithAmt,
  columnsWithQty,
  columnsWithPercentage,
  rows,
  data,
  isFilterActive,
  additionalCell,
}) {
  const classes = useStyles();

  if (!data || !Object.keys(data)?.length) {
    return null;
  }

  const getType = (colName) => {
    if (columnsWithAmt && columnsWithAmt.includes(colName)) return amount;
    else if (columnsWithQty && columnsWithQty.includes(colName))
      return quantity;
    else if (columnsWithPercentage && columnsWithPercentage.includes(colName))
      return percentage;
  };

  const getFooterCell = (col, index) => {
    if (!col) return null;
    if (col.display !== 'true') return null;

    // Checked if add footer is enabled
    if (col.addFooter === false)
      return <TableCell className={classes.footerCell} key={index} />;

    if (data[col.name] === undefined)
      return <TableCell className={classes.footerCell} key={index} />;

    const bgColor = {
      background: data[col.name].includes('-') ? '#f44336' : '#4caf50',
    };

    return (
      <TableCell className={classes.footerCell} align="right" key={index}>
        <b style={bgColor} className={classes.totalBadge}>
          {data[col.name] || 0}
        </b>
      </TableCell>
    );
  };

  const getFilteredFooterCell = (col, type, index) => {
    const total = rows.reduce((runningTotal, row) => {
      return runningTotal + parseFloat(row[col.name] || 0);
    }, 0);

    const bgColor = { background: total < 0 ? '#f44336' : '#4caf50' };

    let displayValue = total;

    switch (type) {
      case amount:
        displayValue = formatCurrency(total);
        break;
      case quantity:
        displayValue = formatQty(total);
        break;
      case percentage:
        displayValue = formatPercentage(total);
        break;
      default:
        break;
    }

    return (
      <TableCell className={classes.footerCell} align="right" key={index}>
        <b style={bgColor} className={classes.totalBadge}>
          {displayValue}
        </b>
      </TableCell>
    );
  };

  return (
    <TableFooter>
      <TableRow>
        {additionalCell ? (
          <TableCell className={classes.footerCell} key="action" />
        ) : null}
        {columnOrder.map((order) => {
          const col = columns[order];
          const type = getType(col.name);

          if (isFilterActive) {
            if (!col.name)
              return <TableCell className={classes.footerCell} key={order} />;

            if (col.display !== 'true') return null;

            if (!type)
              return <TableCell className={classes.footerCell} key={order} />;
          }

          return isFilterActive
            ? getFilteredFooterCell(col, type, order)
            : getFooterCell(col, order);
        })}
      </TableRow>
    </TableFooter>
  );
}
