import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Popper,
  MenuList,
  Button,
  Grow,
  MenuItem,
  Paper,
  ClickAwayListener,
} from '@material-ui/core';
import { MENU_PAGES } from './MenuPages';

export const HeaderMenu = () => {
  return (
    <>
      {MENU_PAGES.map((menu) => (
        <Menu item={menu} key={menu.id} />
      ))}
    </>
  );
};

const Menu = ({ item }) => {
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleNavigate = (link) => {
    setOpen(false);
    history.push(link);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <span>
      <Button
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        style={{
          color: 'white',
          padding: '10px 20px',
        }}
      >
        {item.label}
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={false}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  {item.children.map((menuLink) => (
                    <MenuItem
                      key={menuLink.label}
                      disabled={menuLink.type === 'sub label'}
                      onClick={() => {
                        handleNavigate(menuLink.link);
                      }}
                      selected={menuLink.link === history.location.pathname}
                    >
                      <div
                        style={
                          menuLink.type === 'sub label'
                            ? {
                                fontWeight: 'bold',
                                fontSize: 'small',
                              }
                            : {
                                margin: '2px 10px',
                              }
                        }
                      >
                        {menuLink.label}
                      </div>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </span>
  );
};
