/*eslint eqeqeq: "off"*/
import {
  TinValidationServiceClient,
  TinValidationDownloadRequest,
  TinValidationUploadRequest,
  TinValidationProcessRequest,
} from '../proto/taxfilibpb/tin_validation_grpc_web_pb';
import {
  notifyError,
  notifySuccess,
} from 'components/Notification/Notification';
import { auth } from '../lib/auth/Auth';
import download from './DownloadService';
import { File } from '../proto/commonpb/file_pb';
const service = new TinValidationServiceClient(
  window.env.GRPC_ENDPOINT,
  {},
  { ...auth }
);

export async function downloadTin(param) {
  return new Promise((resolve, reject) => {
    let request = new TinValidationDownloadRequest();
    request.setYear(param.year);
    service.download(request, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        download(response);
        resolve(response);
        if (response.array[2]) {
          notifySuccess('Download ' + response.array[2] + ' completed');
        } else {
          notifySuccess('Download completed');
        }
      }
    });
  });
}

export async function upload(param) {
  return new Promise((resolve, reject) => {
    const file = new File();
    file.setFileName(param.fileName);
    file.setFileBytes(new Uint8Array(param.bytes));

    const req = new TinValidationUploadRequest();
    console.log(param.batchNo);
    req.setBatchNo(param.batchNo);
    req.setFile(file);
    service.upload(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        notifySuccess('successfully uploaded');
        resolve(response.toObject());
      }
    });
  });
}

export async function process(param) {
  return new Promise((resolve, reject) => {
    let request = new TinValidationProcessRequest();
    request.setBatchNo(param.batchNo);
    service.process(request, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        notifySuccess('process run successfully');
        resolve(response);
      }
    });
  });
}
