import React, { useState } from 'react';
import TrnsModal from './TrnsModal';
import TrnsUploadModal from './TrnsUploadModal';
import {
  notifySuccess,
  notifyInfo,
  notifyError,
} from 'components/Notification/Notification';
import {
  createTrns,
  updateTrns,
  deleteTrns,
  listTrns,
} from '../../../services/TrnsService';
import {
  Button,
  Box,
  TextField,
  IconButton,
  InputAdornment,
} from '@material-ui/core';
import {
  Add as AddIcon,
  Create as EditIcon,
  Delete as DeleteIcon,
  AssignmentReturn as ImportIcon,
} from '@material-ui/icons';
import Table, { columnType } from 'components/Table/Table';
import useStyles from '../../../styles';
import SearchButton from '../../../components/Button/Search';
import {
  SelectCorrespondent,
  SelectAccountNo,
  SelectIRSForms,
  SelectEntryType,
  SelectMasterProfile,
  SelectBranch,
} from 'components/AutoComplete';
import QueryParam from '../../../services/QueryParamService';
import { getSystemDate, getTradeDate } from '../../../services/ProfileService';
import { useConfirm } from 'material-ui-confirm';

export default function TrnsTable({ params }) {
  const year = new Date().getFullYear();
  const classes = useStyles();
  const confirm = useConfirm();
  React.useEffect(() => {
    async function init() {
      const systemDate = await getSystemDate();
      setSysDate(systemDate);
      const tradeDate = await getTradeDate();
      setTrdDate(tradeDate);
    }

    init();
  }, []);

  const options = {
    isRowSelectable: (dataIndex) => isDeleteEnalbed(dataIndex),
    onRowsDelete: (selectedItems) => {
      handleDelete(selectedItems.data);
      return false;
    },
  };

  const columns = [
    {
      name: '',
      type: columnType.buttons,
      options: {
        customBodyRenderLite: (dataIndex) => {
          return (
            <div
              align={'left'}
              className={classes.grdRow}
              style={{ margin: 0, padding: 0 }}
            >
              <div className={classes.grdCellNone}>
                <IconButton
                  aria-label="delete"
                  //disabled={!isDeleteEnabled(dataIndex)}
                  onClick={() => {
                    handleDelete([
                      {
                        dataIndex: dataIndex,
                      },
                    ]);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
              <div align={'left'} className={classes.grdCellNone}>
                <IconButton
                  aria-label="edit"
                  onClick={() => handleOpen(rows[dataIndex])}
                >
                  <EditIcon />
                </IconButton>
              </div>
            </div>
          );
        },
        setCellProps: () => ({
          style: { padding: '4px 16px' },
        }),
      },
    },
    {
      name: 'year',
      label: 'Year',
    },
    {
      name: 'correspondent',
      label: 'Correspondent',
    },
    {
      name: 'branch',
      label: 'Branch',
    },
    {
      name: 'accountNo',
      label: 'Account No',
    },
    {
      name: 'tradeDate',
      label: 'Trade Date',
      type: columnType.date,
    },
    {
      name: 'entryType',
      label: 'Entry Type',
    },
    {
      name: 'symbol',
      label: 'Symbol',
    },
    {
      name: 'cusip',
      label: 'Cusip',
    },
    {
      name: 'price',
      label: 'Price',
      type: columnType.amount,
    },
    {
      name: 'qty',
      label: 'Qty',
      type: columnType.quantity,
      addFooter: true,
    },
    {
      name: 'fees',
      label: 'Fees',
      type: columnType.quantity,
    },
    {
      name: 'netAmount',
      label: 'Net Amount',
      type: columnType.amount,
      addFooter: true,
    },
    {
      name: 'accountId',
      label: 'Account ID',
      options: {
        display: false,
      },
    },
    {
      name: 'settleDate',
      label: 'Settle Date',
      type: columnType.date,
    },
    {
      name: 'externalId',
      label: 'External ID',
    },
    {
      name: 'description',
      label: 'Description',
    },
    {
      name: 'grossAmount',
      label: 'Gross Amount',
      type: columnType.amount,
      addFooter: true,
    },
    {
      name: 'irsForm',
      label: 'IRS Form',
    },
    {
      name: 'symbolDescription',
      label: 'Symbol Description',
    },
    {
      name: 'originalCusip',
      label: 'Original Cusip',
    },
  ];

  const [rowData, setRowData] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [sysDate, setSysDate] = React.useState(null);
  const [trdDate, setTrdDate] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(true);
  const [rows, setRows] = useState([]);
  const [openImport, setOpenImport] = React.useState(false);
  const [searchData, setSearchData] = React.useState(
    QueryParam.get({
      year: '',
      correspondent: '',
      branch: '',
      accountNo: '',
      entryType: '',
      qty: '',
      price: '',
      fees: '',
      netAmount: '',
      tradeDate: '',
      settleDate: '',
      externalId: '',
      systemDate: '',
      description: '',
      grossAmount: '',
      irsForm: '',
      symbolDescription: '',
      symbolId: '',
      accountId: '',
      symbolSelectField: 'symbol',
      assetType: '',
      symbol: '',
      cusip: '',
      originalCusip: '',
    })
  );

  const handleChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;

    const isValidYear = /^[0-9]{0,4}$/.test(input?.value); // Allow only up to 4 digits
    if (input.name === 'year' && !isValidYear) return;

    const update = {};
    if (input.name == 'symbolDetails') {
      update.symbolSelectField = input.field;
      update.assetType = input.assetType;
      update.symbolDetails = input.details;
      update.symbol = input.details?.symbol || '';
      update.originalCusip = input.details?.originalCusip || '';
    } else {
      update[input.name] = input.value;
    }

    setSearchData((current) => {
      return { ...current, ...update };
    });
  };

  const handleSearch = async () => {
    try {
      setLoading(true);

      QueryParam.set(searchData);
      const data = await listTrns(searchData);

      setRows(data.trnsList);
      notifyInfo(data.trnsList.length + ' search results.');
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = (rowsToDelete) => {
    let messageKey = '';
    if (rowsToDelete.length === 1) {
      messageKey = rows[rowsToDelete[0].dataIndex].correspondent;
    } else {
      messageKey = rowsToDelete.length + ' items';
    }

    let idsToDelete = [];
    confirm({
      description:
        "You are about to delete '" +
        messageKey +
        "'. Please confirm your action.",
      confirmationText: 'Yes, delete',
    }).then(async () => {
      const rowsCopy = [...rows];
      try {
        for (const r of rowsToDelete) {
          let index = r.dataIndex;
          await deleteTrns(rows[index].trnsId);
          idsToDelete.push(rows[index].trnsId);
        }
      } catch (error) {
        console.error(error);
      } finally {
        const filtered = rowsCopy.filter(
          (r) => !idsToDelete.includes(r.trnsId)
        );
        setRows(filtered);
        notifySuccess(messageKey + 'Transactions has been deleted');
      }
    });
  };

  const isDeleteEnalbed = (dataIndex) => {
    return rows[dataIndex].status !== 'disabled';
  };

  const handleOpen = (data) => {
    if (!data) {
      //ADD default values
      data = {
        year: year.toString(),
        correspondent: '',
        branch: '',
        accountNo: '',
        entryType: 'TRD',
        qty: '',
        netAmount: '',
        settleDate: '',
        externalId: '',
        description: '',
        grossAmount: '',
        fees: '0',
        price: '',
        irsForm: '',
        symbolDescription: '',
        symbolId: '',
        accountId: '',
        systemDate: sysDate,
        tradeDate: trdDate,
        symbolSelectField: 'symbol',
        assetType: '',
        symbol: '',
        cusip: '',
        originalCusip: '',
      };
    }
    data.symbolSelectField = 'symbol';

    setRowData(data);
    setOpen(true);
  };

  const handleClose = async (data, isEdit) => {
    if (!data) {
      setOpen(false);
      return;
    }

    let valid = true;

    if (!data.correspondent) {
      notifyError('Correspondent is required.');
      valid = false;
    }

    if (!data.accountNo) {
      notifyError('Account No is required.');
      valid = false;
    }

    if (!data.year) {
      notifyError('Year is required.');
      valid = false;
    } else if (data.year?.length < 4) {
      notifyError('Year should have 4 characters');
      valid = false;
    }

    if (!data.tradeDate) {
      notifyError('Trade Date is required.');
      valid = false;
    }

    if (!data.settleDate || data.settleDate === 'Invalid date') {
      notifyError('Settle Date is required.');
      valid = false;
    }

    if (!data.cusip) {
      notifyError('Cusip is required.');
      valid = false;
    }

    if (!data.entryType) {
      notifyError('Entry Type is required.');
      valid = false;
    }

    if (!data.grossAmount) {
      notifyError('Gross Amount is required.');
      valid = false;
    }

    if (!data.qty) {
      notifyError('Qty is required.');
      valid = false;
    }

    if (!data.symbol) {
      notifyError('Symbol is required.');
      valid = false;
    }

    if (!data.irsForm) {
      notifyError('IRS Form is required.');
      valid = false;
    }

    if (!valid) {
      return;
    }

    try {
      if (isEdit) {
        //EDIT
        const resp = await updateTrns(data);
        const rowsCopy = [...rows];
        const index = rows.indexOf(rowData);
        rowsCopy[index] = resp.trns;
        setRows(rowsCopy);
        notifySuccess('Transaction has been updated.');
      } else {
        //ADD
        const resp = await createTrns(data);

        setRows([resp.trns, ...rows]);
        notifySuccess('New Transaction has been added.');
      }

      setOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleUploadClose = async (data, isEdit) => {
    setOpenImport(false);
  };

  return (
    <div className={classes.root}>
      <Box component="div" mt={5}>
        {showFilter ? (
          <div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  name="year"
                  label="Year"
                  type="number"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={searchData.year}
                  onChange={handleChange}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <SelectCorrespondent
                  name="correspondent"
                  label="Correspondent"
                  value={searchData.correspondent}
                  onChange={handleChange}
                  inputProps={{ maxLength: 4 }}
                ></SelectCorrespondent>
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <SelectBranch
                  name="branch"
                  label="Branch"
                  value={searchData.branch}
                  onChange={handleChange}
                  inputProps={{ maxLength: 3 }}
                  correspondent={searchData.correspondent}
                />
              </div>
              <div className={classes.grdCell1}>
                <SelectAccountNo
                  name="accountNo"
                  label="Account No"
                  value={searchData.accountNo}
                  onChange={handleChange}
                  inputProps={{ maxLength: 12 }}
                  correspondent={searchData.correspondent}
                />
              </div>
            </div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  name="tradeDate"
                  fullWidth
                  label="Trade Date"
                  type="date"
                  value={searchData.tradeDate}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  name="settleDate"
                  fullWidth
                  label="Settle Date"
                  type="date"
                  value={searchData.settleDate}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <SelectMasterProfile
                  freeSolo
                  name="symbolDetails"
                  value={searchData.symbolDetails}
                  originalCusipValue={searchData.originalCusip} // for getting selected value on load
                  assetTypeValue={searchData.assetType}
                  fieldValue={searchData.symbolSelectField}
                  defaultInputValue={searchData[searchData.symbolSelectField]}
                  onChange={handleChange}
                />
              </div>
              <div className={classes.grdCell1}>
                <TextField
                  name="cusip"
                  fullWidth
                  label="Cusip"
                  inputProps={{
                    maxlength: 12,
                  }}
                  value={searchData.cusip}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
            </div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  name="symbolDescription"
                  fullWidth
                  label="Symbol Description"
                  inputProps={{
                    maxlength: 300,
                  }}
                  value={searchData.symbolDescription}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <SelectEntryType
                  name="entryType"
                  label="Entry Type"
                  value={searchData.entryType}
                  onChange={handleChange}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  name="qty"
                  fullWidth
                  label="Qty"
                  type="number"
                  value={searchData.qty}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className={classes.grdCell1}>
                <TextField
                  name="price"
                  fullWidth
                  label="Price"
                  type="number"
                  value={searchData.price}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
            </div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  name="grossAmount"
                  fullWidth
                  label="Gross Amount"
                  type="number"
                  value={searchData.grossAmount}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  name="fees"
                  fullWidth
                  label="Fees"
                  type="number"
                  value={searchData.fees ? searchData.fees : '0'}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  name="netAmount"
                  fullWidth
                  label="Net Amount"
                  type="number"
                  value={searchData.netAmount}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className={classes.grdCell1}>
                <SelectIRSForms
                  freeSolo={false}
                  name="irsForm"
                  label="IRS Form"
                  value={searchData.irsForm}
                  onChange={handleChange}
                  inputProps={{
                    maxlength: 20,
                  }}
                />
              </div>
            </div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  name="externalId"
                  fullWidth
                  label="External ID"
                  inputProps={{
                    maxlength: 50,
                  }}
                  value={searchData.externalId}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  name="description"
                  fullWidth
                  label="Description"
                  inputProps={{
                    maxlength: 150,
                  }}
                  value={searchData.description}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div
                className={classes.grdCell1}
                style={{ marginRight: 30 }}
              ></div>
              <div className={classes.grdCell1}></div>
            </div>
          </div>
        ) : null}
        <div className={classes.actionContainer}>
          <div className={classes.grdCellNone} style={{ marginRight: 10 }}>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              startIcon={<AddIcon />}
              onClick={() => {
                handleOpen();
              }}
            >
              Add New
            </Button>
          </div>
          <div className={classes.grdCellNone}>
            <SearchButton
              onClick={handleSearch}
              loading={loading}
              showfilter={(status) => setShowFilter(status)}
            />
          </div>
          <div className={classes.grdCellNone} style={{ marginLeft: 10 }}>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              startIcon={<ImportIcon />}
              onClick={() => {
                setOpenImport(true);
              }}
            >
              Import
            </Button>
          </div>
        </div>
      </Box>
      <Box component="div" mt={2}>
        <Table
          title={'Transaction'}
          data={rows}
          columns={columns}
          options={options}
          additionalCell={true}
        />
      </Box>
      {open && (
        <TrnsModal
          onClose={handleClose}
          open={open}
          value={rowData}
        ></TrnsModal>
      )}

      {openImport && (
        <TrnsUploadModal
          onClose={handleUploadClose}
          open={openImport}
          value={rowData}
        ></TrnsUploadModal>
      )}
    </div>
  );
}
