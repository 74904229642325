/*ReactJS*/
import React, { useState, useEffect } from 'react';
import useStyles from '../../../styles';
import moment from 'moment-timezone';
// import SymbolSelect from '../../../components/AutoComplete/Symbol';

import {
  Typography,
  Button,
  Modal,
  Backdrop,
  Fade,
  Box,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import {
  SelectCorrespondent,
  SelectAccountNo,
  SelectIRSForms,
  SelectBranch,
  SelectEntryType,
  SelectMasterProfile,
} from 'components/AutoComplete';

import { formatPbDate } from 'lib/fmt';

import { dateProtoObjectToString } from '../../../services/ConvertService';
import { readSettleDate } from '../../../services/ProfileService';

export default function TrnsModal({
  onClose: handleClose,
  open: isOpen,
  value,
}) {
  const classes = useStyles();
  const [modalData, setModalData] = useState({
    symbolSelectField: 'symbol',
    assetType: '',
    symbol: '',
    cusip: '',
    originalCusip: '',
  });
  const [isEdit, setIsEdit] = React.useState(false);
  const [tradeDateValue, setTradeDateValue] = React.useState('');
  const [assetTypeValue, setAssetTypeValue] = React.useState('');
  const [entryTypeValue, setEntryTypeValue] = React.useState('');

  useEffect(() => {
    async function init() {
      if (isOpen) {
        let systemDate = value.systemDate;
        let tradeDate = value.tradeDate;
        let settleDate = value.settleDate;
        if (value.trnsId) {
          systemDate = dateProtoObjectToString(systemDate);
          tradeDate = dateProtoObjectToString(tradeDate);
          settleDate = dateProtoObjectToString(settleDate);
          setIsEdit(true);
        }
        setModalData({
          ...value,
          tradeDate: tradeDate,
          settleDate: settleDate,
          systemDate: systemDate,
        });
      }
    }
    init();
  }, [isOpen, value]);

  useEffect(() => {
    async function setSettleDate() {
      const params = {
        tradeDate: modalData.tradeDate || tradeDateValue,
        assetType: modalData.assetType || assetTypeValue,
        entryType: modalData.entryType || entryTypeValue,
      };
      let settleDateValue = await readSettleDate(params);
      if (settleDateValue && settleDateValue !== 'Invalid date') {
        setModalData((current) => {
          return {
            ...current,
            settleDate: moment(new Date(formatPbDate(settleDateValue))).format(
              'YYYY-MM-DD'
            ),
          };
        });
      }
    }
    if (tradeDateValue || assetTypeValue || entryTypeValue) {
      setSettleDate();
    }
  }, [tradeDateValue, assetTypeValue, entryTypeValue]);

  const handleChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;

    const isValidYear = /^[0-9]{0,4}$/.test(input?.value); // Allow only up to 4 digits
    if (input.name === 'year' && !isValidYear) return;

    const update = {};

    if (['fees'].includes(input.name)) {
      update.netAmount = modalData.price * modalData.qty - input.value;
    } else if (['price'].includes(input.name)) {
      update.netAmount = input.value * modalData.qty - (modalData.fees || 0);
      update.grossAmount = input.value * modalData.qty;
    } else if (['qty'].includes(input.name)) {
      update.netAmount = modalData.price * input.value - (modalData.fees || 0);
      update.grossAmount = input.value * modalData.price;
    } else if (['tradeDate'].includes(input.name)) {
      setTradeDateValue(input.value);
    } else if (['assetType'].includes(input.name)) {
      setAssetTypeValue(input.value);
    } else if (['entryType'].includes(input.name)) {
      setEntryTypeValue(input.value);
    }

    if (input.name == 'symbolDetails') {
      update.symbolSelectField = input.field;
      update.assetType = input.assetType;
      update.symbolDetails = input.details;
      update.symbol = input.details?.symbol || '';
      update.cusip = input.details?.cusip || value.cusip || '';
      update.originalCusip =
        input.details?.originalCusip || value.originalCusip || '';
      update.symbolDescription =
        input.details?.symbolDescription || value.symbolDescription || '';

      setAssetTypeValue(input.assetType);
    } else {
      update[input.name] = input.value;
    }

    setModalData((current) => {
      return { ...current, ...update };
    });
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modalBackdrop}
      open={isOpen}
      onClose={() => {
        handleClose();
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={isOpen}>
        <div className={classes.fadeModalFull}>
          <Typography
            id="transition-modal-title"
            variant="h4"
            className={classes.textBold}
            gutterBottom
          >
            {isEdit ? 'Edit' : 'Add New'} Transactions
          </Typography>
          <Box mt={5}>
            <form noValidate autoComplete="off">
              <div className={classes.grdRow}>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    required
                    name="year"
                    label="Year"
                    type="number"
                    fullWidth
                    inputProps={{
                      min: 2000,
                      max: 3000,
                    }}
                    InputLabelProps={{ shrink: true }}
                    value={modalData.year}
                    onChange={handleChange}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <SelectCorrespondent
                    required={true}
                    name="correspondent"
                    label="Correspondent"
                    value={modalData.correspondent || ''}
                    onChange={handleChange}
                    inputProps={{ maxLength: 4 }}
                  ></SelectCorrespondent>
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <SelectBranch
                    name="branch"
                    label="Branch"
                    value={modalData.branch || ''}
                    onChange={handleChange}
                    inputProps={{ maxLength: 3 }}
                    correspondent={modalData.correspondent}
                  />
                </div>
                <div className={classes.grdCell1}>
                  <SelectAccountNo
                    required={true}
                    name="accountNo"
                    label="Account No"
                    value={modalData.accountNo || ''}
                    onChange={handleChange}
                    inputProps={{ maxLength: 12 }}
                    correspondent={modalData.correspondent}
                  />
                </div>
              </div>
              <div className={classes.grdRow}>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    required
                    name="tradeDate"
                    fullWidth
                    label="Trade Date"
                    type="date"
                    value={modalData.tradeDate}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    required
                    name="settleDate"
                    fullWidth
                    label="Settle Date"
                    type="date"
                    value={modalData.settleDate}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <SelectMasterProfile
                    freeSolo
                    required
                    name="symbolDetails"
                    value={modalData.symbolDetails}
                    originalCusipValue={modalData.originalCusip || ''} // for getting selected value on load
                    assetTypeValue={modalData.assetType || ''}
                    fieldValue={modalData.symbolSelectField || ''}
                    defaultInputValue={
                      modalData[modalData.symbolSelectField] || ''
                    }
                    onChange={handleChange}
                  />
                </div>
                <div className={classes.grdCell1}>
                  <TextField
                    required
                    name="cusip"
                    fullWidth
                    label="Cusip"
                    inputProps={{
                      maxlength: 12,
                    }}
                    value={modalData.cusip}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
              </div>
              <div className={classes.grdRow}>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    name="symbolDescription"
                    fullWidth
                    label="Symbol Description"
                    inputProps={{
                      maxlength: 300,
                    }}
                    value={modalData.symbolDescription}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <SelectEntryType
                    required={true}
                    name="entryType"
                    label="Entry Type"
                    value={modalData.entryType || ''}
                    onChange={handleChange}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    required
                    name="qty"
                    fullWidth
                    label="Qty"
                    type="number"
                    value={modalData.qty}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
                <div className={classes.grdCell1}>
                  <TextField
                    required
                    name="price"
                    fullWidth
                    label="Price"
                    type="number"
                    value={modalData.price}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
              </div>
              <div className={classes.grdRow}>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    required
                    disabled={true}
                    name="grossAmount"
                    fullWidth
                    label="Gross Amount"
                    type="number"
                    value={modalData.grossAmount}
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    name="fees"
                    fullWidth
                    label="Fees"
                    type="number"
                    value={modalData.fees}
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    disabled={true}
                    name="netAmount"
                    fullWidth
                    label="Net Amount"
                    type="number"
                    value={modalData.netAmount}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className={classes.grdCell1}>
                  <SelectIRSForms
                    required={true}
                    freeSolo={false}
                    name="irsForm"
                    label="IRS Form"
                    value={modalData.irsForm || ''}
                    onChange={handleChange}
                    inputProps={{
                      maxlength: 20,
                    }}
                  />
                </div>
              </div>
              <div className={classes.grdRow}>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    name="externalId"
                    fullWidth
                    label="External ID"
                    inputProps={{
                      maxlength: 50,
                    }}
                    value={modalData.externalId}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    name="description"
                    fullWidth
                    label="Description"
                    inputProps={{
                      maxlength: 150,
                    }}
                    value={modalData.description}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
                <div
                  className={classes.grdCell1}
                  style={{ marginRight: 30 }}
                ></div>
                <div className={classes.grdCell1}></div>
              </div>
              <div className={classes.modalFooter}>
                <div
                  className={classes.grdCellNone}
                  style={{ marginRight: 10 }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Close
                  </Button>
                </div>
                <div className={classes.grdCellNone}>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    onClick={() => {
                      handleClose(modalData, isEdit);
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </form>
          </Box>
        </div>
      </Fade>
    </Modal>
  );
}
