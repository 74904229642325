/*ReactJS*/
import React, { useState, useEffect } from 'react';
import useStyles from '../../../styles';
import {
  IconButton,
  Modal,
  Backdrop,
  Fade,
  Box,
  jssPreset,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import Table, { columnType } from 'components/Table/Table';
import { form1099bDetails } from '../../../services/Form1099BService';
import { notifyError, notifyInfo } from 'components/Notification/Notification';

export default function Form1099BDetailsModal({
  onClose: handleClose,
  open: isOpen,
  value,
}) {
  const classes = useStyles();
  const [pagination, setPagination] = useState({});
  const [previousRows, setPreviousRows] = useState(0);

  const [rows, setRows] = useState([]);

  const columns = [
    {
      name: 'dateAcq',
      label: 'Acquired Date',
      type: columnType.date,
    },
    {
      name: 'dateSale',
      label: 'Disposed Date',
      type: columnType.date,
    },
    {
      name: 'symbol',
      label: 'Symbol',
    },
    {
      name: 'originalCusip',
      label: 'Original Cusip',
    },
    {
      name: 'cusip',
      label: 'Cusip',
    },
    {
      name: 'symbolDescription',
      label: 'Symbol Description',
    },
    {
      name: 'cost',
      label: 'Cost',
      type: columnType.amount9Decimal,
      addFooter: true,
    },
    {
      name: 'proceed',
      label: 'Proceeds',
      type: columnType.amount9Decimal,
      addFooter: true,
    },
    {
      name: 'netAmount',
      label: 'Net Amount',
      type: columnType.amount9Decimal,
      addFooter: true,
    },
    {
      name: 'accruedMarketDiscount',
      label: 'Accrued Market Discount',
      type: columnType.amount,
      addFooter: true,
    },
    {
      name: 'washSaleLossDisallowed',
      label: 'Wash Sale Loss Disallowed',
      type: columnType.amount9Decimal,
      addFooter: true,
    },
    {
      name: 'currentYearRealized',
      label: 'Current Year Realized',
      type: columnType.amount,
      addFooter: true,
    },
    {
      name: 'currentYearUnrealized',
      label: 'Current Year Unrealized',
      type: columnType.amount,
      addFooter: true,
    },
    {
      name: 'priorYearRealized',
      label: 'Prior Year Unrealized',
      type: columnType.amount,
      addFooter: true,
    },
    {
      name: 'aggregateProfitLoss',
      label: 'Aggregate Profit Loss',
      type: columnType.amount,
      addFooter: true,
    },
    {
      name: 'entryType',
      label: 'Entry Type',
    },
    {
      name: 'sellTrnsId',
      label: 'Sell Trns Id',
    },
    {
      name: 'buyTrnsId',
      label: 'Buy Trns Id',
    },
    {
      name: 'trnsId',
      label: 'Trns Id',
    },
    {
      name: 'qty',
      label: 'Qty',
    },
    {
      name: 'sellPrice',
      label: 'Sell Price',
      type: columnType.amount,
      addFooter: true,
    },
    {
      name: 'buyPrice',
      label: 'Buy Price',
      type: columnType.amount,
      addFooter: true,
    },
    {
      name: 'shortTerm',
      label: 'Short Term',
      type: columnType.boolean,
      options: {
        setCellProps: () => ({
          align: 'center',
        }),
      },
    },
    {
      name: 'longTerm',
      label: 'Long Term',
      type: columnType.boolean,
      options: {
        setCellProps: () => ({
          align: 'center',
        }),
      },
    },
  ];

  const options = {
    selectableRows: 'none',
  };

  useEffect(() => {
    if (isOpen) {
      handleSearch();
    }
  }, [isOpen]);

  const handleSearch = async (paginationChange) => {
    try {
      let paginationCopy = {
        ...pagination,
        reload: false,
        pageNo: paginationChange === true ? pagination.pageNo : 0,
        rowsPerPage: pagination.rowsPerPage || 100,
      };
      if (previousRows < pagination.rowsPerPage * pagination.pageNo) {
        paginationCopy.pageNo = 0;
      }
      if (paginationChange !== true) {
        paginationCopy.filterNames = [];
        paginationCopy.filterValues = [];
      }
      const data = await form1099bDetails(value, paginationCopy);
      paginationCopy.count = data.summary.totalRows;
      setPreviousRows(data.summary.totalRows);
      setPagination(paginationCopy);
      setRows(data.form1099BDetailsList);

      if (paginationChange !== true) {
        notifyInfo(data.summary.totalRows + ' search results.');
      }
    } catch (error) {
      notifyError(error.message);
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modalBackdrop}
      open={isOpen}
      onClose={() => {
        handleClose();
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={isOpen}>
        <div className={classes.tableModalFull}>
          <Box component="div" mt={2}>
            <Table
              title="FORM 1099 B DETAILS"
              data={rows}
              columns={columns}
              options={options}
            />
          </Box>

          <div className={classes.modalFooter} style={{ margin: 0 }}>
            <div className={classes.grdCellNone} style={{ marginRight: 10 }}>
              <div
                className={classes.modalCloseIcon}
                style={{ marginRight: 10 }}
              >
                <IconButton
                  aria-label="close"
                  onClick={() => {
                    handleClose();
                  }}
                >
                  <CloseIcon style={{ color: '#f1f1f1' }} />
                </IconButton>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
