/*eslint eqeqeq: "off"*/
import {
  Form1099IntServiceClient,
  Form1099IntRequest,
  DownLoadIntRequest,
  Form1099Int,
} from '../proto/taxfilibpb/form1099_int_grpc_web_pb';
import {
  notifyError,
  notifySuccess,
} from 'components/Notification/Notification';
import { auth } from '../lib/auth/Auth';
import download from './DownloadService';
import { Pagination } from 'proto/utilspb/pagination_pb';

const service = new Form1099IntServiceClient(
  window.env.GRPC_ENDPOINT,
  {},
  { ...auth }
);

export async function listForm1099Int(param, paging) {
  return new Promise((resolve, reject) => {
    const req = new Form1099IntRequest();
    req.setYear(param.year);
    req.setCorrespondent(param.correspondent);
    req.setBranch(param.branch);
    req.setAccountNo(param.accountNo);

    if (paging) {
      let pg = new Pagination();
      pg.setPageNo(paging.pageNo);
      pg.setPageSize(paging.rowsPerPage);
      pg.setSortName(paging.sortName);
      pg.setSortDirection(paging.sortDirection);
      pg.setFilterNamesList(paging.filterNames);
      pg.setFilterValuesList(paging.filterValues);
      req.setPagination(pg);
    }

    service.listForm1099Int(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function form1099IntDetails(param, paging) {
  return new Promise((resolve, reject) => {
    const req = new Form1099IntRequest();
    req.setYear(param.year);
    req.setCorrespondent(param.correspondent);
    req.setBranch(param.branch);
    req.setAccountNo(param.accountNo);

    if (paging) {
      let pg = new Pagination();
      pg.setPageNo(paging.pageNo);
      pg.setPageSize(paging.rowsPerPage);
      pg.setSortName(paging.sortName);
      pg.setSortDirection(paging.sortDirection);
      pg.setFilterNamesList(paging.filterNames);
      pg.setFilterValuesList(paging.filterValues);
      req.setPagination(pg);
    }

    service.form1099IntDetails(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function downloadInt(param, filetype, batchNo, downloadType) {
  return new Promise((resolve, reject) => {
    let request = new DownLoadIntRequest();
    request.setFileType(filetype);
    request.setBatchNo(batchNo);
    request.setDownloadType(downloadType);
    let req = new Form1099IntRequest();
    req.setYear(param.year);
    req.setCorrespondent(param.correspondent);
    req.setBranch(param.branch);
    req.setAccountNo(param.accountNo);
    // req.setUsrId(param.usrId);
    request.setForm1099IntRequest(req);

    service.download(request, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        console.log(response.array);
        download(response);
        resolve(response);
        if (response.array[2]) {
          notifySuccess('Download ' + response.array[2] + ' completed');
        } else {
          notifySuccess('Download completed');
        }
      }
    });
  });
}

export async function generateInt(param, filetype, batchNo, downloadType) {
  return new Promise((resolve, reject) => {
    let request = new DownLoadIntRequest();
    request.setFileType(filetype);
    request.setBatchNo(batchNo);
    request.setDownloadType(downloadType);
    let req = new Form1099IntRequest();
    req.setYear(param.year);
    req.setCorrespondent(param.correspondent);
    req.setBranch(param.branch);
    req.setAccountNo(param.accountNo);
    // req.setUsrId(param.usrId);
    request.setForm1099IntRequest(req);

    service.generate(request, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        console.log(response.array);
        resolve(response);
      }
    });
  });
}

export async function updateForm1099Int(param) {
  return new Promise((resolve, reject) => {
    const req = new Form1099Int();
    req.setYear(param.year);
    req.setTaxCountry(param.taxCountry);
    req.setInt(param.pb_int);
    req.setForm1099intId(param.form1099intId);

    service.updateFormInt(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}
