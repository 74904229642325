// source: proto/taxfilingpb/trns.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_type_date_pb = require('../../google/type/date_pb.js');
goog.object.extend(proto, google_type_date_pb);
var proto_commonpb_file_pb = require('../../proto/commonpb/file_pb.js');
goog.object.extend(proto, proto_commonpb_file_pb);
goog.exportSymbol('proto.taxfilingpb.CreateTrnsResponse', null, global);
goog.exportSymbol('proto.taxfilingpb.DeleteTrnsRequest', null, global);
goog.exportSymbol('proto.taxfilingpb.DeleteTrnsResponse', null, global);
goog.exportSymbol('proto.taxfilingpb.ListTrnsResponse', null, global);
goog.exportSymbol('proto.taxfilingpb.ReadTrnsRequest', null, global);
goog.exportSymbol('proto.taxfilingpb.ReadTrnsResponse', null, global);
goog.exportSymbol('proto.taxfilingpb.Trns', null, global);
goog.exportSymbol('proto.taxfilingpb.TrnsUploadRequest', null, global);
goog.exportSymbol('proto.taxfilingpb.TrnsUploadResponse', null, global);
goog.exportSymbol('proto.taxfilingpb.UpdateTrnsResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.Trns = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.taxfilingpb.Trns, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.Trns.displayName = 'proto.taxfilingpb.Trns';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.CreateTrnsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.taxfilingpb.CreateTrnsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.CreateTrnsResponse.displayName = 'proto.taxfilingpb.CreateTrnsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.ReadTrnsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.taxfilingpb.ReadTrnsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.ReadTrnsRequest.displayName = 'proto.taxfilingpb.ReadTrnsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.ReadTrnsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.taxfilingpb.ReadTrnsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.ReadTrnsResponse.displayName = 'proto.taxfilingpb.ReadTrnsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.UpdateTrnsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.taxfilingpb.UpdateTrnsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.UpdateTrnsResponse.displayName = 'proto.taxfilingpb.UpdateTrnsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.DeleteTrnsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.taxfilingpb.DeleteTrnsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.DeleteTrnsRequest.displayName = 'proto.taxfilingpb.DeleteTrnsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.DeleteTrnsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.taxfilingpb.DeleteTrnsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.DeleteTrnsResponse.displayName = 'proto.taxfilingpb.DeleteTrnsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.ListTrnsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.taxfilingpb.ListTrnsResponse.repeatedFields_, null);
};
goog.inherits(proto.taxfilingpb.ListTrnsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.ListTrnsResponse.displayName = 'proto.taxfilingpb.ListTrnsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.TrnsUploadRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.taxfilingpb.TrnsUploadRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.TrnsUploadRequest.displayName = 'proto.taxfilingpb.TrnsUploadRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.TrnsUploadResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.taxfilingpb.TrnsUploadResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.TrnsUploadResponse.displayName = 'proto.taxfilingpb.TrnsUploadResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.Trns.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.Trns.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.Trns} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.Trns.toObject = function(includeInstance, msg) {
  var f, obj = {
    trnsId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    year: jspb.Message.getFieldWithDefault(msg, 2, ""),
    correspondent: jspb.Message.getFieldWithDefault(msg, 3, ""),
    branch: jspb.Message.getFieldWithDefault(msg, 4, ""),
    accountNo: jspb.Message.getFieldWithDefault(msg, 5, ""),
    tradeDate: (f = msg.getTradeDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    entryType: jspb.Message.getFieldWithDefault(msg, 7, ""),
    symbol: jspb.Message.getFieldWithDefault(msg, 8, ""),
    cusip: jspb.Message.getFieldWithDefault(msg, 9, ""),
    qty: jspb.Message.getFieldWithDefault(msg, 10, ""),
    netAmount: jspb.Message.getFieldWithDefault(msg, 11, ""),
    settleDate: (f = msg.getSettleDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    externalId: jspb.Message.getFieldWithDefault(msg, 13, ""),
    systemDate: (f = msg.getSystemDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    description: jspb.Message.getFieldWithDefault(msg, 15, ""),
    grossAmount: jspb.Message.getFieldWithDefault(msg, 16, ""),
    irsForm: jspb.Message.getFieldWithDefault(msg, 17, ""),
    symbolDescription: jspb.Message.getFieldWithDefault(msg, 18, ""),
    originalCusip: jspb.Message.getFieldWithDefault(msg, 19, ""),
    accountId: jspb.Message.getFieldWithDefault(msg, 20, 0),
    multiplier: jspb.Message.getFieldWithDefault(msg, 21, ""),
    price: jspb.Message.getFieldWithDefault(msg, 22, ""),
    assetType: jspb.Message.getFieldWithDefault(msg, 23, ""),
    fees: jspb.Message.getFieldWithDefault(msg, 24, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.Trns}
 */
proto.taxfilingpb.Trns.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.Trns;
  return proto.taxfilingpb.Trns.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.Trns} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.Trns}
 */
proto.taxfilingpb.Trns.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTrnsId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setYear(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBranch(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 6:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setTradeDate(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntryType(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCusip(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setQty(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setNetAmount(value);
      break;
    case 12:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setSettleDate(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalId(value);
      break;
    case 14:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setSystemDate(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setGrossAmount(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setIrsForm(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbolDescription(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginalCusip(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAccountId(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setMultiplier(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrice(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetType(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setFees(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.Trns.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.Trns.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.Trns} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.Trns.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrnsId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getYear();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBranch();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTradeDate();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getEntryType();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCusip();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getQty();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getNetAmount();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getSettleDate();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getExternalId();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getSystemDate();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getGrossAmount();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getIrsForm();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getSymbolDescription();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getOriginalCusip();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeInt32(
      20,
      f
    );
  }
  f = message.getMultiplier();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getPrice();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getAssetType();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getFees();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
};


/**
 * optional int32 trns_id = 1;
 * @return {number}
 */
proto.taxfilingpb.Trns.prototype.getTrnsId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.taxfilingpb.Trns} returns this
 */
proto.taxfilingpb.Trns.prototype.setTrnsId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string year = 2;
 * @return {string}
 */
proto.taxfilingpb.Trns.prototype.getYear = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Trns} returns this
 */
proto.taxfilingpb.Trns.prototype.setYear = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string correspondent = 3;
 * @return {string}
 */
proto.taxfilingpb.Trns.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Trns} returns this
 */
proto.taxfilingpb.Trns.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string branch = 4;
 * @return {string}
 */
proto.taxfilingpb.Trns.prototype.getBranch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Trns} returns this
 */
proto.taxfilingpb.Trns.prototype.setBranch = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string account_no = 5;
 * @return {string}
 */
proto.taxfilingpb.Trns.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Trns} returns this
 */
proto.taxfilingpb.Trns.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional google.type.Date trade_date = 6;
 * @return {?proto.google.type.Date}
 */
proto.taxfilingpb.Trns.prototype.getTradeDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 6));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.taxfilingpb.Trns} returns this
*/
proto.taxfilingpb.Trns.prototype.setTradeDate = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.taxfilingpb.Trns} returns this
 */
proto.taxfilingpb.Trns.prototype.clearTradeDate = function() {
  return this.setTradeDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.taxfilingpb.Trns.prototype.hasTradeDate = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string entry_type = 7;
 * @return {string}
 */
proto.taxfilingpb.Trns.prototype.getEntryType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Trns} returns this
 */
proto.taxfilingpb.Trns.prototype.setEntryType = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string symbol = 8;
 * @return {string}
 */
proto.taxfilingpb.Trns.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Trns} returns this
 */
proto.taxfilingpb.Trns.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string cusip = 9;
 * @return {string}
 */
proto.taxfilingpb.Trns.prototype.getCusip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Trns} returns this
 */
proto.taxfilingpb.Trns.prototype.setCusip = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string qty = 10;
 * @return {string}
 */
proto.taxfilingpb.Trns.prototype.getQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Trns} returns this
 */
proto.taxfilingpb.Trns.prototype.setQty = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string net_amount = 11;
 * @return {string}
 */
proto.taxfilingpb.Trns.prototype.getNetAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Trns} returns this
 */
proto.taxfilingpb.Trns.prototype.setNetAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional google.type.Date settle_date = 12;
 * @return {?proto.google.type.Date}
 */
proto.taxfilingpb.Trns.prototype.getSettleDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 12));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.taxfilingpb.Trns} returns this
*/
proto.taxfilingpb.Trns.prototype.setSettleDate = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.taxfilingpb.Trns} returns this
 */
proto.taxfilingpb.Trns.prototype.clearSettleDate = function() {
  return this.setSettleDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.taxfilingpb.Trns.prototype.hasSettleDate = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional string external_id = 13;
 * @return {string}
 */
proto.taxfilingpb.Trns.prototype.getExternalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Trns} returns this
 */
proto.taxfilingpb.Trns.prototype.setExternalId = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional google.type.Date system_date = 14;
 * @return {?proto.google.type.Date}
 */
proto.taxfilingpb.Trns.prototype.getSystemDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 14));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.taxfilingpb.Trns} returns this
*/
proto.taxfilingpb.Trns.prototype.setSystemDate = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.taxfilingpb.Trns} returns this
 */
proto.taxfilingpb.Trns.prototype.clearSystemDate = function() {
  return this.setSystemDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.taxfilingpb.Trns.prototype.hasSystemDate = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional string description = 15;
 * @return {string}
 */
proto.taxfilingpb.Trns.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Trns} returns this
 */
proto.taxfilingpb.Trns.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string gross_amount = 16;
 * @return {string}
 */
proto.taxfilingpb.Trns.prototype.getGrossAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Trns} returns this
 */
proto.taxfilingpb.Trns.prototype.setGrossAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string irs_form = 17;
 * @return {string}
 */
proto.taxfilingpb.Trns.prototype.getIrsForm = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Trns} returns this
 */
proto.taxfilingpb.Trns.prototype.setIrsForm = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string symbol_description = 18;
 * @return {string}
 */
proto.taxfilingpb.Trns.prototype.getSymbolDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Trns} returns this
 */
proto.taxfilingpb.Trns.prototype.setSymbolDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string original_cusip = 19;
 * @return {string}
 */
proto.taxfilingpb.Trns.prototype.getOriginalCusip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Trns} returns this
 */
proto.taxfilingpb.Trns.prototype.setOriginalCusip = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional int32 account_id = 20;
 * @return {number}
 */
proto.taxfilingpb.Trns.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.taxfilingpb.Trns} returns this
 */
proto.taxfilingpb.Trns.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional string multiplier = 21;
 * @return {string}
 */
proto.taxfilingpb.Trns.prototype.getMultiplier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Trns} returns this
 */
proto.taxfilingpb.Trns.prototype.setMultiplier = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string price = 22;
 * @return {string}
 */
proto.taxfilingpb.Trns.prototype.getPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Trns} returns this
 */
proto.taxfilingpb.Trns.prototype.setPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string asset_type = 23;
 * @return {string}
 */
proto.taxfilingpb.Trns.prototype.getAssetType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Trns} returns this
 */
proto.taxfilingpb.Trns.prototype.setAssetType = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string fees = 24;
 * @return {string}
 */
proto.taxfilingpb.Trns.prototype.getFees = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Trns} returns this
 */
proto.taxfilingpb.Trns.prototype.setFees = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.CreateTrnsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.CreateTrnsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.CreateTrnsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.CreateTrnsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    trns: (f = msg.getTrns()) && proto.taxfilingpb.Trns.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.CreateTrnsResponse}
 */
proto.taxfilingpb.CreateTrnsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.CreateTrnsResponse;
  return proto.taxfilingpb.CreateTrnsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.CreateTrnsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.CreateTrnsResponse}
 */
proto.taxfilingpb.CreateTrnsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.taxfilingpb.Trns;
      reader.readMessage(value,proto.taxfilingpb.Trns.deserializeBinaryFromReader);
      msg.setTrns(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.CreateTrnsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.CreateTrnsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.CreateTrnsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.CreateTrnsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrns();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.taxfilingpb.Trns.serializeBinaryToWriter
    );
  }
};


/**
 * optional Trns trns = 1;
 * @return {?proto.taxfilingpb.Trns}
 */
proto.taxfilingpb.CreateTrnsResponse.prototype.getTrns = function() {
  return /** @type{?proto.taxfilingpb.Trns} */ (
    jspb.Message.getWrapperField(this, proto.taxfilingpb.Trns, 1));
};


/**
 * @param {?proto.taxfilingpb.Trns|undefined} value
 * @return {!proto.taxfilingpb.CreateTrnsResponse} returns this
*/
proto.taxfilingpb.CreateTrnsResponse.prototype.setTrns = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.taxfilingpb.CreateTrnsResponse} returns this
 */
proto.taxfilingpb.CreateTrnsResponse.prototype.clearTrns = function() {
  return this.setTrns(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.taxfilingpb.CreateTrnsResponse.prototype.hasTrns = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.ReadTrnsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.ReadTrnsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.ReadTrnsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.ReadTrnsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    trnsId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.ReadTrnsRequest}
 */
proto.taxfilingpb.ReadTrnsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.ReadTrnsRequest;
  return proto.taxfilingpb.ReadTrnsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.ReadTrnsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.ReadTrnsRequest}
 */
proto.taxfilingpb.ReadTrnsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTrnsId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.ReadTrnsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.ReadTrnsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.ReadTrnsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.ReadTrnsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrnsId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 trns_id = 1;
 * @return {number}
 */
proto.taxfilingpb.ReadTrnsRequest.prototype.getTrnsId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.taxfilingpb.ReadTrnsRequest} returns this
 */
proto.taxfilingpb.ReadTrnsRequest.prototype.setTrnsId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.ReadTrnsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.ReadTrnsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.ReadTrnsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.ReadTrnsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    trns: (f = msg.getTrns()) && proto.taxfilingpb.Trns.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.ReadTrnsResponse}
 */
proto.taxfilingpb.ReadTrnsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.ReadTrnsResponse;
  return proto.taxfilingpb.ReadTrnsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.ReadTrnsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.ReadTrnsResponse}
 */
proto.taxfilingpb.ReadTrnsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.taxfilingpb.Trns;
      reader.readMessage(value,proto.taxfilingpb.Trns.deserializeBinaryFromReader);
      msg.setTrns(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.ReadTrnsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.ReadTrnsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.ReadTrnsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.ReadTrnsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrns();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.taxfilingpb.Trns.serializeBinaryToWriter
    );
  }
};


/**
 * optional Trns trns = 1;
 * @return {?proto.taxfilingpb.Trns}
 */
proto.taxfilingpb.ReadTrnsResponse.prototype.getTrns = function() {
  return /** @type{?proto.taxfilingpb.Trns} */ (
    jspb.Message.getWrapperField(this, proto.taxfilingpb.Trns, 1));
};


/**
 * @param {?proto.taxfilingpb.Trns|undefined} value
 * @return {!proto.taxfilingpb.ReadTrnsResponse} returns this
*/
proto.taxfilingpb.ReadTrnsResponse.prototype.setTrns = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.taxfilingpb.ReadTrnsResponse} returns this
 */
proto.taxfilingpb.ReadTrnsResponse.prototype.clearTrns = function() {
  return this.setTrns(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.taxfilingpb.ReadTrnsResponse.prototype.hasTrns = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.UpdateTrnsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.UpdateTrnsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.UpdateTrnsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.UpdateTrnsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    trns: (f = msg.getTrns()) && proto.taxfilingpb.Trns.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.UpdateTrnsResponse}
 */
proto.taxfilingpb.UpdateTrnsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.UpdateTrnsResponse;
  return proto.taxfilingpb.UpdateTrnsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.UpdateTrnsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.UpdateTrnsResponse}
 */
proto.taxfilingpb.UpdateTrnsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.taxfilingpb.Trns;
      reader.readMessage(value,proto.taxfilingpb.Trns.deserializeBinaryFromReader);
      msg.setTrns(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.UpdateTrnsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.UpdateTrnsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.UpdateTrnsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.UpdateTrnsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrns();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.taxfilingpb.Trns.serializeBinaryToWriter
    );
  }
};


/**
 * optional Trns trns = 1;
 * @return {?proto.taxfilingpb.Trns}
 */
proto.taxfilingpb.UpdateTrnsResponse.prototype.getTrns = function() {
  return /** @type{?proto.taxfilingpb.Trns} */ (
    jspb.Message.getWrapperField(this, proto.taxfilingpb.Trns, 1));
};


/**
 * @param {?proto.taxfilingpb.Trns|undefined} value
 * @return {!proto.taxfilingpb.UpdateTrnsResponse} returns this
*/
proto.taxfilingpb.UpdateTrnsResponse.prototype.setTrns = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.taxfilingpb.UpdateTrnsResponse} returns this
 */
proto.taxfilingpb.UpdateTrnsResponse.prototype.clearTrns = function() {
  return this.setTrns(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.taxfilingpb.UpdateTrnsResponse.prototype.hasTrns = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.DeleteTrnsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.DeleteTrnsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.DeleteTrnsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.DeleteTrnsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    trnsId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.DeleteTrnsRequest}
 */
proto.taxfilingpb.DeleteTrnsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.DeleteTrnsRequest;
  return proto.taxfilingpb.DeleteTrnsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.DeleteTrnsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.DeleteTrnsRequest}
 */
proto.taxfilingpb.DeleteTrnsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTrnsId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.DeleteTrnsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.DeleteTrnsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.DeleteTrnsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.DeleteTrnsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrnsId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 trns_id = 1;
 * @return {number}
 */
proto.taxfilingpb.DeleteTrnsRequest.prototype.getTrnsId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.taxfilingpb.DeleteTrnsRequest} returns this
 */
proto.taxfilingpb.DeleteTrnsRequest.prototype.setTrnsId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.DeleteTrnsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.DeleteTrnsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.DeleteTrnsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.DeleteTrnsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    trnsId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.DeleteTrnsResponse}
 */
proto.taxfilingpb.DeleteTrnsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.DeleteTrnsResponse;
  return proto.taxfilingpb.DeleteTrnsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.DeleteTrnsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.DeleteTrnsResponse}
 */
proto.taxfilingpb.DeleteTrnsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTrnsId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.DeleteTrnsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.DeleteTrnsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.DeleteTrnsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.DeleteTrnsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrnsId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 trns_id = 1;
 * @return {number}
 */
proto.taxfilingpb.DeleteTrnsResponse.prototype.getTrnsId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.taxfilingpb.DeleteTrnsResponse} returns this
 */
proto.taxfilingpb.DeleteTrnsResponse.prototype.setTrnsId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.taxfilingpb.ListTrnsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.ListTrnsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.ListTrnsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.ListTrnsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.ListTrnsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    trnsList: jspb.Message.toObjectList(msg.getTrnsList(),
    proto.taxfilingpb.Trns.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.ListTrnsResponse}
 */
proto.taxfilingpb.ListTrnsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.ListTrnsResponse;
  return proto.taxfilingpb.ListTrnsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.ListTrnsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.ListTrnsResponse}
 */
proto.taxfilingpb.ListTrnsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.taxfilingpb.Trns;
      reader.readMessage(value,proto.taxfilingpb.Trns.deserializeBinaryFromReader);
      msg.addTrns(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.ListTrnsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.ListTrnsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.ListTrnsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.ListTrnsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrnsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.taxfilingpb.Trns.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Trns trns = 1;
 * @return {!Array<!proto.taxfilingpb.Trns>}
 */
proto.taxfilingpb.ListTrnsResponse.prototype.getTrnsList = function() {
  return /** @type{!Array<!proto.taxfilingpb.Trns>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.taxfilingpb.Trns, 1));
};


/**
 * @param {!Array<!proto.taxfilingpb.Trns>} value
 * @return {!proto.taxfilingpb.ListTrnsResponse} returns this
*/
proto.taxfilingpb.ListTrnsResponse.prototype.setTrnsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.taxfilingpb.Trns=} opt_value
 * @param {number=} opt_index
 * @return {!proto.taxfilingpb.Trns}
 */
proto.taxfilingpb.ListTrnsResponse.prototype.addTrns = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.taxfilingpb.Trns, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.taxfilingpb.ListTrnsResponse} returns this
 */
proto.taxfilingpb.ListTrnsResponse.prototype.clearTrnsList = function() {
  return this.setTrnsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.TrnsUploadRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.TrnsUploadRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.TrnsUploadRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.TrnsUploadRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    batchNo: jspb.Message.getFieldWithDefault(msg, 1, ""),
    file: (f = msg.getFile()) && proto_commonpb_file_pb.File.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.TrnsUploadRequest}
 */
proto.taxfilingpb.TrnsUploadRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.TrnsUploadRequest;
  return proto.taxfilingpb.TrnsUploadRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.TrnsUploadRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.TrnsUploadRequest}
 */
proto.taxfilingpb.TrnsUploadRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBatchNo(value);
      break;
    case 2:
      var value = new proto_commonpb_file_pb.File;
      reader.readMessage(value,proto_commonpb_file_pb.File.deserializeBinaryFromReader);
      msg.setFile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.TrnsUploadRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.TrnsUploadRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.TrnsUploadRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.TrnsUploadRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBatchNo();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFile();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto_commonpb_file_pb.File.serializeBinaryToWriter
    );
  }
};


/**
 * optional string batch_no = 1;
 * @return {string}
 */
proto.taxfilingpb.TrnsUploadRequest.prototype.getBatchNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.TrnsUploadRequest} returns this
 */
proto.taxfilingpb.TrnsUploadRequest.prototype.setBatchNo = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional commonpb.File file = 2;
 * @return {?proto.commonpb.File}
 */
proto.taxfilingpb.TrnsUploadRequest.prototype.getFile = function() {
  return /** @type{?proto.commonpb.File} */ (
    jspb.Message.getWrapperField(this, proto_commonpb_file_pb.File, 2));
};


/**
 * @param {?proto.commonpb.File|undefined} value
 * @return {!proto.taxfilingpb.TrnsUploadRequest} returns this
*/
proto.taxfilingpb.TrnsUploadRequest.prototype.setFile = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.taxfilingpb.TrnsUploadRequest} returns this
 */
proto.taxfilingpb.TrnsUploadRequest.prototype.clearFile = function() {
  return this.setFile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.taxfilingpb.TrnsUploadRequest.prototype.hasFile = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.TrnsUploadResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.TrnsUploadResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.TrnsUploadResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.TrnsUploadResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.TrnsUploadResponse}
 */
proto.taxfilingpb.TrnsUploadResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.TrnsUploadResponse;
  return proto.taxfilingpb.TrnsUploadResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.TrnsUploadResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.TrnsUploadResponse}
 */
proto.taxfilingpb.TrnsUploadResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.TrnsUploadResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.TrnsUploadResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.TrnsUploadResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.TrnsUploadResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.taxfilingpb.TrnsUploadResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.taxfilingpb.TrnsUploadResponse} returns this
 */
proto.taxfilingpb.TrnsUploadResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


goog.object.extend(exports, proto.taxfilingpb);
