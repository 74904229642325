/**
 * @fileoverview gRPC-Web generated client stub for taxfilingpb
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_type_date_pb = require('../../google/type/date_pb.js')

var proto_utilspb_pagination_pb = require('../../proto/utilspb/pagination_pb.js')

var proto_commonpb_file_pb = require('../../proto/commonpb/file_pb.js')
const proto = {};
proto.taxfilingpb = require('./form1099_int_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.taxfilingpb.Form1099IntServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.taxfilingpb.Form1099IntServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.taxfilingpb.Form1099IntRequest,
 *   !proto.taxfilingpb.ListForm1099IntResponse>}
 */
const methodDescriptor_Form1099IntService_ListForm1099Int = new grpc.web.MethodDescriptor(
  '/taxfilingpb.Form1099IntService/ListForm1099Int',
  grpc.web.MethodType.UNARY,
  proto.taxfilingpb.Form1099IntRequest,
  proto.taxfilingpb.ListForm1099IntResponse,
  /**
   * @param {!proto.taxfilingpb.Form1099IntRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.ListForm1099IntResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.taxfilingpb.Form1099IntRequest,
 *   !proto.taxfilingpb.ListForm1099IntResponse>}
 */
const methodInfo_Form1099IntService_ListForm1099Int = new grpc.web.AbstractClientBase.MethodInfo(
  proto.taxfilingpb.ListForm1099IntResponse,
  /**
   * @param {!proto.taxfilingpb.Form1099IntRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.ListForm1099IntResponse.deserializeBinary
);


/**
 * @param {!proto.taxfilingpb.Form1099IntRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.taxfilingpb.ListForm1099IntResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.taxfilingpb.ListForm1099IntResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.taxfilingpb.Form1099IntServiceClient.prototype.listForm1099Int =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/taxfilingpb.Form1099IntService/ListForm1099Int',
      request,
      metadata || {},
      methodDescriptor_Form1099IntService_ListForm1099Int,
      callback);
};


/**
 * @param {!proto.taxfilingpb.Form1099IntRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.taxfilingpb.ListForm1099IntResponse>}
 *     Promise that resolves to the response
 */
proto.taxfilingpb.Form1099IntServicePromiseClient.prototype.listForm1099Int =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/taxfilingpb.Form1099IntService/ListForm1099Int',
      request,
      metadata || {},
      methodDescriptor_Form1099IntService_ListForm1099Int);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.taxfilingpb.Form1099IntRequest,
 *   !proto.taxfilingpb.Form1099IntDetailsResponse>}
 */
const methodDescriptor_Form1099IntService_Form1099IntDetails = new grpc.web.MethodDescriptor(
  '/taxfilingpb.Form1099IntService/Form1099IntDetails',
  grpc.web.MethodType.UNARY,
  proto.taxfilingpb.Form1099IntRequest,
  proto.taxfilingpb.Form1099IntDetailsResponse,
  /**
   * @param {!proto.taxfilingpb.Form1099IntRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.Form1099IntDetailsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.taxfilingpb.Form1099IntRequest,
 *   !proto.taxfilingpb.Form1099IntDetailsResponse>}
 */
const methodInfo_Form1099IntService_Form1099IntDetails = new grpc.web.AbstractClientBase.MethodInfo(
  proto.taxfilingpb.Form1099IntDetailsResponse,
  /**
   * @param {!proto.taxfilingpb.Form1099IntRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.Form1099IntDetailsResponse.deserializeBinary
);


/**
 * @param {!proto.taxfilingpb.Form1099IntRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.taxfilingpb.Form1099IntDetailsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.taxfilingpb.Form1099IntDetailsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.taxfilingpb.Form1099IntServiceClient.prototype.form1099IntDetails =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/taxfilingpb.Form1099IntService/Form1099IntDetails',
      request,
      metadata || {},
      methodDescriptor_Form1099IntService_Form1099IntDetails,
      callback);
};


/**
 * @param {!proto.taxfilingpb.Form1099IntRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.taxfilingpb.Form1099IntDetailsResponse>}
 *     Promise that resolves to the response
 */
proto.taxfilingpb.Form1099IntServicePromiseClient.prototype.form1099IntDetails =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/taxfilingpb.Form1099IntService/Form1099IntDetails',
      request,
      metadata || {},
      methodDescriptor_Form1099IntService_Form1099IntDetails);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.taxfilingpb.Form1099IntProcessRequest,
 *   !proto.taxfilingpb.Form1099IntProcessResponse>}
 */
const methodDescriptor_Form1099IntService_Form1099IntProcess = new grpc.web.MethodDescriptor(
  '/taxfilingpb.Form1099IntService/Form1099IntProcess',
  grpc.web.MethodType.UNARY,
  proto.taxfilingpb.Form1099IntProcessRequest,
  proto.taxfilingpb.Form1099IntProcessResponse,
  /**
   * @param {!proto.taxfilingpb.Form1099IntProcessRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.Form1099IntProcessResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.taxfilingpb.Form1099IntProcessRequest,
 *   !proto.taxfilingpb.Form1099IntProcessResponse>}
 */
const methodInfo_Form1099IntService_Form1099IntProcess = new grpc.web.AbstractClientBase.MethodInfo(
  proto.taxfilingpb.Form1099IntProcessResponse,
  /**
   * @param {!proto.taxfilingpb.Form1099IntProcessRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.Form1099IntProcessResponse.deserializeBinary
);


/**
 * @param {!proto.taxfilingpb.Form1099IntProcessRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.taxfilingpb.Form1099IntProcessResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.taxfilingpb.Form1099IntProcessResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.taxfilingpb.Form1099IntServiceClient.prototype.form1099IntProcess =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/taxfilingpb.Form1099IntService/Form1099IntProcess',
      request,
      metadata || {},
      methodDescriptor_Form1099IntService_Form1099IntProcess,
      callback);
};


/**
 * @param {!proto.taxfilingpb.Form1099IntProcessRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.taxfilingpb.Form1099IntProcessResponse>}
 *     Promise that resolves to the response
 */
proto.taxfilingpb.Form1099IntServicePromiseClient.prototype.form1099IntProcess =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/taxfilingpb.Form1099IntService/Form1099IntProcess',
      request,
      metadata || {},
      methodDescriptor_Form1099IntService_Form1099IntProcess);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.taxfilingpb.DownLoadIntRequest,
 *   !proto.commonpb.File>}
 */
const methodDescriptor_Form1099IntService_Download = new grpc.web.MethodDescriptor(
  '/taxfilingpb.Form1099IntService/Download',
  grpc.web.MethodType.UNARY,
  proto.taxfilingpb.DownLoadIntRequest,
  proto_commonpb_file_pb.File,
  /**
   * @param {!proto.taxfilingpb.DownLoadIntRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_commonpb_file_pb.File.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.taxfilingpb.DownLoadIntRequest,
 *   !proto.commonpb.File>}
 */
const methodInfo_Form1099IntService_Download = new grpc.web.AbstractClientBase.MethodInfo(
  proto_commonpb_file_pb.File,
  /**
   * @param {!proto.taxfilingpb.DownLoadIntRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_commonpb_file_pb.File.deserializeBinary
);


/**
 * @param {!proto.taxfilingpb.DownLoadIntRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.commonpb.File)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.commonpb.File>|undefined}
 *     The XHR Node Readable Stream
 */
proto.taxfilingpb.Form1099IntServiceClient.prototype.download =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/taxfilingpb.Form1099IntService/Download',
      request,
      metadata || {},
      methodDescriptor_Form1099IntService_Download,
      callback);
};


/**
 * @param {!proto.taxfilingpb.DownLoadIntRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.commonpb.File>}
 *     Promise that resolves to the response
 */
proto.taxfilingpb.Form1099IntServicePromiseClient.prototype.download =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/taxfilingpb.Form1099IntService/Download',
      request,
      metadata || {},
      methodDescriptor_Form1099IntService_Download);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.taxfilingpb.DownLoadIntRequest,
 *   !proto.taxfilingpb.GenerateIntResponse>}
 */
const methodDescriptor_Form1099IntService_Generate = new grpc.web.MethodDescriptor(
  '/taxfilingpb.Form1099IntService/Generate',
  grpc.web.MethodType.UNARY,
  proto.taxfilingpb.DownLoadIntRequest,
  proto.taxfilingpb.GenerateIntResponse,
  /**
   * @param {!proto.taxfilingpb.DownLoadIntRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.GenerateIntResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.taxfilingpb.DownLoadIntRequest,
 *   !proto.taxfilingpb.GenerateIntResponse>}
 */
const methodInfo_Form1099IntService_Generate = new grpc.web.AbstractClientBase.MethodInfo(
  proto.taxfilingpb.GenerateIntResponse,
  /**
   * @param {!proto.taxfilingpb.DownLoadIntRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.GenerateIntResponse.deserializeBinary
);


/**
 * @param {!proto.taxfilingpb.DownLoadIntRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.taxfilingpb.GenerateIntResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.taxfilingpb.GenerateIntResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.taxfilingpb.Form1099IntServiceClient.prototype.generate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/taxfilingpb.Form1099IntService/Generate',
      request,
      metadata || {},
      methodDescriptor_Form1099IntService_Generate,
      callback);
};


/**
 * @param {!proto.taxfilingpb.DownLoadIntRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.taxfilingpb.GenerateIntResponse>}
 *     Promise that resolves to the response
 */
proto.taxfilingpb.Form1099IntServicePromiseClient.prototype.generate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/taxfilingpb.Form1099IntService/Generate',
      request,
      metadata || {},
      methodDescriptor_Form1099IntService_Generate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.taxfilingpb.Form1099Int,
 *   !proto.taxfilingpb.UpdateForm1099IntResponse>}
 */
const methodDescriptor_Form1099IntService_UpdateFormInt = new grpc.web.MethodDescriptor(
  '/taxfilingpb.Form1099IntService/UpdateFormInt',
  grpc.web.MethodType.UNARY,
  proto.taxfilingpb.Form1099Int,
  proto.taxfilingpb.UpdateForm1099IntResponse,
  /**
   * @param {!proto.taxfilingpb.Form1099Int} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.UpdateForm1099IntResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.taxfilingpb.Form1099Int,
 *   !proto.taxfilingpb.UpdateForm1099IntResponse>}
 */
const methodInfo_Form1099IntService_UpdateFormInt = new grpc.web.AbstractClientBase.MethodInfo(
  proto.taxfilingpb.UpdateForm1099IntResponse,
  /**
   * @param {!proto.taxfilingpb.Form1099Int} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.UpdateForm1099IntResponse.deserializeBinary
);


/**
 * @param {!proto.taxfilingpb.Form1099Int} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.taxfilingpb.UpdateForm1099IntResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.taxfilingpb.UpdateForm1099IntResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.taxfilingpb.Form1099IntServiceClient.prototype.updateFormInt =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/taxfilingpb.Form1099IntService/UpdateFormInt',
      request,
      metadata || {},
      methodDescriptor_Form1099IntService_UpdateFormInt,
      callback);
};


/**
 * @param {!proto.taxfilingpb.Form1099Int} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.taxfilingpb.UpdateForm1099IntResponse>}
 *     Promise that resolves to the response
 */
proto.taxfilingpb.Form1099IntServicePromiseClient.prototype.updateFormInt =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/taxfilingpb.Form1099IntService/UpdateFormInt',
      request,
      metadata || {},
      methodDescriptor_Form1099IntService_UpdateFormInt);
};


module.exports = proto.taxfilingpb;

