import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { listSystemCode } from '../../services/SystemCodeService';

export default function SelectW8W9(props) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [inputValue, setInputValue] = React.useState(props.value);

  const setPropsValue = (value) => {
    props.onChange({
      currentTarget: {
        name: props.name,
        value: value,
      },
      target: {
        name: props.name,
        value: value,
      },
    });
  };

  const handleOnBlur = async (value) => {
    if (props.freeSolo) {
      setPropsValue(value);
      return;
    }

    if (options.find((o) => o.code === value)) {
      setPropsValue(value);
      return;
    } else {
      // if selected value does not exist in options and is not a free solo
      setInputValue('');
      setPropsValue('');
    }
  };

  //set local input value and get options
  const handleInputChange = async (event, value) => {
    if (!event) return;
    if (event.type === 'blur') return;
    if (event.type === 'click' && value) return;

    setPropsValue(value);
    setInputValue(value);
    getOptions(value);
  };

  //get list from backend
  const getOptions = async (value) => {
    const { systemCodeList } = await listSystemCode({
      type: 'Tax Treaty Type',
      code: value,
      note: props.note,
    });

    if (systemCodeList.length === 0) {
      return; // fix for white screen
    }
    setOptions(
      systemCodeList
        .filter((a) => a.code)
        .sort((a, b) => (a.code > b.code ? 1 : b.code > a.code ? -1 : 0))
    );

    if (!open && props.value) {
      const list = systemCodeList.filter((a) => a.code === value);
      if (list.length !== 0) {
        setInputValue(
          systemCodeList.filter((a) => a.code === value).map((v) => v.code)[0]
        );
      } else {
        setInputValue(value);
      }
    }
  };

  React.useEffect(
    () => {
      if (open || props.value) {
        getOptions(props.value);
      }
    },
    // eslint-disable-next-line
    [open, props.value]
  );

  return (
    <Autocomplete
      disabled={props.disabled}
      freeSolo={props.freeSolo}
      id={props.name}
      onInputChange={handleInputChange}
      onBlur={() => {
        handleOnBlur(props.value);
      }}
      blurOnSelect={false}
      onChange={(_, v) => {
        const value = v ? (v.code ? v.code : v) : '';
        setPropsValue(value);
        setInputValue(value ? value : '');
      }}
      getOptionSelected={(option, value) => option.code === value.code}
      getOptionLabel={(option) => option.code}
      inputValue={inputValue}
      options={options}
      name={props.name}
      autoHighlight={true}
      clearOnEscape={true}
      onClose={() => {
        setOpen(false);
      }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      renderInput={(params) => {
        params.inputProps.onKeyDown = props.onKeyDown;
        return (
          <TextField
            {...params}
            name={props.name}
            required={props.required}
            disabled={props.disabled}
            label={props.label}
            InputLabelProps={{ shrink: true }}
          />
        );
      }}
    />
  );
}
