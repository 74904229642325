import React from 'react';
import { TextField } from '@material-ui/core';

import SelectState from './SelectState';
import SelectStateCA from './SelectStateCA';

const SelectCountryState = ({ name, country, onChange, ...props }) => {
  const disabled = !country;

  React.useEffect(() => {
    if (disabled) {
      const clearValue = {
        name: name,
        value: '',
      };

      onChange({
        currentTarget: clearValue,
        target: clearValue,
      });
    }
  }, [country]);

  if (country === 'CA') {
    return (
      <SelectStateCA
        name={name}
        onChange={onChange}
        disabled={disabled}
        freeSolo
        {...props}
      />
    );
  }

  if (country === 'US') {
    return (
      <SelectState
        name={name}
        freeSolo
        disabled={disabled}
        onChange={onChange}
        {...props}
      />
    );
  }

  return (
    <TextField
      fullWidth={true}
      InputLabelProps={{ shrink: true }}
      inputProps={{
        maxLength: 50,
      }}
      name={name}
      onChange={onChange}
      disabled={disabled}
      {...props}
    />
  );
};

export default SelectCountryState;
