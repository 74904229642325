import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
  },
  paper: {
    width: '100%',
    boxSizing: 'border-box',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  textBold: {
    fontWeight: 'bold',
  },
  grdRow: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 20,
  },
  grdCol: {
    display: 'flex',
    flexDirection: 'column',
  },
  grdCell1: {
    flex: 1,
  },
  grdCell2: {
    flex: 2,
  },
  grdCellNone: {
    flex: 'none',
  },
  actionContainer: {
    display: 'flex',
    marginTop: 20,
  },
  pageContainer: {},
  searchField: {
    width: 275,
  },
  fadeModalMd: {
    maxWidth: 800,
    width: '100%',
    padding: '25px 30px 30px 30px',
    margin: '0px auto',
    backgroundColor: '#ffffff',
    border: 'none',
    borderRadius: 5,
    boxShadow: '0px 0px 10px #333333',
    boxSizing: 'border-box',
    outline: 'none',
  },
  fadeModalLg: {
    maxWidth: 900,
    width: '100%',
    padding: '25px 30px 30px 30px',
    margin: '0px auto',
    backgroundColor: '#ffffff',
    border: 'none',
    borderRadius: 5,
    boxShadow: '0px 0px 10px #333333',
    boxSizing: 'border-box',
    outline: 'none',
  },
  fadeModalFull: {
    maxWidth: 1400,
    maxHeight: '90vh',
    width: '100%',
    padding: '25px 30px 30px 30px',
    margin: '0px auto',
    backgroundColor: '#ffffff',
    border: 'none',
    borderRadius: 5,
    boxShadow: '0px 0px 10px #333333',
    boxSizing: 'border-box',
    outline: 'none',
    overflowY: 'auto',
  },
  tableModalFull: {
    maxWidth: 1400,
    maxHeight: '90vh',
    width: '100%',
    padding: '0px',
    margin: '0px auto',
    backgroundColor: '#ffffff',
    border: 'none',
    borderRadius: 5,
    boxShadow: '0px 0px 10px #333333',
    boxSizing: 'border-box',
    outline: 'none',
    position: 'relative',
  },
  modalBackdrop: {
    padding: '40px',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalForm: {
    marginTop: 5,
  },
  modalActionContainer: {
    display: 'flex',
    marginTop: 15,
  },
  modalHeader: {
    padding: '0px',
  },
  modalFooter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    marginTop: 30,
  },
  tabModalLg: {
    maxWidth: 1150,
    maxHeight: '90vh',
    width: '100%',
    margin: '0px auto',
    backgroundColor: '#ffffff',
    border: 'none',
    borderRadius: 5,
    boxShadow: '0px 0px 10px #333333',
    boxSizing: 'border-box',
    outline: 'none',
    overflowY: 'auto',
  },
  tabModalHeader: {
    padding: '25px 30px 30px 30px',
  },
  tabModalFooter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    padding: '0px 30px 25px 30px',
  },
  tabHeader: {
    backgroundColor: '#ffffff',
    boxShadow: 'none',
    borderBottom: '1px solid #e8e8e8',
  },
  tabBody: {
    padding: '0px 6px',
  },
  totalBadge: {
    padding: '7px',
    borderRadius: '4px',
    color: '#ffffff',
  },
  tblHeader: {
    backgroundColor: '#778eff',
    color: '#ffffff',
    fontWeight: 'bold',
  },
  modalCloseIcon: {
    position: 'absolute',
    top: 7,
    right: -65,
    borderRadius: '100%',
    backgroundColor: 'rgba(0,0,0,0.2)',
  },
  textColorRed: {
    color: 'red',
  },
  textColorDefault: {
    color: '#131215',
  },
  customHoverFocus: {
    '&:hover, &.Mui-focusVisible': { backgroundColor: '#4055d0' },
    backgroundColor: '#4962ea',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    color: '#ffffff',
  },
  MuiButton: {
    root: {
      '&:hover, &.Mui-focusVisible': { backgroundColor: '#4055d0' },
    },
  },
  userGuidePreview: {
    '& table': {
      '& thead': {
        '& th': {
          border: '1px solid #e1e1e1',
          borderBottom: '2px solid #333',
          padding: '.4em',
          backgroundClip: 'padding-box',
          backgroundColor: '#f3f3f3',
        },
      },
      '& tbody': {
        '& td': {
          border: '1px solid #e1e1e1',
          padding: '.4em',
          backgroundClip: 'padding-box',
        },
      },
      width: '100%',
      borderCollapse: 'collapse',
    },
  },
  fadeModalMd2: {
    maxWidth: 900,
    width: '100%',
    padding: '25px 30px 30px 30px',
    backgroundColor: '#F2F2FF',
    borderRadius: 5,
    boxSizing: 'border-box',
  },
  uploadThumbnail: {
    width: 1005,
    maxWidth: '120px',
    margin: '0px auto',
    display: 'block',
  },
  logoContainer: {
    width: '100%',
    height: 62,
    overflow: 'hidden',
    marginBottom: 12,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
  },
  noThumbnailLabel: {
    display: 'block',
    background: '#e9e9ec',
    padding: '55px 0px',
    borderRadius: '5px',
    width: '100%',
  },
}));
