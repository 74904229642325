/*eslint eqeqeq: "off"*/
/*ReactJS*/
import React, { useState, useEffect } from 'react';
import useStyles from '../../../styles';

/*components*/
import Table, { columnType } from 'components/Table/Table';
/*Service*/

import { upload } from '../../../services/Form1099DivReClassService';
import { getFilesInfo } from '../../../services/ExcelService';

/*Material UI*/
import { makeStyles } from '@material-ui/core/styles';
import { useConfirm } from 'material-ui-confirm';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  Create as EditIcon,
  Delete as DeleteIcon,
  AttachFile,
  TrendingUpRounded,
} from '@material-ui/icons';

import {
  Typography,
  Button,
  Modal,
  Backdrop,
  Fade,
  Box,
  IconButton,
} from '@material-ui/core';

export default function Form1099DivReclassUploadModal({
  onClose: handleClose,
  open: isOpen,
  value,
  isCancelEnable,
}) {
  /*=========================================================================================
  Component style
  ===========================================================================================*/

  const confirm = useConfirm();
  const options = {
    selectableRows: 'none',
  };

  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [enableCancel, setEnableCancel] = useState(false);

  useEffect(() => {
    setEnableCancel(isCancelEnable);
    setRows(value);
  }, [isOpen, value, isCancelEnable]);

  /*=========================================================================================
  Table modifications
  ===========================================================================================*/
  const columns = [
    {
      name: 'process',
      label: 'Process',
    },
    {
      name: 'progress',
      label: 'Progress',
    },
    {
      name: 'status',
      label: 'Status',
    },
    // {
    //   name: 'isInProgress',
    //   label: '____',
    //   type: columnType.progress,
    // },
    // {
    //   name: 'counter',
    //   label: 'Counter',
    // },
  ];

  return (
    <div className={classes.root}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modalBackdrop}
        open={isOpen}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={isOpen}>
          <div className={classes.fadeModalMd}>
            <Box mt={5}>
              <div className={classes.root}>
                <Box component="div" mt={2}>
                  <Table
                    title={'Processing Files'}
                    data={rows}
                    columns={columns}
                    options={options}
                  />
                </Box>
                <div className={classes.modalFooter}>
                  <div
                    className={classes.grdCellNone}
                    style={{ marginRight: 10 }}
                  >
                    {enableCancel && (
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={() => {
                          handleClose();
                        }}
                      >
                        Close
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </Box>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
