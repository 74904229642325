import React, { useEffect } from 'react';
import { TextField, CircularProgress } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { menus } from '../SearchBar/MenuLinks';

export default function MenuSelect(props) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [inputValue, setInputValue] = React.useState('');

  const setPropsValue = (label, page) => {
    props.onChange({
      currentTarget: {
        name: props.name,
        value: page,
        label: label,
      },
      target: {
        name: props.name,
        value: page,
        label: label,
      },
    });
  };

  const handleOnBlur = (value) => {
    if (props.freeSolo) {
      setPropsValue(value, null);
      return;
    }

    const selectedOption = options.find((o) => o.label === value);
    if (selectedOption) {
      setPropsValue(value, selectedOption.page);
      return;
    }

    setInputValue('');
    setPropsValue('', null);
  };

  const handleInputChange = (event, value) => {
    if (!event) return;
    if (event.type === 'blur') return;
    if (event.type === 'click' && value) return;

    setInputValue(value);
  };

  const getOptions = async () => {
    setOptions(
      Object.keys(menus).map((menu) => ({
        label: menus[menu].label,
        page: menus[menu].page,
      }))
    );
  };

  useEffect(() => {
    getOptions();
  }, []);

  useEffect(() => {
    if (props.value) {
      const matchedOption = options.find(
        (option) => option.page === props.value || option.label === props.value
      );
      if (matchedOption) {
        setInputValue(matchedOption.label);
      } else {
        setInputValue(props.value);
      }
    }
  }, [props.value, options]);

  return (
    <Autocomplete
      disabled={props.disabled}
      freeSolo={props.freeSolo}
      id={props.name}
      value={
        options.find(
          (option) =>
            option.page === props.value || option.label === props.value
        ) || null
      }
      onInputChange={handleInputChange}
      onBlur={() => {
        handleOnBlur(inputValue);
      }}
      blurOnSelect={false}
      onChange={(_, v) => {
        const label = v ? v.label || v : '';
        const page = v ? v.page || null : null;
        setPropsValue(label, page);
        setInputValue(label);
      }}
      getOptionSelected={(option, value) => {
        if (typeof value === 'string') {
          return option.label === value;
        }
        return option.page === value.page;
      }}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option;
        }
        return option.label || '';
      }}
      inputValue={inputValue}
      options={options}
      name={props.name}
      autoHighlight={true}
      clearOnEscape={true}
      onClose={() => {
        setOpen(false);
      }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          name={props.name}
          required={props.required}
          disabled={props.disabled}
          label={props.label}
          InputLabelProps={{ shrink: true }}
          inputProps={{
            ...params.inputProps,
            maxLength: props.maxLength || 12,
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {open && options.length === 0 ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}
