// source: proto/taxfilingpb/tax_treaty.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var proto_utilspb_pagination_pb = require('../../proto/utilspb/pagination_pb.js');
goog.object.extend(proto, proto_utilspb_pagination_pb);
goog.exportSymbol('proto.taxfilingpb.CreateTaxTreatyResponse', null, global);
goog.exportSymbol('proto.taxfilingpb.DeleteTaxTreatyRequest', null, global);
goog.exportSymbol('proto.taxfilingpb.DeleteTaxTreatyResponse', null, global);
goog.exportSymbol('proto.taxfilingpb.ListTaxTreatyRequest', null, global);
goog.exportSymbol('proto.taxfilingpb.ListTaxTreatyResponse', null, global);
goog.exportSymbol('proto.taxfilingpb.ListTaxTreatySummary', null, global);
goog.exportSymbol('proto.taxfilingpb.TaxTreaty', null, global);
goog.exportSymbol('proto.taxfilingpb.UpdateTaxTreatyResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.TaxTreaty = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.taxfilingpb.TaxTreaty, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.TaxTreaty.displayName = 'proto.taxfilingpb.TaxTreaty';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.ListTaxTreatyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.taxfilingpb.ListTaxTreatyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.ListTaxTreatyRequest.displayName = 'proto.taxfilingpb.ListTaxTreatyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.ListTaxTreatyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.taxfilingpb.ListTaxTreatyResponse.repeatedFields_, null);
};
goog.inherits(proto.taxfilingpb.ListTaxTreatyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.ListTaxTreatyResponse.displayName = 'proto.taxfilingpb.ListTaxTreatyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.ListTaxTreatySummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.taxfilingpb.ListTaxTreatySummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.ListTaxTreatySummary.displayName = 'proto.taxfilingpb.ListTaxTreatySummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.CreateTaxTreatyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.taxfilingpb.CreateTaxTreatyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.CreateTaxTreatyResponse.displayName = 'proto.taxfilingpb.CreateTaxTreatyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.UpdateTaxTreatyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.taxfilingpb.UpdateTaxTreatyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.UpdateTaxTreatyResponse.displayName = 'proto.taxfilingpb.UpdateTaxTreatyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.DeleteTaxTreatyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.taxfilingpb.DeleteTaxTreatyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.DeleteTaxTreatyRequest.displayName = 'proto.taxfilingpb.DeleteTaxTreatyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.DeleteTaxTreatyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.taxfilingpb.DeleteTaxTreatyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.DeleteTaxTreatyResponse.displayName = 'proto.taxfilingpb.DeleteTaxTreatyResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.TaxTreaty.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.TaxTreaty.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.TaxTreaty} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.TaxTreaty.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    country: jspb.Message.getFieldWithDefault(msg, 2, ""),
    countryCode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    symbol: jspb.Message.getFieldWithDefault(msg, 4, ""),
    originalCusip: jspb.Message.getFieldWithDefault(msg, 5, ""),
    entryType: jspb.Message.getFieldWithDefault(msg, 6, ""),
    rate: jspb.Message.getFieldWithDefault(msg, 7, ""),
    taxTreatyId: jspb.Message.getFieldWithDefault(msg, 8, 0),
    state: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.TaxTreaty}
 */
proto.taxfilingpb.TaxTreaty.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.TaxTreaty;
  return proto.taxfilingpb.TaxTreaty.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.TaxTreaty} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.TaxTreaty}
 */
proto.taxfilingpb.TaxTreaty.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryCode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginalCusip(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntryType(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setRate(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTaxTreatyId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.TaxTreaty.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.TaxTreaty.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.TaxTreaty} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.TaxTreaty.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCountryCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOriginalCusip();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getEntryType();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getRate();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getTaxTreatyId();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.taxfilingpb.TaxTreaty.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.TaxTreaty} returns this
 */
proto.taxfilingpb.TaxTreaty.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string country = 2;
 * @return {string}
 */
proto.taxfilingpb.TaxTreaty.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.TaxTreaty} returns this
 */
proto.taxfilingpb.TaxTreaty.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string country_code = 3;
 * @return {string}
 */
proto.taxfilingpb.TaxTreaty.prototype.getCountryCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.TaxTreaty} returns this
 */
proto.taxfilingpb.TaxTreaty.prototype.setCountryCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string symbol = 4;
 * @return {string}
 */
proto.taxfilingpb.TaxTreaty.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.TaxTreaty} returns this
 */
proto.taxfilingpb.TaxTreaty.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string original_cusip = 5;
 * @return {string}
 */
proto.taxfilingpb.TaxTreaty.prototype.getOriginalCusip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.TaxTreaty} returns this
 */
proto.taxfilingpb.TaxTreaty.prototype.setOriginalCusip = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string entry_type = 6;
 * @return {string}
 */
proto.taxfilingpb.TaxTreaty.prototype.getEntryType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.TaxTreaty} returns this
 */
proto.taxfilingpb.TaxTreaty.prototype.setEntryType = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string rate = 7;
 * @return {string}
 */
proto.taxfilingpb.TaxTreaty.prototype.getRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.TaxTreaty} returns this
 */
proto.taxfilingpb.TaxTreaty.prototype.setRate = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional uint32 tax_treaty_id = 8;
 * @return {number}
 */
proto.taxfilingpb.TaxTreaty.prototype.getTaxTreatyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.taxfilingpb.TaxTreaty} returns this
 */
proto.taxfilingpb.TaxTreaty.prototype.setTaxTreatyId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string state = 9;
 * @return {string}
 */
proto.taxfilingpb.TaxTreaty.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.TaxTreaty} returns this
 */
proto.taxfilingpb.TaxTreaty.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.ListTaxTreatyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.ListTaxTreatyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.ListTaxTreatyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.ListTaxTreatyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    country: jspb.Message.getFieldWithDefault(msg, 2, ""),
    symbol: jspb.Message.getFieldWithDefault(msg, 3, ""),
    state: jspb.Message.getFieldWithDefault(msg, 4, ""),
    originalCusip: jspb.Message.getFieldWithDefault(msg, 6, ""),
    pagination: (f = msg.getPagination()) && proto_utilspb_pagination_pb.Pagination.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.ListTaxTreatyRequest}
 */
proto.taxfilingpb.ListTaxTreatyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.ListTaxTreatyRequest;
  return proto.taxfilingpb.ListTaxTreatyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.ListTaxTreatyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.ListTaxTreatyRequest}
 */
proto.taxfilingpb.ListTaxTreatyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginalCusip(value);
      break;
    case 5:
      var value = new proto_utilspb_pagination_pb.Pagination;
      reader.readMessage(value,proto_utilspb_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.ListTaxTreatyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.ListTaxTreatyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.ListTaxTreatyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.ListTaxTreatyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOriginalCusip();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_utilspb_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.taxfilingpb.ListTaxTreatyRequest.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.ListTaxTreatyRequest} returns this
 */
proto.taxfilingpb.ListTaxTreatyRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string country = 2;
 * @return {string}
 */
proto.taxfilingpb.ListTaxTreatyRequest.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.ListTaxTreatyRequest} returns this
 */
proto.taxfilingpb.ListTaxTreatyRequest.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string symbol = 3;
 * @return {string}
 */
proto.taxfilingpb.ListTaxTreatyRequest.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.ListTaxTreatyRequest} returns this
 */
proto.taxfilingpb.ListTaxTreatyRequest.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string state = 4;
 * @return {string}
 */
proto.taxfilingpb.ListTaxTreatyRequest.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.ListTaxTreatyRequest} returns this
 */
proto.taxfilingpb.ListTaxTreatyRequest.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string original_cusip = 6;
 * @return {string}
 */
proto.taxfilingpb.ListTaxTreatyRequest.prototype.getOriginalCusip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.ListTaxTreatyRequest} returns this
 */
proto.taxfilingpb.ListTaxTreatyRequest.prototype.setOriginalCusip = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional utilspb.Pagination pagination = 5;
 * @return {?proto.utilspb.Pagination}
 */
proto.taxfilingpb.ListTaxTreatyRequest.prototype.getPagination = function() {
  return /** @type{?proto.utilspb.Pagination} */ (
    jspb.Message.getWrapperField(this, proto_utilspb_pagination_pb.Pagination, 5));
};


/**
 * @param {?proto.utilspb.Pagination|undefined} value
 * @return {!proto.taxfilingpb.ListTaxTreatyRequest} returns this
*/
proto.taxfilingpb.ListTaxTreatyRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.taxfilingpb.ListTaxTreatyRequest} returns this
 */
proto.taxfilingpb.ListTaxTreatyRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.taxfilingpb.ListTaxTreatyRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.taxfilingpb.ListTaxTreatyResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.ListTaxTreatyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.ListTaxTreatyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.ListTaxTreatyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.ListTaxTreatyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    taxTreatyList: jspb.Message.toObjectList(msg.getTaxTreatyList(),
    proto.taxfilingpb.TaxTreaty.toObject, includeInstance),
    summary: (f = msg.getSummary()) && proto.taxfilingpb.ListTaxTreatySummary.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.ListTaxTreatyResponse}
 */
proto.taxfilingpb.ListTaxTreatyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.ListTaxTreatyResponse;
  return proto.taxfilingpb.ListTaxTreatyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.ListTaxTreatyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.ListTaxTreatyResponse}
 */
proto.taxfilingpb.ListTaxTreatyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.taxfilingpb.TaxTreaty;
      reader.readMessage(value,proto.taxfilingpb.TaxTreaty.deserializeBinaryFromReader);
      msg.addTaxTreaty(value);
      break;
    case 2:
      var value = new proto.taxfilingpb.ListTaxTreatySummary;
      reader.readMessage(value,proto.taxfilingpb.ListTaxTreatySummary.deserializeBinaryFromReader);
      msg.setSummary(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.ListTaxTreatyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.ListTaxTreatyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.ListTaxTreatyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.ListTaxTreatyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTaxTreatyList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.taxfilingpb.TaxTreaty.serializeBinaryToWriter
    );
  }
  f = message.getSummary();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.taxfilingpb.ListTaxTreatySummary.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TaxTreaty tax_treaty = 1;
 * @return {!Array<!proto.taxfilingpb.TaxTreaty>}
 */
proto.taxfilingpb.ListTaxTreatyResponse.prototype.getTaxTreatyList = function() {
  return /** @type{!Array<!proto.taxfilingpb.TaxTreaty>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.taxfilingpb.TaxTreaty, 1));
};


/**
 * @param {!Array<!proto.taxfilingpb.TaxTreaty>} value
 * @return {!proto.taxfilingpb.ListTaxTreatyResponse} returns this
*/
proto.taxfilingpb.ListTaxTreatyResponse.prototype.setTaxTreatyList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.taxfilingpb.TaxTreaty=} opt_value
 * @param {number=} opt_index
 * @return {!proto.taxfilingpb.TaxTreaty}
 */
proto.taxfilingpb.ListTaxTreatyResponse.prototype.addTaxTreaty = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.taxfilingpb.TaxTreaty, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.taxfilingpb.ListTaxTreatyResponse} returns this
 */
proto.taxfilingpb.ListTaxTreatyResponse.prototype.clearTaxTreatyList = function() {
  return this.setTaxTreatyList([]);
};


/**
 * optional ListTaxTreatySummary summary = 2;
 * @return {?proto.taxfilingpb.ListTaxTreatySummary}
 */
proto.taxfilingpb.ListTaxTreatyResponse.prototype.getSummary = function() {
  return /** @type{?proto.taxfilingpb.ListTaxTreatySummary} */ (
    jspb.Message.getWrapperField(this, proto.taxfilingpb.ListTaxTreatySummary, 2));
};


/**
 * @param {?proto.taxfilingpb.ListTaxTreatySummary|undefined} value
 * @return {!proto.taxfilingpb.ListTaxTreatyResponse} returns this
*/
proto.taxfilingpb.ListTaxTreatyResponse.prototype.setSummary = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.taxfilingpb.ListTaxTreatyResponse} returns this
 */
proto.taxfilingpb.ListTaxTreatyResponse.prototype.clearSummary = function() {
  return this.setSummary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.taxfilingpb.ListTaxTreatyResponse.prototype.hasSummary = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.ListTaxTreatySummary.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.ListTaxTreatySummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.ListTaxTreatySummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.ListTaxTreatySummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalRows: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.ListTaxTreatySummary}
 */
proto.taxfilingpb.ListTaxTreatySummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.ListTaxTreatySummary;
  return proto.taxfilingpb.ListTaxTreatySummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.ListTaxTreatySummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.ListTaxTreatySummary}
 */
proto.taxfilingpb.ListTaxTreatySummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalRows(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.ListTaxTreatySummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.ListTaxTreatySummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.ListTaxTreatySummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.ListTaxTreatySummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalRows();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 total_rows = 1;
 * @return {number}
 */
proto.taxfilingpb.ListTaxTreatySummary.prototype.getTotalRows = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.taxfilingpb.ListTaxTreatySummary} returns this
 */
proto.taxfilingpb.ListTaxTreatySummary.prototype.setTotalRows = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.CreateTaxTreatyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.CreateTaxTreatyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.CreateTaxTreatyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.CreateTaxTreatyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    taxTreaty: (f = msg.getTaxTreaty()) && proto.taxfilingpb.TaxTreaty.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.CreateTaxTreatyResponse}
 */
proto.taxfilingpb.CreateTaxTreatyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.CreateTaxTreatyResponse;
  return proto.taxfilingpb.CreateTaxTreatyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.CreateTaxTreatyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.CreateTaxTreatyResponse}
 */
proto.taxfilingpb.CreateTaxTreatyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.taxfilingpb.TaxTreaty;
      reader.readMessage(value,proto.taxfilingpb.TaxTreaty.deserializeBinaryFromReader);
      msg.setTaxTreaty(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.CreateTaxTreatyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.CreateTaxTreatyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.CreateTaxTreatyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.CreateTaxTreatyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTaxTreaty();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.taxfilingpb.TaxTreaty.serializeBinaryToWriter
    );
  }
};


/**
 * optional TaxTreaty tax_treaty = 1;
 * @return {?proto.taxfilingpb.TaxTreaty}
 */
proto.taxfilingpb.CreateTaxTreatyResponse.prototype.getTaxTreaty = function() {
  return /** @type{?proto.taxfilingpb.TaxTreaty} */ (
    jspb.Message.getWrapperField(this, proto.taxfilingpb.TaxTreaty, 1));
};


/**
 * @param {?proto.taxfilingpb.TaxTreaty|undefined} value
 * @return {!proto.taxfilingpb.CreateTaxTreatyResponse} returns this
*/
proto.taxfilingpb.CreateTaxTreatyResponse.prototype.setTaxTreaty = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.taxfilingpb.CreateTaxTreatyResponse} returns this
 */
proto.taxfilingpb.CreateTaxTreatyResponse.prototype.clearTaxTreaty = function() {
  return this.setTaxTreaty(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.taxfilingpb.CreateTaxTreatyResponse.prototype.hasTaxTreaty = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.UpdateTaxTreatyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.UpdateTaxTreatyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.UpdateTaxTreatyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.UpdateTaxTreatyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    taxTreaty: (f = msg.getTaxTreaty()) && proto.taxfilingpb.TaxTreaty.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.UpdateTaxTreatyResponse}
 */
proto.taxfilingpb.UpdateTaxTreatyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.UpdateTaxTreatyResponse;
  return proto.taxfilingpb.UpdateTaxTreatyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.UpdateTaxTreatyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.UpdateTaxTreatyResponse}
 */
proto.taxfilingpb.UpdateTaxTreatyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.taxfilingpb.TaxTreaty;
      reader.readMessage(value,proto.taxfilingpb.TaxTreaty.deserializeBinaryFromReader);
      msg.setTaxTreaty(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.UpdateTaxTreatyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.UpdateTaxTreatyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.UpdateTaxTreatyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.UpdateTaxTreatyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTaxTreaty();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.taxfilingpb.TaxTreaty.serializeBinaryToWriter
    );
  }
};


/**
 * optional TaxTreaty tax_treaty = 1;
 * @return {?proto.taxfilingpb.TaxTreaty}
 */
proto.taxfilingpb.UpdateTaxTreatyResponse.prototype.getTaxTreaty = function() {
  return /** @type{?proto.taxfilingpb.TaxTreaty} */ (
    jspb.Message.getWrapperField(this, proto.taxfilingpb.TaxTreaty, 1));
};


/**
 * @param {?proto.taxfilingpb.TaxTreaty|undefined} value
 * @return {!proto.taxfilingpb.UpdateTaxTreatyResponse} returns this
*/
proto.taxfilingpb.UpdateTaxTreatyResponse.prototype.setTaxTreaty = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.taxfilingpb.UpdateTaxTreatyResponse} returns this
 */
proto.taxfilingpb.UpdateTaxTreatyResponse.prototype.clearTaxTreaty = function() {
  return this.setTaxTreaty(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.taxfilingpb.UpdateTaxTreatyResponse.prototype.hasTaxTreaty = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.DeleteTaxTreatyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.DeleteTaxTreatyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.DeleteTaxTreatyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.DeleteTaxTreatyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    taxTreatyId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.DeleteTaxTreatyRequest}
 */
proto.taxfilingpb.DeleteTaxTreatyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.DeleteTaxTreatyRequest;
  return proto.taxfilingpb.DeleteTaxTreatyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.DeleteTaxTreatyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.DeleteTaxTreatyRequest}
 */
proto.taxfilingpb.DeleteTaxTreatyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTaxTreatyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.DeleteTaxTreatyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.DeleteTaxTreatyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.DeleteTaxTreatyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.DeleteTaxTreatyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTaxTreatyId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 tax_treaty_id = 1;
 * @return {number}
 */
proto.taxfilingpb.DeleteTaxTreatyRequest.prototype.getTaxTreatyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.taxfilingpb.DeleteTaxTreatyRequest} returns this
 */
proto.taxfilingpb.DeleteTaxTreatyRequest.prototype.setTaxTreatyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.DeleteTaxTreatyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.DeleteTaxTreatyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.DeleteTaxTreatyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.DeleteTaxTreatyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    taxTreatyId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.DeleteTaxTreatyResponse}
 */
proto.taxfilingpb.DeleteTaxTreatyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.DeleteTaxTreatyResponse;
  return proto.taxfilingpb.DeleteTaxTreatyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.DeleteTaxTreatyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.DeleteTaxTreatyResponse}
 */
proto.taxfilingpb.DeleteTaxTreatyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTaxTreatyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.DeleteTaxTreatyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.DeleteTaxTreatyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.DeleteTaxTreatyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.DeleteTaxTreatyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTaxTreatyId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 tax_treaty_id = 1;
 * @return {number}
 */
proto.taxfilingpb.DeleteTaxTreatyResponse.prototype.getTaxTreatyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.taxfilingpb.DeleteTaxTreatyResponse} returns this
 */
proto.taxfilingpb.DeleteTaxTreatyResponse.prototype.setTaxTreatyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


goog.object.extend(exports, proto.taxfilingpb);
