/*eslint eqeqeq: "off"*/
import React, { useState } from 'react';
import ProcessModal from './ProcessModal';
import {
  notifyInfo,
  notifyError,
  notifySuccess,
} from 'components/Notification/Notification';
import {
  listProcess,
  process,
  processDinvra,
} from '../../../services/ProcessService';
import { Box, TextField, IconButton, Tooltip, Button } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';

import Table, { columnType } from 'components/Table/Table';
import useStyles from '../../../styles';
import SearchButton from '../../../components/Button/Search';
import SelectCorrespondent from '../../../components/AutoComplete/SelectCorrespondent';
import SelectAccountNo from '../../../components/AutoComplete/SelectAccountNo';
import QueryParam from '../../../services/QueryParamService';

export default function ProcessTable({ params }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [isRowsSelected, setIsRowsSelected] = React.useState(false);

  let columns = [
    {
      name: '',
      type: columnType.buttons,
      options: {
        customBodyRenderLite: (dataIndex) => {
          return (
            <div
              align={'left'}
              className={classes.grdRow}
              style={{ margin: 0, padding: 0 }}
            >
              <div align={'left'} className={classes.grdCell1}>
                <Tooltip title="Process" arrow>
                  <div>
                    <IconButton
                      aria-label="process"
                      disabled={
                        rows[dataIndex].status === 'canceled' ? true : false
                      }
                      onClick={() => {
                        handleOpen([rows[dataIndex]]);
                      }}
                    >
                      <SettingsIcon />
                    </IconButton>
                  </div>
                </Tooltip>
              </div>
            </div>
          );
        },
        setCellProps: () => ({
          style: { padding: '4px 16px' },
        }),
      },
    },
    {
      name: 'year',
      label: 'Year',
    },
    {
      name: 'correspondent',
      label: 'Correspondent',
    },
    {
      name: 'office',
      label: 'Branch',
    },
    {
      name: 'accountNo',
      label: 'Account No',
    },
    {
      name: 'form1042divnra',
      label: '1042 DIVNRA',
      type: columnType.boolean,
    },
    {
      name: 'form1099b',
      label: '1099 B',
      type: columnType.boolean,
    },
    {
      name: 'form1099int',
      label: '1099 INT',
      type: columnType.boolean,
    },
    {
      name: 'form1099div',
      label: '1099 DIV',
      type: columnType.boolean,
    },
    {
      name: 'form1099misc',
      label: '1099 MISC',
      type: columnType.boolean,
    },
  ];

  const options = {
    onRowSelectionChange: (
      currentRowsSelected,
      allRowsSelected,
      rowsSelected
    ) => {
      setIsRowsSelected(rowsSelected.length);
    },
    customToolbarSelect: (selectedRows, a, b) => {
      return (
        <div
          className={classes.grdRow}
          style={{ margin: 0, padding: 0, marginRight: 20 }}
        >
          <div align={'left'} className={classes.grdCell1}>
            <Tooltip title="Process Selected" arrow>
              <div>
                <IconButton
                  aria-label="process"
                  onClick={() => {
                    let selectedData = [];
                    for (const r of selectedRows.data) {
                      selectedData.push(rows[r.dataIndex]);
                    }
                    handleOpen(selectedData);
                  }}
                >
                  <SettingsIcon />
                </IconButton>
              </div>
            </Tooltip>
          </div>
        </div>
      );
    },
  };

  const [rowData, setRowData] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(true);
  const [rows, setRows] = useState([]);
  const [searchData, setSearchData] = React.useState(
    QueryParam.get({
      year: '',
      correspondent: '',
      office: '',
      accountNo: '',
    })
  );

  const handleChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;

    setSearchData({
      ...searchData,
      [input.name]: input.value,
    });
  };

  const handleSearch = async () => {
    try {
      setLoading(true);

      QueryParam.set(searchData);
      const { listProcessList } = await listProcess(searchData);

      setRows(listProcessList);
      notifyInfo(listProcessList.length + ' search results.');
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleOpen = (data) => {
    setRowData(data || []);
    setOpen(true);
  };

  const ProcessRequest = async (modalData) => {
    console.log(modalData);
    if (modalData.type == 'dinvra') {
      const resp = await processDinvra(modalData);
      return resp;
    } else {
      const resp = await process(
        modalData.type,
        modalData.year,
        modalData.accountId,
        modalData.correspondent,
        modalData.branch,
        modalData.accountNo
      );
      return resp;
    }
  };

  const handleClose = async (modalData, selected, isClickcSave) => {
    if (!isClickcSave) {
      setOpen(false);
      return;
    }
    if (!modalData) {
      setOpen(false);
      return;
    }
    let isValid = true;
    if (!modalData.type) {
      isValid = false;
      notifyError('Form type is required');
    }
    console.log(modalData.year);
    if (!modalData.year) {
      isValid = false;
      notifyError('Year is required');
    }
    if (!isValid) {
      return;
    }

    try {
      if (selected.length > 0) {
        for (const item of selected) {
          const resp = await ProcessRequest({
            ...modalData,
            accountId: item.accountId,
            year: item.year,
            correspondent: item.correspondent,
            branch: item.branch,
            accountNo: item.accountNo,
          });
          if (resp.success) {
            notifySuccess(
              'Form successfully processed (year: ' +
                item.year +
                ' account: ' +
                item.correspondent +
                '' +
                item.office +
                '' +
                item.accountNo +
                ')'
            );
          } else {
            notifyError('Unable to process');
          }
        }
      } else {
        const resp = await ProcessRequest({ ...modalData, accountId: 0 });
        if (resp.success) {
          if (modalData.type == 'dinvra') {
            notifySuccess(
              'Form successfully processed (From Date: ' +
                modalData.fromDate +
                ' To Date:' +
                modalData.toDate +
                ')'
            );
          } else {
            notifySuccess(
              'Form successfully processed (year: ' + modalData.year + ')'
            );
          }
        } else {
          notifyError('Unable to process');
        }
      }
    } catch (error) {
      notifyError(error.message);
    }
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <Box component="div" mt={5}>
        {showFilter ? (
          <div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  name="year"
                  label="Year"
                  type="number"
                  inputProps={{
                    min: 2000,
                    max: 3000,
                    maxLength: 4,
                    minLength: 4,
                  }}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={searchData.year}
                  onChange={handleChange}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <SelectCorrespondent
                  required={false}
                  name="correspondent"
                  label="Correspondent"
                  value={searchData.correspondent}
                  onChange={handleChange}
                ></SelectCorrespondent>
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  name="office"
                  fullWidth
                  label="Branch"
                  value={searchData.office}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ maxLength: 3 }}
                />
              </div>
              <div className={classes.grdCell1}>
                <SelectAccountNo
                  required={false}
                  name="accountNo"
                  label="Account No"
                  value={searchData.accountNo}
                  onChange={handleChange}
                ></SelectAccountNo>
              </div>
            </div>
          </div>
        ) : null}
        <div className={classes.actionContainer}>
          <div className={classes.grdCellNone} style={{ marginRight: 10 }}>
            <SearchButton
              onClick={handleSearch}
              loading={loading}
              showfilter={(status) => setShowFilter(status)}
            />
          </div>
          {!isRowsSelected && (
            <div className={classes.grdCellNone}>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                onClick={() => {
                  handleOpen();
                }}
              >
                Process
              </Button>
            </div>
          )}
        </div>
      </Box>
      <Box component="div" mt={2}>
        <Table
          title={'PROCESS'}
          data={rows}
          columns={columns}
          options={options}
          additionalCell={true}
        />
      </Box>

      {open && (
        <ProcessModal
          onClose={handleClose}
          open={open}
          value={rowData}
        ></ProcessModal>
      )}
    </div>
  );
}
