// source: proto/taxfilingpb/form1099_div.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var proto_commonpb_file_pb = require('../../proto/commonpb/file_pb.js');
goog.object.extend(proto, proto_commonpb_file_pb);
var proto_utilspb_pagination_pb = require('../../proto/utilspb/pagination_pb.js');
goog.object.extend(proto, proto_utilspb_pagination_pb);
var google_type_date_pb = require('../../google/type/date_pb.js');
goog.object.extend(proto, google_type_date_pb);
goog.exportSymbol('proto.taxfilingpb.DownLoadDivRequest', null, global);
goog.exportSymbol('proto.taxfilingpb.Form1099Div', null, global);
goog.exportSymbol('proto.taxfilingpb.Form1099DivDetails', null, global);
goog.exportSymbol('proto.taxfilingpb.Form1099DivProcessRequest', null, global);
goog.exportSymbol('proto.taxfilingpb.Form1099DivProcessResponse', null, global);
goog.exportSymbol('proto.taxfilingpb.Form1099DivRequest', null, global);
goog.exportSymbol('proto.taxfilingpb.GenerateDivResponse', null, global);
goog.exportSymbol('proto.taxfilingpb.ListForm1099DivDetailsResponse', null, global);
goog.exportSymbol('proto.taxfilingpb.ListForm1099DivDetailsSummary', null, global);
goog.exportSymbol('proto.taxfilingpb.ListForm1099DivResponse', null, global);
goog.exportSymbol('proto.taxfilingpb.ListForm1099DivSummary', null, global);
goog.exportSymbol('proto.taxfilingpb.UpdateDivResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.Form1099Div = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.taxfilingpb.Form1099Div, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.Form1099Div.displayName = 'proto.taxfilingpb.Form1099Div';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.Form1099DivDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.taxfilingpb.Form1099DivDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.Form1099DivDetails.displayName = 'proto.taxfilingpb.Form1099DivDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.Form1099DivRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.taxfilingpb.Form1099DivRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.Form1099DivRequest.displayName = 'proto.taxfilingpb.Form1099DivRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.ListForm1099DivResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.taxfilingpb.ListForm1099DivResponse.repeatedFields_, null);
};
goog.inherits(proto.taxfilingpb.ListForm1099DivResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.ListForm1099DivResponse.displayName = 'proto.taxfilingpb.ListForm1099DivResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.ListForm1099DivSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.taxfilingpb.ListForm1099DivSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.ListForm1099DivSummary.displayName = 'proto.taxfilingpb.ListForm1099DivSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.ListForm1099DivDetailsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.taxfilingpb.ListForm1099DivDetailsResponse.repeatedFields_, null);
};
goog.inherits(proto.taxfilingpb.ListForm1099DivDetailsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.ListForm1099DivDetailsResponse.displayName = 'proto.taxfilingpb.ListForm1099DivDetailsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.ListForm1099DivDetailsSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.taxfilingpb.ListForm1099DivDetailsSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.ListForm1099DivDetailsSummary.displayName = 'proto.taxfilingpb.ListForm1099DivDetailsSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.Form1099DivProcessRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.taxfilingpb.Form1099DivProcessRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.Form1099DivProcessRequest.displayName = 'proto.taxfilingpb.Form1099DivProcessRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.Form1099DivProcessResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.taxfilingpb.Form1099DivProcessResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.Form1099DivProcessResponse.displayName = 'proto.taxfilingpb.Form1099DivProcessResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.DownLoadDivRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.taxfilingpb.DownLoadDivRequest.repeatedFields_, null);
};
goog.inherits(proto.taxfilingpb.DownLoadDivRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.DownLoadDivRequest.displayName = 'proto.taxfilingpb.DownLoadDivRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.GenerateDivResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.taxfilingpb.GenerateDivResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.GenerateDivResponse.displayName = 'proto.taxfilingpb.GenerateDivResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.UpdateDivResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.taxfilingpb.UpdateDivResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.UpdateDivResponse.displayName = 'proto.taxfilingpb.UpdateDivResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.Form1099Div.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.Form1099Div.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.Form1099Div} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.Form1099Div.toObject = function(includeInstance, msg) {
  var f, obj = {
    account: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accountName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    address: jspb.Message.getFieldWithDefault(msg, 3, ""),
    city: jspb.Message.getFieldWithDefault(msg, 4, ""),
    state: jspb.Message.getFieldWithDefault(msg, 5, ""),
    zip: jspb.Message.getFieldWithDefault(msg, 6, ""),
    country: jspb.Message.getFieldWithDefault(msg, 7, ""),
    div: jspb.Message.getFieldWithDefault(msg, 8, ""),
    taxAmount: jspb.Message.getFieldWithDefault(msg, 9, ""),
    correspondent: jspb.Message.getFieldWithDefault(msg, 10, ""),
    branch: jspb.Message.getFieldWithDefault(msg, 11, ""),
    accountNo: jspb.Message.getFieldWithDefault(msg, 12, ""),
    taxCountry: jspb.Message.getFieldWithDefault(msg, 13, ""),
    year: jspb.Message.getFieldWithDefault(msg, 14, ""),
    totalOrdinaryDividends: jspb.Message.getFieldWithDefault(msg, 15, ""),
    qualifiedDividends: jspb.Message.getFieldWithDefault(msg, 16, ""),
    totalCapitalGainDistr: jspb.Message.getFieldWithDefault(msg, 17, ""),
    unrecapSec1250Gain: jspb.Message.getFieldWithDefault(msg, 18, ""),
    section1202Gain: jspb.Message.getFieldWithDefault(msg, 19, ""),
    collectiblesGain: jspb.Message.getFieldWithDefault(msg, 20, ""),
    nonDividendDistributions: jspb.Message.getFieldWithDefault(msg, 21, ""),
    federalIncomeTaxWithheld: jspb.Message.getFieldWithDefault(msg, 22, ""),
    section199aDividends: jspb.Message.getFieldWithDefault(msg, 23, ""),
    investmentExpenses: jspb.Message.getFieldWithDefault(msg, 24, ""),
    foreignTaxPaid: jspb.Message.getFieldWithDefault(msg, 25, ""),
    foreignCountryOrUsPossession: jspb.Message.getFieldWithDefault(msg, 26, ""),
    cashLiquidationDistributions: jspb.Message.getFieldWithDefault(msg, 27, ""),
    noncashLiquidationDistributions: jspb.Message.getFieldWithDefault(msg, 28, ""),
    exempInterestDividends: jspb.Message.getFieldWithDefault(msg, 29, ""),
    specifiedPrivateActivityBondInterestDividends: jspb.Message.getFieldWithDefault(msg, 30, ""),
    accountId: jspb.Message.getFieldWithDefault(msg, 31, 0),
    form1099divId: jspb.Message.getFieldWithDefault(msg, 32, 0),
    fatchFilingRequirement: jspb.Message.getFieldWithDefault(msg, 33, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.Form1099Div}
 */
proto.taxfilingpb.Form1099Div.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.Form1099Div;
  return proto.taxfilingpb.Form1099Div.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.Form1099Div} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.Form1099Div}
 */
proto.taxfilingpb.Form1099Div.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccount(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setZip(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDiv(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaxAmount(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setBranch(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaxCountry(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setYear(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setTotalOrdinaryDividends(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setQualifiedDividends(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setTotalCapitalGainDistr(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnrecapSec1250Gain(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setSection1202Gain(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setCollectiblesGain(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setNonDividendDistributions(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setFederalIncomeTaxWithheld(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setSection199aDividends(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvestmentExpenses(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setForeignTaxPaid(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setForeignCountryOrUsPossession(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setCashLiquidationDistributions(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setNoncashLiquidationDistributions(value);
      break;
    case 29:
      var value = /** @type {string} */ (reader.readString());
      msg.setExempInterestDividends(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpecifiedPrivateActivityBondInterestDividends(value);
      break;
    case 31:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAccountId(value);
      break;
    case 32:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setForm1099divId(value);
      break;
    case 33:
      var value = /** @type {string} */ (reader.readString());
      msg.setFatchFilingRequirement(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.Form1099Div.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.Form1099Div.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.Form1099Div} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.Form1099Div.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccount();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getZip();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDiv();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getTaxAmount();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getBranch();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getTaxCountry();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getYear();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getTotalOrdinaryDividends();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getQualifiedDividends();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getTotalCapitalGainDistr();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getUnrecapSec1250Gain();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getSection1202Gain();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getCollectiblesGain();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getNonDividendDistributions();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getFederalIncomeTaxWithheld();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getSection199aDividends();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getInvestmentExpenses();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getForeignTaxPaid();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getForeignCountryOrUsPossession();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getCashLiquidationDistributions();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getNoncashLiquidationDistributions();
  if (f.length > 0) {
    writer.writeString(
      28,
      f
    );
  }
  f = message.getExempInterestDividends();
  if (f.length > 0) {
    writer.writeString(
      29,
      f
    );
  }
  f = message.getSpecifiedPrivateActivityBondInterestDividends();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeUint32(
      31,
      f
    );
  }
  f = message.getForm1099divId();
  if (f !== 0) {
    writer.writeUint32(
      32,
      f
    );
  }
  f = message.getFatchFilingRequirement();
  if (f.length > 0) {
    writer.writeString(
      33,
      f
    );
  }
};


/**
 * optional string account = 1;
 * @return {string}
 */
proto.taxfilingpb.Form1099Div.prototype.getAccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099Div} returns this
 */
proto.taxfilingpb.Form1099Div.prototype.setAccount = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_name = 2;
 * @return {string}
 */
proto.taxfilingpb.Form1099Div.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099Div} returns this
 */
proto.taxfilingpb.Form1099Div.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string address = 3;
 * @return {string}
 */
proto.taxfilingpb.Form1099Div.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099Div} returns this
 */
proto.taxfilingpb.Form1099Div.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string city = 4;
 * @return {string}
 */
proto.taxfilingpb.Form1099Div.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099Div} returns this
 */
proto.taxfilingpb.Form1099Div.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string state = 5;
 * @return {string}
 */
proto.taxfilingpb.Form1099Div.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099Div} returns this
 */
proto.taxfilingpb.Form1099Div.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string zip = 6;
 * @return {string}
 */
proto.taxfilingpb.Form1099Div.prototype.getZip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099Div} returns this
 */
proto.taxfilingpb.Form1099Div.prototype.setZip = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string country = 7;
 * @return {string}
 */
proto.taxfilingpb.Form1099Div.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099Div} returns this
 */
proto.taxfilingpb.Form1099Div.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string div = 8;
 * @return {string}
 */
proto.taxfilingpb.Form1099Div.prototype.getDiv = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099Div} returns this
 */
proto.taxfilingpb.Form1099Div.prototype.setDiv = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string tax_amount = 9;
 * @return {string}
 */
proto.taxfilingpb.Form1099Div.prototype.getTaxAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099Div} returns this
 */
proto.taxfilingpb.Form1099Div.prototype.setTaxAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string correspondent = 10;
 * @return {string}
 */
proto.taxfilingpb.Form1099Div.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099Div} returns this
 */
proto.taxfilingpb.Form1099Div.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string branch = 11;
 * @return {string}
 */
proto.taxfilingpb.Form1099Div.prototype.getBranch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099Div} returns this
 */
proto.taxfilingpb.Form1099Div.prototype.setBranch = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string account_no = 12;
 * @return {string}
 */
proto.taxfilingpb.Form1099Div.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099Div} returns this
 */
proto.taxfilingpb.Form1099Div.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string tax_country = 13;
 * @return {string}
 */
proto.taxfilingpb.Form1099Div.prototype.getTaxCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099Div} returns this
 */
proto.taxfilingpb.Form1099Div.prototype.setTaxCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string year = 14;
 * @return {string}
 */
proto.taxfilingpb.Form1099Div.prototype.getYear = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099Div} returns this
 */
proto.taxfilingpb.Form1099Div.prototype.setYear = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string total_ordinary_dividends = 15;
 * @return {string}
 */
proto.taxfilingpb.Form1099Div.prototype.getTotalOrdinaryDividends = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099Div} returns this
 */
proto.taxfilingpb.Form1099Div.prototype.setTotalOrdinaryDividends = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string qualified_dividends = 16;
 * @return {string}
 */
proto.taxfilingpb.Form1099Div.prototype.getQualifiedDividends = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099Div} returns this
 */
proto.taxfilingpb.Form1099Div.prototype.setQualifiedDividends = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string total_capital_gain_distr = 17;
 * @return {string}
 */
proto.taxfilingpb.Form1099Div.prototype.getTotalCapitalGainDistr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099Div} returns this
 */
proto.taxfilingpb.Form1099Div.prototype.setTotalCapitalGainDistr = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string unrecap_sec1250_gain = 18;
 * @return {string}
 */
proto.taxfilingpb.Form1099Div.prototype.getUnrecapSec1250Gain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099Div} returns this
 */
proto.taxfilingpb.Form1099Div.prototype.setUnrecapSec1250Gain = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string section1202_gain = 19;
 * @return {string}
 */
proto.taxfilingpb.Form1099Div.prototype.getSection1202Gain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099Div} returns this
 */
proto.taxfilingpb.Form1099Div.prototype.setSection1202Gain = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string collectibles_gain = 20;
 * @return {string}
 */
proto.taxfilingpb.Form1099Div.prototype.getCollectiblesGain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099Div} returns this
 */
proto.taxfilingpb.Form1099Div.prototype.setCollectiblesGain = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string non_dividend_distributions = 21;
 * @return {string}
 */
proto.taxfilingpb.Form1099Div.prototype.getNonDividendDistributions = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099Div} returns this
 */
proto.taxfilingpb.Form1099Div.prototype.setNonDividendDistributions = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string federal_income_tax_withheld = 22;
 * @return {string}
 */
proto.taxfilingpb.Form1099Div.prototype.getFederalIncomeTaxWithheld = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099Div} returns this
 */
proto.taxfilingpb.Form1099Div.prototype.setFederalIncomeTaxWithheld = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string section199A_dividends = 23;
 * @return {string}
 */
proto.taxfilingpb.Form1099Div.prototype.getSection199aDividends = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099Div} returns this
 */
proto.taxfilingpb.Form1099Div.prototype.setSection199aDividends = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string investment_expenses = 24;
 * @return {string}
 */
proto.taxfilingpb.Form1099Div.prototype.getInvestmentExpenses = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099Div} returns this
 */
proto.taxfilingpb.Form1099Div.prototype.setInvestmentExpenses = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string foreign_tax_paid = 25;
 * @return {string}
 */
proto.taxfilingpb.Form1099Div.prototype.getForeignTaxPaid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099Div} returns this
 */
proto.taxfilingpb.Form1099Div.prototype.setForeignTaxPaid = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional string foreign_country_or_us_possession = 26;
 * @return {string}
 */
proto.taxfilingpb.Form1099Div.prototype.getForeignCountryOrUsPossession = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099Div} returns this
 */
proto.taxfilingpb.Form1099Div.prototype.setForeignCountryOrUsPossession = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional string cash_liquidation_distributions = 27;
 * @return {string}
 */
proto.taxfilingpb.Form1099Div.prototype.getCashLiquidationDistributions = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099Div} returns this
 */
proto.taxfilingpb.Form1099Div.prototype.setCashLiquidationDistributions = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional string noncash_liquidation_distributions = 28;
 * @return {string}
 */
proto.taxfilingpb.Form1099Div.prototype.getNoncashLiquidationDistributions = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099Div} returns this
 */
proto.taxfilingpb.Form1099Div.prototype.setNoncashLiquidationDistributions = function(value) {
  return jspb.Message.setProto3StringField(this, 28, value);
};


/**
 * optional string exemp_interest_dividends = 29;
 * @return {string}
 */
proto.taxfilingpb.Form1099Div.prototype.getExempInterestDividends = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 29, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099Div} returns this
 */
proto.taxfilingpb.Form1099Div.prototype.setExempInterestDividends = function(value) {
  return jspb.Message.setProto3StringField(this, 29, value);
};


/**
 * optional string specified_private_activity_bond_interest_dividends = 30;
 * @return {string}
 */
proto.taxfilingpb.Form1099Div.prototype.getSpecifiedPrivateActivityBondInterestDividends = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099Div} returns this
 */
proto.taxfilingpb.Form1099Div.prototype.setSpecifiedPrivateActivityBondInterestDividends = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional uint32 account_id = 31;
 * @return {number}
 */
proto.taxfilingpb.Form1099Div.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 31, 0));
};


/**
 * @param {number} value
 * @return {!proto.taxfilingpb.Form1099Div} returns this
 */
proto.taxfilingpb.Form1099Div.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 31, value);
};


/**
 * optional uint32 form1099div_id = 32;
 * @return {number}
 */
proto.taxfilingpb.Form1099Div.prototype.getForm1099divId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 32, 0));
};


/**
 * @param {number} value
 * @return {!proto.taxfilingpb.Form1099Div} returns this
 */
proto.taxfilingpb.Form1099Div.prototype.setForm1099divId = function(value) {
  return jspb.Message.setProto3IntField(this, 32, value);
};


/**
 * optional string fatch_filing_requirement = 33;
 * @return {string}
 */
proto.taxfilingpb.Form1099Div.prototype.getFatchFilingRequirement = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 33, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099Div} returns this
 */
proto.taxfilingpb.Form1099Div.prototype.setFatchFilingRequirement = function(value) {
  return jspb.Message.setProto3StringField(this, 33, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.Form1099DivDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.Form1099DivDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.Form1099DivDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.Form1099DivDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    trnsId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    settleDate: (f = msg.getSettleDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    symbol: jspb.Message.getFieldWithDefault(msg, 3, ""),
    cusip: jspb.Message.getFieldWithDefault(msg, 4, ""),
    symbolDescription: jspb.Message.getFieldWithDefault(msg, 5, ""),
    symbolCountry: jspb.Message.getFieldWithDefault(msg, 6, ""),
    div: jspb.Message.getFieldWithDefault(msg, 7, ""),
    taxRate: jspb.Message.getFieldWithDefault(msg, 8, ""),
    taxAmount: jspb.Message.getFieldWithDefault(msg, 9, ""),
    totalOrdinaryDividends: jspb.Message.getFieldWithDefault(msg, 10, ""),
    qualifiedDividends: jspb.Message.getFieldWithDefault(msg, 11, ""),
    totalCapitalGainDistr: jspb.Message.getFieldWithDefault(msg, 12, ""),
    unrecapSec1250Gain: jspb.Message.getFieldWithDefault(msg, 13, ""),
    section1202Gain: jspb.Message.getFieldWithDefault(msg, 14, ""),
    collectiblesGain: jspb.Message.getFieldWithDefault(msg, 15, ""),
    nonDividendDistributions: jspb.Message.getFieldWithDefault(msg, 16, ""),
    federalIncomeTaxWithheld: jspb.Message.getFieldWithDefault(msg, 17, ""),
    section199aDividends: jspb.Message.getFieldWithDefault(msg, 18, ""),
    investmentExpenses: jspb.Message.getFieldWithDefault(msg, 19, ""),
    foreignTaxPaid: jspb.Message.getFieldWithDefault(msg, 20, ""),
    foreignCountryOrUsPossession: jspb.Message.getFieldWithDefault(msg, 21, ""),
    cashLiquidationDistributions: jspb.Message.getFieldWithDefault(msg, 22, ""),
    noncashLiquidationDistributions: jspb.Message.getFieldWithDefault(msg, 23, ""),
    exemptInterestDividends: jspb.Message.getFieldWithDefault(msg, 24, ""),
    specifiedPrivateActivityBondInterestDividends: jspb.Message.getFieldWithDefault(msg, 25, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.Form1099DivDetails}
 */
proto.taxfilingpb.Form1099DivDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.Form1099DivDetails;
  return proto.taxfilingpb.Form1099DivDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.Form1099DivDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.Form1099DivDetails}
 */
proto.taxfilingpb.Form1099DivDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrnsId(value);
      break;
    case 2:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setSettleDate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCusip(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbolDescription(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbolCountry(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDiv(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaxRate(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaxAmount(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setTotalOrdinaryDividends(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setQualifiedDividends(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setTotalCapitalGainDistr(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnrecapSec1250Gain(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setSection1202Gain(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setCollectiblesGain(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setNonDividendDistributions(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setFederalIncomeTaxWithheld(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setSection199aDividends(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvestmentExpenses(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setForeignTaxPaid(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setForeignCountryOrUsPossession(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setCashLiquidationDistributions(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setNoncashLiquidationDistributions(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setExemptInterestDividends(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpecifiedPrivateActivityBondInterestDividends(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.Form1099DivDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.Form1099DivDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.Form1099DivDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.Form1099DivDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrnsId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSettleDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCusip();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSymbolDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSymbolCountry();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDiv();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getTaxRate();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getTaxAmount();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getTotalOrdinaryDividends();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getQualifiedDividends();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getTotalCapitalGainDistr();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getUnrecapSec1250Gain();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getSection1202Gain();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getCollectiblesGain();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getNonDividendDistributions();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getFederalIncomeTaxWithheld();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getSection199aDividends();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getInvestmentExpenses();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getForeignTaxPaid();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getForeignCountryOrUsPossession();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getCashLiquidationDistributions();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getNoncashLiquidationDistributions();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getExemptInterestDividends();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getSpecifiedPrivateActivityBondInterestDividends();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
};


/**
 * optional string trns_id = 1;
 * @return {string}
 */
proto.taxfilingpb.Form1099DivDetails.prototype.getTrnsId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099DivDetails} returns this
 */
proto.taxfilingpb.Form1099DivDetails.prototype.setTrnsId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.type.Date settle_date = 2;
 * @return {?proto.google.type.Date}
 */
proto.taxfilingpb.Form1099DivDetails.prototype.getSettleDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 2));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.taxfilingpb.Form1099DivDetails} returns this
*/
proto.taxfilingpb.Form1099DivDetails.prototype.setSettleDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.taxfilingpb.Form1099DivDetails} returns this
 */
proto.taxfilingpb.Form1099DivDetails.prototype.clearSettleDate = function() {
  return this.setSettleDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.taxfilingpb.Form1099DivDetails.prototype.hasSettleDate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string symbol = 3;
 * @return {string}
 */
proto.taxfilingpb.Form1099DivDetails.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099DivDetails} returns this
 */
proto.taxfilingpb.Form1099DivDetails.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string cusip = 4;
 * @return {string}
 */
proto.taxfilingpb.Form1099DivDetails.prototype.getCusip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099DivDetails} returns this
 */
proto.taxfilingpb.Form1099DivDetails.prototype.setCusip = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string symbol_description = 5;
 * @return {string}
 */
proto.taxfilingpb.Form1099DivDetails.prototype.getSymbolDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099DivDetails} returns this
 */
proto.taxfilingpb.Form1099DivDetails.prototype.setSymbolDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string symbol_country = 6;
 * @return {string}
 */
proto.taxfilingpb.Form1099DivDetails.prototype.getSymbolCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099DivDetails} returns this
 */
proto.taxfilingpb.Form1099DivDetails.prototype.setSymbolCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string div = 7;
 * @return {string}
 */
proto.taxfilingpb.Form1099DivDetails.prototype.getDiv = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099DivDetails} returns this
 */
proto.taxfilingpb.Form1099DivDetails.prototype.setDiv = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string tax_rate = 8;
 * @return {string}
 */
proto.taxfilingpb.Form1099DivDetails.prototype.getTaxRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099DivDetails} returns this
 */
proto.taxfilingpb.Form1099DivDetails.prototype.setTaxRate = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string tax_amount = 9;
 * @return {string}
 */
proto.taxfilingpb.Form1099DivDetails.prototype.getTaxAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099DivDetails} returns this
 */
proto.taxfilingpb.Form1099DivDetails.prototype.setTaxAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string total_ordinary_dividends = 10;
 * @return {string}
 */
proto.taxfilingpb.Form1099DivDetails.prototype.getTotalOrdinaryDividends = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099DivDetails} returns this
 */
proto.taxfilingpb.Form1099DivDetails.prototype.setTotalOrdinaryDividends = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string qualified_dividends = 11;
 * @return {string}
 */
proto.taxfilingpb.Form1099DivDetails.prototype.getQualifiedDividends = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099DivDetails} returns this
 */
proto.taxfilingpb.Form1099DivDetails.prototype.setQualifiedDividends = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string total_capital_gain_distr = 12;
 * @return {string}
 */
proto.taxfilingpb.Form1099DivDetails.prototype.getTotalCapitalGainDistr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099DivDetails} returns this
 */
proto.taxfilingpb.Form1099DivDetails.prototype.setTotalCapitalGainDistr = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string unrecap_sec1250_gain = 13;
 * @return {string}
 */
proto.taxfilingpb.Form1099DivDetails.prototype.getUnrecapSec1250Gain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099DivDetails} returns this
 */
proto.taxfilingpb.Form1099DivDetails.prototype.setUnrecapSec1250Gain = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string section1202_gain = 14;
 * @return {string}
 */
proto.taxfilingpb.Form1099DivDetails.prototype.getSection1202Gain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099DivDetails} returns this
 */
proto.taxfilingpb.Form1099DivDetails.prototype.setSection1202Gain = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string collectibles_gain = 15;
 * @return {string}
 */
proto.taxfilingpb.Form1099DivDetails.prototype.getCollectiblesGain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099DivDetails} returns this
 */
proto.taxfilingpb.Form1099DivDetails.prototype.setCollectiblesGain = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string non_dividend_distributions = 16;
 * @return {string}
 */
proto.taxfilingpb.Form1099DivDetails.prototype.getNonDividendDistributions = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099DivDetails} returns this
 */
proto.taxfilingpb.Form1099DivDetails.prototype.setNonDividendDistributions = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string federal_income_tax_withheld = 17;
 * @return {string}
 */
proto.taxfilingpb.Form1099DivDetails.prototype.getFederalIncomeTaxWithheld = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099DivDetails} returns this
 */
proto.taxfilingpb.Form1099DivDetails.prototype.setFederalIncomeTaxWithheld = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string section199A_dividends = 18;
 * @return {string}
 */
proto.taxfilingpb.Form1099DivDetails.prototype.getSection199aDividends = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099DivDetails} returns this
 */
proto.taxfilingpb.Form1099DivDetails.prototype.setSection199aDividends = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string investment_expenses = 19;
 * @return {string}
 */
proto.taxfilingpb.Form1099DivDetails.prototype.getInvestmentExpenses = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099DivDetails} returns this
 */
proto.taxfilingpb.Form1099DivDetails.prototype.setInvestmentExpenses = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string foreign_tax_paid = 20;
 * @return {string}
 */
proto.taxfilingpb.Form1099DivDetails.prototype.getForeignTaxPaid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099DivDetails} returns this
 */
proto.taxfilingpb.Form1099DivDetails.prototype.setForeignTaxPaid = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string foreign_country_or_us_possession = 21;
 * @return {string}
 */
proto.taxfilingpb.Form1099DivDetails.prototype.getForeignCountryOrUsPossession = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099DivDetails} returns this
 */
proto.taxfilingpb.Form1099DivDetails.prototype.setForeignCountryOrUsPossession = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string cash_liquidation_distributions = 22;
 * @return {string}
 */
proto.taxfilingpb.Form1099DivDetails.prototype.getCashLiquidationDistributions = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099DivDetails} returns this
 */
proto.taxfilingpb.Form1099DivDetails.prototype.setCashLiquidationDistributions = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string noncash_liquidation_distributions = 23;
 * @return {string}
 */
proto.taxfilingpb.Form1099DivDetails.prototype.getNoncashLiquidationDistributions = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099DivDetails} returns this
 */
proto.taxfilingpb.Form1099DivDetails.prototype.setNoncashLiquidationDistributions = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string exempt_interest_dividends = 24;
 * @return {string}
 */
proto.taxfilingpb.Form1099DivDetails.prototype.getExemptInterestDividends = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099DivDetails} returns this
 */
proto.taxfilingpb.Form1099DivDetails.prototype.setExemptInterestDividends = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string specified_private_activity_bond_interest_dividends = 25;
 * @return {string}
 */
proto.taxfilingpb.Form1099DivDetails.prototype.getSpecifiedPrivateActivityBondInterestDividends = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099DivDetails} returns this
 */
proto.taxfilingpb.Form1099DivDetails.prototype.setSpecifiedPrivateActivityBondInterestDividends = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.Form1099DivRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.Form1099DivRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.Form1099DivRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.Form1099DivRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    year: jspb.Message.getFieldWithDefault(msg, 1, ""),
    correspondent: jspb.Message.getFieldWithDefault(msg, 2, ""),
    branch: jspb.Message.getFieldWithDefault(msg, 3, ""),
    accountNo: jspb.Message.getFieldWithDefault(msg, 4, ""),
    accountId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    pagination: (f = msg.getPagination()) && proto_utilspb_pagination_pb.Pagination.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.Form1099DivRequest}
 */
proto.taxfilingpb.Form1099DivRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.Form1099DivRequest;
  return proto.taxfilingpb.Form1099DivRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.Form1099DivRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.Form1099DivRequest}
 */
proto.taxfilingpb.Form1099DivRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setYear(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBranch(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAccountId(value);
      break;
    case 6:
      var value = new proto_utilspb_pagination_pb.Pagination;
      reader.readMessage(value,proto_utilspb_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.Form1099DivRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.Form1099DivRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.Form1099DivRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.Form1099DivRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getYear();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBranch();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_utilspb_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
};


/**
 * optional string year = 1;
 * @return {string}
 */
proto.taxfilingpb.Form1099DivRequest.prototype.getYear = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099DivRequest} returns this
 */
proto.taxfilingpb.Form1099DivRequest.prototype.setYear = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string correspondent = 2;
 * @return {string}
 */
proto.taxfilingpb.Form1099DivRequest.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099DivRequest} returns this
 */
proto.taxfilingpb.Form1099DivRequest.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string branch = 3;
 * @return {string}
 */
proto.taxfilingpb.Form1099DivRequest.prototype.getBranch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099DivRequest} returns this
 */
proto.taxfilingpb.Form1099DivRequest.prototype.setBranch = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string account_no = 4;
 * @return {string}
 */
proto.taxfilingpb.Form1099DivRequest.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099DivRequest} returns this
 */
proto.taxfilingpb.Form1099DivRequest.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 account_id = 5;
 * @return {number}
 */
proto.taxfilingpb.Form1099DivRequest.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.taxfilingpb.Form1099DivRequest} returns this
 */
proto.taxfilingpb.Form1099DivRequest.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional utilspb.Pagination pagination = 6;
 * @return {?proto.utilspb.Pagination}
 */
proto.taxfilingpb.Form1099DivRequest.prototype.getPagination = function() {
  return /** @type{?proto.utilspb.Pagination} */ (
    jspb.Message.getWrapperField(this, proto_utilspb_pagination_pb.Pagination, 6));
};


/**
 * @param {?proto.utilspb.Pagination|undefined} value
 * @return {!proto.taxfilingpb.Form1099DivRequest} returns this
*/
proto.taxfilingpb.Form1099DivRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.taxfilingpb.Form1099DivRequest} returns this
 */
proto.taxfilingpb.Form1099DivRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.taxfilingpb.Form1099DivRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.taxfilingpb.ListForm1099DivResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.ListForm1099DivResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.ListForm1099DivResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.ListForm1099DivResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.ListForm1099DivResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    form1099DivList: jspb.Message.toObjectList(msg.getForm1099DivList(),
    proto.taxfilingpb.Form1099Div.toObject, includeInstance),
    summary: (f = msg.getSummary()) && proto.taxfilingpb.ListForm1099DivSummary.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.ListForm1099DivResponse}
 */
proto.taxfilingpb.ListForm1099DivResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.ListForm1099DivResponse;
  return proto.taxfilingpb.ListForm1099DivResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.ListForm1099DivResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.ListForm1099DivResponse}
 */
proto.taxfilingpb.ListForm1099DivResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.taxfilingpb.Form1099Div;
      reader.readMessage(value,proto.taxfilingpb.Form1099Div.deserializeBinaryFromReader);
      msg.addForm1099Div(value);
      break;
    case 2:
      var value = new proto.taxfilingpb.ListForm1099DivSummary;
      reader.readMessage(value,proto.taxfilingpb.ListForm1099DivSummary.deserializeBinaryFromReader);
      msg.setSummary(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.ListForm1099DivResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.ListForm1099DivResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.ListForm1099DivResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.ListForm1099DivResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getForm1099DivList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.taxfilingpb.Form1099Div.serializeBinaryToWriter
    );
  }
  f = message.getSummary();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.taxfilingpb.ListForm1099DivSummary.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Form1099Div form1099_div = 1;
 * @return {!Array<!proto.taxfilingpb.Form1099Div>}
 */
proto.taxfilingpb.ListForm1099DivResponse.prototype.getForm1099DivList = function() {
  return /** @type{!Array<!proto.taxfilingpb.Form1099Div>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.taxfilingpb.Form1099Div, 1));
};


/**
 * @param {!Array<!proto.taxfilingpb.Form1099Div>} value
 * @return {!proto.taxfilingpb.ListForm1099DivResponse} returns this
*/
proto.taxfilingpb.ListForm1099DivResponse.prototype.setForm1099DivList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.taxfilingpb.Form1099Div=} opt_value
 * @param {number=} opt_index
 * @return {!proto.taxfilingpb.Form1099Div}
 */
proto.taxfilingpb.ListForm1099DivResponse.prototype.addForm1099Div = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.taxfilingpb.Form1099Div, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.taxfilingpb.ListForm1099DivResponse} returns this
 */
proto.taxfilingpb.ListForm1099DivResponse.prototype.clearForm1099DivList = function() {
  return this.setForm1099DivList([]);
};


/**
 * optional ListForm1099DivSummary summary = 2;
 * @return {?proto.taxfilingpb.ListForm1099DivSummary}
 */
proto.taxfilingpb.ListForm1099DivResponse.prototype.getSummary = function() {
  return /** @type{?proto.taxfilingpb.ListForm1099DivSummary} */ (
    jspb.Message.getWrapperField(this, proto.taxfilingpb.ListForm1099DivSummary, 2));
};


/**
 * @param {?proto.taxfilingpb.ListForm1099DivSummary|undefined} value
 * @return {!proto.taxfilingpb.ListForm1099DivResponse} returns this
*/
proto.taxfilingpb.ListForm1099DivResponse.prototype.setSummary = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.taxfilingpb.ListForm1099DivResponse} returns this
 */
proto.taxfilingpb.ListForm1099DivResponse.prototype.clearSummary = function() {
  return this.setSummary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.taxfilingpb.ListForm1099DivResponse.prototype.hasSummary = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.ListForm1099DivSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.ListForm1099DivSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.ListForm1099DivSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.ListForm1099DivSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalRows: jspb.Message.getFieldWithDefault(msg, 1, 0),
    div: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.ListForm1099DivSummary}
 */
proto.taxfilingpb.ListForm1099DivSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.ListForm1099DivSummary;
  return proto.taxfilingpb.ListForm1099DivSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.ListForm1099DivSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.ListForm1099DivSummary}
 */
proto.taxfilingpb.ListForm1099DivSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalRows(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDiv(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.ListForm1099DivSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.ListForm1099DivSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.ListForm1099DivSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.ListForm1099DivSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalRows();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getDiv();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional uint32 total_rows = 1;
 * @return {number}
 */
proto.taxfilingpb.ListForm1099DivSummary.prototype.getTotalRows = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.taxfilingpb.ListForm1099DivSummary} returns this
 */
proto.taxfilingpb.ListForm1099DivSummary.prototype.setTotalRows = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string div = 2;
 * @return {string}
 */
proto.taxfilingpb.ListForm1099DivSummary.prototype.getDiv = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.ListForm1099DivSummary} returns this
 */
proto.taxfilingpb.ListForm1099DivSummary.prototype.setDiv = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.taxfilingpb.ListForm1099DivDetailsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.ListForm1099DivDetailsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.ListForm1099DivDetailsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.ListForm1099DivDetailsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.ListForm1099DivDetailsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    form1099DivDetailsList: jspb.Message.toObjectList(msg.getForm1099DivDetailsList(),
    proto.taxfilingpb.Form1099DivDetails.toObject, includeInstance),
    summary: (f = msg.getSummary()) && proto.taxfilingpb.ListForm1099DivDetailsSummary.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.ListForm1099DivDetailsResponse}
 */
proto.taxfilingpb.ListForm1099DivDetailsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.ListForm1099DivDetailsResponse;
  return proto.taxfilingpb.ListForm1099DivDetailsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.ListForm1099DivDetailsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.ListForm1099DivDetailsResponse}
 */
proto.taxfilingpb.ListForm1099DivDetailsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.taxfilingpb.Form1099DivDetails;
      reader.readMessage(value,proto.taxfilingpb.Form1099DivDetails.deserializeBinaryFromReader);
      msg.addForm1099DivDetails(value);
      break;
    case 2:
      var value = new proto.taxfilingpb.ListForm1099DivDetailsSummary;
      reader.readMessage(value,proto.taxfilingpb.ListForm1099DivDetailsSummary.deserializeBinaryFromReader);
      msg.setSummary(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.ListForm1099DivDetailsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.ListForm1099DivDetailsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.ListForm1099DivDetailsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.ListForm1099DivDetailsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getForm1099DivDetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.taxfilingpb.Form1099DivDetails.serializeBinaryToWriter
    );
  }
  f = message.getSummary();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.taxfilingpb.ListForm1099DivDetailsSummary.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Form1099DivDetails form1099_div_details = 1;
 * @return {!Array<!proto.taxfilingpb.Form1099DivDetails>}
 */
proto.taxfilingpb.ListForm1099DivDetailsResponse.prototype.getForm1099DivDetailsList = function() {
  return /** @type{!Array<!proto.taxfilingpb.Form1099DivDetails>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.taxfilingpb.Form1099DivDetails, 1));
};


/**
 * @param {!Array<!proto.taxfilingpb.Form1099DivDetails>} value
 * @return {!proto.taxfilingpb.ListForm1099DivDetailsResponse} returns this
*/
proto.taxfilingpb.ListForm1099DivDetailsResponse.prototype.setForm1099DivDetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.taxfilingpb.Form1099DivDetails=} opt_value
 * @param {number=} opt_index
 * @return {!proto.taxfilingpb.Form1099DivDetails}
 */
proto.taxfilingpb.ListForm1099DivDetailsResponse.prototype.addForm1099DivDetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.taxfilingpb.Form1099DivDetails, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.taxfilingpb.ListForm1099DivDetailsResponse} returns this
 */
proto.taxfilingpb.ListForm1099DivDetailsResponse.prototype.clearForm1099DivDetailsList = function() {
  return this.setForm1099DivDetailsList([]);
};


/**
 * optional ListForm1099DivDetailsSummary summary = 2;
 * @return {?proto.taxfilingpb.ListForm1099DivDetailsSummary}
 */
proto.taxfilingpb.ListForm1099DivDetailsResponse.prototype.getSummary = function() {
  return /** @type{?proto.taxfilingpb.ListForm1099DivDetailsSummary} */ (
    jspb.Message.getWrapperField(this, proto.taxfilingpb.ListForm1099DivDetailsSummary, 2));
};


/**
 * @param {?proto.taxfilingpb.ListForm1099DivDetailsSummary|undefined} value
 * @return {!proto.taxfilingpb.ListForm1099DivDetailsResponse} returns this
*/
proto.taxfilingpb.ListForm1099DivDetailsResponse.prototype.setSummary = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.taxfilingpb.ListForm1099DivDetailsResponse} returns this
 */
proto.taxfilingpb.ListForm1099DivDetailsResponse.prototype.clearSummary = function() {
  return this.setSummary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.taxfilingpb.ListForm1099DivDetailsResponse.prototype.hasSummary = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.ListForm1099DivDetailsSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.ListForm1099DivDetailsSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.ListForm1099DivDetailsSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.ListForm1099DivDetailsSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalRows: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.ListForm1099DivDetailsSummary}
 */
proto.taxfilingpb.ListForm1099DivDetailsSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.ListForm1099DivDetailsSummary;
  return proto.taxfilingpb.ListForm1099DivDetailsSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.ListForm1099DivDetailsSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.ListForm1099DivDetailsSummary}
 */
proto.taxfilingpb.ListForm1099DivDetailsSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalRows(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.ListForm1099DivDetailsSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.ListForm1099DivDetailsSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.ListForm1099DivDetailsSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.ListForm1099DivDetailsSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalRows();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 total_rows = 1;
 * @return {number}
 */
proto.taxfilingpb.ListForm1099DivDetailsSummary.prototype.getTotalRows = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.taxfilingpb.ListForm1099DivDetailsSummary} returns this
 */
proto.taxfilingpb.ListForm1099DivDetailsSummary.prototype.setTotalRows = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.Form1099DivProcessRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.Form1099DivProcessRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.Form1099DivProcessRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.Form1099DivProcessRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    year: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.Form1099DivProcessRequest}
 */
proto.taxfilingpb.Form1099DivProcessRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.Form1099DivProcessRequest;
  return proto.taxfilingpb.Form1099DivProcessRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.Form1099DivProcessRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.Form1099DivProcessRequest}
 */
proto.taxfilingpb.Form1099DivProcessRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setYear(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.Form1099DivProcessRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.Form1099DivProcessRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.Form1099DivProcessRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.Form1099DivProcessRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getYear();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string year = 1;
 * @return {string}
 */
proto.taxfilingpb.Form1099DivProcessRequest.prototype.getYear = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099DivProcessRequest} returns this
 */
proto.taxfilingpb.Form1099DivProcessRequest.prototype.setYear = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.Form1099DivProcessResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.Form1099DivProcessResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.Form1099DivProcessResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.Form1099DivProcessResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.Form1099DivProcessResponse}
 */
proto.taxfilingpb.Form1099DivProcessResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.Form1099DivProcessResponse;
  return proto.taxfilingpb.Form1099DivProcessResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.Form1099DivProcessResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.Form1099DivProcessResponse}
 */
proto.taxfilingpb.Form1099DivProcessResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.Form1099DivProcessResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.Form1099DivProcessResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.Form1099DivProcessResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.Form1099DivProcessResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.taxfilingpb.Form1099DivProcessResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.taxfilingpb.Form1099DivProcessResponse} returns this
 */
proto.taxfilingpb.Form1099DivProcessResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.taxfilingpb.DownLoadDivRequest.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.DownLoadDivRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.DownLoadDivRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.DownLoadDivRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.DownLoadDivRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    form1099DivRequest: (f = msg.getForm1099DivRequest()) && proto.taxfilingpb.Form1099DivRequest.toObject(includeInstance, f),
    fileType: jspb.Message.getFieldWithDefault(msg, 2, ""),
    downloadType: jspb.Message.getFieldWithDefault(msg, 3, ""),
    batchNo: jspb.Message.getFieldWithDefault(msg, 4, ""),
    saveToExternal: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    accountIdList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.DownLoadDivRequest}
 */
proto.taxfilingpb.DownLoadDivRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.DownLoadDivRequest;
  return proto.taxfilingpb.DownLoadDivRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.DownLoadDivRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.DownLoadDivRequest}
 */
proto.taxfilingpb.DownLoadDivRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.taxfilingpb.Form1099DivRequest;
      reader.readMessage(value,proto.taxfilingpb.Form1099DivRequest.deserializeBinaryFromReader);
      msg.setForm1099DivRequest(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDownloadType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBatchNo(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSaveToExternal(value);
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAccountId(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.DownLoadDivRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.DownLoadDivRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.DownLoadDivRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.DownLoadDivRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getForm1099DivRequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.taxfilingpb.Form1099DivRequest.serializeBinaryToWriter
    );
  }
  f = message.getFileType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDownloadType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBatchNo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSaveToExternal();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getAccountIdList();
  if (f.length > 0) {
    writer.writePackedInt32(
      6,
      f
    );
  }
};


/**
 * optional Form1099DivRequest form1099_div_request = 1;
 * @return {?proto.taxfilingpb.Form1099DivRequest}
 */
proto.taxfilingpb.DownLoadDivRequest.prototype.getForm1099DivRequest = function() {
  return /** @type{?proto.taxfilingpb.Form1099DivRequest} */ (
    jspb.Message.getWrapperField(this, proto.taxfilingpb.Form1099DivRequest, 1));
};


/**
 * @param {?proto.taxfilingpb.Form1099DivRequest|undefined} value
 * @return {!proto.taxfilingpb.DownLoadDivRequest} returns this
*/
proto.taxfilingpb.DownLoadDivRequest.prototype.setForm1099DivRequest = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.taxfilingpb.DownLoadDivRequest} returns this
 */
proto.taxfilingpb.DownLoadDivRequest.prototype.clearForm1099DivRequest = function() {
  return this.setForm1099DivRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.taxfilingpb.DownLoadDivRequest.prototype.hasForm1099DivRequest = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string file_type = 2;
 * @return {string}
 */
proto.taxfilingpb.DownLoadDivRequest.prototype.getFileType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.DownLoadDivRequest} returns this
 */
proto.taxfilingpb.DownLoadDivRequest.prototype.setFileType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string download_type = 3;
 * @return {string}
 */
proto.taxfilingpb.DownLoadDivRequest.prototype.getDownloadType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.DownLoadDivRequest} returns this
 */
proto.taxfilingpb.DownLoadDivRequest.prototype.setDownloadType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string batch_no = 4;
 * @return {string}
 */
proto.taxfilingpb.DownLoadDivRequest.prototype.getBatchNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.DownLoadDivRequest} returns this
 */
proto.taxfilingpb.DownLoadDivRequest.prototype.setBatchNo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool save_to_external = 5;
 * @return {boolean}
 */
proto.taxfilingpb.DownLoadDivRequest.prototype.getSaveToExternal = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.taxfilingpb.DownLoadDivRequest} returns this
 */
proto.taxfilingpb.DownLoadDivRequest.prototype.setSaveToExternal = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * repeated int32 account_id = 6;
 * @return {!Array<number>}
 */
proto.taxfilingpb.DownLoadDivRequest.prototype.getAccountIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.taxfilingpb.DownLoadDivRequest} returns this
 */
proto.taxfilingpb.DownLoadDivRequest.prototype.setAccountIdList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.taxfilingpb.DownLoadDivRequest} returns this
 */
proto.taxfilingpb.DownLoadDivRequest.prototype.addAccountId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.taxfilingpb.DownLoadDivRequest} returns this
 */
proto.taxfilingpb.DownLoadDivRequest.prototype.clearAccountIdList = function() {
  return this.setAccountIdList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.GenerateDivResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.GenerateDivResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.GenerateDivResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.GenerateDivResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.GenerateDivResponse}
 */
proto.taxfilingpb.GenerateDivResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.GenerateDivResponse;
  return proto.taxfilingpb.GenerateDivResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.GenerateDivResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.GenerateDivResponse}
 */
proto.taxfilingpb.GenerateDivResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.GenerateDivResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.GenerateDivResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.GenerateDivResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.GenerateDivResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.taxfilingpb.GenerateDivResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.taxfilingpb.GenerateDivResponse} returns this
 */
proto.taxfilingpb.GenerateDivResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.UpdateDivResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.UpdateDivResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.UpdateDivResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.UpdateDivResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    formDiv: (f = msg.getFormDiv()) && proto.taxfilingpb.Form1099Div.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.UpdateDivResponse}
 */
proto.taxfilingpb.UpdateDivResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.UpdateDivResponse;
  return proto.taxfilingpb.UpdateDivResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.UpdateDivResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.UpdateDivResponse}
 */
proto.taxfilingpb.UpdateDivResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.taxfilingpb.Form1099Div;
      reader.readMessage(value,proto.taxfilingpb.Form1099Div.deserializeBinaryFromReader);
      msg.setFormDiv(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.UpdateDivResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.UpdateDivResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.UpdateDivResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.UpdateDivResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFormDiv();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.taxfilingpb.Form1099Div.serializeBinaryToWriter
    );
  }
};


/**
 * optional Form1099Div form_div = 1;
 * @return {?proto.taxfilingpb.Form1099Div}
 */
proto.taxfilingpb.UpdateDivResponse.prototype.getFormDiv = function() {
  return /** @type{?proto.taxfilingpb.Form1099Div} */ (
    jspb.Message.getWrapperField(this, proto.taxfilingpb.Form1099Div, 1));
};


/**
 * @param {?proto.taxfilingpb.Form1099Div|undefined} value
 * @return {!proto.taxfilingpb.UpdateDivResponse} returns this
*/
proto.taxfilingpb.UpdateDivResponse.prototype.setFormDiv = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.taxfilingpb.UpdateDivResponse} returns this
 */
proto.taxfilingpb.UpdateDivResponse.prototype.clearFormDiv = function() {
  return this.setFormDiv(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.taxfilingpb.UpdateDivResponse.prototype.hasFormDiv = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.taxfilingpb);
