import {
  TaxTreaty,
  ListTaxTreatyRequest,
  DeleteTaxTreatyRequest,
  TaxTreatyServiceClient,
} from '../proto/taxfilibpb/tax_treaty_grpc_web_pb';
import { notifyError } from 'components/Notification/Notification';
import { auth } from '../lib/auth/Auth';
import { Pagination } from 'proto/utilspb/pagination_pb';

const service = new TaxTreatyServiceClient(
  window.env.GRPC_ENDPOINT,
  {},
  { ...auth }
);

const requestParameter = (param) => {
  let req = new TaxTreaty();
  req.setTaxTreatyId(param.taxTreatyId);
  req.setType(param.type);
  req.setCountryCode(param.countryCode);
  req.setSymbol(param.symbol);
  req.setOriginalCusip(param.originalCusip);
  req.setEntryType(param.entryType);
  req.setRate(param.rate);
  req.setState(param.state);

  return req;
};

export async function createTaxTreaty(param) {
  return new Promise((resolve, reject) => {
    const req = requestParameter(param);
    service.createTaxTreaty(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function updateTaxTreaty(param) {
  return new Promise((resolve, reject) => {
    const req = requestParameter(param);
    service.updateTaxTreaty(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function listTaxTreaty(param, paging) {
  return new Promise((resolve, reject) => {
    const req = new ListTaxTreatyRequest();
    req.setCountry(param.country);
    req.setType(param.type);
    req.setSymbol(param.symbol);
    req.setOriginalCusip(param.originalCusip);
    req.setState(param.state);

    if (paging) {
      let pg = new Pagination();
      pg.setPageNo(paging.pageNo);
      pg.setPageSize(paging.rowsPerPage);
      pg.setSortName(paging.sortName);
      pg.setSortDirection(paging.sortDirection);
      pg.setFilterNamesList(paging.filterNames);
      pg.setFilterValuesList(paging.filterValues);
      req.setPagination(pg);
    }

    service.listTaxTreaty(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function deleteTaxTreaty(id) {
  return new Promise((resolve, reject) => {
    const req = new DeleteTaxTreatyRequest();
    req.setTaxTreatyId(id);
    service.deleteTaxTreaty(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}
