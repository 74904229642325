import moment from 'moment-timezone';
import {
  UserGuideServiceClient,
  UserGuide,
  DeleteUserGuideRequest,
} from '../proto/admpb/userguide_grpc_web_pb';
import { notifyError } from 'components/Notification/Notification';
import { auth } from '../lib/auth/Auth';
import { stringToProtoDate } from './ConvertService';
const service = new UserGuideServiceClient(
  window.env.GRPC_ENDPOINT,
  {},
  { ...auth }
);

export async function createUserGuide(params) {
  return new Promise((resolve, reject) => {
    let req = new UserGuide();
    req.setPageName(params.pageName);
    req.setPagePath(params.pagePath);
    req.setContent(params.content);
    req.setType('User Guide');
    req.setModifiedBy(params.modifiedBy);
    req.setModifiedDate(
      stringToProtoDate(moment(new Date()).format('yyyy-MM-DD'))
    );

    service.createUserGuide(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function updateUserGuide(params) {
  return new Promise((resolve, reject) => {
    let req = new UserGuide();
    req.setUserGuideId(params.userGuideId);
    req.setPageName(params.pageName);
    req.setPagePath(params.pagePath);
    req.setContent(params.content);
    req.setType('User Guide');
    req.setModifiedBy(params.modifiedBy);
    req.setModifiedDate(
      stringToProtoDate(moment(new Date()).format('yyyy-MM-DD'))
    );

    service.updateUserGuide(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function listUserGuide(params) {
  return new Promise((resolve, reject) => {
    let req = new UserGuide();
    req.setPagePath(params.pagePath);
    req.setContent(params.content);

    service.listUserGuide(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function deleteUserGuide(id) {
  return new Promise((resolve, reject) => {
    let req = new DeleteUserGuideRequest();
    req.setUserGuideId(id);

    service.deleteUserGuide(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}
