/*ReactJS*/
import React, { useState, useEffect } from 'react';
import useStyles from '../../../styles';
import { SelectMasterProfile } from 'components/AutoComplete';
import { dateProtoObjectToString } from '../../../services/ConvertService';

import {
  Typography,
  Button,
  Modal,
  Backdrop,
  Fade,
  Box,
  TextField,
} from '@material-ui/core';

export default function BoyCostModal({
  onClose: handleClose,
  open: isOpen,
  value,
}) {
  const classes = useStyles();
  const [modalData, setModalData] = useState({
    symbolSelectField: 'symbol',
    assetType: '',
    symbol: '',
    originalCusip: '',
  });
  const [isEdit, setIsEdit] = React.useState(false);
  //protoDateObjectToString
  useEffect(() => {
    if (isOpen) {
      setModalData({
        ...value,
        acquiredDate: dateProtoObjectToString(value.acquiredDate),
      });
      if (value.boyCostId) {
        setIsEdit(true);
      }
    }
  }, [isOpen, value]);

  const handleChange = (e) => {
    const input = e.currentTarget?.name ? e.currentTarget : e.target;
    const update = {};

    if (input.name == 'symbolDetails') {
      update.symbolSelectField = input.field;
      update.assetType = input.assetType;
      update.symbolDetails = input.details;
      update.symbol = input.details?.symbol || '';
      update.cusip = input.details?.cusip || value.cusip || '';
      update.originalCusip = input.details?.originalCusip || '';
    } else {
      update[input.name] = input.value;
    }

    setModalData((current) => {
      return { ...current, ...update };
    });
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modalBackdrop}
      open={isOpen}
      onClose={() => {
        handleClose();
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={isOpen}>
        <div className={classes.fadeModalFull}>
          <Typography
            id="transition-modal-title"
            variant="h4"
            className={classes.textBold}
            gutterBottom
          >
            Edit Beginning of the Year
          </Typography>
          <Box mt={5}>
            <form noValidate autoComplete="off">
              <div className={classes.grdRow}>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    name="year"
                    required
                    label="Year"
                    type="number"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={modalData.year}
                    onChange={handleChange}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    name="correspondent"
                    required
                    label="Correspondent"
                    inputProps={{
                      maxlength: 4,
                    }}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={modalData.correspondent}
                    onChange={handleChange}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    name="office"
                    required
                    label="Branch"
                    inputProps={{
                      maxlength: 3,
                    }}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={modalData.office}
                    onChange={handleChange}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    name="accountNo"
                    required
                    label="Account No"
                    inputProps={{
                      maxlength: 12,
                    }}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={modalData.accountNo}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className={classes.grdRow}>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    required
                    name="acquiredDate"
                    fullWidth
                    label="Acquired Date"
                    type="date"
                    value={modalData.acquiredDate}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <SelectMasterProfile
                    freeSolo
                    name="symbolDetails"
                    value={modalData.symbolDetails}
                    originalCusipValue={modalData.originalCusip} // for getting selected value on load
                    assetTypeValue={modalData.assetType}
                    fieldValue={modalData.symbolSelectField}
                    defaultInputValue={modalData[modalData.symbolSelectField]}
                    onChange={handleChange}
                    required={true}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    name="cusip"
                    required
                    label="Cusip"
                    inputProps={{
                      maxlength: 12,
                    }}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={modalData.cusip}
                    onChange={handleChange}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    name="qty"
                    required
                    label="Qty"
                    type="number"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={modalData.qty}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className={classes.grdRow}>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    name="price"
                    label="Price"
                    type="number"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={modalData.price}
                    onChange={handleChange}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    name="netAmt"
                    label="Net Amount"
                    type="number"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={modalData.price * modalData.qty}
                    disabled={true}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    name="multiplier"
                    label="Multiplier"
                    type="number"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={modalData.multiplier}
                    onChange={handleChange}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    name="value"
                    required
                    label="Value"
                    type="number"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={modalData.value}
                    onChange={handleChange}
                  />
                </div>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <TextField
                    name="price"
                    required
                    label="Price"
                    type="number"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={modalData.price}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className={classes.modalFooter}>
                <div
                  className={classes.grdCellNone}
                  style={{ marginRight: 10 }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Close
                  </Button>
                </div>
                <div className={classes.grdCellNone}>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    onClick={() => {
                      handleClose(modalData, isEdit);
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </form>
          </Box>
        </div>
      </Fade>
    </Modal>
  );
}
