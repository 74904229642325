import React from 'react';
import { TableFooter, TableRow, TableCell } from '@material-ui/core';
import {
  formatCurrency,
  formatCurrencySymbol,
  formatPercentage,
  formatQty,
} from 'lib/fmt';
import useStyles from '../../styles';

const cellBgColor = 'rgba(230, 230, 230, 1)';
const quantity = 'quantity';
const amount = 'amount';
const percentage = 'percentage';
const amount9Decimal = 'amount9Decimal';

export default function TableBodyFooter({
  columnOrder,
  columns,
  columnsWithAmt,
  columnsWithQty,
  columnsWithPercentage,
  rows,
  additionalCell,
  columnsWithAmtDecimal,
}) {
  const classes = useStyles();
  const getType = (colName) => {
    if (columnsWithAmt && columnsWithAmt.includes(colName)) return amount;
    else if (columnsWithQty && columnsWithQty.includes(colName))
      return quantity;
    else if (columnsWithPercentage && columnsWithPercentage.includes(colName))
      return percentage;
    else if (columnsWithAmtDecimal && columnsWithAmtDecimal.includes(colName))
      return amount9Decimal;
  };

  const getColumnSum = (col, type) => {
    rows = rows || [];

    const total = rows.reduce((runningTotal, row) => {
      return runningTotal + parseFloat(row[col.name] || 0);
    }, 0);

    const bgColor = { background: total < 0 ? '#f44336' : '#4caf50' };
    let displayValue = total;
    switch (type) {
      case amount:
        displayValue = formatCurrency(total);
        break;
      case quantity:
        displayValue = formatQty(total);
        break;
      case percentage:
        displayValue = formatPercentage(total);
        break;
      case amount9Decimal:
        displayValue = formatCurrencySymbol(total);
        break;
      default:
        displayValue = formatCurrency(total);
        break;
    }

    return (
      <b style={bgColor} className={classes.totalBadge}>
        {displayValue}
      </b>
    );
  };

  return (
    <TableFooter>
      <TableRow>
        {additionalCell ? (
          <TableCell style={{ background: cellBgColor }} key="action" />
        ) : null}
        {columnOrder.map((order) => {
          const col = columns[order];

          if (col.display !== 'true') return null;

          const type = getType(col.name);
          if (!type)
            return (
              <TableCell style={{ background: cellBgColor }} key={order} />
            );

          return (
            <TableCell
              align="right"
              key={order}
              style={{ background: cellBgColor }}
            >
              {getColumnSum(col, type)}
            </TableCell>
          );
        })}
      </TableRow>
    </TableFooter>
  );
}
