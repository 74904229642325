function formatPbDate(d, separator = '/') {
  if (!d) {
    return '';
  }
  if (
    !Object.hasOwn(d, 'month') ||
    !Object.hasOwn(d, 'day') ||
    !Object.hasOwn(d, 'year')
  ) {
    return '';
  }
  return (
    `${d.month.toString().padStart(2, '0')}${separator}` +
    `${d.day.toString().padStart(2, '0')}${separator}` +
    `${d.year.toString().padStart(2, '0')}`
  );
}

// d is assumed a decimal string
function formatDollar(d) {
  if (!d) {
    return '';
  }
  if (parseFloat(d) === 0) {
    return '$0.00';
  }
  const sign = parseFloat(d) < 0 ? '-$' : '$';
  let [digit, decimal] = d.replace(/^-/, '').split('.');
  const minDecimal = parseFloat(digit) < 1 ? 4 : 2;
  if (!decimal) {
    decimal = '';
  }
  if (decimal.length < minDecimal) {
    decimal += '0'.repeat(minDecimal - decimal.length);
  }
  return `${sign}${digit}.${decimal}`;
}

function formatCurrency(
  amount,
  decimalCount = 2,
  decimal = '.',
  thousands = ','
) {
  decimalCount = Math.abs(decimalCount);
  decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

  const negativeSign = amount < 0 ? '-$' : '$';

  let i = parseInt(
    (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
  ).toString();

  let j = i.length > 3 ? i.length % 3 : 0;

  const finalAmount =
    negativeSign +
    (j ? i.substr(0, j) + thousands : '') +
    i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
    (decimalCount
      ? decimal +
        Math.abs(amount - i)
          .toFixed(decimalCount)
          .slice(2)
      : '');

  return `${finalAmount}`;
}

function formatCurrencySymbol(
  amount,
  currency = 'USD',
  decimalCount = 9,
  decimal = '.',
  thousands = ','
) {
  try {
    return new Intl.NumberFormat('en-EN', {
      style: 'currency',
      currency: currency.split(' ')[0],
      minimumFractionDigits: decimalCount ? decimalCount : 9,
      maximumFractionDigits: 9,
    }).format(amount);
  } catch (error) {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 9 : decimalCount;

    const negativeSign = amount < 0 ? '-$' : '$';

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();

    let j = i.length > 3 ? i.length % 3 : 0;

    const finalAmount =
      negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : '');

    return `${finalAmount}`;
  }
}

function formatPercentage(value) {
  return value + '%';
}

function formatRate(value) {
  return value * 100 + '%';
}

function formatQty(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export {
  formatPbDate,
  formatDollar,
  formatCurrency,
  formatPercentage,
  formatQty,
  formatRate,
  formatCurrencySymbol,
};
