import React from 'react';
import { TextField } from '@material-ui/core';

export function InputField({
  value,
  readOnly,
  inputProps,
  InputLabelProps,
  ...rest
}) {
  return (
    <TextField
      value={value || ''} // to avoid error "component is changing an uncontrolled input"
      inputProps={{
        readOnly: readOnly,
        ...inputProps,
      }}
      fullWidth
      InputLabelProps={{
        shrink: true,
        ...InputLabelProps,
      }}
      {...rest}
    />
  );
}

export default InputField;
