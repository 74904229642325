import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, MenuItem, CircularProgress } from '@material-ui/core';
import PlacesAutocomplete from 'react-places-autocomplete';
import { GetAddress } from '../../services/LocationPlaceService';
import { ReactComponent as Pin } from '../../images/placeholder.svg';

const useStyles = makeStyles(() => ({
  placesFieldWrapper: {
    position: 'relative',
  },
  placesOptionsWrapper: {
    position: 'absolute',
    top: 60,
    left: 0,
    zIndex: 9,
    border: '1px solid #d5dae5',
    borderRadius: 5,
    '& div, label': {
      cursor: 'pointer !important',
    },
    '& div:first-child': {
      marginRight: 10,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& svg path': {
        fill: '#ff425f',
      },
    },
    '& div:last-child > label:last-child': {
      fontSize: 13,
      fontWeight: 500,
      color: '#70737c',
    },
    '& li:hover': {
      backgroundColor: '#1e7dff !important',
    },
    '& li:hover label': {
      color: '#e5e6f0 !important',
    },
  },
  loaderText: {
    fontSize: '15px important',
    fontWeight: 600,
    color: '#46485c',
    backgroundColor: '#FFFFFF',
    borderRadius: 5,
    padding: '12px 20px',
    '& .MuiCircularProgress-root': {
      position: 'relative',
      top: 2,
      width: '20px !important',
      height: '20px !important',
    },
    '& span': {
      position: 'relative',
      top: -4,
      display: 'inline-block',
      margin: '0 0 0 10px',
    },
  },
}));

export default function PlaceAutoCompleteField(props) {
  const classes = useStyles();

  const [gmapsLoaded, setGmapsLoaded] = React.useState(false);
  const [mounted, setMounted] = React.useState(false);
  const [address, setAddress] = React.useState('');

  React.useEffect(() => {
    if (!mounted) {
      let googlePlaceScript = document.getElementById('google-place');

      if (!gmapsLoaded && !googlePlaceScript) {
        window.initMap = () => setGmapsLoaded(true);
        const gmapScriptEl = document.createElement(`script`);
        gmapScriptEl.id = 'google-place';
        gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCgzwhK3NSQKfbsHg_cFzexywNepnqZlkQ&libraries=places&callback=initMap`;
        document
          .querySelector(`body`)
          .insertAdjacentElement(`beforeend`, gmapScriptEl);
      } else {
        setGmapsLoaded(true);
      }

      setTimeout(function() {
        setMounted(true);
      }, 1000);
    }
    return () => {
      setMounted(true);
    };
    // eslint-disable-next-line
  }, [mounted]);

  const handleSelect = async (value) => {
    const address = await GetAddress(value);

    props.setAddressData(props.name, address);
    setAddress(address.address1);
  };

  return (
    <div>
      {mounted && gmapsLoaded && window.google ? (
        <PlacesAutocomplete
          value={address ? address : props.value}
          onChange={
            address
              ? setAddress
              : (value) => {
                  props.onChange({
                    currentTarget: { name: props.name, value: value },
                  });
                }
          }
          onSelect={handleSelect}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div className={classes.placesFieldWrapper}>
              <TextField
                {...getInputProps({
                  fullWidth: true,
                  error: props.error,
                  required: props.required,
                  max: props.max,
                  name: props.name,
                  label: props.label,
                  placeholder: props.placeholder,
                })}
                InputLabelProps={{ shrink: true }}
              />
              <div className={classes.placesOptionsWrapper}>
                {loading ? (
                  <div className={classes.loaderText}>
                    <CircularProgress /> <span>Loading...</span>
                  </div>
                ) : null}
                {suggestions.map((suggestion, key) => {
                  const style = {
                    backgroundColor: suggestion.active ? '#1e7dff' : '#FFFFFF',
                    color: suggestion.active ? '#e5e6f0' : '#46485c',
                    whiteSpace: 'pre',
                  };

                  return (
                    <MenuItem
                      {...getSuggestionItemProps(suggestion, { style })}
                      key={key}
                      value={suggestion.description}
                    >
                      <div className="grd-row nm">
                        <div className="grd-cell-none">
                          <Pin />
                        </div>
                        <div className="grd-cell">
                          <label>
                            {suggestion.formattedSuggestion.mainText}
                          </label>
                          <br />
                          <label>
                            {suggestion.formattedSuggestion.secondaryText}
                          </label>
                        </div>
                      </div>
                    </MenuItem>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      ) : (
        // <span>Loading Component...</span>
        <TextField
          fullWidth={true}
          error={props.error}
          required={props.required}
          max={props.max}
          name={props.name}
          label={props.label}
          placeholder={props.placeholder}
          InputLabelProps={{ shrink: true }}
          value={props.value}
          onChange={(event) => {
            props.onChange({
              currentTarget: { name: props.name, value: event.target.value },
            });
          }}
        />
      )}
    </div>
  );
}
