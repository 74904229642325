/*ReactJS*/
import React, { useState, useEffect } from 'react';
import useStyles from '../../../styles';
import {
  SelectMasterProfile,
  SelectTaxCountry,
  StatesSelect,
  SelectEntryType,
  SelectTaxTreatyType,
} from 'components/AutoComplete';

import {
  Typography,
  Button,
  Modal,
  Backdrop,
  Fade,
  Box,
  TextField,
} from '@material-ui/core';

export default function AccountModal({
  onClose: handleClose,
  open: isOpen,
  value,
}) {
  const classes = useStyles();
  const [modalData, setModalData] = useState({
    type: 'Per Country',
    entryType: 'DIV',
    countryCode: '',
    rate: 0,
    symbolSelectField: 'symbol',
    assetType: '',
    symbol: '',
    cusip: '',
    originalCusip: '',
  });
  const [isEdit, setIsEdit] = React.useState(false);
  const [isPerSymbol, setIsPerSymbol] = React.useState(false);

  useEffect(() => {
    if (isOpen) {
      if (value.taxTreatyId) {
        setIsEdit(true);

        if (value.type === 'Per Symbol') {
          setIsPerSymbol(true);
        } else {
          setIsPerSymbol(false);
        }

        setModalData({ ...value });
      }
    }
  }, [isOpen, value]);

  const handleChange = (e) => {
    const input = e.currentTarget?.name ? e.currentTarget : e.target;
    var entryType = '';

    const update = {};

    if (input.name === 'type') {
      if (input.value === 'Per Symbol') {
        setIsPerSymbol(true);
        entryType = 'TRD';
      } else {
        setIsPerSymbol(false);
        entryType = 'DIV';
      }

      update.entryType = entryType;
    }

    if (input.name == 'symbolDetails') {
      update.symbolSelectField = input.field;
      update.assetType = input.assetType;
      update.symbolDetails = input.details;
      update.symbol = input.details?.symbol || '';
      update.cusip = input.details?.cusip || '';
      update.originalCusip = input.details?.originalCusip || '';
    } else {
      update[input.name] = input.value;
    }

    setModalData((current) => {
      return { ...current, ...update };
    });
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modalBackdrop}
      open={isOpen}
      onClose={() => {
        handleClose();
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={isOpen}>
        <div className={classes.fadeModalMd}>
          <Typography
            id="transition-modal-title"
            variant="h4"
            className={classes.textBold}
            gutterBottom
          >
            {isEdit ? 'Edit' : 'Add New'} TAX Treaty
          </Typography>
          <Box mt={5}>
            <form noValidate autoComplete="off">
              <div className={classes.grdRow}>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <SelectTaxTreatyType
                    freeSolo={false}
                    name="type"
                    label="Tax Rate Type"
                    required={true}
                    type="Tax Treaty"
                    subType="Type"
                    value={modalData.type}
                    onChange={handleChange}
                  ></SelectTaxTreatyType>
                </div>
                <div className={classes.grdCell1}>
                  <SelectEntryType
                    required={false}
                    name="entryType"
                    label="Entry Type"
                    value={modalData.entryType}
                    onChange={handleChange}
                  ></SelectEntryType>
                </div>
              </div>
              <div className={classes.grdRow}>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  {isPerSymbol && (
                    <SelectMasterProfile
                      freeSolo
                      name="symbolDetails"
                      value={modalData.symbolDetails}
                      originalCusipValue={modalData.originalCusip} // for getting selected value on load
                      assetTypeValue={modalData.assetType}
                      fieldValue={modalData.symbolSelectField}
                      defaultInputValue={modalData[modalData.symbolSelectField]}
                      onChange={handleChange}
                      required={true}
                    />
                  )}
                  {modalData.type === 'Per State' && (
                    <StatesSelect
                      required={true}
                      name="state"
                      label="State"
                      value={
                        isEdit ? modalData.state.slice(0, 2) : modalData.state
                      }
                      onChange={handleChange}
                    />
                  )}
                  {!isPerSymbol && modalData.type !== 'Per State' && (
                    <SelectTaxCountry
                      required={true}
                      name="countryCode"
                      label="Country"
                      value={modalData.countryCode}
                      key={modalData.countryCode}
                      onChange={handleChange}
                    ></SelectTaxCountry>
                  )}
                </div>
                <div className={classes.grdCell1}>
                  <TextField
                    fullWidth
                    required={true}
                    name="rate"
                    label="Rate"
                    type="number"
                    placeholder="Rate"
                    value={modalData.rate}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
              </div>
              <div className={classes.modalFooter}>
                <div
                  className={classes.grdCellNone}
                  style={{ marginRight: 10 }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Close
                  </Button>
                </div>
                <div className={classes.grdCellNone}>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    onClick={() => {
                      handleClose(modalData, isEdit);
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </form>
          </Box>
        </div>
      </Fade>
    </Modal>
  );
}
