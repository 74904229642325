/*eslint eqeqeq: "off"*/
import {
  Form1099bServiceClient,
  Form1099bRequest,
  DownLoadBRequest,
  Form1099b,
} from '../proto/taxfilibpb/form1099b_grpc_web_pb';
import {
  notifyError,
  notifySuccess,
} from 'components/Notification/Notification';
import { auth } from '../lib/auth/Auth';
import download from './DownloadService';
import { Pagination } from 'proto/utilspb/pagination_pb';

const service = new Form1099bServiceClient(
  window.env.GRPC_ENDPOINT,
  {},
  { ...auth }
);

export async function listForm1099b(param, paging) {
  return new Promise((resolve, reject) => {
    const req = new Form1099bRequest();
    req.setYear(param.year);
    req.setCorrespondent(param.correspondent);
    req.setBranch(param.brach);
    req.setAccountNo(param.accountNo);
    req.setUsrId(param.usrId);

    if (paging) {
      let pg = new Pagination();
      pg.setPageNo(paging.pageNo);
      pg.setPageSize(paging.rowsPerPage);
      pg.setSortName(paging.sortName);
      pg.setSortDirection(paging.sortDirection);
      pg.setFilterNamesList(paging.filterNames);
      pg.setFilterValuesList(paging.filterValues);
      req.setPagination(pg);
    }

    service.listForm1099b(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function form1099bDetails(param, paging) {
  return new Promise((resolve, reject) => {
    const req = new Form1099bRequest();
    req.setYear(param.year);
    req.setCorrespondent(param.correspondent);
    req.setBranch(param.brach);
    req.setAccountNo(param.accountNo);
    req.setUsrId(param.usrId);

    if (paging) {
      let pg = new Pagination();
      pg.setPageNo(paging.pageNo);
      pg.setPageSize(paging.rowsPerPage);
      pg.setSortName(paging.sortName);
      pg.setSortDirection(paging.sortDirection);
      pg.setFilterNamesList(paging.filterNames);
      pg.setFilterValuesList(paging.filterValues);
      req.setPagination(pg);
    }

    service.form1099bDetails(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function downloadB(param, filetype, batchNo, downloadType) {
  return new Promise((resolve, reject) => {
    let request = new DownLoadBRequest();
    request.setFileType(filetype);
    request.setBatchNo(batchNo);
    request.setDownloadType(downloadType);
    let req = new Form1099bRequest();
    req.setYear(param.year);
    req.setCorrespondent(param.correspondent);
    req.setBranch(param.brach);
    req.setAccountNo(param.accountNo);
    req.setUsrId(param.usrId);
    request.setForm1099BRequest(req);
    service.download(request, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        if (response) {
          download(response);
          resolve(response);
          if (response?.array[2]) {
            notifySuccess('Download ' + response.array[2] + ' completed');
          } else {
            notifySuccess('Download completed');
          }
        }
      }
    });
  });
}

export async function generateB(param, filetype, batchNo, downloadType) {
  return new Promise((resolve, reject) => {
    let request = new DownLoadBRequest();
    request.setFileType(filetype);
    request.setBatchNo(batchNo);
    request.setDownloadType(downloadType);
    let req = new Form1099bRequest();
    req.setYear(param.year);
    req.setCorrespondent(param.correspondent);
    req.setBranch(param.brach);
    req.setAccountNo(param.accountNo);
    req.setUsrId(param.usrId);
    request.setForm1099BRequest(req);

    service.generate(request, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        console.log(response.array);
        resolve(response);
      }
    });
  });
}

export async function updateForm1099b(param) {
  return new Promise((resolve, reject) => {
    const req = new Form1099b();
    req.setForm1099bId(param.form1099bId);
    req.setYear(param.year);
    req.setTaxCountry(param.taxCountry);
    req.setCost(param.cost);
    req.setProceed(param.proceed);
    req.setNetAmount(param.netAmount);
    req.setCity(param.city);
    req.setState(param.state);
    req.setZip(param.zip);
    req.setCountry(param.country);
    req.setAddress(param.address);
    req.setLegalEntity(param.legalEntity);
    req.setGovernmentId(param.governmentId);
    req.setAccruedMarketDiscount(param.accruedMarketDiscount);
    req.setWashSaleLossDisallowed(param.washSaleLossDisallowed);
    req.setCurrentYearRealized(param.currentYearRealized);
    req.setCurrentYearUnrealized(param.currentYearUnrealized);
    req.setPriorYearRealized(param.priorYearRealized);
    req.setAggregateProfitLoss(param.aggregateProfitLoss);

    service.updateForm1099b(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}
