/*ReactJS*/
import React, { useState } from 'react';

/*Toast Notification*/
import {
  notifySuccess,
  notifyInfo,
  notifyError,
} from 'components/Notification/Notification';
import QueryParam from '../../../services/QueryParamService';

import {
  downloadTin,
  upload,
  process,
} from '../../../services/TinValidatoinService';

import {
  SystemUpdateAltOutlined,
  GetAppRounded,
  Create as EditIcon,
  Delete as DeleteIcon,
  AttachFile,
  Publish as PublishIcon,
  ArrowForward as RunIcon,
} from '@material-ui/icons';

/*Material UI Components*/
import {
  Button,
  Box,
  TextField,
  IconButton,
  InputLabel,
  Typography,
} from '@material-ui/core';

/*Material UI Icons*/

/*Styles*/
import useStyles from '../../../styles';

/*Custom components*/

import { useConfirm } from 'material-ui-confirm';

export default function TinValidation({ params }) {
  const classes = useStyles();
  const confirm = useConfirm();
  const options = {
    selectableRows: 'none',
  };

  const [loading, setLoading] = React.useState(false);
  const [searchData, setSearchData] = React.useState(
    QueryParam.get({
      year: '',
    })
  );
  const [file, setFile] = React.useState({});
  const [batchNo, setBatchNo] = React.useState('');

  const readBytes = (file) => {
    let promise = new Promise((resolve, reject) => {
      let fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
      fileReader.onload = (e) => {
        let bufferArray = e.target.result;
        resolve({ bytes: bufferArray, fileName: file.name });
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
    return promise;
  };

  const readFile = (file) => {
    if (!file) {
      return;
    }
    readBytes(file).then((d) => {
      setFile(d);
    });
  };

  const handleChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;

    setSearchData({
      ...searchData,
      [input.name]: input.value,
    });
  };

  const handleDownload = async () => {
    if (!searchData.year) {
      notifyError('Year is required');
    } else {
      await downloadTin(searchData);
    }
  };

  const handleUpload = async () => {
    let today = new Date();
    let batchNo =
      today.getFullYear() +
      '' +
      ('0' + (today.getMonth() + 1)).slice(-2) +
      '' +
      ('0' + today.getDate()).slice(-2) +
      '-' +
      ('0' + today.getHours()).slice(-2) +
      '' +
      ('0' + today.getMinutes()).slice(-2) +
      '' +
      ('0' + today.getSeconds()).slice(-2);
    setBatchNo(batchNo);
    if (Object.keys(file).length === 0 && file?.constructor === Object) {
      notifyError('Choose file to upload first');
    } else {
      await upload({ ...file, batchNo: batchNo });
    }
  };

  const handleProcess = async () => {
    await process({ batchNo: batchNo });
  };

  return (
    <div className={classes.root}>
      <div className={classes.fadeModalMd2}>
        <Box component="div" mt={5}>
          <div className={classes.actionContainer}>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 10 }}>
                <TextField
                  name="year"
                  label="Year"
                  type="number"
                  fullWidth
                  required
                  InputLabelProps={{ shrink: true }}
                  value={searchData.year}
                  onChange={handleChange}
                  onClick={() => {}}
                />
              </div>
              <div className={classes.grdCellNone} style={{ marginLeft: 10 }}>
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  startIcon={<GetAppRounded />}
                  onClick={() => {
                    handleDownload();
                  }}
                >
                  Download
                </Button>
              </div>
            </div>
          </div>
        </Box>

        <Box component="div" mt={5}>
          <div className={classes.grdRow}>
            <div className={classes.grdCellNone} style={{ marginRight: 10 }}>
              <input
                accept="text/plain"
                className={classes.input}
                style={{ display: 'none' }}
                id="raised-button-file"
                type="file"
                onChange={(e) => {
                  const file = e.target.files[0];
                  readFile(file);
                }}
              />
              <label htmlFor="raised-button-file">
                <Button
                  component="span"
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  size="large"
                  startIcon={<AttachFile />}
                >
                  Choose File
                </Button>
              </label>
            </div>
            <div className={classes.grdCell1} style={{ paddingTop: 5 }}>
              <Typography variant="h6" align="left" gutterBottom={true}>
                {file.fileName}
              </Typography>
            </div>
            <div className={classes.grdCellNone} style={{ marginLeft: 10 }}>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                startIcon={<PublishIcon />}
                onClick={() => {
                  handleUpload();
                }}
              >
                Upload File
              </Button>
            </div>
            <div className={classes.grdCellNone} style={{ marginLeft: 10 }}>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                startIcon={<RunIcon />}
                onClick={() => {
                  handleProcess();
                }}
              >
                Process
              </Button>
            </div>
          </div>
        </Box>
      </div>
    </div>
  );
}
