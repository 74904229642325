import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Popper,
  TextField,
} from '@material-ui/core';
import { lazyLoadSecurity } from '../../services/CommonService';

const DEFAULT_FIELD = 'symbol';

const filter = createFilterOptions();

const styles = () => ({
  popper: {
    width: 'fit-content',
  },
  listItem: {
    width: 300,
  },
});

const useStyles = makeStyles({
  asterisk: {
    marginTop: '5px',
  },
});

const PopperMy = function(props) {
  return <Popper {...props} style={styles.popper} placement="bottom-start" />;
};

const customOptionItem = (details) => {
  const emptyDetails = {
    status: '',
    assetType: '',
    symbol: '',
    symbolDescription: '',
    id: 0,
    ...details,
  };
  return emptyDetails;
};

export const SelectMasterProfile = ({
  freeSolo,
  required,
  name,
  value,
  originalCusipValue, // for getting selected value on load
  assetTypeValue,
  fieldValue,
  filterFields,
  filterAssetTypes,
  defaultInputValue,
  onChange,
  disableAssetType,
  isActiveOnly,
  isBlueSheet,
  isDigitalAssetOnly,
  disabled,
  assetTypeRequired,
}) => {
  const classes = useStyles();
  const [mounted, setMounted] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [inputValue, setInputValue] = React.useState(defaultInputValue || '');
  const [options, setOptions] = React.useState([]);

  const changeModel = (changes) => {
    onChange({
      currentTarget: {
        name: name,
        field: fieldValue,
        assetType: assetTypeValue,
        ...changes,
      },
    });
  };

  React.useEffect(() => {
    let timeoutId;

    if (mounted) {
      timeoutId = setTimeout(() => {
        getOptions(assetTypeValue, inputValue, '', false);
      }, 500);
    } else {
      setMounted(true);
      getOptions(assetTypeValue, inputValue, originalCusipValue, true);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [inputValue]);

  function changeOptions(assetType) {
    let details = null;
    // if current selected have same asset type or a custom value, do not remove current selection
    if (value && (['', value.assetType].includes(assetType) || !value.id)) {
      details = value;
    }

    changeModel({ assetType: assetType, details: details });
    if (!details) {
      setInputValue('');
      getOptions(assetType, '', '', false);
    }
  }

  React.useEffect(() => {
    // if asset type change is not from default onChange
    changeOptions(assetTypeValue);
  }, [assetTypeValue]);

  const getOptions = async (
    assetType,
    key,
    originalCusip,
    isOnload,
    fieldName
  ) => {
    setLoading(true);
    try {
      let field = fieldName || fieldValue;
      if (field === 'originalCusip') field = 'original_cusip';

      if (isBlueSheet && assetType === 'O' && !isDigitalAssetOnly)
        assetType = 'E';

      const { securitiesList } = await lazyLoadSecurity(
        key, // search key
        assetType, // asset type
        true, // cusip
        isActiveOnly,
        field || DEFAULT_FIELD, // search field
        isDigitalAssetOnly
      );

      if (isOnload) {
        if (originalCusip) {
          const item = securitiesList.find(
            (i) => i.originalCusip === originalCusip
          );
          if (item) {
            changeModel({ details: item, field: fieldName || fieldValue });
            setLoading(false);
            return;
          }
        }

        if (key) {
          if (
            securitiesList.length === 1 &&
            securitiesList[0][fieldValue] === key
          ) {
            changeModel({ details: securitiesList[0] });
          } else if (freeSolo) {
            const customItem = customOptionItem({
              [fieldValue]: key,
            });
            changeModel({ details: customItem });
            securitiesList.push(customItem);
          }
        }
      }

      setOptions(securitiesList);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  return (
    <Grid container spacing={1} direction="row" wrap="nowrap">
      <Grid item xs style={{ flexGrow: 0 }}>
        <SelectAssetType
          filterAssetTypes={filterAssetTypes}
          required={required}
          disabled={disableAssetType || disabled}
          value={assetTypeValue}
          onChange={(e) => {
            changeOptions(e.target.value);
          }}
        />
      </Grid>
      <Grid item xs style={{ flexGrow: 1 }}>
        <Autocomplete
          clearOnEscape
          fullWidth
          disabled={
            (isBlueSheet && !assetTypeValue && !isDigitalAssetOnly) ||
            disabled ||
            assetTypeRequired
          }
          loading={loading}
          freeSolo={freeSolo}
          PopperComponent={PopperMy}
          selectOnFocus
          clearOnBlur
          value={value || null}
          onOpen={() => {
            if (!inputValue && options.length === 0) {
              getOptions(assetTypeValue, inputValue, '', false);
            }
          }}
          onChange={(_, newValue) => {
            let details = newValue;

            if (typeof newValue === 'string') {
              details = customOptionItem({
                [fieldValue]: newValue,
              });
            } else if (newValue && newValue.inputValue) {
              // Create a new value from the user input
              details = customOptionItem({
                [fieldValue]: newValue.inputValue,
              });
            }
            changeModel({ details });
          }}
          options={options}
          onInputChange={(event, newInputValue) => {
            if (event && event.type !== 'blur') {
              setInputValue(newInputValue);
            }
          }}
          getOptionLabel={(option) => option[fieldValue]}
          getOptionSelected={(option, value) => {
            if (value.originalCusip) {
              return option.originalCusip === value.originalCusip;
            }
            return option[fieldValue] === value[fieldValue];
          }}
          filterOptions={(options, params) => {
            if (!freeSolo) {
              return options;
            }

            const filtered = filter(options, params);

            // Suggest the creation of a new value
            if (params.inputValue !== '') {
              // for option to search by custom cusip/symbol value
              const customItem = customOptionItem({
                [fieldValue]: params.inputValue,
              });
              filtered.push(customItem);
            }

            return filtered;
          }}
          renderOption={(o) => {
            if (!o.id && o[fieldValue]) {
              return (
                <div style={styles.listItem}>
                  Custom value &quot;{o[fieldValue]}&quot;
                </div>
              );
            }

            let label =
              'Stat: ' + o.status.substring(0, 1) + ' | Type: ' + o.assetType;

            if (fieldValue !== 'symbol') {
              label += ' | Symb: ' + o.symbol;
            }

            if (fieldValue !== 'cusip') {
              label += ' | Cusp: ' + o.cusip;
            }

            if (fieldValue !== 'originalCusip') {
              label += ' | Orig: ' + o.originalCusip;
            }

            const details = (dtls) => {
              if (!dtls) return <React.Fragment />;

              return (
                <div
                  style={{
                    fontSize: 10,
                    color: 'gray',
                  }}
                >
                  {dtls}
                </div>
              );
            };

            return (
              <div style={styles.listItem}>
                <div
                  style={{
                    fontSize: 14,
                  }}
                >
                  {o[fieldValue]}
                </div>
                {details(label)}
                {details('Dscr: ' + o.symbolDescription)}
              </div>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputLabelProps={{
                classes: {
                  asterisk: classes.asterisk,
                },
                style: {
                  top: -27,
                  left: 0,
                  right: 0,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                  fontSize: '12px',
                },
                shrink: false,
              }}
              helperText={
                value
                  ? value.id
                    ? `Original Cusip: ${value.originalCusip}`
                    : 'Custom value'
                  : ''
              }
              label={
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    color:
                      (isBlueSheet && !assetTypeValue && !isDigitalAssetOnly) ||
                      assetTypeRequired
                        ? '#00000061'
                        : '#000000',
                  }}
                >
                  {assetTypeValue === 'O' &&
                    isBlueSheet &&
                    fieldValue === 'symbol' &&
                    !isDigitalAssetOnly && <span>{'Underlying'}&nbsp;</span>}
                  <React.Fragment>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        justifyContent: 'space-between',
                      }}
                    >
                      <SelectField
                        disabled={
                          (isBlueSheet &&
                            !assetTypeValue &&
                            !isDigitalAssetOnly) ||
                          disabled ||
                          assetTypeRequired
                        }
                        filterFields={filterFields}
                        style={{
                          fontSize: '12px',
                          color:
                            (isBlueSheet &&
                              !assetTypeValue &&
                              !isDigitalAssetOnly) ||
                            disabled ||
                            assetTypeRequired
                              ? '#00000061'
                              : '#000000',
                        }}
                        value={fieldValue || DEFAULT_FIELD}
                        onChange={(e) => {
                          let details = null;

                          // with current valid selection
                          if (value && value.id) {
                            details = { ...value };
                          } else if (value) {
                            // with current custom selected
                            // push custom option and mark it as current selected
                            details = customOptionItem({
                              [e.target.value]: value[fieldValue],
                            });

                            setOptions([details]);
                          }

                          changeModel({
                            field: e.target.value,
                            details: details,
                          });
                        }}
                      />
                    </div>
                  </React.Fragment>
                </div>
              }
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

const fieldOptions = [
  {
    value: 'symbol',
    label: 'Symbol',
  },
  {
    value: 'cusip',
    label: 'Cusip',
  },
  {
    value: 'originalCusip',
    label: 'Original Cusip',
  },
];

const SelectField = ({ filterFields, ...rest }) => {
  return (
    <FormControl>
      <Select disableUnderline {...rest}>
        {fieldOptions
          .filter((item) =>
            filterFields?.length ? filterFields.includes(item.label) : true
          )
          .map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

const assetTypeOptions = [
  {
    value: 'ALT',
    label: 'Alternative Investment',
  },
  {
    value: 'DA',
    label: 'Digital Asset',
  },
  {
    value: 'DES',
    label: 'Digital Enhanced Security',
  },
  {
    value: 'E',
    label: 'Equity',
  },
  {
    value: 'FI',
    label: 'Fixed Income',
  },
  {
    value: 'MF',
    label: 'Mutual Funds',
  },
  {
    value: 'MM',
    label: 'Money Market',
  },
  {
    value: 'O',
    label: 'Option',
  },
];

const SelectAssetType = ({ filterAssetTypes, label, required, ...rest }) => {
  return (
    <FormControl style={{ width: 95 }}>
      <InputLabel shrink required={required} disabled={rest.disabled}>
        {label || 'Asset Type'}
      </InputLabel>
      <Select renderValue={(value) => value} {...rest}>
        {/* hard coded so we don't have to ping DB */}
        {!required && (
          <MenuItem value="">
            <em>Blank</em>
          </MenuItem>
        )}
        {assetTypeOptions
          .filter((item) =>
            filterAssetTypes?.length
              ? filterAssetTypes.includes(item.label)
              : true
          )
          .map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default SelectMasterProfile;
