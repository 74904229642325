/**
 * @fileoverview gRPC-Web generated client stub for taxfilingpb
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_type_date_pb = require('../../google/type/date_pb.js')

var proto_utilspb_pagination_pb = require('../../proto/utilspb/pagination_pb.js')

var proto_commonpb_file_pb = require('../../proto/commonpb/file_pb.js')
const proto = {};
proto.taxfilingpb = require('./form1099b_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.taxfilingpb.Form1099bServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.taxfilingpb.Form1099bServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.taxfilingpb.Form1099bRequest,
 *   !proto.taxfilingpb.ListForm1099bResponse>}
 */
const methodDescriptor_Form1099bService_ListForm1099b = new grpc.web.MethodDescriptor(
  '/taxfilingpb.Form1099bService/ListForm1099b',
  grpc.web.MethodType.UNARY,
  proto.taxfilingpb.Form1099bRequest,
  proto.taxfilingpb.ListForm1099bResponse,
  /**
   * @param {!proto.taxfilingpb.Form1099bRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.ListForm1099bResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.taxfilingpb.Form1099bRequest,
 *   !proto.taxfilingpb.ListForm1099bResponse>}
 */
const methodInfo_Form1099bService_ListForm1099b = new grpc.web.AbstractClientBase.MethodInfo(
  proto.taxfilingpb.ListForm1099bResponse,
  /**
   * @param {!proto.taxfilingpb.Form1099bRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.ListForm1099bResponse.deserializeBinary
);


/**
 * @param {!proto.taxfilingpb.Form1099bRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.taxfilingpb.ListForm1099bResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.taxfilingpb.ListForm1099bResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.taxfilingpb.Form1099bServiceClient.prototype.listForm1099b =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/taxfilingpb.Form1099bService/ListForm1099b',
      request,
      metadata || {},
      methodDescriptor_Form1099bService_ListForm1099b,
      callback);
};


/**
 * @param {!proto.taxfilingpb.Form1099bRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.taxfilingpb.ListForm1099bResponse>}
 *     Promise that resolves to the response
 */
proto.taxfilingpb.Form1099bServicePromiseClient.prototype.listForm1099b =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/taxfilingpb.Form1099bService/ListForm1099b',
      request,
      metadata || {},
      methodDescriptor_Form1099bService_ListForm1099b);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.taxfilingpb.Form1099bRequest,
 *   !proto.taxfilingpb.Form1099bDetailsResponse>}
 */
const methodDescriptor_Form1099bService_Form1099bDetails = new grpc.web.MethodDescriptor(
  '/taxfilingpb.Form1099bService/Form1099bDetails',
  grpc.web.MethodType.UNARY,
  proto.taxfilingpb.Form1099bRequest,
  proto.taxfilingpb.Form1099bDetailsResponse,
  /**
   * @param {!proto.taxfilingpb.Form1099bRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.Form1099bDetailsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.taxfilingpb.Form1099bRequest,
 *   !proto.taxfilingpb.Form1099bDetailsResponse>}
 */
const methodInfo_Form1099bService_Form1099bDetails = new grpc.web.AbstractClientBase.MethodInfo(
  proto.taxfilingpb.Form1099bDetailsResponse,
  /**
   * @param {!proto.taxfilingpb.Form1099bRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.Form1099bDetailsResponse.deserializeBinary
);


/**
 * @param {!proto.taxfilingpb.Form1099bRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.taxfilingpb.Form1099bDetailsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.taxfilingpb.Form1099bDetailsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.taxfilingpb.Form1099bServiceClient.prototype.form1099bDetails =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/taxfilingpb.Form1099bService/Form1099bDetails',
      request,
      metadata || {},
      methodDescriptor_Form1099bService_Form1099bDetails,
      callback);
};


/**
 * @param {!proto.taxfilingpb.Form1099bRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.taxfilingpb.Form1099bDetailsResponse>}
 *     Promise that resolves to the response
 */
proto.taxfilingpb.Form1099bServicePromiseClient.prototype.form1099bDetails =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/taxfilingpb.Form1099bService/Form1099bDetails',
      request,
      metadata || {},
      methodDescriptor_Form1099bService_Form1099bDetails);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.taxfilingpb.Form1099bProcessRequest,
 *   !proto.taxfilingpb.Form1099bProcessResponse>}
 */
const methodDescriptor_Form1099bService_Form1099bProcess = new grpc.web.MethodDescriptor(
  '/taxfilingpb.Form1099bService/Form1099bProcess',
  grpc.web.MethodType.UNARY,
  proto.taxfilingpb.Form1099bProcessRequest,
  proto.taxfilingpb.Form1099bProcessResponse,
  /**
   * @param {!proto.taxfilingpb.Form1099bProcessRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.Form1099bProcessResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.taxfilingpb.Form1099bProcessRequest,
 *   !proto.taxfilingpb.Form1099bProcessResponse>}
 */
const methodInfo_Form1099bService_Form1099bProcess = new grpc.web.AbstractClientBase.MethodInfo(
  proto.taxfilingpb.Form1099bProcessResponse,
  /**
   * @param {!proto.taxfilingpb.Form1099bProcessRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.Form1099bProcessResponse.deserializeBinary
);


/**
 * @param {!proto.taxfilingpb.Form1099bProcessRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.taxfilingpb.Form1099bProcessResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.taxfilingpb.Form1099bProcessResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.taxfilingpb.Form1099bServiceClient.prototype.form1099bProcess =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/taxfilingpb.Form1099bService/Form1099bProcess',
      request,
      metadata || {},
      methodDescriptor_Form1099bService_Form1099bProcess,
      callback);
};


/**
 * @param {!proto.taxfilingpb.Form1099bProcessRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.taxfilingpb.Form1099bProcessResponse>}
 *     Promise that resolves to the response
 */
proto.taxfilingpb.Form1099bServicePromiseClient.prototype.form1099bProcess =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/taxfilingpb.Form1099bService/Form1099bProcess',
      request,
      metadata || {},
      methodDescriptor_Form1099bService_Form1099bProcess);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.taxfilingpb.DownLoadBRequest,
 *   !proto.commonpb.File>}
 */
const methodDescriptor_Form1099bService_Download = new grpc.web.MethodDescriptor(
  '/taxfilingpb.Form1099bService/Download',
  grpc.web.MethodType.UNARY,
  proto.taxfilingpb.DownLoadBRequest,
  proto_commonpb_file_pb.File,
  /**
   * @param {!proto.taxfilingpb.DownLoadBRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_commonpb_file_pb.File.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.taxfilingpb.DownLoadBRequest,
 *   !proto.commonpb.File>}
 */
const methodInfo_Form1099bService_Download = new grpc.web.AbstractClientBase.MethodInfo(
  proto_commonpb_file_pb.File,
  /**
   * @param {!proto.taxfilingpb.DownLoadBRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_commonpb_file_pb.File.deserializeBinary
);


/**
 * @param {!proto.taxfilingpb.DownLoadBRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.commonpb.File)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.commonpb.File>|undefined}
 *     The XHR Node Readable Stream
 */
proto.taxfilingpb.Form1099bServiceClient.prototype.download =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/taxfilingpb.Form1099bService/Download',
      request,
      metadata || {},
      methodDescriptor_Form1099bService_Download,
      callback);
};


/**
 * @param {!proto.taxfilingpb.DownLoadBRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.commonpb.File>}
 *     Promise that resolves to the response
 */
proto.taxfilingpb.Form1099bServicePromiseClient.prototype.download =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/taxfilingpb.Form1099bService/Download',
      request,
      metadata || {},
      methodDescriptor_Form1099bService_Download);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.taxfilingpb.DownLoadBRequest,
 *   !proto.taxfilingpb.GenerateBResponse>}
 */
const methodDescriptor_Form1099bService_Generate = new grpc.web.MethodDescriptor(
  '/taxfilingpb.Form1099bService/Generate',
  grpc.web.MethodType.UNARY,
  proto.taxfilingpb.DownLoadBRequest,
  proto.taxfilingpb.GenerateBResponse,
  /**
   * @param {!proto.taxfilingpb.DownLoadBRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.GenerateBResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.taxfilingpb.DownLoadBRequest,
 *   !proto.taxfilingpb.GenerateBResponse>}
 */
const methodInfo_Form1099bService_Generate = new grpc.web.AbstractClientBase.MethodInfo(
  proto.taxfilingpb.GenerateBResponse,
  /**
   * @param {!proto.taxfilingpb.DownLoadBRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.GenerateBResponse.deserializeBinary
);


/**
 * @param {!proto.taxfilingpb.DownLoadBRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.taxfilingpb.GenerateBResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.taxfilingpb.GenerateBResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.taxfilingpb.Form1099bServiceClient.prototype.generate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/taxfilingpb.Form1099bService/Generate',
      request,
      metadata || {},
      methodDescriptor_Form1099bService_Generate,
      callback);
};


/**
 * @param {!proto.taxfilingpb.DownLoadBRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.taxfilingpb.GenerateBResponse>}
 *     Promise that resolves to the response
 */
proto.taxfilingpb.Form1099bServicePromiseClient.prototype.generate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/taxfilingpb.Form1099bService/Generate',
      request,
      metadata || {},
      methodDescriptor_Form1099bService_Generate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.taxfilingpb.Form1099b,
 *   !proto.taxfilingpb.UpdateForm1099bResponse>}
 */
const methodDescriptor_Form1099bService_UpdateForm1099b = new grpc.web.MethodDescriptor(
  '/taxfilingpb.Form1099bService/UpdateForm1099b',
  grpc.web.MethodType.UNARY,
  proto.taxfilingpb.Form1099b,
  proto.taxfilingpb.UpdateForm1099bResponse,
  /**
   * @param {!proto.taxfilingpb.Form1099b} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.UpdateForm1099bResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.taxfilingpb.Form1099b,
 *   !proto.taxfilingpb.UpdateForm1099bResponse>}
 */
const methodInfo_Form1099bService_UpdateForm1099b = new grpc.web.AbstractClientBase.MethodInfo(
  proto.taxfilingpb.UpdateForm1099bResponse,
  /**
   * @param {!proto.taxfilingpb.Form1099b} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.UpdateForm1099bResponse.deserializeBinary
);


/**
 * @param {!proto.taxfilingpb.Form1099b} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.taxfilingpb.UpdateForm1099bResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.taxfilingpb.UpdateForm1099bResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.taxfilingpb.Form1099bServiceClient.prototype.updateForm1099b =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/taxfilingpb.Form1099bService/UpdateForm1099b',
      request,
      metadata || {},
      methodDescriptor_Form1099bService_UpdateForm1099b,
      callback);
};


/**
 * @param {!proto.taxfilingpb.Form1099b} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.taxfilingpb.UpdateForm1099bResponse>}
 *     Promise that resolves to the response
 */
proto.taxfilingpb.Form1099bServicePromiseClient.prototype.updateForm1099b =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/taxfilingpb.Form1099bService/UpdateForm1099b',
      request,
      metadata || {},
      methodDescriptor_Form1099bService_UpdateForm1099b);
};


module.exports = proto.taxfilingpb;

