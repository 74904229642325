/**
 * @fileoverview gRPC-Web generated client stub for taxfilingpb
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_type_date_pb = require('../../google/type/date_pb.js')

var proto_commonpb_file_pb = require('../../proto/commonpb/file_pb.js')
const proto = {};
proto.taxfilingpb = require('./boy_cost_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.taxfilingpb.BoyCostServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.taxfilingpb.BoyCostServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.taxfilingpb.BoyCost,
 *   !proto.taxfilingpb.CreateBoyCostResponse>}
 */
const methodDescriptor_BoyCostService_CreateBoyCost = new grpc.web.MethodDescriptor(
  '/taxfilingpb.BoyCostService/CreateBoyCost',
  grpc.web.MethodType.UNARY,
  proto.taxfilingpb.BoyCost,
  proto.taxfilingpb.CreateBoyCostResponse,
  /**
   * @param {!proto.taxfilingpb.BoyCost} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.CreateBoyCostResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.taxfilingpb.BoyCost,
 *   !proto.taxfilingpb.CreateBoyCostResponse>}
 */
const methodInfo_BoyCostService_CreateBoyCost = new grpc.web.AbstractClientBase.MethodInfo(
  proto.taxfilingpb.CreateBoyCostResponse,
  /**
   * @param {!proto.taxfilingpb.BoyCost} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.CreateBoyCostResponse.deserializeBinary
);


/**
 * @param {!proto.taxfilingpb.BoyCost} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.taxfilingpb.CreateBoyCostResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.taxfilingpb.CreateBoyCostResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.taxfilingpb.BoyCostServiceClient.prototype.createBoyCost =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/taxfilingpb.BoyCostService/CreateBoyCost',
      request,
      metadata || {},
      methodDescriptor_BoyCostService_CreateBoyCost,
      callback);
};


/**
 * @param {!proto.taxfilingpb.BoyCost} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.taxfilingpb.CreateBoyCostResponse>}
 *     Promise that resolves to the response
 */
proto.taxfilingpb.BoyCostServicePromiseClient.prototype.createBoyCost =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/taxfilingpb.BoyCostService/CreateBoyCost',
      request,
      metadata || {},
      methodDescriptor_BoyCostService_CreateBoyCost);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.taxfilingpb.BoyCost,
 *   !proto.taxfilingpb.UpdateBoyCostResponse>}
 */
const methodDescriptor_BoyCostService_UpdateBoyCost = new grpc.web.MethodDescriptor(
  '/taxfilingpb.BoyCostService/UpdateBoyCost',
  grpc.web.MethodType.UNARY,
  proto.taxfilingpb.BoyCost,
  proto.taxfilingpb.UpdateBoyCostResponse,
  /**
   * @param {!proto.taxfilingpb.BoyCost} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.UpdateBoyCostResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.taxfilingpb.BoyCost,
 *   !proto.taxfilingpb.UpdateBoyCostResponse>}
 */
const methodInfo_BoyCostService_UpdateBoyCost = new grpc.web.AbstractClientBase.MethodInfo(
  proto.taxfilingpb.UpdateBoyCostResponse,
  /**
   * @param {!proto.taxfilingpb.BoyCost} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.UpdateBoyCostResponse.deserializeBinary
);


/**
 * @param {!proto.taxfilingpb.BoyCost} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.taxfilingpb.UpdateBoyCostResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.taxfilingpb.UpdateBoyCostResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.taxfilingpb.BoyCostServiceClient.prototype.updateBoyCost =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/taxfilingpb.BoyCostService/UpdateBoyCost',
      request,
      metadata || {},
      methodDescriptor_BoyCostService_UpdateBoyCost,
      callback);
};


/**
 * @param {!proto.taxfilingpb.BoyCost} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.taxfilingpb.UpdateBoyCostResponse>}
 *     Promise that resolves to the response
 */
proto.taxfilingpb.BoyCostServicePromiseClient.prototype.updateBoyCost =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/taxfilingpb.BoyCostService/UpdateBoyCost',
      request,
      metadata || {},
      methodDescriptor_BoyCostService_UpdateBoyCost);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.taxfilingpb.ReadBoyCostRequest,
 *   !proto.taxfilingpb.ReadBoyCostResponse>}
 */
const methodDescriptor_BoyCostService_ReadBoyCost = new grpc.web.MethodDescriptor(
  '/taxfilingpb.BoyCostService/ReadBoyCost',
  grpc.web.MethodType.UNARY,
  proto.taxfilingpb.ReadBoyCostRequest,
  proto.taxfilingpb.ReadBoyCostResponse,
  /**
   * @param {!proto.taxfilingpb.ReadBoyCostRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.ReadBoyCostResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.taxfilingpb.ReadBoyCostRequest,
 *   !proto.taxfilingpb.ReadBoyCostResponse>}
 */
const methodInfo_BoyCostService_ReadBoyCost = new grpc.web.AbstractClientBase.MethodInfo(
  proto.taxfilingpb.ReadBoyCostResponse,
  /**
   * @param {!proto.taxfilingpb.ReadBoyCostRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.ReadBoyCostResponse.deserializeBinary
);


/**
 * @param {!proto.taxfilingpb.ReadBoyCostRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.taxfilingpb.ReadBoyCostResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.taxfilingpb.ReadBoyCostResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.taxfilingpb.BoyCostServiceClient.prototype.readBoyCost =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/taxfilingpb.BoyCostService/ReadBoyCost',
      request,
      metadata || {},
      methodDescriptor_BoyCostService_ReadBoyCost,
      callback);
};


/**
 * @param {!proto.taxfilingpb.ReadBoyCostRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.taxfilingpb.ReadBoyCostResponse>}
 *     Promise that resolves to the response
 */
proto.taxfilingpb.BoyCostServicePromiseClient.prototype.readBoyCost =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/taxfilingpb.BoyCostService/ReadBoyCost',
      request,
      metadata || {},
      methodDescriptor_BoyCostService_ReadBoyCost);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.taxfilingpb.DeleteBoyCostRequest,
 *   !proto.taxfilingpb.DeleteBoyCostResponse>}
 */
const methodDescriptor_BoyCostService_DeleteBoyCost = new grpc.web.MethodDescriptor(
  '/taxfilingpb.BoyCostService/DeleteBoyCost',
  grpc.web.MethodType.UNARY,
  proto.taxfilingpb.DeleteBoyCostRequest,
  proto.taxfilingpb.DeleteBoyCostResponse,
  /**
   * @param {!proto.taxfilingpb.DeleteBoyCostRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.DeleteBoyCostResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.taxfilingpb.DeleteBoyCostRequest,
 *   !proto.taxfilingpb.DeleteBoyCostResponse>}
 */
const methodInfo_BoyCostService_DeleteBoyCost = new grpc.web.AbstractClientBase.MethodInfo(
  proto.taxfilingpb.DeleteBoyCostResponse,
  /**
   * @param {!proto.taxfilingpb.DeleteBoyCostRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.DeleteBoyCostResponse.deserializeBinary
);


/**
 * @param {!proto.taxfilingpb.DeleteBoyCostRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.taxfilingpb.DeleteBoyCostResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.taxfilingpb.DeleteBoyCostResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.taxfilingpb.BoyCostServiceClient.prototype.deleteBoyCost =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/taxfilingpb.BoyCostService/DeleteBoyCost',
      request,
      metadata || {},
      methodDescriptor_BoyCostService_DeleteBoyCost,
      callback);
};


/**
 * @param {!proto.taxfilingpb.DeleteBoyCostRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.taxfilingpb.DeleteBoyCostResponse>}
 *     Promise that resolves to the response
 */
proto.taxfilingpb.BoyCostServicePromiseClient.prototype.deleteBoyCost =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/taxfilingpb.BoyCostService/DeleteBoyCost',
      request,
      metadata || {},
      methodDescriptor_BoyCostService_DeleteBoyCost);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.taxfilingpb.BoyCost,
 *   !proto.taxfilingpb.ListBoyCostResponse>}
 */
const methodDescriptor_BoyCostService_ListBoyCost = new grpc.web.MethodDescriptor(
  '/taxfilingpb.BoyCostService/ListBoyCost',
  grpc.web.MethodType.UNARY,
  proto.taxfilingpb.BoyCost,
  proto.taxfilingpb.ListBoyCostResponse,
  /**
   * @param {!proto.taxfilingpb.BoyCost} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.ListBoyCostResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.taxfilingpb.BoyCost,
 *   !proto.taxfilingpb.ListBoyCostResponse>}
 */
const methodInfo_BoyCostService_ListBoyCost = new grpc.web.AbstractClientBase.MethodInfo(
  proto.taxfilingpb.ListBoyCostResponse,
  /**
   * @param {!proto.taxfilingpb.BoyCost} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.ListBoyCostResponse.deserializeBinary
);


/**
 * @param {!proto.taxfilingpb.BoyCost} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.taxfilingpb.ListBoyCostResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.taxfilingpb.ListBoyCostResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.taxfilingpb.BoyCostServiceClient.prototype.listBoyCost =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/taxfilingpb.BoyCostService/ListBoyCost',
      request,
      metadata || {},
      methodDescriptor_BoyCostService_ListBoyCost,
      callback);
};


/**
 * @param {!proto.taxfilingpb.BoyCost} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.taxfilingpb.ListBoyCostResponse>}
 *     Promise that resolves to the response
 */
proto.taxfilingpb.BoyCostServicePromiseClient.prototype.listBoyCost =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/taxfilingpb.BoyCostService/ListBoyCost',
      request,
      metadata || {},
      methodDescriptor_BoyCostService_ListBoyCost);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.taxfilingpb.BoyCost,
 *   !proto.taxfilingpb.CreateBoyCostResponse>}
 */
const methodDescriptor_BoyCostService_ImportBoyCost = new grpc.web.MethodDescriptor(
  '/taxfilingpb.BoyCostService/ImportBoyCost',
  grpc.web.MethodType.UNARY,
  proto.taxfilingpb.BoyCost,
  proto.taxfilingpb.CreateBoyCostResponse,
  /**
   * @param {!proto.taxfilingpb.BoyCost} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.CreateBoyCostResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.taxfilingpb.BoyCost,
 *   !proto.taxfilingpb.CreateBoyCostResponse>}
 */
const methodInfo_BoyCostService_ImportBoyCost = new grpc.web.AbstractClientBase.MethodInfo(
  proto.taxfilingpb.CreateBoyCostResponse,
  /**
   * @param {!proto.taxfilingpb.BoyCost} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.CreateBoyCostResponse.deserializeBinary
);


/**
 * @param {!proto.taxfilingpb.BoyCost} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.taxfilingpb.CreateBoyCostResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.taxfilingpb.CreateBoyCostResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.taxfilingpb.BoyCostServiceClient.prototype.importBoyCost =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/taxfilingpb.BoyCostService/ImportBoyCost',
      request,
      metadata || {},
      methodDescriptor_BoyCostService_ImportBoyCost,
      callback);
};


/**
 * @param {!proto.taxfilingpb.BoyCost} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.taxfilingpb.CreateBoyCostResponse>}
 *     Promise that resolves to the response
 */
proto.taxfilingpb.BoyCostServicePromiseClient.prototype.importBoyCost =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/taxfilingpb.BoyCostService/ImportBoyCost',
      request,
      metadata || {},
      methodDescriptor_BoyCostService_ImportBoyCost);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.taxfilingpb.BoyCostUploadRequest,
 *   !proto.taxfilingpb.BoyCostUploadResponse>}
 */
const methodDescriptor_BoyCostService_Upload = new grpc.web.MethodDescriptor(
  '/taxfilingpb.BoyCostService/Upload',
  grpc.web.MethodType.UNARY,
  proto.taxfilingpb.BoyCostUploadRequest,
  proto.taxfilingpb.BoyCostUploadResponse,
  /**
   * @param {!proto.taxfilingpb.BoyCostUploadRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.BoyCostUploadResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.taxfilingpb.BoyCostUploadRequest,
 *   !proto.taxfilingpb.BoyCostUploadResponse>}
 */
const methodInfo_BoyCostService_Upload = new grpc.web.AbstractClientBase.MethodInfo(
  proto.taxfilingpb.BoyCostUploadResponse,
  /**
   * @param {!proto.taxfilingpb.BoyCostUploadRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.BoyCostUploadResponse.deserializeBinary
);


/**
 * @param {!proto.taxfilingpb.BoyCostUploadRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.taxfilingpb.BoyCostUploadResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.taxfilingpb.BoyCostUploadResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.taxfilingpb.BoyCostServiceClient.prototype.upload =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/taxfilingpb.BoyCostService/Upload',
      request,
      metadata || {},
      methodDescriptor_BoyCostService_Upload,
      callback);
};


/**
 * @param {!proto.taxfilingpb.BoyCostUploadRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.taxfilingpb.BoyCostUploadResponse>}
 *     Promise that resolves to the response
 */
proto.taxfilingpb.BoyCostServicePromiseClient.prototype.upload =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/taxfilingpb.BoyCostService/Upload',
      request,
      metadata || {},
      methodDescriptor_BoyCostService_Upload);
};


module.exports = proto.taxfilingpb;

