/*ReactJS*/
import React, { useState, useEffect } from 'react';
import useStyles from '../../../styles';

import { Box, TextField, InputLabel } from '@material-ui/core';

import Button from '../../../components/Button/Button';
import MaskedField from '../../../components/TextField/MaskedField';
import {
  StatesSelect,
  SelectSystemCode,
  SelectCountry,
  AutoCompletePlace,
} from 'components/AutoComplete';

export default function ProfileModal({
  onClose: handleClose,
  open: isOpen,
  value,
  loading,
}) {
  const classes = useStyles();
  const [modalData, setModalData] = useState({
    companyName: value.companyName,
    photoLink: value.photoLink,
    companyTin: value.companyTin,
    telNo: value.telNo,
    address: value.address,
    state: value.state,
    city: value.city,
    zip: value.zip,
    country: value.country,
    photo: value.photo,
  });

  useEffect(() => {
    if (isOpen) {
      setModalData({ ...value });
    }
  }, [isOpen, value]);

  const handleChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;

    setModalData({
      ...modalData,
      [input.name]: input.value,
    });
  };

  const selectPhoto = (e) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      setModalData({ ...modalData, photo: reader.result });
    };

    reader.readAsDataURL(file);
  };

  const setAddressData = (name, address) => {
    const { address1, state, city, zipCode, country } = address;

    setModalData({
      ...modalData,
      address: address1,
      city: city,
      state: state,
      zip: zipCode,
      country: country,
    });
  };

  return (
    <Box>
      <Box mt={5}>
        <form noValidate autoComplete="off">
          <div className={classes.grdRow}>
            <div className={classes.grdCell1} style={{ marginRight: 30 }}>
              <div className={classes.grdRow}>
                <div className={classes.grdCell1}>
                  <div className={classes.logoContainer}>
                    {modalData.photo ? (
                      <img
                        className={classes.uploadThumbnail}
                        src={modalData.photo}
                        alt="logo"
                      />
                    ) : (
                      <label className={classes.noThumbnailLabel}>
                        Please select an Image for report logo.
                      </label>
                    )}
                  </div>
                  <input
                    style={{ display: 'none' }}
                    accept="image/*"
                    id="input-photo"
                    type="file"
                    onChange={(e) => {
                      selectPhoto(e);
                    }}
                  />
                  <label htmlFor="input-photo">
                    <Button
                      disabled={loading}
                      color="primary"
                      component="span"
                      type="photo"
                      label="Select Photo"
                    />
                  </label>
                </div>
              </div>
              <div className={classes.grdRow}>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <AutoCompletePlace
                    required={true}
                    max={150}
                    name="address"
                    label="Address"
                    value={modalData.address || ''}
                    onChange={handleChange}
                    setAddressData={setAddressData}
                  />
                </div>
                <div className={classes.grdCell1}>
                  <TextField
                    fullWidth
                    required={true}
                    name="city"
                    label="City"
                    value={modalData.city}
                    onChange={handleChange}
                    inputProps={{ maxLength: 50 }}
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
              </div>
              <div className={classes.grdRow}>
                <div className={classes.grdCell1}>
                  <MaskedField
                    required={true}
                    name="companyTin"
                    label="Company TIN"
                    format="##-#######"
                    value={modalData.companyTin}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className={classes.grdRow}>
                <div className={classes.grdCell1}>
                  <MaskedField
                    required={true}
                    name="telNo"
                    label="Telephone No."
                    format="###-###-####"
                    value={modalData.telNo}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className={classes.grdCell1}>
              <div className={classes.grdRow}>
                <div className={classes.grdCell1}>
                  <TextField
                    fullWidth
                    name="photoLink"
                    label="Photo Link"
                    value={modalData.photoLink}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
              </div>
              <div className={classes.grdRow}>
                <div className={classes.grdCell1}>
                  <TextField
                    fullWidth
                    required={true}
                    name="companyName"
                    label="Company Name"
                    value={modalData.companyName}
                    onChange={handleChange}
                    inputProps={{ maxLength: 50 }}
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
              </div>

              <div className={classes.grdRow}>
                <div className={classes.grdCell1}>
                  <StatesSelect
                    required={true}
                    name="state"
                    label="State"
                    value={modalData.state}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className={classes.grdRow}>
                <div className={classes.grdCell1}>
                  <TextField
                    fullWidth
                    name="zip"
                    label="Zip Code"
                    value={modalData.zip}
                    onChange={handleChange}
                    inputProps={{ maxLength: 10 }}
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
              </div>
              <div className={classes.grdRow}>
                <div className={classes.grdCell1}>
                  <SelectCountry
                    freeSolo={false}
                    name="country"
                    label="Country"
                    value={modalData.country}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={classes.modalFooter}>
            <div className={classes.grdCellNone}>
              <Button
                disabled={loading}
                loading={loading}
                color="secondary"
                label={loading ? 'Saving...' : 'Save'}
                onClick={() => {
                  handleClose(modalData);
                }}
              />
            </div>
          </div>
        </form>
      </Box>
    </Box>
  );
}
