import React from 'react';
import { InputField } from 'components/Input/InputField';
import {
  Typography,
  Button,
  Modal,
  Backdrop,
  Fade,
  Box,
  Grid,
  Select,
  InputLabel,
} from '@material-ui/core';
import useStyles from '../../styles';
import { columnType, getColumnValue } from 'components/Table/Table';
import {
  Cancel as IconUncheck,
  CheckCircle as IconCheck,
} from '@material-ui/icons';

const DEFAULT_FIELD_SIZE = 3;
const groupBy = (xs, key) => {
  return xs.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const RowDetailsModal = ({ open, onClose, title, data }) => {
  const classes = useStyles();
  const groupFields = groupBy(
    data.filter(
      (i) => i.type !== columnType.buttons && i.label !== 'Select All'
    ),
    'detailsGroup'
  );

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modalBackdrop}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={open}>
        <div className={classes.fadeModalFull}>
          <Typography
            id="transition-modal-title"
            variant="h4"
            className={classes.textBold}
            gutterBottom
          >
            <div
              style={{
                textTransform: 'uppercase',
              }}
            >
              {title + ' - Row Details'}
            </div>
          </Typography>
          <Box mt={5}>
            <form noValidate autoComplete="off">
              <div className={classes.grdRow}>
                {Object.keys(groupFields)
                  .sort()
                  .map((groupLabel) => {
                    return detailsGroup(groupLabel, groupFields[groupLabel]);
                  })}
              </div>
              <div className={classes.modalFooter}>
                <div
                  className={classes.grdCellNone}
                  style={{ marginRight: 10 }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={onClose}
                  >
                    Close
                  </Button>
                </div>
              </div>
            </form>
          </Box>
        </div>
      </Fade>
    </Modal>
  );
};

const detailsGroup = (label, fields) => {
  return (
    <Grid key={label} container spacing={2}>
      {label && (
        <Grid
          item
          xs={12}
          style={{
            marginTop: 20,
            textTransform: 'uppercase',
            fontWeight: 'bold',
            fontSize: 18,
          }}
        >
          {label}
        </Grid>
      )}
      {fields
        ?.filter((i) => i.label !== 'Select All')
        .map((field) => {
          return dynamicField(field);
        })}
    </Grid>
  );
};

// recursive function
const getElementValue = (element) => {
  if (typeof element === 'string' || element instanceof String) {
    return element;
  }

  if (element?.props && element?.props?.children) {
    return getElementValue(element.props.children);
  }

  return element;
};

const dynamicField = (field) => {
  if (field.type === columnType.list) {
    return listField(field);
  } else if (field.type === columnType.bool) {
    return checkboxField(field);
  }

  let value = field.value;

  if (field.customRender) {
    const element = field.customRender();
    value = getElementValue(element);

    if (!(typeof value === 'string' || value instanceof String)) {
      return customField(field.label, element, DEFAULT_FIELD_SIZE);
    }
  } else if (value === '<nil>') {
    value = '';
  } else {
    value = getColumnValue(field.type, field.value);
  }

  let fieldSize = DEFAULT_FIELD_SIZE;
  if (typeof value === 'string' || value instanceof String) {
    value = value.trim();
  }
  if (value?.length > 60) {
    fieldSize = 12;
  } else if (value?.length > 30) {
    fieldSize = 6;
  }

  return customField(field.label, value, fieldSize);
};

const checkboxField = (field) => {
  return customField(
    field.label,
    field.value ? <IconCheck /> : <IconUncheck />,
    DEFAULT_FIELD_SIZE
  );
};

const customField = (label, element, fieldSize) => {
  if (element?.props?.dangerouslySetInnerHTML) {
    fieldSize = 12;
  }
  return (
    <Grid
      item
      key={label}
      xs={12}
      sm={fieldSize === 12 ? 12 : 6}
      md={fieldSize}
    >
      <Grid wrap="nowrap" container direction="column">
        <Grid item>
          <InputLabel shrink>{label}</InputLabel>
        </Grid>
        <Grid
          item
          style={{
            borderBottom: '1px solid gray',
            minHeight: 27,
            fontSize: 16,
            wordWrap: 'break-word',
            overflowWrap: 'break-word',
          }}
        >
          {element}
        </Grid>
      </Grid>
    </Grid>
  );
};

const listField = (field) => {
  let list = field?.value?.split(field.listSeparator) || [];
  list = list.filter((value, index) => list.indexOf(value) === index);

  let fieldSize = DEFAULT_FIELD_SIZE;
  if (list?.length > 3) {
    fieldSize = 6;
  }

  return (
    <Grid
      item
      key={field.label}
      xs={12}
      sm={fieldSize === 12 ? 12 : 6}
      md={fieldSize}
    >
      <Select
        multiple
        readOnly
        value={list}
        options={list}
        input={<InputField label={field.label} />}
      />
    </Grid>
  );
};
