import React, { useState, useEffect } from 'react';
import useStyles from '../../../styles';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

import {
  Typography,
  Button,
  Modal,
  Backdrop,
  Fade,
  Box,
} from '@material-ui/core';

import MenuSelect from '../../../components/AutoComplete/Menu';

export default function AccountModal({
  onClose: handleClose,
  open: isOpen,
  value,
}) {
  const classes = useStyles();
  const [modalData, setModalData] = useState({});
  const [modalContentValue, setModalContentValue] = useState('');
  const [disableSave, setDisableSave] = useState(false);
  const [isEdit, setIsEdit] = React.useState(false);

  const complex = [
    ['undo', 'redo'],
    ['font', 'fontSize', 'formatBlock'],
    ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
    ['removeFormat'],
    ['fontColor', 'hiliteColor'],
    ['outdent', 'indent'],
    ['align', 'horizontalRule', 'list', 'table'],
    ['link', 'image'],
    ['showBlocks', 'preview', 'fullScreen'],
  ];

  useEffect(() => {
    if (isOpen) {
      setModalData({ ...value });
      setModalContentValue(value.content);
      if (value.userGuideId) {
        setIsEdit(true);
      }
    }
  }, [isOpen, value]);

  const handleChange = (e) => {
    const input = e.currentTarget?.name ? e.currentTarget : e.target;

    const update = {};

    if (input.name === 'pagePath') {
      update[input.name] = input.value;
      update.pageName = input.label;
    } else {
      update[input.name] = input.value;
    }

    setModalData((current) => {
      return { ...current, ...update };
    });
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modalBackdrop}
      open={isOpen}
      onClose={() => {
        handleClose();
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={isOpen}>
        <div className={classes.fadeModalFull}>
          <Typography
            id="transition-modal-title"
            variant="h4"
            className={classes.textBold}
            gutterBottom
          >
            {isEdit ? 'Edit' : 'Add New'} User Guide
          </Typography>
          <Box mt={5}>
            <form noValidate autoComplete="off">
              <div className={classes.grdRow}>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <MenuSelect
                    required
                    name="pagePath"
                    label="Select the appropriate page for this content"
                    value={modalData.pagePath}
                    onChange={handleChange}
                  />
                </div>
                <div className={classes.grdCell1}></div>
                <div className={classes.grdCell1}></div>
              </div>
              <div className={classes.grdRow}>
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <SunEditor
                    showToolbar={true}
                    setOptions={{
                      height: '100%',
                      buttonList: complex,
                    }}
                    setContents={modalContentValue}
                    onChange={setModalContentValue}
                  />
                </div>
              </div>
              <div className={classes.modalFooter}>
                <div
                  className={classes.grdCellNone}
                  style={{ marginRight: 10 }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Close
                  </Button>
                </div>
                <div className={classes.grdCellNone}>
                  <Button
                    variant="contained"
                    disabled={disableSave}
                    color="secondary"
                    size="large"
                    onClick={() => {
                      handleClose(modalData, modalContentValue, isEdit);
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </form>
          </Box>
        </div>
      </Fade>
    </Modal>
  );
}
