import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import { getArrayKeyIndex } from '../../lib/utils/utils';
const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: '16px 24px 16px 24px',
      fontFamily: 'Roboto',
    },
    title: {
      marginLeft: '-7px',
      marginRight: '24px',
      fontSize: '14px',
      color: theme.palette.text.secondary,
      textAlign: 'left',
      fontWeight: 500,
    },
    formGroup: {
      marginTop: '8px',
    },
    formControl: {},
    checkbox: {
      padding: '0px',
      width: '32px',
      height: '32px',
    },
    checkboxRoot: {},
    checked: {},
    label: {
      fontSize: '15px',
      marginLeft: '8px',
      color: theme.palette.text.primary,
    },
  }),
  { name: 'MUIDataTableViewCol' }
);

const excludeFieldLabels = ['', 'Select All', 'Actions', 'View Row Details'];

const getIsAllSelected = (columns) => {
  for (let index = 0; index < columns.length; index++) {
    if (['false', false].includes(columns[index].display)) {
      if (columns[index].name !== 'actions') {
        return false;
      }
    }
  }

  return true;
};

const TableViewCol = ({
  columns,
  options,
  components = {},
  onColumnUpdate,
  updateColumns,
}) => {
  const classes = useStyles();
  const textLabels = options.textLabels.viewColumns;
  const CheckboxComponent = components.Checkbox || Checkbox;

  const [isAllSelected, setIsAllSelected] = React.useState(false);

  React.useEffect(() => {
    setIsAllSelected(getIsAllSelected(columns));
  }, [columns]);

  const handleColChange = (colName) => {
    const index = getArrayKeyIndex(columns, colName, 'name');
    onColumnUpdate(index);
  };

  const handleSelectAll = (isSelected) => {
    const copy = [...columns];
    const displayValue = isSelected ? 'true' : 'false';
    for (let index = 0; index < columns.length; index++) {
      if (
        !excludeFieldLabels.includes(columns[index].label) &&
        copy[index].display !== 'excluded' &&
        copy[index].viewColumns !== false
      ) {
        copy[index] = {
          ...columns[index],
          display: displayValue,
        };
      }
    }

    updateColumns(copy);
  };

  const fields = columns
    .reduce((filtered, col) => {
      if (
        col.display !== 'excluded' &&
        !excludeFieldLabels.includes(col.label) &&
        col.viewColumns !== false
      ) {
        filtered.push(col.label);
      }

      return filtered;
    }, [])
    .sort((a, b) => a.localeCompare(b, undefined, { numeric: true }));

  const fieldComponent = (label) => {
    const index = getArrayKeyIndex(columns, label, 'label');
    const column = columns[index];
    return (
      <FormControlLabel
        key={label}
        classes={{
          root: classes.formControl,
          label: classes.label,
        }}
        control={
          <CheckboxComponent
            color="primary"
            data-description="table-view-col"
            className={classes.checkbox}
            classes={{
              root: classes.checkboxRoot,
              checked: classes.checked,
            }}
            onChange={() => handleColChange(column.name)}
            checked={column.display === 'true'}
            value={column.name}
          />
        }
        label={label}
      />
    );
  };

  return (
    <FormControl
      component={'fieldset'}
      className={classes.root}
      aria-label={textLabels.titleAria}
    >
      <Typography variant="caption" className={classes.title}>
        {textLabels.title}
      </Typography>
      <FormGroup className={classes.formGroup}>
        <FormControlLabel
          classes={{
            root: classes.formControl,
            label: classes.label,
          }}
          control={
            <CheckboxComponent
              color="primary"
              data-description="table-view-col"
              className={classes.checkbox}
              classes={{
                root: classes.checkboxRoot,
                checked: classes.checked,
              }}
              onChange={() => handleSelectAll(!isAllSelected)}
              checked={isAllSelected}
              value={isAllSelected}
            />
          }
          label="Select All"
        />
        {fields.map((label) => {
          return fieldComponent(label);
        })}
      </FormGroup>
    </FormControl>
  );
};

TableViewCol.propTypes = {
  /** Columns used to describe table */
  columns: PropTypes.array.isRequired,
  /** Options used to describe table */
  options: PropTypes.object.isRequired,
  /** Callback to trigger View column update */
  onColumnUpdate: PropTypes.func,
  /** Extend the style applied to components */
  classes: PropTypes.object,
};

export default TableViewCol;
