/*eslint eqeqeq: "off"*/
import {
  ProcessServiceClient,
  ListProcessRequest,
  ProcessRequest,
  ProcessDivnraRequest,
} from '../proto/processpb/process_grpc_web_pb';
import { notifyError } from 'components/Notification/Notification';
import { auth } from '../lib/auth/Auth';
import { stringToProtoDate } from './ConvertService';

const service = new ProcessServiceClient(
  window.env.GRPC_ENDPOINT,
  {},
  { ...auth }
);

export async function listProcess(param) {
  return new Promise((resolve, reject) => {
    const req = new ListProcessRequest();
    req.setYear(param.year);
    req.setCorrespondent(param.correspondent);
    req.setOffice(param.office);
    req.setAccountNo(param.accountNo);

    service.listProcess(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function process(
  type,
  year,
  accountId,
  correspondent,
  branch,
  acc
) {
  return new Promise((resolve, reject) => {
    const req = new ProcessRequest();
    req.setAccountId(accountId);
    req.setYear(year.toString());
    req.setType(type);
    req.setCorrespondent(correspondent);
    req.setBranch(branch);
    req.setAccountNo(acc);

    service.process(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function processDinvra(param) {
  return new Promise((resolve, reject) => {
    const req = new ProcessDivnraRequest();
    req.setAccountId(param.accountId);
    req.setFromDate(stringToProtoDate(param.fromDate));
    req.setToDate(stringToProtoDate(param.toDate));
    service.processDinvra(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}
