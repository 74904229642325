import {
  Form1099DivReClassServiceClient,
  Form1099DivReClass,
  ReadForm1099DivReClassRequest,
  DeleteForm1099DivReClassRequest,
  UploadRequest,
  UploadStatus,
  ProcessRequest,
} from '../proto/taxfilibpb/form1099_divreclass_grpc_web_pb';
import { File } from '../proto/commonpb/file_pb';
import { stringToProtoDate } from './ConvertService';
import {
  notifyError,
  notifySuccess,
} from 'components/Notification/Notification';
import { auth } from '../lib/auth/Auth';
import { Pagination } from 'proto/utilspb/pagination_pb';

const service = new Form1099DivReClassServiceClient(
  window.env.GRPC_ENDPOINT,
  {},
  { ...auth }
);

const requestForm1099DivReClassParameter = (param) => {
  let req = new Form1099DivReClass();
  req.setYear(param.year);
  req.setCusip(param.cusip);
  req.setSymbol(param.symbol);
  req.setSymbolId(param.symbolId);
  req.setRecordDate(stringToProtoDate(param.recordDate));
  req.setPaymentDate(stringToProtoDate(param.paymentDate));
  req.setDivQualified(param.divQualified);
  req.setDivNonQualified(param.divNonQualified);
  req.setForeignTaxQualified(param.foreignTaxQualified);
  req.setForeignTaxNonQualified(param.foreignTaxNonQualified);
  req.setStGainQualified(param.stGainQualified);
  req.setStGainNonQualified(param.stGainNonQualified);
  req.setLtCapitalGain(param.ltCapitalGain);
  req.setRcap(param.rcap);
  req.setUnrecapturedTwelvefiftyGain(param.unrecapturedTwelvefiftyGain);
  req.setDefPymtAmount(param.defPymtAmount);
  req.setCashLiquidation(param.cashLiquidation);
  req.setNonCashLiquidation(param.nonCashLiquidation);
  req.setSectionTwelvezerotwoGain(param.sectionTwelvezerotwoGain);
  req.setCollectibleTwentyeightGain(param.collectibleTwentyeightGain);
  req.setIntt(param.intt);
  req.setAdrFee(param.adrFee);
  req.setExemptInterestDiv(param.exemptInterestDiv);
  req.setAltMinTax(param.altMinTax);
  req.setDivNineteenninea(param.divNineteenninea);
  req.setGrossProceeds(param.grossProceeds);
  req.setTotal(param.total);
  req.setId(param.id);

  return req;
};

export async function createForm1099DivReClass(param) {
  return new Promise((resolve, reject) => {
    const req = requestForm1099DivReClassParameter(param);
    service.createForm1099DivReClass(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function updateForm1099DivReClass(param) {
  return new Promise((resolve, reject) => {
    const req = requestForm1099DivReClassParameter(param);
    service.updateForm1099DivReClass(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function readForm1099DivReClass(param) {
  return new Promise((resolve, reject) => {
    const req = new ReadForm1099DivReClassRequest();
    req.setForm1099DivReClassId(param.formtenninetyninedivReclass);

    service.readForm1099DivReClass(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function deleteForm1099DivReClass(param) {
  return new Promise((resolve, reject) => {
    const req = new DeleteForm1099DivReClassRequest();
    req.setId(param);

    service.deleteForm1099DivReClass(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function listForm1099DivReClass(param, paging) {
  return new Promise((resolve, reject) => {
    const req = new Form1099DivReClass();
    req.setSymbol(param.symbol);
    req.setYear(param.year);

    if (paging) {
      let pg = new Pagination();
      pg.setPageNo(paging.pageNo);
      pg.setPageSize(paging.rowsPerPage);
      pg.setSortName(paging.sortName);
      pg.setSortDirection(paging.sortDirection);
      pg.setFilterNamesList(paging.filterNames);
      pg.setFilterValuesList(paging.filterValues);
      req.setPagination(pg);
    }

    service.listForm1099DivReClass(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function upload(param) {
  return new Promise((resolve, reject) => {
    const file = new File();
    file.setFileName(param.fileName);
    file.setFileBytes(new Uint8Array(param.bytes));
    const req = new UploadRequest();
    req.setBatchNo(param.batchNo);
    req.setFile(file);
    service.upload(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function createUploadStatus(param) {
  return new Promise((resolve, reject) => {
    const req = new UploadStatus();
    req.setBatchNo(param.batchNo);
    req.setUploadedSheets(0);
    service.createUploadStatus(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function processUpload(param) {
  return new Promise((resolve, reject) => {
    const req = new ProcessRequest();
    req.setBatchNo(param.batchNo);
    service.processUpload(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function readUploadStatus(param) {
  return new Promise((resolve, reject) => {
    const req = new UploadStatus();
    req.setBatchNo(param.batchNo);
    service.readUploadStatus(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}
