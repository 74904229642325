/*eslint eqeqeq: "off"*/
import {
  Form1099DivServiceClient,
  Form1099DivRequest,
  DownLoadDivRequest,
  Form1099Div,
} from '../proto/taxfilibpb/form1099_div_grpc_web_pb';
import {
  notifyError,
  notifySuccess,
} from 'components/Notification/Notification';
import { auth } from '../lib/auth/Auth';
import download from './DownloadService';
import { Pagination } from 'proto/utilspb/pagination_pb';

const service = new Form1099DivServiceClient(
  window.env.GRPC_ENDPOINT,
  {},
  { ...auth }
);

export async function listForm1099Div(param, paging) {
  return new Promise((resolve, reject) => {
    const req = new Form1099DivRequest();
    req.setYear(param.year);
    req.setCorrespondent(param.correspondent);
    req.setBranch(param.branch);
    req.setAccountNo(param.accountNo);

    if (paging) {
      let pg = new Pagination();
      pg.setPageNo(paging.pageNo);
      pg.setPageSize(paging.rowsPerPage);
      pg.setSortName(paging.sortName);
      pg.setSortDirection(paging.sortDirection);
      pg.setFilterNamesList(paging.filterNames);
      pg.setFilterValuesList(paging.filterValues);
      req.setPagination(pg);
    }

    service.listForm1099Div(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function listForm1099DivDetails(param, paging) {
  return new Promise((resolve, reject) => {
    const req = new Form1099DivRequest();
    req.setYear(param.year);
    req.setCorrespondent(param.correspondent);
    req.setBranch(param.branch);
    req.setAccountNo(param.accountNo);

    if (paging) {
      let pg = new Pagination();
      pg.setPageNo(paging.pageNo);
      pg.setPageSize(paging.rowsPerPage);
      pg.setSortName(paging.sortName);
      pg.setSortDirection(paging.sortDirection);
      pg.setFilterNamesList(paging.filterNames);
      pg.setFilterValuesList(paging.filterValues);
      req.setPagination(pg);
    }

    service.listForm1099DivDetails(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function downloadDiv(param, filetype, batchNo, downloadType) {
  return new Promise((resolve, reject) => {
    let request = new DownLoadDivRequest();
    request.setFileType(filetype);
    request.setBatchNo(batchNo);
    request.setDownloadType(downloadType);

    let req = new Form1099DivRequest();
    req.setYear(param.year);
    req.setCorrespondent(param.correspondent);
    req.setBranch(param.branch);
    req.setAccountNo(param.accountNo);
    request.setForm1099DivRequest(req);

    service.download(request, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        download(response);
        resolve(response);
        if (response.array[2]) {
          notifySuccess('Download ' + response.array[2] + ' completed');
        } else {
          notifySuccess('Download completed');
        }
      }
    });
  });
}

export async function generateDiv(param, filetype, batchNo, downloadType) {
  return new Promise((resolve, reject) => {
    let request = new DownLoadDivRequest();
    request.setFileType(filetype);
    request.setBatchNo(batchNo);
    request.setDownloadType(downloadType);

    let req = new Form1099DivRequest();
    req.setYear(param.year);
    req.setCorrespondent(param.correspondent);
    req.setBranch(param.branch);
    req.setAccountNo(param.accountNo);
    request.setForm1099DivRequest(req);

    service.generate(request, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
        return;
      } else {
        resolve(response);
      }
    });
  });
}

export async function updateDiv(param) {
  return new Promise((resolve, reject) => {
    const req = new Form1099Div();
    req.setYear(param.year);
    req.setTaxCountry(param.taxCountry);
    req.setDiv(param.div);
    req.setForm1099divId(param.form1099divId);

    service.updateDiv(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}
