/*ReactJS*/
import React, { useState, useEffect } from 'react';
import useStyles from '../../../styles';
import { IconButton, Modal, Backdrop, Fade, Box } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import Table, { columnType } from 'components/Table/Table';
import { form1099IntDetails } from '../../../services/Form1099IntService';
import { notifyInfo, notifyError } from 'components/Notification/Notification';

export default function Form1099IntDetailsModal({
  onClose: handleClose,
  open: isOpen,
  value,
}) {
  const classes = useStyles();
  const [pagination, setPagination] = useState({});
  const [previousRows, setPreviousRows] = useState(0);
  const [rows, setRows] = useState([]);

  const columns = [
    {
      name: 'trnsId',
      label: 'TRNS ID',
    },
    {
      name: 'year',
      label: 'Year',
    },
    {
      name: 'month',
      label: 'Month',
    },
    {
      name: 'settleDate',
      label: 'Settle Date',
      type: columnType.date,
    },
    {
      name: 'description',
      label: 'Description',
    },
    {
      name: 'pb_int',
      label: 'Interest',
      type: columnType.amount,
      addFooter: true,
    },
  ];

  const options = {
    // serverSide: true,
    selectableRows: 'none',
  };

  useEffect(() => {
    if (isOpen) {
      handleSearch();
    }
  }, [isOpen]);

  const handleSearch = async (paginationChange) => {
    try {
      let paginationCopy = {
        ...pagination,
        reload: false,
        pageNo: paginationChange === true ? pagination.pageNo : 0,
        rowsPerPage: pagination.rowsPerPage || 100,
      };
      if (previousRows < pagination.rowsPerPage * pagination.pageNo) {
        paginationCopy.pageNo = 0;
      }
      if (paginationChange !== true) {
        paginationCopy.filterNames = [];
        paginationCopy.filterValues = [];
      }
      const data = await form1099IntDetails(value, paginationCopy);
      paginationCopy.count = data.summary.totalRows;
      setPreviousRows(data.summary.totalRows);
      setPagination(paginationCopy);
      setRows(data.form1099IntDetailsList);
      notifyInfo(data.form1099IntDetailsList.length + ' search results.');

      // if (paginationChange !== true) {
      //   notifyInfo(data.summary.totalRows + ' search results.');
      // }
    } catch (error) {
      notifyError(error.message);
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modalBackdrop}
      open={isOpen}
      onClose={() => {
        handleClose();
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={isOpen}>
        <div className={classes.tableModalFull}>
          <Box component="div" mt={2}>
            <Table
              title="FORM 1099 Int DETAILS"
              data={rows}
              columns={columns}
              options={options}
            />
          </Box>

          <div className={classes.modalFooter} style={{ margin: 0 }}>
            <div className={classes.grdCellNone} style={{ marginRight: 10 }}>
              <div
                className={classes.modalCloseIcon}
                style={{ marginRight: 10 }}
              >
                <IconButton
                  aria-label="close"
                  onClick={() => {
                    handleClose();
                  }}
                >
                  <CloseIcon style={{ color: '#f1f1f1' }} />
                </IconButton>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
