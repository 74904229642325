import { createSelector } from 'reselect'; //memoization
import {
  NoteServiceClient,
  ListNoteRequest,
} from '../proto/commonpb/note_grpc_web_pb';
import {
  LazyAccountRequest,
  LazyAccountServiceClient,
  EmptyRequest,
} from '../proto/commonpb/lazyaccount_grpc_web_pb';
import {
  ReadPrimaryOwnerRequest,
  ListServiceClient,
  ListBankAccountRequest,
  LazyLoadSecurityRequest,
  ListAdmEntryTypeRequest,
} from '../proto/commonpb/list_grpc_web_pb';
import { auth } from '../lib/auth/Auth';

const listService = new ListServiceClient(
  window.env.GRPC_ENDPOINT,
  {},
  { ...auth }
);

const lazyService = new LazyAccountServiceClient(
  window.env.GRPC_ENDPOINT,
  {},
  { ...auth }
);

const noteService = new NoteServiceClient(
  window.env.GRPC_ENDPOINT,
  {},
  { ...auth }
);

export async function listBankAccount(correspondent, accountNo) {
  return new Promise((resolve, reject) => {
    const req = new ListBankAccountRequest();
    req.setCorrespondent(correspondent);
    req.setAccountNo(accountNo);

    listService.listBankAccount(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function readPrimaryOwner(param) {
  return new Promise((resolve, reject) => {
    const req = new ReadPrimaryOwnerRequest();
    req.setCorrespondent(param.correspondent);
    req.setAccountNo(param.accountNo);

    listService.readPrimaryOwner(req, {}, (error, response) => {
      if (error) {
        console.log(error, response);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function listNotes(accountId, linkId, noteType) {
  return new Promise((resolve, reject) => {
    let req = new ListNoteRequest();

    if (accountId) {
      req.setAccountId(accountId);
    }

    if (linkId) {
      req.setLinkId(linkId);
    }

    req.setNoteType(noteType);

    noteService.listNote(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

//memoization -  cached result when the same inputs occur again
export const lazyCorrespondent = createSelector(
  () => (async () => await lazyCorrespondentPromise())(),
  (correspondents) => correspondents
);

async function lazyCorrespondentPromise() {
  return new Promise((resolve, reject) => {
    lazyService.lazyCorrespondent(new EmptyRequest(), {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

//memoization -  cached result when the same inputs occur again
export const lazyAccountName = createSelector(
  (correspondent, key, accountType) =>
    (async () =>
      await lazyAccountNamePromise(key, correspondent, accountType))(),
  (correspondents) => correspondents
);

async function lazyAccountNamePromise(key, correspondent, accountType) {
  return new Promise((resolve, reject) => {
    const req = getLazyAccountRequest(key, correspondent, accountType);

    lazyService.lazyAccountName(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

//memoization -  cached result when the same inputs occur again
export const lazyBranch = createSelector(
  (correspondent, key, accountType) =>
    (async () => await lazyBranchPromise(key, correspondent, accountType))(),
  (correspondents) => correspondents
);

async function lazyBranchPromise(key, correspondent, accountType) {
  return new Promise((resolve, reject) => {
    const req = getLazyAccountRequest(key, correspondent, accountType);

    lazyService.lazyBranch(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

//memoization -  cached result when the same inputs occur again
export const lazyAccountNo = createSelector(
  (correspondent, key, accountType) =>
    (async () => await lazyAccountNoPromise(key, correspondent, accountType))(),
  (correspondents) => correspondents
);

async function lazyAccountNoPromise(key, correspondent, accountType) {
  return new Promise((resolve, reject) => {
    const req = getLazyAccountRequest(key, correspondent, accountType);

    lazyService.lazyAccountNo(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

const getLazyAccountRequest = (key, correspondent, accountType) => {
  const req = new LazyAccountRequest();
  req.setKey(key);
  req.setLimit(50);
  req.setCorrespondent(correspondent);
  req.setType(accountType);

  return req;
};

//memoization -  cached result when the same inputs occur again
export const lazyLoadSecurity = createSelector(
  (key, assetType, cusip, isActiveOnly, field, isDigitalAssetOnly) => ({
    key: key,
    assetType: assetType,
    cusip: cusip,
    isActiveOnly: isActiveOnly,
    field: field,
    isDigitalAssetOnly: isDigitalAssetOnly,
  }),
  (p) =>
    (async () =>
      await lazyLoadSecurityPromise(
        p.key,
        30,
        p.assetType,
        p.cusip,
        p.isActiveOnly,
        p.field,
        p.isDigitalAssetOnly
      ))()
);

const lazyLoadSecurityPromise = async (
  key,
  limit,
  assetType,
  cusip,
  isActiveOnly,
  field,
  isDigitalAssetOnly
) => {
  return new Promise((resolve, reject) => {
    let req = new LazyLoadSecurityRequest();
    req.setLimit(limit);
    req.setKey(key);
    req.setAssetType(assetType);
    req.setCusip(cusip);
    req.setIsActiveOnly(isActiveOnly);
    req.setField(field);
    req.setIsDigitalAssetOnly(isDigitalAssetOnly);

    listService.lazyLoadSecurity(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
};

export const listEntryType = createSelector(
  (props) => (async () => await listAdmEntryType(props))(),
  (admEntryType) => admEntryType
);

const listAdmEntryType = async (props) => {
  return new Promise((resolve, reject) => {
    let req = new ListAdmEntryTypeRequest();
    req.setEntryType(props.entryType);
    req.setScreenType(props.screenType);
    req.setCorrespondent(props.correspondent);
    req.setAccountNo(props.accountNo);
    req.setStatus(props.status);
    req.setNote(props.note);

    listService.listAdmEntryType(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
};
