import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { listEntryType } from '../../services/CommonService';

export default function SelectEntryType(props) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [inputValue, setInputValue] = React.useState(props.value);

  const setPropsValue = (value) => {
    props.onChange({
      currentTarget: {
        name: props.name,
        value: value,
      },
      target: {
        name: props.name,
        value: value,
      },
    });
  };

  const handleOnBlur = async (value) => {
    if (props.freeSolo) {
      setPropsValue(value);
      return;
    }

    if (options.find((o) => o.entryType === value)) {
      setPropsValue(value);
      return;
    }

    // if selected value does not exist in options and is not a free solo
    setInputValue('');
    setPropsValue('');
  };

  //set local input value and get options
  const handleInputChange = async (event, value) => {
    if (!event) return;
    if (event.type === 'blur') return;
    if (event.type === 'click' && value) return;
    setInputValue(value);
    getOptions(value);
  };

  //get list from backend
  const getOptions = async () => {
    const params = {
      entryType: props.entryType,
      correspondent: props.correspondent,
      accountNo: props.accountNo,
      screenType: props.screenType,
      status: props.status,
      note: props.note,
    };
    const { entryTypesList } = await listEntryType(params);

    const list = entryTypesList.filter((s) => s.entryType);

    const nonTrade = {
      entryType: 'NON TRD',
      entryTypeDescription: 'Non Trade Entry',
    };

    if (props.isViewFilter) {
      list.push(nonTrade);
    }

    setOptions(list);
  };

  React.useEffect(
    () => {
      if (open) {
        getOptions(props);
      }
    },
    // eslint-disable-next-line
    [open]
  );

  React.useEffect(
    () => {
      if (!open && props.value !== inputValue) {
        setInputValue(props.value);
      }
    },
    // eslint-disable-next-line
    [open, props.value]
  );

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (
        e.target.value.length === 0 ||
        options.find((o) => o.entryType === e.target.value)
      ) {
        return;
      }
      if (!options.find((o) => o.entryType.indexOf(e.target.value) === -1)) {
        e.preventDefault();
        e.stopPropagation();
        return;
      }
    }
  };

  return (
    <Autocomplete
      disabled={props.disabled}
      freeSolo={props.freeSolo}
      id={props.name}
      onInputChange={handleInputChange}
      onBlur={() => {
        handleOnBlur(inputValue);
      }}
      blurOnSelect={false}
      onChange={(_, v) => {
        const value = v ? v.entryType : '';
        setPropsValue(value);
        setInputValue(value);
      }}
      getOptionSelected={(option, value) =>
        option.entryType === value.entryType ||
        options.find((o) => o.entryType !== value)
      }
      getOptionLabel={(option) => {
        return (
          option?.entryType +
          (option?.entryTypeDescription
            ? ' - ' + option.entryTypeDescription
            : '')
        );
      }}
      inputValue={inputValue}
      options={options}
      name={props.name}
      autoHighlight={true}
      clearOnEscape={true}
      onClose={() => {
        setOpen(false);
      }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      renderInput={(params) => {
        params.inputProps.onKeyDown = handleKeyDown;
        return (
          <TextField
            {...params}
            name={props.name}
            required={props.required}
            disabled={props.disabled}
            label={props.label}
            InputLabelProps={{ shrink: true }}
            inputProps={{ ...params.inputProps }}
          />
        );
      }}
    />
  );
}
