// IN COLUMN SETTINGS
//  {
//   name: 'sdDebitCashBalance',
//   label: 'SD Debit Cash Balance',
//   type: 'quantity', //custom quantity/amount/percentage/date/dateTime
//   addFooter: true, //custom true/false
// },

//pending: filter retain values
/*eslint eqeqeq: "off"*/
import CircularProgress from '@material-ui/core/CircularProgress';
import { FormGroup, IconButton, TextField, Tooltip } from '@material-ui/core';
import React, { useEffect, useState, Fragment } from 'react';
import moment from 'moment-timezone';
import MUIDataTable from 'mui-datatables';
import { MuiThemeProvider } from '@material-ui/core/styles';
import tableTheme from './TableStyle';
import TableBodyFooter from './TableBodyFooter';
import TableViewCol from './TableViewCol';
import { createSelector } from 'reselect'; //memoization
import ServerSideTableBodyFooter from './ServerSideTableBodyFooter';
import Checkbox from '@material-ui/core/Checkbox';
import {
  CheckCircleOutline,
  CheckBoxOutlineBlank,
  FiberManualRecord,
  Receipt as IconReceipt,
  CloudDownload as CsvDownloadIcon,
} from '@material-ui/icons';
import {
  formatPbDate,
  formatCurrency,
  formatPercentage,
  formatQty,
  formatRate,
  formatCurrencySymbol,
} from 'lib/fmt';
import {
  protoTimeSpanObjectToString,
  protoDateObjectToDate,
  protoDatTimeObjectToDate,
  dateStringToDate,
  stringToDateTime,
} from '../../services/ConvertService';
import { RowDetailsModal } from './RowDetailsModal';
import CsvDownload from './CsvDownload';
import {
  getArrayIndex,
  moveArrayIndex,
  tableDateSortCompare,
  tableDateTimeSortCompare,
} from '../../lib/utils/utils';

export const columnType = {
  date: 'date',
  dateTime: 'dateTime',
  quantity: 'quantity',
  percentage: 'percentage',
  rate: 'rate',
  amount: 'amount',
  amount9Decimal: 'amount9Decimal',
  text: 'text',
  buttons: 'buttons',
  boolean: 'boolean',
  progress: 'progress',
  list: 'list',
};

let tempFilteredRows = [];
export const getTempFilteredRow = (index) => {
  if (!tempFilteredRows || tempFilteredRows?.length === 0) {
    return {};
  }

  return tempFilteredRows[index] || {};
};

export const getTempFilteredRows = () => {
  return tempFilteredRows || [];
};

export default function Table({
  data: rows,
  columns,
  title,
  options,
  additionalCell,
  pagination,
  setPagination,
  handleCustomSearch,
  footerData,
  onSearch,
  modalTable,
  getCsvData,
}) {
  const [rowDetailsModal, setRowDetailsModal] = useState({
    data: {},
    open: false,
  });
  const [filterList, setFilterList] = useState({});
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [serverSideSearch, setServerSideSearch] = useState('');
  const [downloadAll, setDownloadAll] = useState(false);
  const [openCsvDownload, setOpenCsvDownload] = useState(false);

  useEffect(() => {
    if (setPagination) {
      setPagination({
        count: 0,
        rowsPerPage: 10,
        pageNo: 0,
        reload: false,
        sortName: '',
        sortDirection: '',
        filterNames: [],
        filterValues: [],
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (pagination) {
      if (pagination.reload) {
        const fetchData = async () => {
          document.body.style.pointerEvents = 'none';
          await onSearch(true, modalTable);
          document.body.style.pointerEvents = 'auto';
        };
        fetchData();
      }
    }
    // eslint-disable-next-line
  }, [pagination]);

  const filterRows = () => {
    const filterKeys = Object.keys(filterList);
    if (!filterKeys.length) {
      return rows;
    }

    const filtered = rows.filter((r) => {
      let ok = true;
      filterKeys.forEach((key) => {
        if (filterList[key] !== r[key]) {
          ok = false;
          return false;
        }
      });
      return ok;
    });

    return filtered;
  };

  tempFilteredRows = filterRows();

  // FOR SERVER SIDE CUSTOM SEARCH
  if (serverSideSearch && options.serverSide && tempFilteredRows.length) {
    tempFilteredRows = serverSideCustomSearch(
      serverSideSearch,
      tempFilteredRows,
      visibleColumns,
      columns
    );
    //handleCustomSearch is a function to properly set filtered data by custom search.
    handleCustomSearch &&
      handleCustomSearch(tempFilteredRows, serverSideSearch);
  }

  const viewRowDetailsColumn = {
    name: 'View Row Details',
    label: 'View Row Details',
    type: columnType.buttons,
    options: {
      display: true,
      setCellProps: () => {
        return {
          style: { padding: 0, width: 10 },
        };
      },
      customBodyRender: (_, tableMeta) => {
        return (
          <div>
            <IconButton
              onClick={() => {
                const data = columns
                  .filter(
                    (col) =>
                      col?.options?.display !== 'excluded' &&
                      !col?.options?.hidden
                  )
                  .map((col) => {
                    const value =
                      tableMeta.rowData[
                        getNewColumnIndex(columnSettings, col.name)
                      ];

                    let customRender;
                    let listSeparator = '';

                    if (col?.type === columnType.list) {
                      listSeparator = col?.options?.listSeparator;
                    } else if (col?.options?.customBodyRender) {
                      customRender = () => {
                        return col.options.customBodyRender(value, tableMeta);
                      };
                    } else if (col?.options?.customBodyRenderLite) {
                      customRender = () => {
                        return col.options.customBodyRenderLite(
                          tableMeta.rowIndex,
                          true // isDisabledFromRowDetails - custom property to disable fields when used in row details
                        );
                      };
                    }

                    return {
                      label: col.label,
                      value: value,
                      type: col.type || '',
                      customRender: customRender,
                      listSeparator: listSeparator,
                      detailsGroup: col.detailsGroup || '',
                    };
                  });

                setRowDetailsModal({
                  title: title,
                  open: true,
                  data: data,
                });
              }}
            >
              <IconReceipt />
            </IconButton>
          </div>
        );
      },
    },
  };

  columns = [viewRowDetailsColumn, ...columns];
  const [visibleColumns, setVisibleColumns] = useState([]);
  const [columnOrder, setColumnOrder] = useState(
    columns.map((a, index) => {
      return index;
    })
  );

  const columnSettings = getColumnSettings(
    columns,
    visibleColumns,
    rows,
    options,
    tempFilteredRows
  );
  const optionSettings = getOptionSettings(
    options,
    columns,
    tempFilteredRows,
    title,
    columnOrder,
    setColumnOrder,
    visibleColumns,
    setVisibleColumns,
    additionalCell,
    setFilterList,
    pagination,
    setPagination,
    isFilterActive,
    setIsFilterActive,
    setServerSideSearch,
    footerData,
    getCsvData,
    downloadAll,
    setDownloadAll,
    setOpenCsvDownload
  );

  useEffect(() => {
    setColumnOrder(getColumnOrder(columns, columnSettings));
    setVisibleColumns(getVisibleColumns(columns));
  }, []);

  const serverSideCustomSearch = (
    searchQuery,
    rows,
    visibleColumns,
    sortedColumns
  ) => {
    const columnTypes = {};

    sortedColumns.forEach((col) => {
      col.options = col.options || {};
      if (visibleColumns.includes(col.name)) {
        col.options.display = true;
        columnTypes[col.name] = col?.type;
      }

      return col;
    });

    const keys = Object.keys(columnTypes);

    return rows.filter((r) => {
      for (let index = 0; index < keys.length; index++) {
        const colName = keys[index];
        const value = r[colName];
        if (!value) {
          continue;
        }

        if (typeof value === 'string') {
          if (value.toLowerCase().includes(searchQuery?.toLowerCase())) {
            return true;
          }
        }
        const formattedValue = getColumnValue(columnTypes[colName], value);
        if (
          formattedValue
            ?.toString()
            .toLowerCase()
            .includes(searchQuery?.toLowerCase())
        ) {
          return true;
        }
      }
      return false;
    });
  };

  const getFormattedCsvData = async () => {
    let raw = [];
    let csvDownloadAsLinks = false;
    if (getCsvData) {
      raw = await getCsvData();
      csvDownloadAsLinks = raw.csvDownloadAsLinks;

      if (csvDownloadAsLinks) {
        if (!raw.list.length || !tempFilteredRows.length) {
          return [];
        }
      } else {
        if (!raw.length || !tempFilteredRows.length) {
          return [];
        }
      }
    } else {
      if (!rows.length || !tempFilteredRows.length) {
        return [];
      }
      raw = rows;
    }

    let formatted = [];
    let retData = [];

    if (csvDownloadAsLinks) {
      raw.list.map((row) => {
        formatted = createFormattedCsvData(row);
        retData.push(formatted);
      });
    } else {
      formatted = createFormattedCsvData(raw);
    }

    let retVal = {
      csvDownloadAsLinks: csvDownloadAsLinks,
      list: retData,
    };

    if (csvDownloadAsLinks) {
      return retVal;
    } else {
      return formatted;
    }
  };

  const createFormattedCsvData = (raw) => {
    let sortedVisibleColumns = [];

    if (!downloadAll) {
      const mapVisibleColumns = {};
      visibleColumns.forEach((key) => {
        if (key != '') {
          mapVisibleColumns[key] = true;
        }
      });

      columnOrder.forEach((index) => {
        const columnName = columnSettings[index].name;
        if (mapVisibleColumns[columnName]) {
          sortedVisibleColumns.push(columnName);
        }
      });
    }

    // move special ids as the first columns
    let keys = Object.keys(raw[0]);
    moveArrayIndex(keys, getArrayIndex(keys, 'accountId'), 0);
    moveArrayIndex(keys, getArrayIndex(keys, 'trnsId'), 0);

    // prepare column settings
    let columnTypes = {};
    let columnLabels = {};
    keys.forEach((key) => {
      const set = getColumnSettingsByName(columnSettings, key);
      columnTypes[key] = set?.type;
      columnLabels[key] = set?.label;
    });

    let arr = keys;
    if (!downloadAll) {
      arr = sortedVisibleColumns;
    }

    // correct value format, ordering, and labels
    let formatted = raw.map((data) => {
      let r = {};
      arr.forEach((key) => {
        const colLabel = columnLabels[key];
        if (!colLabel) {
          return;
        }

        if (['price'].includes(key)) {
          r[colLabel] = getColumnValue(columnType.price, data[key]);
        } else if (key != 'pagination') {
          if (data[key] === '<nil>') {
            r[colLabel] = '';
          } else {
            r[colLabel] = getColumnValue(columnTypes[key], data[key]);
          }
        }
      });

      return r;
    });

    return formatted;
  };

  return (
    <React.Fragment>
      {openCsvDownload && (
        <CsvDownload
          getData={getFormattedCsvData}
          filename={getCsvFileName(title)}
          open={openCsvDownload}
          onClose={() => {
            setOpenCsvDownload(false);
          }}
        />
      )}

      <MuiThemeProvider theme={tableTheme()}>
        <MUIDataTable
          title={title}
          data={tempFilteredRows}
          columns={columnSettings}
          options={optionSettings}
          components={{
            Checkbox: CustomCheckbox,
            TableViewCol: TableViewCol,
          }}
          additionalCell={additionalCell}
        />
      </MuiThemeProvider>
      {rowDetailsModal?.open && (
        <RowDetailsModal
          onClose={() => {
            setRowDetailsModal({
              title: '',
              open: false,
              data: {},
            });
          }}
          {...rowDetailsModal}
        />
      )}
    </React.Fragment>
  );
}

const CustomCheckbox = (props) => {
  const bgColor =
    props['data-description'] === 'row-select-header' ? '' : 'whitesmoke';

  return (
    <Checkbox
      style={{
        backgroundColor: bgColor,
      }}
      {...props}
    />
  );
};

const getColumnSettings = createSelector(
  (columns, visibleColumns, rows, options, tempFilteredRows) => ({
    columns,
    visibleColumns,
    rows,
    options,
    tempFilteredRows,
  }),
  (param) => {
    const sortedColumns = sortColumnSettings([...param.columns]);
    const { visibleColumns, rows } = param;

    return sortedColumns.map((col) => {
      col.options = col.options || {};

      //visibility state
      if (visibleColumns.includes(col.name)) {
        col.options.display = true;
      } else if (visibleColumns.length) {
        col.options.display = false;
      }

      switch (col.type) {
        case columnType.date:
          if (!param.options.serverSide) {
            col.options.filterType = 'custom';
            col.options.filterList = [];
            col.options.customFilterListOptions = getCustomFilterListOptions(
              col
            );
            col.options.filterOptions = {
              names: [],
              logic(stringValue, filters) {
                const from = dateStringToDate(filters[0]);
                const to = dateStringToDate(filters[1]);
                const value = protoDateObjectToDate(stringValue);

                if (from && to) {
                  return !(value >= from && value <= to);
                } else if (from) {
                  return !(value >= from);
                } else if (to) {
                  return !(value <= to);
                }

                return false;
              },
              display: (filterList, onChange, index, column) => {
                return dateRangeTemplate(
                  filterList,
                  onChange,
                  index,
                  column,
                  col,
                  'date'
                );
              },
            };
          } else {
            col.options.filter = false;
          }
          col.options.filter = false;
          col.options.customBodyRenderLite = (dataIndex) => {
            return (
              <div align="right">
                {getColumnValue(col.type, rows[dataIndex][col.name])}
              </div>
            );
          };

          col.options.sortCompare = tableDateSortCompare;
          break;

        // case columnType.date:
        //   col.options.filterType = 'custom';
        //   col.options.filterList = [];
        //   col.options.customFilterListOptions = getCustomFilterListOptions(col);
        //   col.options.filterOptions = {
        //     names: [],
        //     logic(stringValue, filters) {
        //       const from = dateStringToDate(filters[0]);
        //       const to = dateStringToDate(filters[1]);
        //       const value = protoDateObjectToDate(stringValue);

        //       if (from && to) {
        //         return !(value >= from && value <= to);
        //       } else if (from) {
        //         return !(value >= from);
        //       } else if (to) {
        //         return !(value <= to);
        //       }

        //       return false;
        //     },
        //     display: (filterList, onChange, index, column) => {
        //       return dateRangeTemplate(
        //         filterList,
        //         onChange,
        //         index,
        //         column,
        //         col,
        //         'date'
        //       );
        //     },
        //   };

        //   col.options.customBodyRenderLite = (dataIndex) => {
        //     return getColumnValue(col.type, rows[dataIndex][col.name]);
        //   };

        //   col.options.sortCompare = dateSortCompare;
        //   break;
        // case columnType.dateTime:
        //   col.options.filterType = 'custom';
        //   col.options.filterList = [];
        //   col.options.customFilterListOptions = getCustomFilterListOptions(col);
        //   col.options.filterOptions = {
        //     fullWidth: true,
        //     names: [],
        //     logic(stringValue, filters) {
        //       const from = stringToDateTime(filters[0]);
        //       const to = stringToDateTime(filters[1]);
        //       const value = protoDatTimeObjectToDate(stringValue);

        //       if (from && to) {
        //         return !(value >= from && value <= to);
        //       } else if (from) {
        //         return !(value >= from);
        //       } else if (to) {
        //         return !(value <= to);
        //       }

        //       return false;
        //     },
        //     display: (filterList, onChange, index, column) => {
        //       return dateRangeTemplate(
        //         filterList,
        //         onChange,
        //         index,
        //         column,
        //         col,
        //         'datetime-local'
        //       );
        //     },
        //   };
        //   col.options.customBodyRenderLite = (dataIndex) => {
        //     return getColumnValue(col.type, rows[dataIndex][col.name]);
        //   };

        //   col.options.sortCompare = dateTimeSortCompare;
        //   break;
        case columnType.dateTime:
          if (!param.options.serverSide) {
            col.options.filterType = 'custom';
            col.options.filterList = [];
            col.options.customFilterListOptions = getCustomFilterListOptions(
              col
            );
            col.options.filterOptions = {
              fullWidth: true,
              names: [],
              logic(stringValue, filters) {
                const from = stringToDateTime(filters[0]);
                const to = stringToDateTime(filters[1]);
                const value = protoDatTimeObjectToDate(stringValue);

                if (from && to) {
                  return !(value >= from && value <= to);
                } else if (from) {
                  return !(value >= from);
                } else if (to) {
                  return !(value <= to);
                }

                return false;
              },
              display: (filterList, onChange, index, column) => {
                return dateRangeTemplate(
                  filterList,
                  onChange,
                  index,
                  column,
                  col,
                  'datetime-local'
                );
              },
            };
          } else {
            col.options.filter = false;
          }
          col.options.filter = false;
          if (!col.options.customBodyRenderLite && rows?.length) {
            col.options.customBodyRenderLite = (dataIndex) => {
              return getColumnValue(col.type, rows[dataIndex][col.name]);
            };
          }

          col.options.sortCompare = tableDateTimeSortCompare;
          break;

        case columnType.amount:
        case columnType.amount9Decimal:
        case columnType.percentage:
        case columnType.rate:
        case columnType.quantity:
          col.options.customBodyRenderLite = (dataIndex) => {
            const value = rows[dataIndex][col.name];
            const formattedValue = getColumnValue(col.type, value);

            return (
              <div align="right" style={{ color: value < 0 && '#f44336' }}>
                {formattedValue}
              </div>
            );
          };

          col.options.sortCompare = amountSortCompare;
          break;
        case columnType.buttons:
          col.options = {
            ...{
              draggable: false,
              resizable: false,
              print: false,
              searchable: false,
              filter: false,
              sort: false,
              empty: true,
              viewColumns: false,
              download: false,
              customHeadLabelRender: () => {
                return <div></div>;
              },
              setCellProps: () => {
                return {
                  style: { padding: 1, width: 10 },
                };
              },
            },
            ...col.options,
          };

          break;
        case columnType.boolean:
          col.options.customBodyRenderLite = (dataIndex) => {
            return rows[dataIndex][col.name] ? (
              <CheckCircleOutline />
            ) : (
              <CheckBoxOutlineBlank />
            );
          };
          break;
        case columnType.progress:
          col.options.customBodyRenderLite = (dataIndex) => {
            return rows[dataIndex][col.name] ? (
              <CircularProgress size={32} />
            ) : (
              <FiberManualRecord size={32} />
            );
          };
          break;
      }

      return col;
    });
  }
);
const getCustomFilterListOptions = (col) => {
  return {
    render: (value) => {
      if (value[0] && value[1]) {
        return `From ${col.label}: ${value[0]}, To ${col.label}: ${value[1]}`;
      } else if (value[0]) {
        return `From ${col.label}: ${value[0]}`;
      } else if (value[1]) {
        return `To ${col.label}: ${value[1]}`;
      }
      return [];
    },
    update: (filterList, filterPos, index) => {
      if (filterPos === 0) {
        filterList[index].splice(filterPos, 1, '');
      } else if (filterPos === 1) {
        filterList[index].splice(filterPos, 1);
      } else if (filterPos === -1) {
        filterList[index] = [];
      }

      return filterList;
    },
  };
};

const getCsvFileName = (title) => {
  return (
    title?.replace(/ /g, '') + '_' + moment().format('MMMM Do YYYY') + '.csv'
  );
};

const dateRangeTemplate = (
  filterList,
  onChange,
  index,
  column,
  col,
  dateType
) => (
  <div>
    <FormGroup row>
      <TextField
        label={'From ' + col.label}
        type={dateType}
        InputLabelProps={{ shrink: true }}
        value={filterList[index][0] || ''}
        onChange={(event) => {
          filterList[index][0] = event.target.value;
          onChange(filterList[index], index, column);
        }}
        style={{ width: '45%', marginRight: '5%' }}
        inputProps={{
          max: filterList[index][1],
        }}
      />
      <TextField
        label={'To ' + col.label}
        type={dateType}
        InputLabelProps={{ shrink: true }}
        value={filterList[index][1] || ''}
        onChange={(event) => {
          filterList[index][1] = event.target.value;
          onChange(filterList[index], index, column);
        }}
        style={{ width: '45%' }}
        inputProps={{
          min: filterList[index][0],
        }}
      />
    </FormGroup>
  </div>
);

const getOptionSettings = createSelector(
  (
    options,
    columns,
    rows,
    title,
    columnOrder,
    setColumnOrder,
    visibleColumns,
    setVisibleColumns,
    additionalCell,
    setFilterList,
    pagination,
    setPagination,
    isFilterActive,
    setIsFilterActive,
    setServerSideSearch,
    footerData,
    getCsvData,
    downloadAll,
    setDownloadAll,
    setOpenCsvDownload
  ) => {
    const footerSettings = getFooterSettings(columns);

    const defaultSettings = {
      columnOrder: columnOrder,
      filterType: 'multiselect',
      responsive: 'standard',
      download: !getCsvData,
      filter: true,
      search: true,
      print: false,
      sort: true,
      viewColumns: true,
      resizableColumns: false,
      draggableColumns: {
        enabled: true,
      },
      selectableRowsHeader: true,
      selectableRows: 'multiple',
      rowsPerPage: 10,
      rowsPerPageOptions: [5, 10, 25, 50, 100, 500, 1000],
      downloadOptions: {
        filename: getCsvFileName(title),
      },
      tableBodyHeight: 'auto',
      jumpToPage: true, // pagination
      customSort: (data, colIndex, order) => {
        return data.sort((a, b) => {
          if (
            typeof a.data[colIndex] === 'object' &&
            typeof b.data[colIndex] === 'object' &&
            a.data[colIndex].seconds !== 0 &&
            b.data[colIndex].seconds !== 0
          ) {
            const valueA = new Date(a.data[colIndex].seconds * 1000)
              .toISOString()
              .substr(11, 8);
            const valueB = new Date(b.data[colIndex].seconds * 1000)
              .toISOString()
              .substr(11, 8);

            return (valueA < valueB ? -1 : 1) * (order === 'desc' ? 1 : -1);
          }

          return (
            (a.data[colIndex] < b.data[colIndex] ? -1 : 1) *
            (order === 'desc' ? 1 : -1)
          );
        });
      },
      onColumnOrderChange: (newColumnOrder) => {
        setColumnOrder(newColumnOrder);
        if (options.onColumnOrderChange) {
          options.onColumnOrderChange(newColumnOrder);
        }
      },
      customTableBodyFooterRender: function(opts) {
        return (
          <TableBodyFooter
            columnOrder={columnOrder}
            columns={opts.columns}
            columnsWithAmt={footerSettings.columnsWithAmt}
            columnsWithQty={footerSettings.columnsWithQty}
            columnsWithPercentage={footerSettings.columnsWithPercentage}
            rows={rows}
            additionalCell={additionalCell}
            columnsWithAmtDecimal={footerSettings.columnsWithAmtDecimal}
          ></TableBodyFooter>
        );
      },
      onViewColumnsChange: (changedColumn, action, updatedColumns) => {
        if (action === 'add') {
          setVisibleColumns([...visibleColumns, changedColumn]);
        } else if (action === 'remove') {
          let copy = [...visibleColumns];
          let index = copy.indexOf(changedColumn);
          copy.splice(index, 1);
          setVisibleColumns(copy);
        } else {
          setVisibleColumns(
            updatedColumns.reduce((filtered, col) => {
              if (col.name === '') {
                filtered.push(col.name); // buttons
              }

              if (col.display === 'true') {
                filtered.push(col.name);
              }

              return filtered;
            }, [])
          );
        }
      },
      customSearch: (searchQuery, currentRow, cols) => {
        const types = getColumnTypes(cols, columns);
        for (let i = 0; i < currentRow.length; i++) {
          const value = currentRow[i];
          if (!value) continue;
          const clearedValue = value.toString().replace(/(<([^>]+)>)/gi, '');
          if (typeof value === 'string') {
            if (clearedValue.toLowerCase().includes(searchQuery.toLowerCase()))
              return true;
          }
          const formattedValue = getColumnValue(types[i], value);
          const clearedFormattedValue = formattedValue
            .toString()
            .replace(/(<([^>]+)>)/gi, '');
          if (
            clearedFormattedValue
              .toString()
              .toLowerCase()
              .includes(searchQuery.toLowerCase())
          )
            return true;
        }
        return false;
      },
      onDownload: (buildHead, buildBody, cols, data) => {
        const types = getColumnTypes(cols, columns);
        const body = data.map((row) => {
          row.data = row.data.map((value, index) => {
            return getColumnValue(types[index], value);
          });
          return row;
        });
        return '\uFEFF' + buildHead(cols) + buildBody(body).replace(/"'/g, '"');
      },
      customToolbar: function() {
        return (
          <Fragment>
            {getCsvData && (
              <Tooltip title="Download CSV" arrow>
                <IconButton
                  onClick={() => {
                    setDownloadAll(true);
                    setOpenCsvDownload(true);
                  }}
                >
                  <CsvDownloadIcon />
                </IconButton>
              </Tooltip>
            )}
          </Fragment>
        );
      },
    };

    const onTableChange = (action, tableState) => {
      switch (action) {
        case 'changePage':
          setPagination({
            ...pagination,
            pageNo: tableState.page,
            reload: true,
          });
          break;
        case 'changeRowsPerPage': {
          setPagination({
            ...pagination,
            rowsPerPage: tableState.rowsPerPage,
            reload: true,
            pageNo: 0,
          });
          break;
        }
        case 'sort':
          setPagination({
            ...pagination,
            sortDirection: tableState.sortOrder.direction,
            sortName: tableState.sortOrder.name,
            reload: true,
          });
          break;
        case 'filterChange':
          {
            let filterNames = [];
            let filterValues = [];

            let reloadGrid = false;

            tableState.filterList.forEach((item, index) => {
              if (item.length && item.toString() !== '') {
                if (item[0] !== undefined || item[0] !== '') {
                  filterNames.push(tableState.columns[index].name);
                  filterValues.push(item[0]);
                  reloadGrid = true;
                }
              }
            });

            setIsFilterActive(true);

            if (
              tableState.filterList.find(
                (v) => v.length && v.toString() !== ''
              ) !== undefined
            ) {
              setIsFilterActive(true);
            } else {
              setIsFilterActive(false);
            }

            if (pagination.filterNames) {
              if (pagination.filterNames.length === 1) {
                reloadGrid = true;
              }
            }

            //if (rows?.length > 0) {
            setPagination({
              ...pagination,
              sortDirection: tableState.sortOrder.direction,
              sortName: tableState.sortOrder.name,
              filterNames: filterNames,
              filterValues: filterValues,
              reload: reloadGrid,
            });
            //}
          }
          break;
        case 'search':
          if (options.serverSide) {
            setIsFilterActive(true);
            setServerSideSearch(tableState.searchText);

            if (
              tableState.searchText === null ||
              tableState.searchText === '' ||
              tableState.searchText === undefined
            ) {
              setIsFilterActive(false);
            }
          }
          break;
        case 'resetFilters':
          {
            let filter = {};

            tableState.filterList.forEach((item, index) => {
              if (item.length && item.toString() !== '') {
                filter[tableState.columns[index].name] = item[''];
              }
            });

            setFilterList(filter);
            setIsFilterActive(false);
          }
          break;
        default:
      }
    };

    if (options.serverSide) {
      defaultSettings.customSearch = undefined;
      defaultSettings.onDownload = () => {
        // setDownloadAll(false);
        // setOpenCsvDownload(true);
        return false;
      };
      defaultSettings.filterType = 'dropdown';
      defaultSettings.count = pagination.count;
      defaultSettings.page = pagination.pageNo;
      defaultSettings.rowsPerPage = pagination.rowsPerPage;
      defaultSettings.onTableChange = onTableChange;
      defaultSettings.customTableBodyFooterRender = function(opts) {
        if (!footerData) return null;
        return (
          <ServerSideTableBodyFooter
            columnOrder={columnOrder}
            columns={opts.columns}
            columnsWithAmt={footerSettings.columnsWithAmt}
            columnsWithQty={footerSettings.columnsWithQty}
            columnsWithPercentage={footerSettings.columnsWithPercentage}
            rows={rows}
            data={footerData}
            isFilterActive={isFilterActive}
            additionalCell={additionalCell}
          ></ServerSideTableBodyFooter>
        );
      };
    }

    return {
      defaultSettings,
      options,
    };
  },
  (param) => {
    if (!param.options) return param.defaultSettings;

    return { ...param.defaultSettings, ...param.options };
  }
);

const getColumnTypes = (currentColumnSettings, baseColumnSettings) => {
  return currentColumnSettings.map((col) => {
    const columnSetting = getColumnSettingsByName(baseColumnSettings, col.name);
    return columnSetting.type;
  });
};

export const getColumnValue = (type, value) => {
  switch (type) {
    case columnType.date:
      return formatPbDate(value);
    case columnType.dateTime:
      return protoTimeSpanObjectToString(value, 'DD/MM/YYYY hh:mm');
    case columnType.quantity:
      return formatQty(value);
    case columnType.percentage:
      return formatPercentage(value);
    case columnType.rate:
      return formatRate(value);
    case columnType.amount:
      return formatCurrency(value);
    case columnType.amount9Decimal:
      return formatCurrencySymbol(value);
    case columnType.text:
    case '':
    case columnType.buttons:
    case undefined:
      return value;
  }

  console.error('invalid column type');
  return value;
};

const getFooterSettings = createSelector(
  (columns) => columns,
  (columns) =>
    columns.reduce(
      (settings, col) => {
        if (col.addFooter)
          switch (col.type) {
            case columnType.amount:
              settings.columnsWithAmt.push(col.name);
              break;
            case columnType.amount9Decimal:
              settings.columnsWithAmtDecimal.push(col.name);
              break;
            case columnType.percentage:
              settings.columnsWithPercentage.push(col.name);
              break;
            case columnType.quantity:
              settings.columnsWithQty.push(col.name);
              break;
            default:
              console.error(
                "cannot add custom footer, object have invalid 'type' property"
              );
              break;
          }

        return settings;
      },
      {
        columnsWithAmt: [],
        columnsWithQty: [],
        columnsWithPercentage: [],
        columnsWithAmtDecimal: [],
        // additionalCell,
      }
    )
);

const getVisibleColumns = (columns) =>
  columns.reduce((filtered, col) => {
    if (
      col.options === undefined ||
      col.options.display === undefined ||
      col.options.display === true ||
      col.options.display === 'true'
    ) {
      filtered.push(col.name);
    }

    return filtered;
  }, []);

const getColumnOrder = (columns, columnsSettings) =>
  columns.map((col) => {
    return getNewColumnIndex(columnsSettings, col.name);
  });

const getNewColumnIndex = (columnsSettings, name) => {
  for (let i = 0; i < columnsSettings.length; i++) {
    if (columnsSettings[i].name == name) return i;
  }
};

const getColumnSettingsByName = (columnsSettings, name) => {
  const index = getNewColumnIndex(columnsSettings, name);
  return columnsSettings[index];
};

const sortColumnSettings = (columns) =>
  columns.sort(function(a, b) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });

const amountSortCompare = (order) => {
  return (obj1, obj2) => {
    const val1 = parseFloat(obj1.data);
    const val2 = parseFloat(obj2.data);
    return (val1 - val2) * (order === 'asc' ? 1 : -1);
  };
};
