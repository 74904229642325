// source: proto/commonpb/list.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.commonpb.AdmEntryType', null, global);
goog.exportSymbol('proto.commonpb.LazyLoadSecurityRequest', null, global);
goog.exportSymbol('proto.commonpb.LazyLoadSecurityResponse', null, global);
goog.exportSymbol('proto.commonpb.LazySecurity', null, global);
goog.exportSymbol('proto.commonpb.ListAdmEntryTypeRequest', null, global);
goog.exportSymbol('proto.commonpb.ListAdmEntryTypeResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commonpb.LazySecurity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commonpb.LazySecurity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commonpb.LazySecurity.displayName = 'proto.commonpb.LazySecurity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commonpb.LazyLoadSecurityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commonpb.LazyLoadSecurityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commonpb.LazyLoadSecurityRequest.displayName = 'proto.commonpb.LazyLoadSecurityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commonpb.LazyLoadSecurityResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.commonpb.LazyLoadSecurityResponse.repeatedFields_, null);
};
goog.inherits(proto.commonpb.LazyLoadSecurityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commonpb.LazyLoadSecurityResponse.displayName = 'proto.commonpb.LazyLoadSecurityResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commonpb.AdmEntryType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commonpb.AdmEntryType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commonpb.AdmEntryType.displayName = 'proto.commonpb.AdmEntryType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commonpb.ListAdmEntryTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commonpb.ListAdmEntryTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commonpb.ListAdmEntryTypeRequest.displayName = 'proto.commonpb.ListAdmEntryTypeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commonpb.ListAdmEntryTypeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.commonpb.ListAdmEntryTypeResponse.repeatedFields_, null);
};
goog.inherits(proto.commonpb.ListAdmEntryTypeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commonpb.ListAdmEntryTypeResponse.displayName = 'proto.commonpb.ListAdmEntryTypeResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commonpb.LazySecurity.prototype.toObject = function(opt_includeInstance) {
  return proto.commonpb.LazySecurity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commonpb.LazySecurity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commonpb.LazySecurity.toObject = function(includeInstance, msg) {
  var f, obj = {
    symbol: jspb.Message.getFieldWithDefault(msg, 1, ""),
    symbolDescription: jspb.Message.getFieldWithDefault(msg, 2, ""),
    cusip: jspb.Message.getFieldWithDefault(msg, 3, ""),
    isDtcEligible: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    isin: jspb.Message.getFieldWithDefault(msg, 5, ""),
    sedol: jspb.Message.getFieldWithDefault(msg, 6, ""),
    assetType: jspb.Message.getFieldWithDefault(msg, 7, ""),
    originalCusip: jspb.Message.getFieldWithDefault(msg, 8, ""),
    underlyingSymbol: jspb.Message.getFieldWithDefault(msg, 9, ""),
    status: jspb.Message.getFieldWithDefault(msg, 10, ""),
    id: jspb.Message.getFieldWithDefault(msg, 11, 0),
    whenIssue: jspb.Message.getBooleanFieldWithDefault(msg, 12, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commonpb.LazySecurity}
 */
proto.commonpb.LazySecurity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commonpb.LazySecurity;
  return proto.commonpb.LazySecurity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commonpb.LazySecurity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commonpb.LazySecurity}
 */
proto.commonpb.LazySecurity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbolDescription(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCusip(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDtcEligible(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setIsin(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSedol(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetType(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginalCusip(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnderlyingSymbol(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWhenIssue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commonpb.LazySecurity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commonpb.LazySecurity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commonpb.LazySecurity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commonpb.LazySecurity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSymbolDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCusip();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIsDtcEligible();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getIsin();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSedol();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAssetType();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getOriginalCusip();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getUnderlyingSymbol();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      11,
      f
    );
  }
  f = message.getWhenIssue();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
};


/**
 * optional string symbol = 1;
 * @return {string}
 */
proto.commonpb.LazySecurity.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commonpb.LazySecurity} returns this
 */
proto.commonpb.LazySecurity.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string symbol_description = 2;
 * @return {string}
 */
proto.commonpb.LazySecurity.prototype.getSymbolDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.commonpb.LazySecurity} returns this
 */
proto.commonpb.LazySecurity.prototype.setSymbolDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string cusip = 3;
 * @return {string}
 */
proto.commonpb.LazySecurity.prototype.getCusip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.commonpb.LazySecurity} returns this
 */
proto.commonpb.LazySecurity.prototype.setCusip = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool is_dtc_eligible = 4;
 * @return {boolean}
 */
proto.commonpb.LazySecurity.prototype.getIsDtcEligible = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commonpb.LazySecurity} returns this
 */
proto.commonpb.LazySecurity.prototype.setIsDtcEligible = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string isin = 5;
 * @return {string}
 */
proto.commonpb.LazySecurity.prototype.getIsin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.commonpb.LazySecurity} returns this
 */
proto.commonpb.LazySecurity.prototype.setIsin = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string sedol = 6;
 * @return {string}
 */
proto.commonpb.LazySecurity.prototype.getSedol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.commonpb.LazySecurity} returns this
 */
proto.commonpb.LazySecurity.prototype.setSedol = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string asset_type = 7;
 * @return {string}
 */
proto.commonpb.LazySecurity.prototype.getAssetType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.commonpb.LazySecurity} returns this
 */
proto.commonpb.LazySecurity.prototype.setAssetType = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string original_cusip = 8;
 * @return {string}
 */
proto.commonpb.LazySecurity.prototype.getOriginalCusip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.commonpb.LazySecurity} returns this
 */
proto.commonpb.LazySecurity.prototype.setOriginalCusip = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string underlying_symbol = 9;
 * @return {string}
 */
proto.commonpb.LazySecurity.prototype.getUnderlyingSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.commonpb.LazySecurity} returns this
 */
proto.commonpb.LazySecurity.prototype.setUnderlyingSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string status = 10;
 * @return {string}
 */
proto.commonpb.LazySecurity.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.commonpb.LazySecurity} returns this
 */
proto.commonpb.LazySecurity.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional uint32 id = 11;
 * @return {number}
 */
proto.commonpb.LazySecurity.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.commonpb.LazySecurity} returns this
 */
proto.commonpb.LazySecurity.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional bool when_issue = 12;
 * @return {boolean}
 */
proto.commonpb.LazySecurity.prototype.getWhenIssue = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commonpb.LazySecurity} returns this
 */
proto.commonpb.LazySecurity.prototype.setWhenIssue = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commonpb.LazyLoadSecurityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.commonpb.LazyLoadSecurityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commonpb.LazyLoadSecurityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commonpb.LazyLoadSecurityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    field: jspb.Message.getFieldWithDefault(msg, 3, ""),
    assetType: jspb.Message.getFieldWithDefault(msg, 4, ""),
    cusip: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    isActiveOnly: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    isDigitalAssetOnly: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commonpb.LazyLoadSecurityRequest}
 */
proto.commonpb.LazyLoadSecurityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commonpb.LazyLoadSecurityRequest;
  return proto.commonpb.LazyLoadSecurityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commonpb.LazyLoadSecurityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commonpb.LazyLoadSecurityRequest}
 */
proto.commonpb.LazyLoadSecurityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLimit(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setField(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetType(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCusip(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsActiveOnly(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDigitalAssetOnly(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commonpb.LazyLoadSecurityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commonpb.LazyLoadSecurityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commonpb.LazyLoadSecurityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commonpb.LazyLoadSecurityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getField();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAssetType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCusip();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getIsActiveOnly();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getIsDigitalAssetOnly();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.commonpb.LazyLoadSecurityRequest.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commonpb.LazyLoadSecurityRequest} returns this
 */
proto.commonpb.LazyLoadSecurityRequest.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 limit = 2;
 * @return {number}
 */
proto.commonpb.LazyLoadSecurityRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.commonpb.LazyLoadSecurityRequest} returns this
 */
proto.commonpb.LazyLoadSecurityRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string field = 3;
 * @return {string}
 */
proto.commonpb.LazyLoadSecurityRequest.prototype.getField = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.commonpb.LazyLoadSecurityRequest} returns this
 */
proto.commonpb.LazyLoadSecurityRequest.prototype.setField = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string asset_type = 4;
 * @return {string}
 */
proto.commonpb.LazyLoadSecurityRequest.prototype.getAssetType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.commonpb.LazyLoadSecurityRequest} returns this
 */
proto.commonpb.LazyLoadSecurityRequest.prototype.setAssetType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool cusip = 5;
 * @return {boolean}
 */
proto.commonpb.LazyLoadSecurityRequest.prototype.getCusip = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commonpb.LazyLoadSecurityRequest} returns this
 */
proto.commonpb.LazyLoadSecurityRequest.prototype.setCusip = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool is_active_only = 6;
 * @return {boolean}
 */
proto.commonpb.LazyLoadSecurityRequest.prototype.getIsActiveOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commonpb.LazyLoadSecurityRequest} returns this
 */
proto.commonpb.LazyLoadSecurityRequest.prototype.setIsActiveOnly = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool is_digital_asset_only = 7;
 * @return {boolean}
 */
proto.commonpb.LazyLoadSecurityRequest.prototype.getIsDigitalAssetOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commonpb.LazyLoadSecurityRequest} returns this
 */
proto.commonpb.LazyLoadSecurityRequest.prototype.setIsDigitalAssetOnly = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.commonpb.LazyLoadSecurityResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commonpb.LazyLoadSecurityResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.commonpb.LazyLoadSecurityResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commonpb.LazyLoadSecurityResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commonpb.LazyLoadSecurityResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    securitiesList: jspb.Message.toObjectList(msg.getSecuritiesList(),
    proto.commonpb.LazySecurity.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commonpb.LazyLoadSecurityResponse}
 */
proto.commonpb.LazyLoadSecurityResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commonpb.LazyLoadSecurityResponse;
  return proto.commonpb.LazyLoadSecurityResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commonpb.LazyLoadSecurityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commonpb.LazyLoadSecurityResponse}
 */
proto.commonpb.LazyLoadSecurityResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.commonpb.LazySecurity;
      reader.readMessage(value,proto.commonpb.LazySecurity.deserializeBinaryFromReader);
      msg.addSecurities(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commonpb.LazyLoadSecurityResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commonpb.LazyLoadSecurityResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commonpb.LazyLoadSecurityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commonpb.LazyLoadSecurityResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSecuritiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.commonpb.LazySecurity.serializeBinaryToWriter
    );
  }
};


/**
 * repeated LazySecurity securities = 1;
 * @return {!Array<!proto.commonpb.LazySecurity>}
 */
proto.commonpb.LazyLoadSecurityResponse.prototype.getSecuritiesList = function() {
  return /** @type{!Array<!proto.commonpb.LazySecurity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.commonpb.LazySecurity, 1));
};


/**
 * @param {!Array<!proto.commonpb.LazySecurity>} value
 * @return {!proto.commonpb.LazyLoadSecurityResponse} returns this
*/
proto.commonpb.LazyLoadSecurityResponse.prototype.setSecuritiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.commonpb.LazySecurity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.commonpb.LazySecurity}
 */
proto.commonpb.LazyLoadSecurityResponse.prototype.addSecurities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.commonpb.LazySecurity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.commonpb.LazyLoadSecurityResponse} returns this
 */
proto.commonpb.LazyLoadSecurityResponse.prototype.clearSecuritiesList = function() {
  return this.setSecuritiesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commonpb.AdmEntryType.prototype.toObject = function(opt_includeInstance) {
  return proto.commonpb.AdmEntryType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commonpb.AdmEntryType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commonpb.AdmEntryType.toObject = function(includeInstance, msg) {
  var f, obj = {
    entryType: jspb.Message.getFieldWithDefault(msg, 1, ""),
    entryTypeDescription: jspb.Message.getFieldWithDefault(msg, 2, ""),
    screenType: jspb.Message.getFieldWithDefault(msg, 3, ""),
    correspondent: jspb.Message.getFieldWithDefault(msg, 4, ""),
    accountNo: jspb.Message.getFieldWithDefault(msg, 5, ""),
    subAccountNo: jspb.Message.getFieldWithDefault(msg, 6, ""),
    status: jspb.Message.getFieldWithDefault(msg, 7, ""),
    settlementMethod: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commonpb.AdmEntryType}
 */
proto.commonpb.AdmEntryType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commonpb.AdmEntryType;
  return proto.commonpb.AdmEntryType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commonpb.AdmEntryType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commonpb.AdmEntryType}
 */
proto.commonpb.AdmEntryType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntryType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntryTypeDescription(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setScreenType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubAccountNo(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSettlementMethod(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commonpb.AdmEntryType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commonpb.AdmEntryType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commonpb.AdmEntryType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commonpb.AdmEntryType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntryType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEntryTypeDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getScreenType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSubAccountNo();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSettlementMethod();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string entry_type = 1;
 * @return {string}
 */
proto.commonpb.AdmEntryType.prototype.getEntryType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commonpb.AdmEntryType} returns this
 */
proto.commonpb.AdmEntryType.prototype.setEntryType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string entry_type_description = 2;
 * @return {string}
 */
proto.commonpb.AdmEntryType.prototype.getEntryTypeDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.commonpb.AdmEntryType} returns this
 */
proto.commonpb.AdmEntryType.prototype.setEntryTypeDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string screen_type = 3;
 * @return {string}
 */
proto.commonpb.AdmEntryType.prototype.getScreenType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.commonpb.AdmEntryType} returns this
 */
proto.commonpb.AdmEntryType.prototype.setScreenType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string correspondent = 4;
 * @return {string}
 */
proto.commonpb.AdmEntryType.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.commonpb.AdmEntryType} returns this
 */
proto.commonpb.AdmEntryType.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string account_no = 5;
 * @return {string}
 */
proto.commonpb.AdmEntryType.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.commonpb.AdmEntryType} returns this
 */
proto.commonpb.AdmEntryType.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string sub_account_no = 6;
 * @return {string}
 */
proto.commonpb.AdmEntryType.prototype.getSubAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.commonpb.AdmEntryType} returns this
 */
proto.commonpb.AdmEntryType.prototype.setSubAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string status = 7;
 * @return {string}
 */
proto.commonpb.AdmEntryType.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.commonpb.AdmEntryType} returns this
 */
proto.commonpb.AdmEntryType.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string settlement_method = 8;
 * @return {string}
 */
proto.commonpb.AdmEntryType.prototype.getSettlementMethod = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.commonpb.AdmEntryType} returns this
 */
proto.commonpb.AdmEntryType.prototype.setSettlementMethod = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commonpb.ListAdmEntryTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.commonpb.ListAdmEntryTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commonpb.ListAdmEntryTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commonpb.ListAdmEntryTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    entryType: jspb.Message.getFieldWithDefault(msg, 1, ""),
    screenType: jspb.Message.getFieldWithDefault(msg, 2, ""),
    correspondent: jspb.Message.getFieldWithDefault(msg, 3, ""),
    accountNo: jspb.Message.getFieldWithDefault(msg, 4, ""),
    status: jspb.Message.getFieldWithDefault(msg, 6, ""),
    note: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commonpb.ListAdmEntryTypeRequest}
 */
proto.commonpb.ListAdmEntryTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commonpb.ListAdmEntryTypeRequest;
  return proto.commonpb.ListAdmEntryTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commonpb.ListAdmEntryTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commonpb.ListAdmEntryTypeRequest}
 */
proto.commonpb.ListAdmEntryTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntryType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setScreenType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commonpb.ListAdmEntryTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commonpb.ListAdmEntryTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commonpb.ListAdmEntryTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commonpb.ListAdmEntryTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntryType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getScreenType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string entry_type = 1;
 * @return {string}
 */
proto.commonpb.ListAdmEntryTypeRequest.prototype.getEntryType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commonpb.ListAdmEntryTypeRequest} returns this
 */
proto.commonpb.ListAdmEntryTypeRequest.prototype.setEntryType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string screen_type = 2;
 * @return {string}
 */
proto.commonpb.ListAdmEntryTypeRequest.prototype.getScreenType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.commonpb.ListAdmEntryTypeRequest} returns this
 */
proto.commonpb.ListAdmEntryTypeRequest.prototype.setScreenType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string correspondent = 3;
 * @return {string}
 */
proto.commonpb.ListAdmEntryTypeRequest.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.commonpb.ListAdmEntryTypeRequest} returns this
 */
proto.commonpb.ListAdmEntryTypeRequest.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string account_no = 4;
 * @return {string}
 */
proto.commonpb.ListAdmEntryTypeRequest.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.commonpb.ListAdmEntryTypeRequest} returns this
 */
proto.commonpb.ListAdmEntryTypeRequest.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string status = 6;
 * @return {string}
 */
proto.commonpb.ListAdmEntryTypeRequest.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.commonpb.ListAdmEntryTypeRequest} returns this
 */
proto.commonpb.ListAdmEntryTypeRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string note = 7;
 * @return {string}
 */
proto.commonpb.ListAdmEntryTypeRequest.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.commonpb.ListAdmEntryTypeRequest} returns this
 */
proto.commonpb.ListAdmEntryTypeRequest.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.commonpb.ListAdmEntryTypeResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commonpb.ListAdmEntryTypeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.commonpb.ListAdmEntryTypeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commonpb.ListAdmEntryTypeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commonpb.ListAdmEntryTypeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    entryTypesList: jspb.Message.toObjectList(msg.getEntryTypesList(),
    proto.commonpb.AdmEntryType.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commonpb.ListAdmEntryTypeResponse}
 */
proto.commonpb.ListAdmEntryTypeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commonpb.ListAdmEntryTypeResponse;
  return proto.commonpb.ListAdmEntryTypeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commonpb.ListAdmEntryTypeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commonpb.ListAdmEntryTypeResponse}
 */
proto.commonpb.ListAdmEntryTypeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.commonpb.AdmEntryType;
      reader.readMessage(value,proto.commonpb.AdmEntryType.deserializeBinaryFromReader);
      msg.addEntryTypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commonpb.ListAdmEntryTypeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commonpb.ListAdmEntryTypeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commonpb.ListAdmEntryTypeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commonpb.ListAdmEntryTypeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntryTypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.commonpb.AdmEntryType.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AdmEntryType entry_types = 1;
 * @return {!Array<!proto.commonpb.AdmEntryType>}
 */
proto.commonpb.ListAdmEntryTypeResponse.prototype.getEntryTypesList = function() {
  return /** @type{!Array<!proto.commonpb.AdmEntryType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.commonpb.AdmEntryType, 1));
};


/**
 * @param {!Array<!proto.commonpb.AdmEntryType>} value
 * @return {!proto.commonpb.ListAdmEntryTypeResponse} returns this
*/
proto.commonpb.ListAdmEntryTypeResponse.prototype.setEntryTypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.commonpb.AdmEntryType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.commonpb.AdmEntryType}
 */
proto.commonpb.ListAdmEntryTypeResponse.prototype.addEntryTypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.commonpb.AdmEntryType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.commonpb.ListAdmEntryTypeResponse} returns this
 */
proto.commonpb.ListAdmEntryTypeResponse.prototype.clearEntryTypesList = function() {
  return this.setEntryTypesList([]);
};


goog.object.extend(exports, proto.commonpb);
