import React, { useEffect } from 'react';
import { TextField, CircularProgress } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { listSystemCode } from '../../services/SystemCodeService';

export default function SelectSystemCode(props) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;

  useEffect(() => {
    let active = true;

    if (!loading) {
      return;
    }

    (async () => {
      if (active) {
        const { systemCodeList } = await listSystemCode({
          type: props.type,
        });

        if (props.type === 'Legal Entity') {
          const uniqueSystemCode = Array.from(
            systemCodeList
              .reduce((map, systemCode) => {
                if (systemCode.note2 !== 'NULL') {
                  map.set(systemCode.description, systemCode);
                }
                return map;
              }, new Map())
              .values()
          );
          setOptions(uniqueSystemCode);
        } else {
          setOptions(systemCodeList);
        }
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  return (
    <Autocomplete
      name={props.name}
      autoHighlight={true}
      blurOnSelect={false}
      autoComplete={true}
      autoSelect={false}
      clearOnEscape={true}
      id={props.id}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionSelected={(option, value) =>
        option.description === value.description
      }
      getOptionLabel={(option) => option.description || ''}
      options={options}
      loading={loading}
      value={{
        description: props.value || '',
      }}
      onChange={(_, selectedValue) => {
        props.onChange({
          currentTarget: {
            name: props.name,
            value: selectedValue ? selectedValue.description : '',
            note2: selectedValue ? selectedValue.note2 : '',
            code: selectedValue ? selectedValue.code : '',
          },
        });
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          name={props.name}
          required
          labelid={props.labelid}
          label={props.label}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
